import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@core/shared/services/api.service';
import {Observable} from 'rxjs';
import {FormService} from '@core/shared/services/form.service';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Pagination} from '@core/shared/models/pagination';
import {OfferAttributeService} from '@core/shared/services/offer-attribute.service';
import {ActivatedRoute} from '@angular/router';
import {OfferAttributeType} from '@core/shared/models/offer-attribute-type';
import {OfferAttribute, OfferAttributeTranslation} from '@core/shared/models/offer-attribute';
import {TranslationBuilder} from "@core/shared/models/translation";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
  selector: 'app-page-offer-update',
  templateUrl: './page-offer-attribute-update.component.html',
  styleUrls: ['./page-offer-attribute-update.component.scss'],
    providers: [
        FormService
    ]
})
export class PageOfferAttributeUpdateComponent implements OnInit {

    public characteristics$: Observable<OfferAttributeType[]>;

    public locale: any;

    public idAttribute: number;

    public translationBuilder: TranslationBuilder;

    constructor(
      private _snackBar: MatSnackBar,
      private _router: Router,
      private _formBuilder: UntypedFormBuilder,
      private _translateService: TranslateService,
      private _offerAttributeService: OfferAttributeService,
      private _apiService: ApiService,
      private _activatedRoute: ActivatedRoute,
      public formService: FormService,
      public translationService: TranslationService
  ) { }

  ngOnInit(): void {

      this._loadCharacteristics();

      this._initForm();

      this._initTranslationsForm();

      this._activatedRoute.data.subscribe((data: {offer: OfferAttribute}): void => {

          this.form.patchValue(data.offer);

          this.typeForm.patchValue(data.offer.type);

          this._hydrateTranslations(data.offer);
      });

      this._activatedRoute.params.subscribe(params => {

          this.idAttribute = params['id'];
      });

  }

    private _loadCharacteristics(): void {
        this.characteristics$ = this._offerAttributeService.getPaginationItemType().pipe(
            map((response: Pagination<OfferAttributeType>): OfferAttributeType[] => {
                return response.items;
            })
        );
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            translations: new UntypedFormArray([]),
            type: this._formBuilder.group({
                id: [{ value: null, disabled: true }, Validators.required]
            })
        });

        this.formService.submitCallback = (): void => {


            this._offerAttributeService.updateItemAPI(this.idAttribute, this.form.value).subscribe((): void => {
                this._snackBar.open(this._translateService.instant('offer.attribute.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            })
        }
    }

    private _hydrateTranslations(item: OfferAttribute): void {

        item.translations.forEach((translation: OfferAttributeTranslation): void => {

            const group: UntypedFormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                label: ['', [Validators.required]]
            });
        };
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer/attribute/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get typeForm(): UntypedFormGroup {

        return this.form.get('type') as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
