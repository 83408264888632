import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {fromEvent, ReplaySubject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "@core/shared/services/api.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {FormService} from "@core/shared/services/form.service";
import {debounceTime, map} from "rxjs/operators";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {MatOption} from "@angular/material/core";
import {Pagination} from "@core/shared/models/pagination";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";

@Component({
    selector: 'app-core-network-offer-creator-collection-select',
    templateUrl: './network-offer-creator-collection-select.component.html',
    styleUrls: ['./network-offer-creator-collection-select.component.scss']
})
export class NetworkOfferCreatorCollectionSelectComponent implements OnInit, AfterViewInit {

    @Input() parentForm: UntypedFormGroup;

    @Input() formName: string;

    @ViewChild('nameFilter', { static: true }) nameFilterReference: MatSelectSearchComponent;

    public choices: NetworkOfferCreator[] = [];

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public filteredOptions: ReplaySubject<NetworkOfferCreator[]> = new ReplaySubject<NetworkOfferCreator[]>(1);

    public valuesOptions: NetworkOfferCreator[] = [];

    public searchForm: UntypedFormGroup = new UntypedFormGroup({
        selection: new UntypedFormControl([])
    });

    constructor(
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        public translationService: TranslationService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'focus').subscribe((): void => {

            this.filteredOptions.next([]);

            this.searchForm.get('selection').patchValue([]);
        });

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();
            })
        ;
    }

    private _initValues(): void {

        this.valuesOptions = this.choices;

        this.filteredOptions.next(this.valuesOptions.slice());

        this.searchForm.get('selection').patchValue([...this.selectedItems]);
    }

    private _filterOptions(): void {

        const params: string[] = this.itemsApiParams;

        const filterBuilder: FilterBuilder = new FilterBuilder();

        if(this.optionFilterNameCtrl.value && this.optionFilterNameCtrl.value.length){

            filterBuilder.addField(new TextFilterField('name', 'lk', this.optionFilterNameCtrl.value.toLowerCase()));
        }

        if(filterBuilder.hasFilters){

            params.push(filterBuilder.serializedRequest);
        }

        this._networkOfferCreatorService.getPaginationItemsAPI(params).pipe(map(this.mapApiResult)).subscribe((items: NetworkOfferCreator[]): void => {

            this.choices = items;

            this._initValues();
        });
    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if(element){

            element.onclick = (): void => {

                this.optionFilterNameCtrl.patchValue('');

                this.filteredOptions.next([]);

                this.searchForm.get('selection').patchValue([]);
            };
        }
    }

    public handleClosure(): void {

        this.optionFilterNameCtrl.patchValue('');

        this.filteredOptions.next([]);

        this.searchForm.get('selection').patchValue([]);
    }

    public compareItems(a: NetworkOfferCreator, b: NetworkOfferCreator): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public handleItemSelection(option: MatOption): void {

        const item: NetworkOfferCreator = option.value;

        if(option.selected){

            this.addItemSelection(item);
        }
        else{

            this.removeItemSelection(item);
        }
    }

    public addItemSelection(toAddItem: NetworkOfferCreator): void {

        const items: NetworkOfferCreator[] = [...this.control.value];

        items.push(toAddItem);

        this.control.patchValue(items);
    }

    public removeItemSelection(toDeleteItem: NetworkOfferCreator): void {

        const items: NetworkOfferCreator[] = [...this.control.value];

        const index: number = items.findIndex((item: NetworkOfferCreator): boolean => {

            return item.id === toDeleteItem.id;
        });

        items.splice(index, 1);

        this.control.patchValue(items);
    }

    get form(): UntypedFormGroup {

        return this.parentForm;
    }

    get control(): UntypedFormGroup {

        return this.form.get(this.formName) as UntypedFormGroup;
    }

    get selectedItems(): NetworkOfferCreator[] {

        return this.form.get(this.formName).value as NetworkOfferCreator[];
    }

    get itemsApiParams(): string[] {

        return [
            `page=1`,
            `limit=10`,
            `sort[name]=ASC`
        ];
    }

    get mapApiResult(): (data: Pagination<NetworkOfferCreator>) => NetworkOfferCreator[] {

        return (data: Pagination<NetworkOfferCreator>): NetworkOfferCreator[] => {

            return data.items
        }
    }
}
