import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '@core/shared/services/form.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Pagination} from '@core/shared/models/pagination';
import {OfferAttributeType} from '@core/shared/models/offer-attribute-type';
import {OfferAttributeService} from '@core/shared/services/offer-attribute.service';
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from '@core/shared/models/translation';
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-page-offer-create',
    templateUrl: './page-offer-attribute-create.component.html',
    styleUrls: ['./page-offer-attribute-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageOfferAttributeCreateComponent implements OnInit {

    public characteristics$: Observable<OfferAttributeType[]>;

    public locale: any;

    public translationBuilder: TranslationBuilder;

    constructor(
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _offerAttributeService: OfferAttributeService,
        public formService: FormService,
        public translationService: TranslationService
    ) {}

    ngOnInit(): void {

        this._loadCharacteristics();

        this._initForm();

        this._initTranslationsForm();

        this._addLocaleControls();
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                label: ['', [Validators.required]]
            });
        };
    }

    private _addLocaleControls(): void {

        LOCALE_ITEMS.forEach((localeItem: LocaleItem): void => {

            this.translationBuilder.addItemControl(localeItem);
        });
    }
    private _loadCharacteristics(): void {

        this.characteristics$ = this._offerAttributeService.getPaginationItemType().pipe(
            map((response: Pagination<OfferAttributeType>): OfferAttributeType[] => {

                return response.items;
            })
        );
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            translations: new UntypedFormArray([]),
            type: this._formBuilder.group({
                id: ['', Validators.required]
            })
        });

        this.formService.submitCallback = (): void => {

            this.typeForm.get('id').patchValue(Number(this.typeForm.get('id').value));

            this._offerAttributeService.createItemAPI(this.form.value).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('offer.attribute.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        }
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer/attribute/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get typeForm(): UntypedFormGroup {

        return this.form.get('type') as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get locales(): LocaleItem[] {

        return LOCALE_ITEMS;
    }
}
