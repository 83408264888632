import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {Data, ICmsParams} from "@lib/cms/cms";
import {ApiService} from "@core/shared/services/api.service";
import {Page} from "@core/shared/models/page";
import {ActivatedRoute, Router} from "@angular/router";
import {PageTranslation} from "@core/shared/models/page-translation";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {PageService} from "@core/shared/services/page.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Society} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {FieldCollection} from "@lib/form/field";
import {Observable, of} from "rxjs";

@Component({
    selector: 'app-page-update',
    templateUrl: './page-update.component.html',
    styleUrls: ['./page-update.component.scss'],
    providers: [
        FormService
    ],
})
export class PageUpdateComponent implements OnInit {

    public data: Data;

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public locales$: Observable<LocaleItem[]>;

    public cmsParams: ICmsParams = {
        context: 'cms_content_page',
        blocLabel: 'Bloc Label',
        ckeditor: {
            editor: this.editor
        },
        customButtons: [],
        entityName: 'Page',
        enableMultilingual: true,
        mode: 'edit',
        tag: 'tag',
        entityFieldName: 'content',
        parentContainer: document.getElementById('admin-sidenav-content'),
        apiUrl: `${this._apiService.getApiUrl(false, true)}`,
        optionsImage: {
            enableTitle: true,
            enableSubtitle: true,
            enableAlt: true,
            enableLink: true,
            enableTargetBlank: true
        }
    };

    public societies: Object[] = [];

    public roles : string[] = [];

    public initialTarget : string;

    public fieldCollection = new FieldCollection();

    public constructor(private _translateService: TranslateService, private _snackBar: MatSnackBar, public pageService: PageService, private _router: Router, private _formBuilder: UntypedFormBuilder, public formService: FormService, private _apiService: ApiService, private _activatedRoute: ActivatedRoute) {

        this._buildForm();
    }

    public ngOnInit(): void {

        this._initLocales();
        this._initTranslationsForm();

        this._activatedRoute.data.subscribe((data: { page: Page, societies: Society[] }): void => {

            this.roles = data.page.roles;

            const pageDatas: any = {
                translations: [],
                published: data.page.published,
                type_user: data.page.type_user,
                roles: data.page.roles,
                target: (data.page.target) ? data.page.target.toString() :  null,
                societies: [],
                content: data.page.data.content,
                locales: data.page.locales
            };

            this.initialTarget = (data.page.target) ? data.page.target.toString() :  null;

            data.page.societies.forEach((society: Society) => {

                pageDatas.societies.push(society.id);
            });

            data.page.translations.forEach((translation: PageTranslation) => {

                pageDatas.translations.push({
                    locale: translation.locale,
                    id: translation.id,
                    titre: translation.titre,
                    description: translation.description,
                    picture: translation.picture
                });
            });

            pageDatas.translations.forEach((translation: PageTranslation): void => {

                const group: UntypedFormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);

                group.addControl('id', this._formBuilder.control(translation.id));
            });

            data.societies.forEach((society: Society) => {

                this.societies.push({
                    id: society.id,
                    name: society.name
                });
            });

            this.form.patchValue(pageDatas);

            this.data = data.page.data;
        });

        this.form.get('locales').valueChanges.subscribe((): void => {
            this._handleTranslationControls();
            this.form.get('locales').value.forEach((locale) => {
                var exist = this.data.content.translations.find((item) => {
                    return item.locale == locale
                });

                if (!exist) {
                    this.data.content.translations.push({"id": null, "locale": locale, "rows": []});
                }
            });

            this.data.content.translations.forEach((item, index) => {
                if (!this.form.get('locales').value.includes(item.locale)) {
                    this.data.content.translations.splice(index, 1)
                }
            });
        });
        this.formService.submitCallback = (): void => {

            this.pageService.updateItemAPI(this._activatedRoute.snapshot.params['id'], this.form.value).subscribe((): void => {
                this._snackBar.open(this._translateService.instant('page.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
                this.redirectToList();
            });
        };
    }

    private _initLocales(): void {
        this.locales$ = of(LOCALE_ITEMS);
    }
    public patchRolesValue(role): void {

        if(this.roles.indexOf(role) > -1){

            const index: number = this.roles.indexOf(role);

            this.roles.splice(index, 1);

        }
        else{

            this.roles.push(role);
        }

        this.form.get('roles').setValue(this.roles);
    }

    public changeSocieties(event): void {

        this.form.get('societies').patchValue(event.value);
    }

    private _buildForm(): void {

        this.formService.form = this._formBuilder.group({
            published: [''],
            content: [''],
            type_user: ['role', Validators.required],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            target: [['_self'], Validators.required],
            locales: [[], [Validators.required]],
            translations: new UntypedFormArray([]),
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'target',
                attrs: {
                    required: true,
                    placeholder: this._translateService.instant('page.target.value'),
                    choices: [
                        {
                            '_self': 'Non',
                            '_blank': 'Oui'
                        }
                    ]
                }
            }
        });
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                titre: ['', [Validators.required]],
                description: ['', [Validators.required]],
                picture: this._formBuilder.group({
                    copyright: [''],
                    image: this._formBuilder.group({
                        image: ['', [Validators.required]]
                    })
                })
            });
        };
    }

    public redirectToList(): void {

        this._router.navigate(['page/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    get imageConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'card_contenu',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            },
        }
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            hasFontColor: true,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {

        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get selectedLocales(): string[] {
        if(!this.form || !this.form.get('locales')){
            return [];
        }

        return this.form.get('locales').value;
    }

    private _handleTranslationControls(): void {

        const locales: string[] = this.form.get('locales').value;

        if(!this.translationBuilder){

            return;
        }


        this.translationBuilder.updateItems(locales);
    }
}
