import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Cropper from "cropperjs";
import {CropperDialogData, CropperDialogResult} from "./cropper-dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    templateUrl: './cropper-dialog.component.html',
    styleUrls: ['./cropper-dialog.component.scss']
})
export class CropperDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('image', {static: false}) public imageElement: ElementRef;

    private _cropper: Cropper;

    public initialized: boolean = false;

    /**
     * @param dialogRef
     * @param data
     */
    constructor(
        public dialogRef: MatDialogRef<CropperDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CropperDialogData) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {

        const that = this;

        const defaultCropperOptions: Cropper.Options = {
            autoCrop: true,
            zoomable: true,
            rotatable: false,
            scalable: false,
            aspectRatio: 1,
            ready(): void {
                that.initialized = true;
            }
        };

        this._cropper = new Cropper(this.imageElement.nativeElement, Object.assign(defaultCropperOptions, this.data.options));
    }

    ngOnDestroy(): void {

        this._cropper.destroy();
    }

    /**
     * @param dragMode
     */
    setDragMode(dragMode: Cropper.DragMode): void {

        this._cropper.setDragMode(dragMode);
    }

    /**
     * @param ratio
     */
    zoom(ratio: number): void {

        this._cropper.zoom(ratio);
    }

    cancel(): void {

        const result: CropperDialogResult = {
            apply: false,
            data: null
        };

        this.dialogRef.close(result);
    }

    confirm(): void {

        const result: CropperDialogResult = {
            apply: true,
            data: this._cropper.getData()
        };

        this.dialogRef.close(result);
    }
}
