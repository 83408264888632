import { Component, OnInit } from '@angular/core';
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {GiftVoucher, GiftVoucherStatus} from "@core/shared/models/gift-voucher/gift-voucher";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {UserService} from "@core/shared/services/user.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Role} from "@core/shared/models/role";
import {Access} from "@core/shared/models/access";
import * as moment from "moment";
import {OfferIncludedTranslation} from "@core/shared/models/offer/offer-included-translation";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {parseMarkup} from "@core/shared/utils/markup";
import {GiftVoucherOffer} from "@core/shared/models/gift-voucher/gift-voucher-offer";
import {Observable, of} from "rxjs";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";

@Component({
    selector: 'app-page-gift-voucher-read',
    templateUrl: './page-gift-voucher-read.component.html',
    styleUrls: ['./page-gift-voucher-read.component.scss'],
    providers: [ FormService ]
})
export class PageGiftVoucherReadComponent implements OnInit {

    public user: User;

    public society: Society;

    public giftVoucher: GiftVoucher;

    public offer: GiftVoucherOffer;

    public status: { id: GiftVoucherStatus, label: string }[];

    public currentCollaborator: User = null;

    public collaboratorSearchConfiguration: SelectSearchConfiguration<User>;

    public collaboratorSearchSourceCallback: (search: string) => Observable<User[]>;

    constructor(
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _giftVoucherService: GiftVoucherService,
        private _formBuilder: UntypedFormBuilder,
        public formService: FormService,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit(): void {

        this.user = this.userService.currentUser.value;

        this._activatedRoute.data.subscribe((data : {giftVoucher: GiftVoucher, society: Society}) => {

            this.society = data.society;

            this.giftVoucher = data.giftVoucher;

            this.offer = data.giftVoucher.isDetached ? data.giftVoucher.offerData : data.giftVoucher.offer;

            this._loadStatus();

            this._initForm();

            this.form.patchValue(Object.assign({...this.giftVoucher}, {}));

            this._initCollaborators();

            this._initCurrentCollaborator();

            this._initEvents();
        });

    }

    private _loadStatus(): void {

        this.status = [
            {
                id: 'not-booked',
                label: this._translateService.instant('giftVoucher.status.not-booked.value')
            },
            {
                id: 'booked',
                label: this._translateService.instant('giftVoucher.status.booked.value')
            },
            {
                id: 'expired',
                label: this._translateService.instant('giftVoucher.status.expired.value')
            },
            {
                id: 'canceled',
                label: this._translateService.instant('giftVoucher.status.canceled.value')
            }
        ];

        if(this.isForwarded){

            this.status.push( {
                id: 'forwarded',
                label: this._translateService.instant('giftVoucher.status.forwarded.value')
            });
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            status: [{value: '', disabled: (!this.isUpdateAllowed || !this.isOfferOwner || this.isForwarded ) }, [Validators.required]],
            comment: [{value: '', disabled: (!this.isUpdateAllowed || !this.isOfferOwner) }],
        });

        this.form.addControl('responsibleUser', this._formBuilder.control({value: '', disabled: (!this.isUpdateCollaboratorAllowed || !this.isInCommercialRelationshipCharge)}));

        this.formService.submitCallback = () => {

            const data: object = Object.assign(this.form.getRawValue(), {});

            this._giftVoucherService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe(() => {

                this._snackBar.open(this._translateService.instant('giftVoucher.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });

        };

    }

    private _initEvents(): void {

        this.form.get('responsibleUser').valueChanges.subscribe((value: number|null): void => {

            this._initCurrentCollaborator();
        });
    }

    private _translatedInclusionItemsMap(items: OfferIncluded[]): OfferIncludedTranslation[] {

        return items.map((included: OfferIncluded): OfferIncludedTranslation => {

            return this.translationService.getFallbackTranslation(included.translations);
        });
    }

    private _initCollaborators(): void {

        this.collaboratorSearchConfiguration = {
            multiple: false,
            currentSelectionLabel: 'collaborator.selection.current.value',
            searchActionLabel: 'collaborator.search.action.value',
            selectOptionActionLabel: 'collaborator.option.select.action.value',
            itemLabel: (item: User): string => {

                return `${item.firstName} ${item.lastName}${item.service && item.service.length ? `- ${item.service}` : ''}`;
            }
        }

        this.collaboratorSearchSourceCallback = (search: string): Observable<User[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if(search && search.length){

                params.push(...[
                    `fullName[lk]=${search}`
                ]);
            }

            if(this.isInCommercialRelationshipCharge){

                return this.userService.getPaginationItemSocietyUsers(this.user.id, params).pipe(map((pagination: Pagination<User>): User[] => {

                    return pagination.items;
                }));
            }
            else{

                return of(this.giftVoucher.responsibleUser ? [this.giftVoucher.responsibleUser] : []);
            }
        }
    }

    private _initCurrentCollaborator(): void {

        this.currentCollaborator = this.form.get('responsibleUser').value || null;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/gift-voucher/list']);
    }

    public downloadItem(): void {

        this._giftVoucherService.downloadItemAPI(this.giftVoucher.id).subscribe((blob) => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            a.href = objectUrl;

            a.download = `${this._translateService.instant('giftVoucher.download.file.title.value')}_${this.giftVoucher.id}_${moment(this.giftVoucher.createdAt).format('DD-MM-YYYY')}.pdf`;

            a.click();

            URL.revokeObjectURL(objectUrl);

        });

    }

    public parsedMarkup(value: number): string {

        return !!value ? (parseMarkup(value) + '%') : '';
    }

    public isAdmin(): boolean {

        return this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    }

    get isInCommercialRelationshipCharge(): boolean {

        if(!this.society || !this.giftVoucher){

            return false;
        }

        return this.society.id === this.giftVoucher.offerOwnerSociety?.id;
    }

    get isUpdateAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'GIFT_VOUCHER_EDIT_IS_MINE';
        });
    }

    get isUpdateCollaboratorAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'GIFT_VOUCHER_EDIT_RESPONSIBLE_USER_IS_MINE';
        });
    }

    get isOfferOwner(): boolean {

        if(!this.society || !this.giftVoucher){

            return false;
        }

        return this.society.id === this.giftVoucher.offerOwnerSociety?.id;
    }

    get translatedIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.offer.included.filter((included: OfferIncluded): boolean => {

            return included.isIncluded;
        });

        return this._translatedInclusionItemsMap(matches);
    }

    get translatedNotIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.offer.included.filter((included: OfferIncluded): boolean => {

            return !included.isIncluded;
        });

        return this._translatedInclusionItemsMap(matches);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isForwarded(): boolean {

        return !!this.giftVoucher.transferredGiftVoucher;
    }

    get currencySymbol(): string {

        if (!this.giftVoucher.channel || !this.giftVoucher.channel.currency) {

            return '€';
        }

        return this.giftVoucher.channel.currency.symbol;
    }

    get originUrl(): string {

        return window.location.origin;
    }

    get renewalAmountHT(): number {

        return this.giftVoucher.customerRenewalPrice / 100;
    }

    get renewalAmountTTC(): number {

        return this.giftVoucher.customerRenewalPriceTTC / 100;
    }
}
