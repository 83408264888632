import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from '@angular/material/snack-bar';
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";

@Component({
    selector: 'page-offer-group-network-provider-list',
    templateUrl: './page-offer-group-network-provider-list.component.html',
    styleUrls: ['./page-offer-group-network-provider-list.component.scss']
})
export class PageOfferGroupNetworkProviderListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'label',
        'comment',
        'createdAt',
        'updatedAt'
    ];

    filterBuilder: FilterBuilder;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public items: OfferGroupNetworkProvider[];


    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _offerNetworkProviderService: OfferNetworkProviderService,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();

    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._offerNetworkProviderService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: OfferGroupNetworkProvider[]): void => {

            this.items = data;
        })
        ;
    }


    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._offerNetworkProviderService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: OfferGroupNetworkProvider[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._offerNetworkProviderService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferGroupNetworkProvider[]): void => {

            this.items = data;
        });
    }



    public edit(id: number): void {

        this._router.navigate(['account/offer/network-provider/update/', id]);
    }

    public openDeleteItemDialog(id: number): void {

        const network: OfferGroupNetworkProvider = this.items.find((item: OfferGroupNetworkProvider): boolean => {

            return item.id === id;
        });


        const title : string = this._translateService.instant('networkProvider.actions.delete.value');

        const content : string = this._translateService.instant('networkProvider.actions.delete.content.value', {
            network: network.label
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._offerNetworkProviderService.deleteItemAPI(network.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('networkProvider.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._offerNetworkProviderService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferGroupNetworkProvider[]): void => {
                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/offer/network-provider/create']);
    }

    public getModel(item: OfferGroupNetworkProvider): OfferGroupNetworkProvider {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<OfferGroupNetworkProvider>) => OfferGroupNetworkProvider[] {

        return (data: Pagination<OfferGroupNetworkProvider>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {
        return DEFAULT_PAGE_SIZE_OPTIONS;
    }


    get localeId(): string {
        return this._translateService.currentLang;
    }
}
