import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {Society} from "@core/shared/models/society";
import {fromEvent, ReplaySubject} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "@core/shared/services/api.service";
import {SocietyService} from "@core/shared/services/society.service";
import {CountryService} from "@core/shared/services/country.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {FormService} from "@core/shared/services/form.service";
import {debounceTime, map} from "rxjs/operators";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {Address} from "@core/shared/models/address";
import {MatOption} from "@angular/material/core";
import {Pagination} from "@core/shared/models/pagination";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";

@Component({
    selector: 'app-core-network-offer-creator-society-collection-select',
    templateUrl: './network-offer-creator-society-collection-select.component.html',
    styleUrls: ['./network-offer-creator-society-collection-select.component.scss']
})
export class NetworkOfferCreatorSocietyCollectionSelectComponent implements OnInit, AfterViewInit {

    @Input() parentForm: UntypedFormGroup;

    @Input() formName: string;

    @ViewChild('nameFilter', { static: true }) nameFilterReference: MatSelectSearchComponent;

    public countries: Country[];

    public choices: Society[] = [];

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public filteredOptions: ReplaySubject<Society[]> = new ReplaySubject<Society[]>(1);

    public valuesOptions: Society[] = [];

    public searchForm: UntypedFormGroup = new UntypedFormGroup({
        selection: new UntypedFormControl([])
    });

    constructor(
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _apiService: ApiService,
        private _societyService: SocietyService,
        private _countryService: CountryService,
        public translationService: TranslationService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initCountries();
    }

    ngAfterViewInit(): void {

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'focus').subscribe((): void => {

            this.filteredOptions.next([]);

            this.searchForm.get('selection').patchValue([]);
        });

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();
            })
        ;
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

            this.countries = countries;
        });
    }

    private _initValues(): void {

        this.valuesOptions = this.choices;

        this.filteredOptions.next(this.valuesOptions.slice());

        this.searchForm.get('selection').patchValue([...this.selectedItems]);
    }

    private _filterOptions(): void {

        const params: string[] = this.itemsApiParams;

        const filterBuilder: FilterBuilder = new FilterBuilder();

        const roleFilter: ArrayFilterField = new ArrayFilterField('admin.roles', 'andlkin', 'ROLE_OFFER_CREATOR');

        params.push(roleFilter.serialize);

        if(this.optionFilterNameCtrl.value && this.optionFilterNameCtrl.value.length){

            filterBuilder.addField(new TextFilterField('name', 'lk', this.optionFilterNameCtrl.value.toLowerCase()));
        }

        if(filterBuilder.hasFilters){

            params.push(filterBuilder.serializedRequest);
        }

        this._societyService.getPaginationItemsAPI(params).pipe(map(this.mapApiResult)).subscribe((items: Society[]): void => {

            this.choices = items;

            this._initValues();
        });
    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if(element){

            element.onclick = (): void => {

                this.optionFilterNameCtrl.patchValue('');

                this.filteredOptions.next([]);

                this.searchForm.get('selection').patchValue([]);
            };
        }
    }

    public handleClosure(): void {

        this.optionFilterNameCtrl.patchValue('');

        this.filteredOptions.next([]);

        this.searchForm.get('selection').patchValue([]);
    }

    public getMailingAddress(item: Society): Address {

        return item.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';
        });
    }

    public getCountryTranslation(society: Society): CountryTranslation {

        const item: Country = this.countries?.find((country: Country): boolean => {

            return country.code === this.getMailingAddress(society).country;
        });

        if(!item){

            return null;
        }

        return this.translationService.getFallbackTranslation(item.translations);
    }

    public compareItems(a: Society, b: Society): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    public handleItemSelection(option: MatOption): void {

        const item: Society = option.value;

        if(option.selected){

            this.addItemSelection(item);
        }
        else{

            this.removeItemSelection(item);
        }
    }

    public addItemSelection(toAddItem: Society): void {

        const items: Society[] = [...this.control.value];

        items.push(toAddItem);

        this.control.patchValue(items);
    }

    public removeItemSelection(toDeleteItem: Society): void {

        const items: Society[] = [...this.control.value];

        const index: number = items.findIndex((item: Society): boolean => {

            return item.id === toDeleteItem.id;
        });

        items.splice(index, 1);

        this.control.patchValue(items);
    }

    get form(): UntypedFormGroup {

        return this.parentForm;
    }

    get control(): UntypedFormGroup {

        return this.form.get(this.formName) as UntypedFormGroup;
    }

    get selectedItems(): Society[] {

        return this.form.get(this.formName).value as Society[];
    }

    get itemsApiParams(): string[] {

        return [
            `page=1`,
            `limit=10`,
            `sort[name]=ASC`
        ];
    }

    get mapApiResult(): (data: Pagination<Society>) => Society[] {

        return (data: Pagination<Society>): Society[] => {

            return data.items
        }
    }
}
