import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge, Observable} from 'rxjs';
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog, OfferCatalogStatusType} from "@core/shared/models/offer/offer-catalog";
import {OfferCatalogRequestViewDialogComponent} from "@core/components/offer/offer-catalog/offer-catalog-request/offer-catalog-request-view/offer-catalog-request-view-dialog/offer-catalog-request-view-dialog.component";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {OfferCatalogRequestRevokeDialogComponent} from "@core/components/offer/offer-catalog/offer-catalog-request/offer-catalog-request-revoke/offer-catalog-request-revoke-dialog/offer-catalog-request-revoke-dialog.component";
import {Access} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Role} from "@core/shared/models/role";
import {TranslationService} from "@core/shared/services/translation.service";
import moment, {Moment} from 'moment/moment';
import {DATE_UNDERSCORE_FORMAT} from '@app/data';

@Component({
    selector: 'app-core-page-distributor-approval-list',
    templateUrl: './page-distributor-approval-list.component.html',
    styleUrls: ['./page-distributor-approval-list.component.scss']
})
export class PageDistributorApprovalListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'society.name',
        'society.admin.roles',
        'offer.translations.name',
        'society.commission',
        'society.incomingPayment',
        'createdAt',
        'status'
    ];

    public filterBuilder: FilterBuilder;

    public items: OfferCatalog[] = [];

    public statusItems: { label: string, value: OfferCatalogStatusType }[] = [];

    public user: User;

    public filterRoles: {identifier: Role[], label: string}[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _offerCatalogService: OfferCatalogService,
        private _snackBar: MatSnackBar,
        private _userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.user = this._userService.currentUser.value;

        this._initStatusItems();

        this._initFilterBuilder();

        this._loadFilterRoles();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._offerCatalogService.getPaginationItemsRequestAPI(this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: OfferCatalog[]): void => {

                this.items = data;
            }
        );
    }

    private _loadFilterRoles(): void {

        this.filterRoles = [
            {
                identifier: ['ROLE_OFFER_DISTRIBUTOR'],
                label: this._translateService.instant('distributor.roles.travelAgency.value')
            },
            {
                identifier: ['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER'],
                label: this._translateService.instant('distributor.roles.businessProvider.value')
            }
        ];
    }

    private _initStatusItems(): void {

        this._translateService.get(['offer.catalog.request.status.accepted.value', 'offer.catalog.request.status.pending.value', 'offer.catalog.request.status.refused.value']).subscribe((translation: object): void => {

            this.statusItems = [
                {
                    value: 'accepted',
                    label: translation['offer.catalog.request.status.accepted.value']
                },
                {
                    value: 'pending',
                    label: translation['offer.catalog.request.status.pending.value']
                },
                {
                    value: 'refused',
                    label: translation['offer.catalog.request.status.refused.value']
                }
            ];
        });

    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._offerCatalogService.getPaginationItemsRequestAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                this.items = data;
            });
        };
    }

    private _rewriteRoleFilters(params: string[]): string[] {

        const identifier: string = 'society.admin.roles[andlkin][]';

        const roleParams: string[] = [...params].filter((param: string): boolean => {

            return param.includes(identifier);
        });

        params = params.filter((param: string): boolean => {

            return !param.includes(identifier);
        });

        roleParams.forEach((roleParam: string): void => {

            const split: string[] = roleParam.split('=');

            const roles: string[] = split[1].split(',');

            roles.forEach((role: string): void => {

                params.push(`${identifier}=${role}`);
            });
        });

        return params;
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._offerCatalogService.getPaginationItemsRequestAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

            this.items = data;
        });
    }

    public getModel(item: OfferCatalog): OfferCatalog {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){
            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }else{
            params.push(`sort[createdAt]=DESC`);
            params.push(`sort[id]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            let explodeParams = this.filterBuilder.serializedRequest.split('&');

            // Traitement des rôles

            explodeParams = this._rewriteRoleFilters(explodeParams);

            params.push(explodeParams.filter((param: string): boolean => {

                return param.length > 0;

            }).join('&'));
        }

        return params;
    }

    public openViewItemDialog(offerCatalog: OfferCatalog): void {

        this._dialog.open(OfferCatalogRequestViewDialogComponent, {
            width: '500px',
            data: {
                id: offerCatalog.id
            }
        });
    }

    public approveItem(offerCatalog: OfferCatalog): void {

        const data: { status : OfferCatalogStatusType} = {
            status: 'accepted'
        };

        this._offerCatalogService.updateItemRequestAPI(offerCatalog.id, data).subscribe((): void => {

            this._snackBar.open(this._translateService.instant('offer.catalog.request.approval.confirm.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });

            this._offerCatalogService.getPaginationItemsRequestAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                this.items = data;
            });
        });
    }

    public openRevokeItemDialog(offerCatalog: OfferCatalog): void {

        const dialogRef: MatDialogRef<OfferCatalogRequestRevokeDialogComponent> = this._dialog.open(OfferCatalogRequestRevokeDialogComponent, {
            width: '500px'
        });

        dialogRef.componentInstance.confirm.subscribe((value: { comment: string }): void => {

            const data: { status : OfferCatalogStatusType, comment: string } = {
                status: 'refused',
                comment: value.comment
            };

            this._offerCatalogService.updateItemRequestAPI(offerCatalog.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('offer.catalog.request.revoke.confirm.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._offerCatalogService.getPaginationItemsRequestAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public hasAccessEdit(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'OFFER_CATALOG_REQUEST_EDIT_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessRead(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'OFFER_CATALOG_REQUEST_READ_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public itemHasRole(role: Role, source: Role[]): boolean {

        return source.includes(role);
    }

    public itemHasOneOfThisRoles(roles: Role[], source: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.itemHasRole(role, source);
        });
    }

    public getRoleLabel(roles: Role[]): string {

        switch (true) {

            case this.itemHasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR'], roles):

                return this._translateService.instant('distributor.roles.travelAgency.value');

            case this.itemHasOneOfThisRoles(['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER'], roles):

                return this._translateService.instant('distributor.roles.businessProvider.value');

            default:

                return null;
        }
    }

    public formatCommission(value: number): string {

        return (value * 100).toFixed(2);
    }

    public getIncomingPaymentLabel(catalog: OfferCatalog): string {

        return this._translateService.instant(`${catalog.incomingPayment ? 'yes' : 'no'}.value`);
    }

    get mapApiResult(): (data: Pagination<OfferCatalog>) => OfferCatalog[] {

        return (data: Pagination<OfferCatalog>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public exportOffersApprovals(): void {

        this._offerCatalogService.exportItemsRequest().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('offerCatalog.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

}
