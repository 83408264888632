import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormService} from '@core/shared/services/form.service';
import {Society} from "@core/shared/models/society";
import {DEFAULT_MARKUP, MIN_MARKUP} from '@core/shared/models/data';
import {REGEX_PRICE} from "@core/shared/models/regex";
import {parseMarkup} from "@core/shared/utils/markup";

@Component({
  selector: 'app-offer-catalog-create',
  templateUrl: './offer-catalog-create.component.html',
  styleUrls: ['./offer-catalog-create.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferCatalogCreateComponent implements OnInit {

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    @Output() markup: EventEmitter<any> = new EventEmitter();

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    constructor(
        private _dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        public formService: FormService,
        @Inject(MAT_DIALOG_DATA) public data: {   title: string; content: string; society:Society}
    ) {
    }

    ngOnInit(): void {

        this.formService.form = this._formBuilder.group({
            markup : [this.defaultMarkupPercent, [Validators.required, Validators.pattern(REGEX_PRICE), Validators.min(this.minMarkupPercent)]],
        });

        const events: EventEmitter<any>[] = [
            this.confirm,
            this.cancel,
            this.markup

        ];

        events.forEach((event: EventEmitter<any>): any => {

            event.subscribe((): any => {

                this._dialogRef.close();
            })
        });
    }

    public confirmEmit(): void {
        this.confirm.emit();
        this.markup.emit(this.form.get('markup').value);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

}
