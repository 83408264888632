import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {RegistrationAffiliation} from "@core/shared/models/registration-affiliation";
import {RegistrationAffiliationService} from "@core/shared/services/registration-affiliation.service";

@Component({
    selector: 'app-core-page-registration-affiliation-list',
    templateUrl: './page-registration-affiliation-list.component.html',
    styleUrls: ['./page-registration-affiliation-list.component.scss']
})
export class PageRegistrationAffiliationListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'name',
        'uniqueIdentifier',
        'comment',
        'createdAt',
        'updatedAt'
    ];

    filterBuilder: FilterBuilder;

    public items: RegistrationAffiliation[] = [];

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _registrationAffiliationService: RegistrationAffiliationService,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._registrationAffiliationService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: RegistrationAffiliation[]): void => {

            this.items = data;
        })
        ;
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._registrationAffiliationService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: RegistrationAffiliation[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._registrationAffiliationService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: RegistrationAffiliation[]): void => {

            this.items = data;
        });
    }

    public addItem(): void {

        this._router.navigate(['account/registration-affiliation/create']);
    }

    public updateItem(id: number): void {

        this._router.navigate(['account/registration-affiliation/update/', id]);
    }

    public openDeleteItemDialog(id: number): void {

        const registrationAffiliation: RegistrationAffiliation = this.items.find((item: RegistrationAffiliation): boolean => {

            return item.id === id;
        });

        const title: string = this._translateService.instant('registrationAffiliation.actions.delete.value');

        const content: string = this._translateService.instant('registrationAffiliation.actions.delete.content.value', {
            registrationAffiliation: registrationAffiliation.name
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '600px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._registrationAffiliationService.deleteItemAPI(registrationAffiliation.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('registrationAffiliation.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._registrationAffiliationService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: RegistrationAffiliation[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public getModel(item: RegistrationAffiliation): RegistrationAffiliation {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get mapApiResult(): (data: Pagination<RegistrationAffiliation>) => RegistrationAffiliation[] {

        return (data: Pagination<RegistrationAffiliation>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}