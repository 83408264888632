import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {StatusType} from "@core/shared/models/status-type";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {Hashtag} from "@core/shared/models/hashtag";
import {HashtagService} from "@core/shared/services/hashtag.service";
import {Access, AccessType} from "@core/shared/models/access";

@Component({
  selector: 'app-core-page-hashtag-list',
  templateUrl: './page-hashtag-list.component.html',
  styleUrls: ['./page-hashtag-list.component.scss']
})
export class PageHashtagListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public status: StatusType[] = [];

    public offerCreatorStatus: StatusType[] = [];

    public customerTypologies: { id: number, label: string }[];

    public regions: OfferAttribute[] = [];

    public totalItems: number = 0;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: Hashtag[];

    public currentUser: User;

    public isTywinHashtagItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('no.value')
        }
    ];

    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _hashtagService: HashtagService,
        public userService: UserService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems();
                    }),
                    map(this.mapApiResult),
                ).subscribe((data: Hashtag[]): void => {

                this.items = data;
            })
            ;
        }

        this._loadItems();
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns.push('actions');

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

            this.displayedColumns.push('isTywinHashtag');

            this.displayedColumns.push('society');
        }

        this.displayedColumns.push('name');

        this.displayedColumns.push('countOffers');
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems().pipe(
                map(this.mapApiResult)
            ).subscribe((data: Hashtag[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems(): Observable<Pagination<Hashtag>> {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

            return this._hashtagService.getPaginationItemsAPI(this.itemsApiParams);

        } else {

            return this._hashtagService.getPaginationSocietyItemsAPI(this.currentUser.society.id, this.itemsApiParams);
        }
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Hashtag[]): void => {

            this.items = data;
        });
    }

    public createItem(): void {

        this._router.navigate(['account/hashtag/create']);
    }

    public updateItem(id: number): void {

        this._router.navigate(['account/hashtag/update', id]);
    }

    public getModel(item: Hashtag): Hashtag {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('hashtag.delete.value');

        const content : string = this._translateService.instant('hashtag.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._hashtagService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('hashtag.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Hashtag[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public hasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.currentUser.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            if(this.filterBuilder.hasFilter('society.id')) {

                switch(parseInt(this.filterBuilder.getFieldByKey('society.id').value)) {

                    case 1:

                        this.filterBuilder.getFieldByKey('society.id').operator = 'null';

                        break;

                    case 0:

                        this.filterBuilder.getFieldByKey('society.id').operator = 'nnull';

                        break;
                }
            }

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Hashtag>) => Hashtag[] {

        return (data: Pagination<Hashtag>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get hasAccessCreate(): boolean {

        return this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']);
    }

    get hasAccessRead(): boolean {

        return this.hasAccess('HASHTAG_READ_IS_MINE');
    }

    get hasAccessEdit(): boolean {

        return ['HASHTAG_EDIT', 'HASHTAG_EDIT_IS_MINE'].some((item: AccessType): boolean => {

            return this.hasAccess(item);
        });
    }
}
