import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {FormTabValidationItem, OfferFormTabValidationService} from "@core/shared/services/form/form-tab-validation.service";
import {MatTabGroup} from "@angular/material/tabs";
import {FileConfig} from "@lib/form/fields/file/file.component";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@core/shared/services/api.service";
import {TranslateService} from "@ngx-translate/core";
import {SocietyGroupService} from "@core/shared/services/society-group.service";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {SelectSearchConfiguration} from "@core/components/select/select-search/select-search.component";
import {NetworkProvider} from "@core/shared/models/network-provider";
import {BehaviorSubject, Observable} from "rxjs";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {map} from "rxjs/operators";
import {Pagination} from "@core/shared/models/pagination";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {NetworkProviderService} from "@core/shared/services/network/network-provider.service";
import {ShortSociety, ShortSocietyGroup} from "@core/shared/models/society";
import {SocietyService} from "@core/shared/services/society.service";
import {Role, ROLE_LABELS, RoleLabel} from "@core/shared/models/role";
import {FilterBuilder, FilterField} from "@core/shared/models/filter";
import {SocietyGroup} from "@core/shared/models/society-group";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {SelectArrayMultipleFilterComponent} from "@core/components/filter/select-array-multiple-filter/select-array-multiple-filter.component";
import {TextFilterComponent} from "@core/components/filter/text-filter/text-filter.component";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CrudService} from "@core/shared/services/crud.service";
import {environment} from '../../../../../environments/environment';

enum FormTabTag {
    GeneralData = 'generalData',
    AssociatedSocieties = 'associatedSocieties'
}

enum SocietyFilterKey {
    Roles = 'roles',
    NetworkProviders = 'networkProviders',
    NetworkOfferCreators = 'networkOfferCreators',
    SocietyGroups = 'societyGroups',
    Name = 'name'
}

@Component({
    selector: 'app-core-society-group-form',
    templateUrl: './society-group-form.component.html',
    styleUrls: ['./society-group-form.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})
export class SocietyGroupFormComponent implements OnInit, AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('generalData', {static: true}) generalDataRef: TemplateRef<any>;

    @ViewChild('associatedSocieties', {static: true}) associatedSocietiesRef: TemplateRef<any>;

    @ViewChild('rolesFilter', {static: false}) rolesFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('networkProvidersFilter', { static: false }) networkProvidersFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('networkOfferCreatorsFilter', { static: false }) networkOfferCreatorsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('societyGroupsFilter', { static: false }) societyGroupsFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('nameFilter', { static: false }) nameFilterComponent: TextFilterComponent;

    protected readonly SocietyFilterKey = SocietyFilterKey;

    public societyGroup: SocietyGroup;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public origin: { key: string, backLabel: string } = null;

    public maxLengths: { comment: number } = {
        comment: 255
    };

    public registrationLogoConfig: FileConfig;

    public administrationLogoConfig: FileConfig;

    public networkAssociationEnabled: boolean = false;

    public networkProviderSearchConfiguration: SelectSearchConfiguration<NetworkProvider>;

    public networkProviderSearchSourceCallback: (search: string) => Observable<NetworkProvider[]>;

    public networkOfferCreatorSearchConfiguration: SelectSearchConfiguration<NetworkOfferCreator>;

    public networkOfferCreatorSearchSourceCallback: (search: string) => Observable<NetworkOfferCreator[]>;

    public societies$: BehaviorSubject<ShortSociety[]> = new BehaviorSubject<ShortSociety[]>([]);

    public filterBuilder: FilterBuilder;

    public roleFilterItems: { id: string, name: string }[] = [];

    public networkProviderFilterItems: NetworkProvider[] = [];

    public networkOfferCreatorFilterItems: NetworkOfferCreator[] = [];

    public societyGroupFilterItems: SocietyGroup[] = [];

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private _apiService: ApiService,
        private _translateService: TranslateService,
        private _societyGroupService: SocietyGroupService,
        private _networkProviderService: NetworkProviderService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        private _societyService: SocietyService,
        public userService: UserService,
        public formService: FormService,
        public crudService: CrudService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {}

    ngOnInit() {

        this.societyGroup = this.crudService.isUpdateAction ? (this._activatedRoute.snapshot.data as { societyGroup: SocietyGroup }).societyGroup : null;

        this._initOrigin();

        this._initRegistrationLogoConfig();

        this._initAdministrationLogoConfig();

        this._initForm();

        this._hydrateForm();

        this._initNetworkProviderConfiguration();

        this._initNetworkOfferCreatorConfiguration();

        this._initFilterBuilder();

        this._initRoleFilterItems();

        this._loadNetworkProviderFilterItems();

        this._loadNetworkOfferCreatorFilterItems();

        this._loadSocietyGroupFilterItems();

        this._loadSocieties();
    }

    ngAfterViewInit() {

        this._initTabItems();

        this._initFormTabValidation();

        this._changeDetectorRef.detectChanges();
    }

    private _initOrigin(): void {

        this.origin = {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `societyGroup.creation.origin.${this._activatedRoute.snapshot.queryParams['origin']}.value`
        };
    }

    private _initRegistrationLogoConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((): void => {

            this.registrationLogoConfig = {
                id: 'registrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_group_registration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                upload: {
                    allowedTypes: ['image/*'].join(',')
                }
            };
        });
    }

    private _initAdministrationLogoConfig(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((): void => {

            this.administrationLogoConfig = {
                id: 'administrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_group_administration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                upload: {
                    allowedTypes: ['image/*'].join(',')
                }
            };
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: [null, [Validators.required]],
            comment: [null, [Validators.required, Validators.maxLength(this.maxLengths.comment)]],
            registrationLogo: [null],
            administrationLogo: [null],
            parsedSocieties: [null],
            networkProviders: [[]],
            networkOfferCreators: [[]],
            societies: [[]]
        });

        this.formService.submitCallback = (): void => {

            if (this.form.invalid) {

                return;
            }

            const formValue = {...this.form.getRawValue()};

            const data: object = Object.assign(formValue, {});

            if(this.crudService.isCreateAction){

                this._societyGroupService.createItemAPI(data).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('societyGroup.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToOrigin();
                });
            }

            if(this.crudService.isUpdateAction){

                this._societyGroupService.updateItemAPI(this.societyGroup.id, data).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('societyGroup.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this.redirectToOrigin();
                });
            }
        };
    }

    private _hydrateForm(): void {

        if (this.crudService.isCreateAction) {

            return;
        }

        this.form.patchValue({...this.societyGroup});

        this.networkAssociationEnabled = Boolean(this.societyGroup.networkProviders.length) || Boolean(this.societyGroup.networkOfferCreators.length);
    }

    private _initNetworkProviderConfiguration(): void {

        this.networkProviderSearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'networkProvider.plural.selected.value',
            searchActionLabel: 'networkProvider.search.action.value',
            selectOptionActionLabel: 'networkProvider.select.action.value',
            itemLabel: (item: NetworkProvider): string => {

                return `${item.name}`;
            }
        }

        this.networkProviderSearchSourceCallback = (search: string): Observable<NetworkProvider[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._networkProviderService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<NetworkProvider>): NetworkProvider[] => {

                return pagination.items;
            }));
        }
    }

    private _initNetworkOfferCreatorConfiguration(): void {

        this.networkOfferCreatorSearchConfiguration = {
            multiple: true,
            enableSearch: true,
            currentSelectionLabel: 'networkOfferCreator.plural.selected.value',
            searchActionLabel: 'networkOfferCreator.search.action.value',
            selectOptionActionLabel: 'networkOfferCreator.select.action.value',
            itemLabel: (item: NetworkOfferCreator): string => {

                return `${item.name}`;
            }
        }

        this.networkOfferCreatorSearchSourceCallback = (search: string): Observable<NetworkOfferCreator[]> => {

            const params: string[] = [
                `page=1`,
                'limit=10'
            ];

            if (search && search.length) {

                params.push(...[
                    `name[lk]=${search}`
                ]);
            }

            return this._networkOfferCreatorService.getPaginationItemsAPI(params).pipe(map((pagination: Pagination<NetworkOfferCreator>): NetworkOfferCreator[] => {

                return pagination.items;
            }));
        }
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._loadSocieties();
        };
    }

    private _loadSocieties(): void {

        this._societyService.getShortItemsAPI(this.societyApiParams).subscribe((items: ShortSociety[]): void => {

            this.societies$.next(items);
        });
    }

    private _initRoleFilterItems(): void {

        const items: RoleLabel[] = ROLE_LABELS.filter((item: RoleLabel): boolean => {

            return (['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] as Role[]).includes(item.identifier);
        });

        this._translateService.get(items.map((item: RoleLabel): string => item.label)).subscribe((): void => {

            this.roleFilterItems = items.map((item: RoleLabel): { id: string, name: string } => {

                return {
                    id: item.identifier,
                    name: this._translateService.instant(item.label)
                };
            });
        });
    }

    private _loadNetworkProviderFilterItems(): void {

        this._networkProviderService.getItemsAPI().subscribe((items: NetworkProvider[]): void => {

            this.networkProviderFilterItems = items;
        });
    }

    private _loadNetworkOfferCreatorFilterItems(): void {

        this._networkOfferCreatorService.getItemsAPI().subscribe((items: NetworkOfferCreator[]): void => {

            this.networkOfferCreatorFilterItems = items;
        });
    }

    private _loadSocietyGroupFilterItems(): void {

        this._societyGroupService.getItemsAPI().subscribe((items: SocietyGroup[]): void => {

            this.societyGroupFilterItems = items;
        });
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: FormTabTag.GeneralData,
                label: 'generalData.value',
                template: this.generalDataRef
            },
            {
                tag: FormTabTag.AssociatedSocieties,
                label: 'society.plural.associated.value',
                template: this.associatedSocietiesRef
            }
        ];
    }

    private _initFormTabValidation(): void {

        this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
    }

    public redirectToOrigin(): void {

        switch (this.origin.key) {

            case 'relationshipList':

                this._router.navigate(['account/relationship/list'], { queryParams: { targetTab: 'societyGroup' }});

                break;
        }
    }

    public resetNetworkAssociation(): void {

        this.form.get('networkOfferCreators').patchValue([]);

        this.form.get('networkProviders').patchValue([]);
    }

    public handleSocietySelection(society: ShortSociety): void {

        const items: ShortSociety[] = [...this.selectedSocieties];

        if(this.isSocietySelected(society)){

            const index: number = items.findIndex((item: ShortSociety): boolean => {

                return item.id === society.id;
            });

            items.splice(index, 1);
        }
        else {

            items.push(society);
        }

        this.form.get('societies').patchValue(items);
    }

    public isSocietySelected(society: ShortSociety): boolean {

        return this.selectedSocieties.some((item: ShortSociety): boolean => {

            return item.id === society.id
        });
    }

    public unselectSociety(society: ShortSociety): void {

        const items: ShortSociety[] = [...this.selectedSocieties];

        const index: number = items.findIndex((item: ShortSociety): boolean => {

            return item.id === society.id;
        });

        items.splice(index, 1);

        this.form.get('societies').patchValue(items);
    }

    public getParsedSociety(society: ShortSociety): string {

        const parts: string[] = [];

        // Nom

        parts.push(society.name);

        // Rôles

        parts.push(society.admin.roles.map((role: Role): string => {

            const item: RoleLabel = ROLE_LABELS.find((roleLabel: RoleLabel): boolean => {

                return roleLabel.identifier === role;
            });

            return this._translateService.instant(item.label);

        }).join(' / '));

        // Groupes

        if(society.groups.length){

            parts.push(society.groups.map((group: ShortSocietyGroup) => group.name).join(' / '));
        }

        return parts.join(' - ');
    }

    public removeFilter(field: FilterField): void {

        const key: SocietyFilterKey = field.key as SocietyFilterKey;

        switch (key){

            case SocietyFilterKey.Roles:
            case SocietyFilterKey.NetworkProviders:
            case SocietyFilterKey.NetworkOfferCreators:
            case SocietyFilterKey.SocietyGroups:

                const collectionField = this.filterBuilder.getFieldByKey(key) as FilterCollectionField;

                const fieldIndex: number = collectionField.fields.findIndex((item: ArrayFilterField): boolean => {

                    return item.value === field.value;
                });

                if(fieldIndex >= 0){

                    collectionField.fields.splice(fieldIndex, 1);
                }

                switch (key){

                    case SocietyFilterKey.Roles:

                        this.rolesFilterComponent.values = collectionField.fields.map((item: FilterField): string => {

                            return item.value;
                        });

                        break;

                    case SocietyFilterKey.NetworkProviders:

                        this.networkProvidersFilterComponent.values = collectionField.fields.map((item: FilterField): number => {

                            return item.value;
                        });

                        break;

                    case SocietyFilterKey.NetworkOfferCreators:

                        this.networkOfferCreatorsFilterComponent.values = collectionField.fields.map((item: FilterField): number => {

                            return item.value;
                        });

                        break;

                    case SocietyFilterKey.SocietyGroups:

                        this.societyGroupsFilterComponent.values = collectionField.fields.map((item: FilterField): number => {

                            return item.value;
                        });

                        break;
                }

                break;

            case SocietyFilterKey.Name:

                this.filterBuilder.resetFieldValueByKey(key);

                this.nameFilterComponent.reset();

                break;
        }

        this.filterBuilder.filter();
    }

    public selectAllSocieties(): void {

        this.form.get('societies').patchValue(this.societies$.getValue());
    }

    public unselectAllSocieties(): void {

        this.form.get('societies').patchValue([]);
    }

    get form(): FormGroup {

        return this.formService.form;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items: Partial<FormTabValidationItem>[] = [];

            // Données générales

            items.push({
                tag: FormTabTag.GeneralData,
                controls: [
                    this.form.get('name'),
                    this.form.get('comment'),
                    this.form.get('registrationLogo'),
                    this.form.get('administrationLogo')
                ]
            });

            // Sociétés associées

            items.push({
                tag: FormTabTag.AssociatedSocieties,
                controls: [
                    this.form.get('parsedSocieties'),
                    this.form.get('networkProviders'),
                    this.form.get('networkOfferCreators'),
                    this.form.get('societies')
                ]
            });

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }

    get selectedSocieties(): ShortSociety[] {

        return this.form.get('societies').value as ShortSociety[];
    }

    get parsedSelectedSocieties(): string {

        if(!this.selectedSocieties.length){

            return null;
        }

        const parts: string[] = [];

        const roles: Role[] = ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'];

        roles.forEach((role: Role): void => {

            const roleLabel: string = role.replace(/^ROLE_/, '').toLowerCase().replace(/_([a-z])/g, (match: string, letter: string): string => letter.toUpperCase());

            const count: number = this.selectedSocieties.filter((society: ShortSociety): boolean => {

                if(role === 'ROLE_OFFER_DISTRIBUTOR'){

                    return (society.admin.roles.length === 1) && society.admin.roles.includes(role);
                }

                return society.admin.roles.includes(role);

            }).length;

            if(count){

                parts.push(`${count} ${(this._translateService.instant(count > 1 ? `role.${roleLabel}.plural.value` : `role.${roleLabel}.value`) as string).toLowerCase()}`);
            }
        });

        return parts.join(' / ');
    }

    get societyApiParams(): string[] {

        const params: string[] = [
            'sort[name]=ASC'
        ];

        params.push(...this.societyFilterParams);

        return params;
    }

    get societyFilterParams(): string[] {

        const params: string[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: SocietyFilterKey = field.key as SocietyFilterKey;

            switch (key){

                case SocietyFilterKey.Roles:

                    (field as FilterCollectionField).fields.forEach((itemField: FilterField): void => {

                        params.push(`admin.roles[lkin][]=${ itemField.value }`);
                    });

                    break;

                case SocietyFilterKey.NetworkProviders:

                    (field as FilterCollectionField).fields.forEach((itemField: FilterField): void => {

                        params.push(`networkProviders.id[in][]=${ itemField.value }`);
                    });

                    break;

                case SocietyFilterKey.NetworkOfferCreators:

                    (field as FilterCollectionField).fields.forEach((itemField: FilterField): void => {

                        params.push(`networkOfferCreators.id[in][]=${ itemField.value }`);
                    });

                    break;

                case SocietyFilterKey.SocietyGroups:

                    (field as FilterCollectionField).fields.forEach((itemField: FilterField): void => {

                        params.push(`societyGroups.id[in][]=${ itemField.value }`);
                    });

                    break;

                default:

                    params.push(field.serialize);
            }
        });

        return params;
    }

    get currentFilters(): { field: FilterField, formattedValue: string }[] {

        const items: { field: FilterField, formattedValue: string }[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: SocietyFilterKey = field.key as SocietyFilterKey;

            switch (key){

                case SocietyFilterKey.Roles:
                case SocietyFilterKey.NetworkProviders:
                case SocietyFilterKey.NetworkOfferCreators:
                case SocietyFilterKey.SocietyGroups:

                    const fields: FilterField[] = (field as FilterCollectionField).fields;

                    const choices: { id: number, name: string }[] = (field as FilterCollectionField).choices;

                    fields.forEach((field: FilterField): void => {

                        const choice: { id: number, name: string } = choices.find((choice: { id: number, name: string }): boolean => {

                            return choice.id === field.value;
                        });

                        items.push({
                            field: field,
                            formattedValue: choice ? choice.name : null
                        });
                    });

                    break;

                default:

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant(`societyGroup.filter.${key}.value`) } : ${ field.isCollection ? (field as FilterCollectionField).fields.map((field: FilterField): string => {

                            return field.value.toString();

                        }).join(', ') : field.value }`
                    });
            }
        });

        return items;
    }

    get registrationIdentifierUrl(): string {

        return `${environment.registerUrl}/${this.userService.currentUser.getValue().locale}/registration/role-choice?societyGroup=${this.societyGroup.uniqueIdentifier}`;
    }

    get loginIdentifierUrl(): string {

        return `${window.location.origin}/${this.userService.currentUser.getValue().locale}/signin?societyGroup=${this.societyGroup.uniqueIdentifier}`;
    }
}
