import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {NgPipesModule} from "ngx-pipes";
import {ContentComponent} from './content/content.component';
import {TranslationComponent} from './translation/translation.component';
import {TemplateComponent} from './template/template.component';
import {RowComponent} from "./row/row.component";
import {ColumnComponent} from './column/column.component';
import {ElementComponent} from "./element/element.component";
import {TextComponent} from "./element/type/text/text.component";
import {ButtonComponent} from './element/type/button/button.component';
import {OrderBy} from "./shared/pipe/orderby.pipe";
import {CkEditorDirective} from "./element/type/text/text.directive";
import {NgxPaginationModule} from "ngx-pagination";
import {CmsComponent} from "./cms.component";
import {TranslationCreateDialogComponent} from "./translation/translation-create-dialog/translation-create-dialog.component";
import {TranslateModule} from "@ngx-translate/core";
import {TranslationService} from "./translation/translation.service";
import {CmsService} from "./cms.service";
import {ApiService} from "./api/api.service";
import {ContentService} from "./content/content.service";
import {TemplateService} from "./template/template.service";
import {RowService} from "./row/row.service";
import {ElementService} from "./element/element.service";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {CommonModule} from "@angular/common";
import {FileComponent} from "./element/type/file/file.component";
import {IframeComponent} from './element/type/iframe/iframe.component';
import {SafePipeModule} from "safe-pipe";
import {VideoComponent} from './element/type/video/video.component';
import {VideoDialogComponent} from './element/type/video/video-dialog/video-dialog.component';
import {IframeDialogComponent} from './element/type/iframe/iframe-dialog/iframe-dialog.component';
import {ImageComponent} from './element/type/image/image.component';
import {SlideshowComponent} from './element/type/slideshow/slideshow.component';
import {FormComponent} from "./element/type/form/form.component";
import {ConfirmDialogModule} from "@lib/confirm-dialog/confirm-dialog.module";
import {MediaModule} from "@lib/media/media.module";
import {MaterialModule} from "@material/material.module";
import {FormModule} from "@lib/form/form.module";

@NgModule({
    declarations: [
        CmsComponent,
        ContentComponent,
        TranslationComponent,
        RowComponent,
        ElementComponent,
        TemplateComponent,
        TextComponent,
        TranslationCreateDialogComponent,
        OrderBy,
        CkEditorDirective,
        ColumnComponent,
        ButtonComponent,
        FormComponent,
        FileComponent,
        IframeComponent,
        VideoComponent,
        VideoDialogComponent,
        IframeDialogComponent,
        ImageComponent,
        SlideshowComponent
    ],
    exports: [
        CmsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        NgPipesModule,
        NgxPaginationModule,
        TranslateModule,
        ConfirmDialogModule,
        MaterialModule,
        MediaModule,
        SafePipeModule,
        FormModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        ApiService,
        CmsService,
        ContentService,
        TranslationService,
        TemplateService,
        RowService,
        ElementService
    ]
})

export class CmsModule {
}
