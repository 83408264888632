import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PromotionPeriodCreateComponent} from "@core/components/promotion/promotion-period/promotion-period-create/promotion-period-create.component";
import {Promotion} from "@core/shared/models/promotion";
import {PromotionPeriodCreateConfiguration} from "@core/shared/models/promotion/promotion-period/promotion-period-create";

@Component({
    selector: 'app-core-promotion-period-create-dialog',
    templateUrl: './promotion-period-create-dialog.component.html',
    styleUrls: ['./promotion-period-create-dialog.component.scss']
})
export class PromotionPeriodCreateDialogComponent implements OnInit {

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(PromotionPeriodCreateComponent) promotionPeriodCreateComponent: PromotionPeriodCreateComponent;

    constructor(
        private _dialogRef: MatDialogRef<PromotionPeriodCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { promotion: Promotion }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.create
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            });
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.promotionPeriodCreateComponent.formService.submit();
    }

    get promotion(): Promotion {

        return this.data.promotion;
    }

    get configuration(): PromotionPeriodCreateConfiguration {

        return {
            showSubmitButton: false
        };
    }
}
