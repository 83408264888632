import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormService} from "@core/shared/services/form.service";
import {mustMatchValidator} from "@core/shared/validators/must-match.validator";

@Component({
    selector: 'app-core-page-user-reset-password',
    templateUrl: './page-user-reset-password.component.html',
    styleUrls: ['./page-user-reset-password.component.scss'],
    providers: [
        FormService
    ]
})
export class PageUserResetPasswordComponent implements OnInit {

    public minLengthPassword: number = 8;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(this.minLengthPassword)]],
            confirmPassword: ['', Validators.required],
        }, {
            validators: [
                mustMatchValidator('password', 'confirmPassword')
            ]
        });

        this.formService.submitCallback = (): void => {

            const data: object = {
                password: this.form.get('password').value
            };

            this._userService.resetAccountPasswordAPI(this._route.snapshot.params['token'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.password.reset.success.self.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 8000
                });

                this._router.navigate(['signin']);
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}

