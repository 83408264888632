import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-core-page-card-payment-response',
    templateUrl: './page-card-payment-response.component.html',
    styleUrls: ['./page-card-payment-response.component.scss']
})
export class PageCardPaymentResponseComponent {

    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute
    ) {}

    public redirectToCard(): void {

        this._router.navigate(['account/card/read']);
    }

    get isSuccess(): boolean {

        return (this._activatedRoute.snapshot.params['response'] === 'success');
    }
}
