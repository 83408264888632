import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatSort} from "@angular/material/sort";
import {Booking} from "@core/shared/models/booking";
import {BookingService} from "@core/shared/services/booking.service";
import {SocietyService} from "@core/shared/services/society.service";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {parsePrice} from "@core/shared/utils/price";
import {Currency} from "@core/shared/models/currency";
import {parseMarkup} from "@core/shared/utils/markup";
import {TranslationService} from "@core/shared/services/translation.service";
import {BOOKING_STATUS, BOOKING_PAYMENT_STATUS} from "@core/shared/models/booking/booking-status";
import {StatusType} from "@core/shared/models/status-type";
import moment, {Moment} from "moment";
import {DATE_UNDERSCORE_FORMAT} from "@app/data";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'page-booking-list',
    templateUrl: './page-booking-list.component.html',
    styleUrls: ['./page-booking-list.component.scss']
})
export class PageBookingListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public hasData: boolean = false;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: Booking[];

    public user: User;

    public types: { id: string, label: string }[] = [
        {
            id: 'booking',
            label: this._translateService.instant('booking.type.booking.value')
        },
        {
            id: 'request',
            label: this._translateService.instant('booking.type.request.value')
        },
        {
            id: 'onlineSale',
            label: this._translateService.instant('booking.type.onlineSale.value')
        }
    ];

    public status: StatusType[] = [];

    public paymentStatus: StatusType[] = [];

    public giftVoucherStatus: { id: number, label: string }[] = [
        {
            id: 1,
            label: this._translateService.instant('booking.read.fields.giftVoucher.isGiftVoucher.yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('booking.read.fields.giftVoucher.isGiftVoucher.no.value')
        }
    ];

    constructor(
        private _router: Router,
        private _translateService: TranslateService,
        private _bookingService: BookingService,
        private _societyService: SocietyService,
        private _activatedRoute: ActivatedRoute,
        public userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.user = this.userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { bookings: Pagination<Booking> }): void => {

            this.hasData = data.bookings.totalItems > 0;

            this._societyService.currentUserSociety.next(this.user.society);
        });

        this._initStatus();

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems();
                    }),
                    map(this.mapApiResult),
                ).subscribe((data: Booking[]): void => {

                this.items = data;
            });
        }
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns = [
            'actions'
        ];

        if(this.isAdmin()){

            this.displayedColumns.push(
                'ownerSociety.name'
            );
        }

        this.displayedColumns.push(
            'status'
        );

        if(this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            this.displayedColumns.push(
                'distributorStatus',
                'paymentId'
            );
        }

        this.displayedColumns.push(
            'createdAt',
            'type',
            'giftVoucher',
            'giftVoucher.code',
            'website',
            'responsibleSociety.name',
            'responsibleUser.name',
            'responsibleUser.service',
            'society.name',
            'channel.translations.name',
            'offerData.translations.name',
            'offerData.societyName',
            'reference'
        );

        if(this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR'])){

            this.displayedColumns.push(
                'referenceResponsibleSociety'
            );
        }

        if(this.userService.hasOneOfThisRoles(this.user, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            this.displayedColumns.push(
                'customer.lastName',
                'customer.society'
            );
        }

        this.displayedColumns.push(
            'composition.dateStart',
            'composition.dateEnd'
        );

        if(this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])){

            this.displayedColumns.push(
                'composition.totalNetPriceTTC',
                'composition.totalNetPriceHT'
            );
        }

        this.displayedColumns.push(
            'composition.totalPriceTTC',
            'composition.totalPriceHT',
            'commission',
            'commissionTTC',
            'amountCommissionTTC',
            'isPaidCommission'
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems().pipe(
                map(this.mapApiResult)
            ).subscribe((data: Booking[]): void => {

                this.items = data;
            });
        };
    }

    private _initStatus(): void {

        this.status = this.translateStatus(BOOKING_STATUS);

        this.paymentStatus = this.translateStatus(BOOKING_PAYMENT_STATUS);
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems(): Observable<Pagination<Booking>> {

        if(this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

            return this._bookingService.getPaginationItemsAPI(this.itemsApiParams);

        } else {

            return this._bookingService.getPaginationSocietyItemsAPI(this._societyService.currentUserSociety.getValue().id, this.itemsApiParams);
        }
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Booking[]): void => {

            this.items = data;
        });
    }

    public view(id: number): void {

        this._router.navigate(['account/booking/read', id]);
    }

    public parsePrice(price: number, currency: Currency): string {

        return price ? parsePrice(price / 100) + ' ' + currency?.symbol : '';
    }

    public parsedMarkup(value: number): string {

        return !!value ? (parseMarkup(value) + '%') : '';
    }

    public getModel(item: Booking): Booking {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public isOnlineSale(booking: Booking): boolean {

        return booking.type === 'booking' && booking.hasStock;
    }

    public translateStatus(status: StatusType[]): StatusType[] {

        return  status.map((item: StatusType ) => {

            return {
                id: item.id,
                label: this._translateService.instant( item.label )
            };
        });
    }

    public getResponsibleFullName(responsibleUser: User): string {

        return `${responsibleUser.lastName} ${responsibleUser.firstName}`;
    }

    public exportBookings(): void {

        this._bookingService.exportItemsAPI(this.user.society.id).subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('booking.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public adminExportBookings(): void {

        this._bookingService.adminExportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('booking.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public hasAccessExportBooking(): boolean {

        return this.user.accesses.some((access : Access): boolean => {

            return access.tag === 'BOOKING_LIST_IS_MINE';
        });
    }

    public isAdmin(): boolean {

        return this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        this.filterBuilder.removeFieldByKey('responsibleSociety.users.id');

        if (this.filterBuilder.hasFilters) {

            if(this.filterBuilder.hasFilter('giftVoucher.id')) {

                switch(parseInt(this.filterBuilder.getFieldByKey('giftVoucher.id').value)) {

                    case 1:

                        this.filterBuilder.getFieldByKey('giftVoucher.id').operator = 'nnull';

                        break;

                    case 0:

                        this.filterBuilder.getFieldByKey('giftVoucher.id').operator = 'null';

                        break;
                }
            }

            if(this.filterBuilder.hasFilter('type')) {

                if(this.filterBuilder.getFieldByKey('type').value === 'booking')
                {
                    params.push(`hasStock[eq]=0`);
                }

                if (this.filterBuilder.getFieldByKey('type').value === 'onlineSale') {

                    this.filterBuilder.getFieldByKey('type').value = 'booking';

                    params.push(`hasStock[eq]=1`);
                }
            }

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Booking>) => Booking[] {

        return (data: Pagination<Booking>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
