import {AfterViewInit, Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ITreeOptions, ITreeState, TreeDraggedElement} from '@circlon/angular-tree-component';
import {MenuService} from "@core/shared/services/menu.service";
import {Menu} from "@core/shared/models/menu";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";

import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslationService} from "@core/shared/services/translation.service";
import {Category} from "@core/shared/models/category";
import {CategoryTranslation} from "@core/shared/models/category-translation";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";


interface ItemNode {
    id: number;
    title: string;
    extern_url: string;
    intern_url: string;
    order: number;
    target_blank: boolean;
    url_type: number
    children?: ItemNode[];
    parent: number;
    movable: boolean;
}


@Component({
    selector: 'app-page-menu-list',
    templateUrl: './page-menu-list.component.html',
    styleUrls: ['./page-menu-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        MenuService,
        TreeDraggedElement
    ]
})
export class PageMenuListComponent implements AfterViewInit {

    @ViewChild('tree', {static: true}) tree;

    public panelOpenState = false;
    nodes: any = [];
    maxItems: number = 6;

    state: ITreeState = {
        expandedNodeIds: {},
        hiddenNodeIds: {},
        activeNodeIds: {}
    };

    options: ITreeOptions = {
        allowDrag: node => {
            return node.data.movable;
        },
        allowDrop: (element: any, to: { parent, index: number }) => {

            let dataReturn = true;

            if (!to.parent.parent) {

                const nbItems = to.parent.data.children.length;

                if(element.level > 1)
                    dataReturn = nbItems !== this.maxItems;

                to.parent.data.children.forEach((item: any) => {
                    if ((item.order == 1 && to.index == 0) || (item.order == 6 && to.index == nbItems)) {
                        dataReturn = item.movable;
                    }
                });

            }

            return dataReturn;
        },
        getNodeClone: (node) => ({
            ...node.data,
            id: 1,
            name: `copy of ${node.data.name}`
        }),
        isExpandedField: 'expanded',
        animateExpand: true

    };


    constructor(private router: Router,
                private _activatedRoute: ActivatedRoute,
                public menuService: MenuService,
                private _translateService: TranslateService,
                public translationService: TranslationService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _router: Router
    ) {

    }

    ngOnInit() {
        this._activatedRoute.data.subscribe((data: {menu: Menu}): void => {
            this.nodes = data.menu;
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.tree.treeModel.expandAll();
        }, 1000);
    }


    saveTree(): void {
        this.menuService.postItemsAPI(this.nodes).subscribe((datas: ItemNode[]) => {
        });

    }

    updateItem(node: ItemNode) {
        this.router.navigate([`/menu/update/${node.id}`]);
    }

    createItem() {
        this.router.navigate([`/menu/create`]);
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('menu.actions.delete.value');

        const content : string = this._translateService.instant('menu.actions.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.menuService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('menu.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                location.reload();

            });
        });
    }

    public duplicate(id: number): void {


        const title : string = this._translateService.instant('menu.actions.duplicate.value');

        const content : string = this._translateService.instant('menu.actions.duplicate.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this.menuService.duplicateItemAPI(id).subscribe((id): void => {

                this._snackBar.open(this._translateService.instant('menu.actions.duplicate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['menu/update/', id]);
            });
        });
    }

    public getLocaleTranslation(items, locale): LocaleItem {

        return items.find((pageTranslation): boolean => {

            return pageTranslation.locale === locale;
        });
    }
}
