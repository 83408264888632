import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {FormService} from "@core/shared/services/form.service";
import {Role} from "@core/shared/models/role";
import {UserService} from "@core/shared/services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
    selector: 'app-page-administrator-create',
    templateUrl: './page-administrator-create.component.html',
    styleUrls: ['./page-administrator-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageAdministratorCreateComponent implements OnInit {

    constructor(
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _userService: UserService,
        public formService: FormService
    ) {
    }

    ngOnInit(): void {

        this._initForm();
    }

    private _initForm(): void {

        const role: Role = 'ROLE_ADMIN';

        this.formService.form = this._formBuilder.group({
            lastName: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
            roles: [[role]],
            username: [''],
            locale: [this._userService.currentUser.value.locale, [Validators.required]],
            timezone: [this._userService.currentUser.value.timezone, [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            this.form.patchValue({
                username: this.form.get('email').value
            });

            this._userService.createItemAPI(this.form.value).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('administrator.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public redirectToList(): void {

        this._router.navigate(['account/administrator/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }
}
