<div class="row">

    <div class="col_12">

        <!-- Destinations -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Destination)">

            <div class="filter_item filter_item_big">
                <app-core-select-array-multiple-filter
                    #destinationFilter
                    [builder]="filterBuilder"
                    [label]="'destination.plural.value' | translate"
                    [key]="OfferCreatorFilterKey.Destination"
                    [operator]="'in'"
                    [itemValueProperty]="'id'"
                    [itemLabelProperty]="'label'"
                    [items]="offerAttributeRegions"
                ></app-core-select-array-multiple-filter>
            </div>

        </ng-container>

        <!-- Cibles -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Target)">

            <div class="filter_item">
                <app-core-select-array-multiple-filter
                    #targetFilter
                    [builder]="filterBuilder"
                    [label]="'target.plural.value' | translate"
                    [key]="OfferCreatorFilterKey.Target"
                    [operator]="'in'"
                    [itemValueProperty]="'id'"
                    [itemLabelProperty]="'label'"
                    [items]="offerCustomerTypologies"
                ></app-core-select-array-multiple-filter>
            </div>

        </ng-container>

        <!-- Réseaux créateur d'offres -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NetworkOfferCreator)">

            <div class="filter_item network_offer_creator">
                <app-core-select-array-multiple-filter
                    #networkOfferCreatorFilter
                    [builder]="filterBuilder"
                    [label]="'offer.list.filter.offerCreatorNetwork.value' | translate"
                    [key]="OfferCreatorFilterKey.NetworkOfferCreator"
                    [operator]="'in'"
                    [itemValueProperty]="'id'"
                    [itemLabelProperty]="'label'"
                    [items]="offerNetworkOfferCreators"
                ></app-core-select-array-multiple-filter>
            </div>

        </ng-container>

        <!-- Types d'offres -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.OfferType)">

            <div class="filter_item filter_item_small">
                <app-core-select-array-multiple-filter
                    #offerTypeFilter
                    [builder]="filterBuilder"
                    [label]="'offer.plural.type.plural.value' | translate"
                    [key]="OfferCreatorFilterKey.OfferType"
                    [operator]="'in'"
                    [itemValueProperty]="'id'"
                    [itemLabelProperty]="'label'"
                    [items]="offerTypes"
                ></app-core-select-array-multiple-filter>
            </div>

        </ng-container>

        <!-- Avec ou sans abonnement -->

        <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

            <div class="filter_item">
                <app-core-select-array-filter
                    #hasSubscriptionFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.filter.subscription.value' | translate"
                    [key]="OfferCreatorFilterKey.HasSubscription"
                    [operator]="'eq'"
                    [itemValueProperty]="'value'"
                    [itemLabelProperty]="'label'"
                    [items]="subscriptionStatus"
                ></app-core-select-array-filter>
            </div>

        </ng-container>

        <!-- Nom du créateur d'offre -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.Name)">

            <div class="filter_item">
                <app-core-text-filter
                    #nameFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.name.value' | translate"
                    [key]="OfferCreatorFilterKey.Name"
                    [operator]="'lk'"
                ></app-core-text-filter>
            </div>

        </ng-container>

        <!-- Présent dans mon catalogue -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.InCatalog)">

            <div class="filter_item">
                <app-core-checkbox-boolean-filter
                    #inCatalogFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.inCatalog.mine.value' | translate"
                    [key]="OfferCreatorFilterKey.InCatalog"
                ></app-core-checkbox-boolean-filter>
            </div>

        </ng-container>

        <!-- Labellisé RSE -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.HasRseLabel)">

            <div class="filter_item">
                <app-core-checkbox-boolean-filter
                    #hasRseLabelFilter
                    [builder]="filterBuilder"
                    [label]="'rse.labelled.value' | translate"
                    [key]="OfferCreatorFilterKey.HasRseLabel"
                ></app-core-checkbox-boolean-filter>
            </div>

        </ng-container>

        <!-- Créateurs pas encore sélectionnés -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.NotSelected)">

            <div class="filter_item">
                <app-core-checkbox-boolean-filter
                    #notSelectedFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.plural.notSelected.yet.value' | translate"
                    [key]="OfferCreatorFilterKey.NotSelected"
                ></app-core-checkbox-boolean-filter>
            </div>

        </ng-container>

        <!-- Type de réponse -->

        <ng-container *ngIf="offerCreatorSearchService.isFilterEnabled(OfferCreatorFilterKey.ResponseType)">

            <div class="filter_item">
                <app-core-select-filter
                    #responseTypeFilter
                    [builder]="filterBuilder"
                    [label]="'response.type.value' | translate"
                    [key]="OfferCreatorFilterKey.ResponseType"
                    [items]="responseTypes"
                    [itemValueProperty]="'id'"
                    [itemLabelProperty]="'label'"
                    [operator]="'eq'"
                ></app-core-select-filter>
            </div>

        </ng-container>

    </div>

</div>

<div class="row">

    <div class="col_12">

        <!-- Réinitialisation des filtres -->

        <button type="button" class="btn_cta btn_small" (click)="reset()">{{ 'offer.list.filter.reinitialise.value' | translate }}</button>

    </div>
</div>
