import {Component, OnInit} from '@angular/core';
import {INavigationItem} from "@app/navigation/shared/models/navigation";
import {NavigationService} from "@app/navigation/shared/services/navigation.service";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {environment} from "../environments/environment";
import {forkJoin, Subscription} from "rxjs";
import {IntroductionDialogComponent} from "@core/components/account/introduction-dialog/introduction-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {DEFAULT_LOCALE, SUPPORTED_LOCALES} from "@app/data";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {MatDialog} from "@angular/material/dialog";
import {TranslationService} from "@core/shared/services/translation.service";
import {IntroductionDialogService} from "@core/shared/services/introduction-dialog.service";
import {allowedLocales} from "@core/shared/utils/locale";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private _navigationItemsSubscription: Subscription;

    constructor(
        private _authenticationService: AuthenticationService,
        private _navigationService: NavigationService,
        private _translateService: TranslateService,
        private _translationService: TranslationService,
        private _userService: UserService,
        private _introductionDialogService: IntroductionDialogService,
        private _dialog: MatDialog
    ){}

    ngOnInit(): void {

        this._initTranslateConfiguration();

        if(environment.enableJiraPlugin){

            this._loadJiraPlugin();
        }

        this._authenticationService.jwtToken.subscribe((): void => {

            if(this.isAuthenticated){

                this._loadNavigationItems();
            }
            else{

                this._navigationItemsSubscription?.unsubscribe();

                this._navigationService.clear();
            }
        });

        this._userService.currentUser.subscribe((): void => {

            if(this.isAuthenticated && this._introductionDialogService.displayEnabled){

                this._initWelcomeDialog();
            }
        });
    }

    private _initTranslateConfiguration(): void {

        const items: { locale: string, regex: RegExp }[] = [
            {
                locale: 'fr',
                regex: /\/fr/
            },
            {
                locale: 'en',
                regex: /\/en/
            },
            {
                locale: 'es',
                regex: /\/es/
            },
            {
                locale: 'pt',
                regex: /\/pt/
            },
            {
                locale: 'it',
                regex: /\/it/
            },
            {
                locale: 'de',
                regex: /\/de/
            },
            {
                locale: 'nl',
                regex: /\/nl/
            }
        ];

        const currentItem = items.find((item:{ locale: string, regex: RegExp }): boolean => {

            return (window.location.href).includes(`${window.location.origin}/${item.locale}`);
        });

        let currentLocale: string = currentItem ? currentItem.locale : DEFAULT_LOCALE;

        this._translateService.addLangs(SUPPORTED_LOCALES);

        this._translateService.setDefaultLang(DEFAULT_LOCALE);

        this._translateService.use(currentLocale);

        this._translationService.onLangChange.subscribe((locale: string): void => {

            if(!locale){

                return;
            }

            const currentItem = items.find((item:{ locale: string, regex: RegExp }): boolean => {

                return item.locale === currentLocale;
            });

            if(currentItem.locale === locale){

                return;
            }

            const newItem = items.find((item:{ locale: string, regex: RegExp }): boolean => {

                return item.locale === locale;
            });

            if(environment.production){

                window.location.replace(window.location.href.replace(currentItem.regex, `/${newItem.locale}`));
            }
            else{

                this._translateService.use(locale);
            }

            currentLocale = locale;
        });

        this._userService.currentUser.subscribe((user: User): void => {

            if(!user){

                return;
            }

            this._translationService.onLangChange.next(user.locale);
        });

        const observables: { [p: string]: object } = {};

        allowedLocales.forEach((locale: string): void => {

            observables[locale] = this._translationService.getTranslationFile$(locale);
        });

        forkJoin(observables).subscribe((translations: { [p: string]: object }): void => {

            this._translationService.translations = translations;
        });
    }

    private _loadJiraPlugin(): void {

        const script = document.createElement("script");

        script.type = "text/javascript";

        script.src = "https://cafe-frappe.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/3/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=2346846e";

        script.async = true;

        document.body.appendChild(script);
    }

    private _loadNavigationItems(): void {

        this._navigationItemsSubscription = this._navigationService.itemsAPI.subscribe((items: INavigationItem[]): void => {

            this._navigationService.items = items.map((item: INavigationItem): INavigationItem => {

                return Object.assign(item, {
                    opened: false
                });
            });
        });
    }

    private _initWelcomeDialog(): void {

        const user: User = this._userService.currentUser.getValue();

        if (this._userService.hasRole(user, 'ROLE_OFFER_CREATOR') || this._userService.hasRole(user, 'ROLE_OFFER_DISTRIBUTOR')) {

            if (!user.society.termsAndConditions.length){

                this._dialog.open(IntroductionDialogComponent, {
                    width: '600px',
                    data: {
                        user: user
                    }
                });
            }
        }
    }

    get isAuthenticated(): boolean {

        return this._authenticationService.isAuthenticated;
    }

    get displayPoweredBy(): boolean {

        const currentUser: User = this._userService.currentUser.getValue();

        if(!currentUser) {

            return false;
        }

        if(!currentUser.society) {

            return false;
        }

        return !!currentUser.society.administrationLogo;
    }

    get navigationItems(): INavigationItem[] {

        return this._navigationService.items;
    }

    get displayCurrentUserRoles(): boolean {

        return !environment.production;
    }

    get parsedCurrentUserRoles(): string {

        return this._authenticationService.roles.join(' / ');
    }
}
