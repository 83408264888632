import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Offer} from "@core/shared/models/offer";
import {OfferPeriodDeleteConfiguration} from "@core/shared/models/offer/offer-period/offer-period-delete";
import {OfferPeriodDeleteComponent} from "@core/components/offer/offer-period/offer-period-delete/offer-period-delete.component";

@Component({
    selector: 'app-core-offer-period-delete-dialog',
    templateUrl: './offer-period-delete-dialog.component.html',
    styleUrls: ['./offer-period-delete-dialog.component.scss']
})
export class OfferPeriodDeleteDialogComponent implements OnInit {

    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(OfferPeriodDeleteComponent) offerPeriodDeleteComponent: OfferPeriodDeleteComponent;

    constructor(
        private _dialogRef: MatDialogRef<OfferPeriodDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offer: Offer }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.delete
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.offerPeriodDeleteComponent.formService.submit();
    }

    get offer(): Offer {

        return this.data.offer;
    }

    get configuration(): OfferPeriodDeleteConfiguration {

        return {
            showSubmitButton: false
        };
    }
}
