import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Pagination} from "../models/pagination";
import {Invoice} from "@core/shared/models/invoice";

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient
    ) {
    }

    public getItemsAPI(params?: string[]): Observable<Invoice[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/invoices`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Invoice[]>(url, {
            headers: headers
        });

    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Invoice>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/invoices`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Invoice>>(url, {
            headers: headers
        });

    }

    public getSocietyItemsAPI(id: number, params?: string[]): Observable<Invoice[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/invoices`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Invoice[]>(url, {
            headers: headers
        });
    }

    public getPaginationSocietyItemsAPI(id:number, params?: string[]): Observable<Pagination<Invoice>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}/invoices`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Invoice>>(url, {
            headers: headers
        });

    }

    public downloadItemAPI(item: Invoice, format: ('pdf'|'csv')): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/invoices/${item.id}/download/${format}`, {
            responseType: 'blob'
        });
    }

    public downloadItemsAPI(items: Invoice[], format: ('pdf'|'csv')): Observable<Blob> {

        const ids: number[] = items.map((item: Invoice): number => {

            return item.id;
        });

        const data: object = {
            ids: ids
        };

        return this._httpClient.post(`${this._apiService.getApiUrl(false, true)}/invoices/download/${format}`, data,{
            responseType: 'blob'
        });
    }
}
