import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Society} from "@core/shared/models/society";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MatSelectSearchComponent} from "ngx-mat-select-search";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {fromEvent, ReplaySubject} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {Address} from "@core/shared/models/address";
import {TranslationService} from "@core/shared/services/translation.service";
import {CountryService} from "@core/shared/services/country.service";
import {SocietyProviderService} from "@core/shared/services/society/society-provider.service";
import {OfferService} from "@core/shared/services/offer.service";
import {MatOption} from "@angular/material/core";

@Component({
    selector: 'app-offer-location-provider',
    templateUrl: './offer-location-provider.component.html',
    styleUrls: ['./offer-location-provider.component.scss']
})
export class OfferLocationProviderComponent implements OnInit, AfterViewInit {

    @Input() form: UntypedFormGroup;

    @Input() providersForm: UntypedFormGroup;

    @Input() formName: string;

    @Input() hideSelection: boolean = false;

    @Input() emitProviderLocationsUpdate: boolean = false;

    @ViewChild('nameFilter', {static: true}) nameFilterReference: MatSelectSearchComponent;

    public providersSocieties: Society[] = [];

    public optionFilterNameCtrl: UntypedFormControl = new UntypedFormControl();

    public filteredOptions: ReplaySubject<Society[]> = new ReplaySubject<Society[]>(1);

    public countries: Country[] = [];

    constructor(
        public translationService: TranslationService,
        private _offerService: OfferService,
        private _societyProviderService: SocietyProviderService,
        private _countryService: CountryService
    ) {}

    ngOnInit(): void {

        this._initCountries();

        this._initProviders();
    }

    ngAfterViewInit(): void {

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'focus').subscribe((): void => {

            this._filterOptions();
        });

        fromEvent(this.nameFilterReference.searchSelectInput.nativeElement, 'input')
            .pipe(
                map((event: any) => {
                    return event.target.value;
                }),
                debounceTime(500)
            )
            .subscribe((): void => {

                this._handleClearSelection();

                this._filterOptions();
            })
        ;
    }

    private _initCountries(): void {

        this._countryService.getItemsAPI().subscribe((countries: Country[]): void => {

            this.countries = countries;
        });
    }

    private _filterOptions(): void {

        const filters = this.providersSocieties.filter((society: Society) => {

            return society.name.toLowerCase().includes(this.optionFilterNameCtrl.value ? this.optionFilterNameCtrl.value.toLowerCase() : '');
        });

        this.filteredOptions.next(filters.slice());
    }

    private _initProviders(): void {

        this._offerService.providersSocieties$.subscribe((societies: Society[]) => {

            this.providersSocieties = societies;

            // Suppression des prestataires ne pouvant plus être associé à localisation

            const items: Society[] = [...(this.providersControl.value as Society[])];

            if(!items.length){

                return;
            }

            (this.providersControl.value as Society[]).forEach((controlProvider: Society): void => {

                const match: boolean = this.providersSocieties.some((providersSociety: Society): boolean => {

                    return providersSociety.id === controlProvider.id;
                });

                if(!match){

                    const itemIndex: number = items.findIndex((item: Society): boolean => {

                        return item.id === controlProvider.id;
                    });

                    items.splice(itemIndex, 1);
                }
            });

            // Mise à jour des prestataires de la localisation

            this.providersControl.patchValue(items);

            setTimeout((): void => {

                this._filterOptions();
            });
        });
    }

    private _handleClearSelection(): void {

        const element: HTMLButtonElement = this.nameFilterReference.innerSelectSearch.nativeElement.querySelector('.mat-select-search-clear');

        if (element) {

            element.onclick = (): void => {

                this.optionFilterNameCtrl.patchValue('');

                this._filterOptions();
            };
        }
    }

    public handleUpdate(option: MatOption): void {

        const provider: Society = option.value;

        setTimeout((): void => {

            if(this.emitProviderLocationsUpdate){

                this._offerService.providerLocationsUpdate$.next({
                    provider: provider,
                    selected: this.selectedProviders.some((selectedProvider: Society): boolean => {

                        return selectedProvider.id === provider.id
                    })
                });
            }
        });
    }

    public getMailingAddress(item: Society): Address {

        return item.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';
        });
    }

    public handleClosure(): void {

        this.optionFilterNameCtrl.patchValue('');

        this._filterOptions();
    }

    public removeProviderSelection(toDeleteItem: Society): void {

        const items: Society[] = [...this.providersControl.value];

        const index: number = items.findIndex((item: Society): boolean => {

            return item.id === toDeleteItem.id;
        });

        items.splice(index, 1);

        this.providersControl.patchValue(items);
    }

    public getCountryTranslation(society: Society): CountryTranslation {

        const item: Country = this.countries?.find((country: Country): boolean => {

            return country.code === this.getMailingAddress(society).country;
        });

        if (!item) {

            return null;
        }

        return this.translationService.getFallbackTranslation(item.translations);
    }

    public compareProvider(a: Society, b: Society): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    get providersControl(): UntypedFormGroup {

        return this.providersForm.get(this.formName) as UntypedFormGroup;
    }

    get selectedProviders(): Society[] {

        return this.providersForm.get(this.formName).value as Society[];
    }
}
