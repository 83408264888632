import {Component, OnInit} from '@angular/core';
import {Service} from "@core/shared/models/service";
import {FieldCollection} from "@lib/form/field";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {PromotionServiceService} from "@core/shared/services/promotion-service.service";
import {UserService} from "@core/shared/services/user.service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {Society} from "@core/shared/models/society";
import {REGEX_PERCENT} from "@core/shared/models/regex";
import {User} from "@core/shared/models/user";
import {PromotionService} from "@core/shared/models/promotion-service";
import moment, {Moment} from "moment";
import {DATETIME_FORMAT} from "@app/data";
import {Role} from "@core/shared/models/role";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {SocietyService} from "@core/shared/services/society.service";

@Component({
    selector: 'app-core-page-promotion-service-update',
    templateUrl: './page-promotion-service-update.component.html',
    styleUrls: ['./page-promotion-service-update.component.scss'],
    providers: [
        FormService
    ]
})
export class PagePromotionServiceUpdateComponent implements OnInit {

    public promotionService: PromotionService;

    public services: Service[] = [];

    public societyItems: { id: number, name: string }[] = [];

    public serviceItems: { id: number, name: string }[] = [];

    public fieldCollection = new FieldCollection();

    public monthlyDurations: { id: number, name: string}[] = [];

    public yearlyDurations: { id: number, name: string}[] = [];

    public durations: { id: number, name: string}[] = [];

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _promotionServiceService: PromotionServiceService,
        private _userService: UserService,
        private _societyService: SocietyService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { societies: Society[], services: Service[], promotionService: PromotionService }): void => {

            this.promotionService = data.promotionService;

            this.services = data.services;

            this.serviceItems = data.services.filter((service: Service): boolean => {

                return service.isWithSubscription || service.hasAdmissionPrice;

            }).map((service: Service): { id: number, name: string } => {

                return {
                    id: service.id,
                    name: this.translationService.getFallbackTranslation(service.translations).name
                };
            });

            this._initDurations();

            this._initForm();

            if(!this.promotionService.isUsed){

                this._initEvent();
            }

            this._hydrateForm();

            this._loadSocietyServices();

            this._hydrateDurations();
        });
    }

    private _initDurations(): void {

        for(let i: number = 1; i <= 12; i++){

            this.monthlyDurations.push({
                id: i,
                name: `${i} ${this._translateService.instant('month.value')}`
            });

            this.yearlyDurations.push({
                id: i,
                name: `${i} ${this._translateService.instant(i > 1 ? 'year.multiple.value' : 'year.value')}`
            });
        }
    }

    private _hydrateDurations(): void {

        const items: { periodicity: ('M'|'Y'), durations: { id: number, name: string}[] }[] = [
            {
                periodicity: 'M',
                durations: this.monthlyDurations
            },
            {
                periodicity: 'Y',
                durations: this.yearlyDurations
            }
        ];

        const item = items.find((item: { periodicity: ('M'|'Y'), durations: { id: number, name: string}[] }): boolean => {

            return item.periodicity === this.form.get('periodicity').value;
        });

        this.durations = item ? item.durations : [];
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            code: ['', [Validators.required]],
            quantity: [1, [Validators.required]],
            timezone: [this.currentUser.timezone, [Validators.required]],
            dateStart: [null, [Validators.required ,(control: UntypedFormControl) => {

              if(!this.form){

                return null;
              }

              if(!control.value){

                return null;
              }

              const dateStart: Moment =  moment(this.form.get('dateStart').value);
              const dateEnd: Moment = this.form.get('dateEnd').value ?  moment(this.form.get('dateEnd').value) : null;

              if(dateEnd && dateEnd.isBefore(dateStart)) {

                return {
                  'startAfter': {
                    valid: false
                  }
                }
              }

              return null;
            }]],
            timeStart: ['00:00', [Validators.required]],
            dateEnd: [null, [Validators.required, (control: UntypedFormControl) => {

              if(!this.form){

                return null;
              }

              if(!control.value){

                return null;
              }

              const dateStart: Moment = this.form.get('dateStart').value ? moment(this.form.get('dateStart').value) : null;
              const dateEnd: Moment =  moment(this.form.get('dateEnd').value);

              if(dateStart && dateStart.isAfter(dateEnd)) {

                return {
                  'startBefore': {
                    valid: false
                  }
                }
              }

              return null;
            }]],
            timeEnd: ['23:59', [Validators.required]],
            admissionPriceFree: [false, [Validators.required]],
            admissionPriceDiscount: [null,  [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                if(!this.service || !this.service.hasAdmissionPrice){

                    return null;
                }

                if(!control.parent || !control.parent.get('admissionPriceFree') || control.parent.get('admissionPriceFree').value){

                    return null;
                }

                if(!control.value){
                    return { isRequired: {valid: false}};
                }
                else {
                    return null;
                }

            }]],
            periodicity: ['M', [(control: UntypedFormControl) => {

                if(!this.service || !this.service.isWithSubscription){

                    return null;
                }

                return (control.value === null) ? { isRequired: {valid: false} } : null;
            }]],
            subscriptionFree: [false, [Validators.required]],
            subscriptionDiscount: ['', [Validators.pattern(REGEX_PERCENT), (control: UntypedFormControl) => {

                if(!this.service || !this.service.isWithSubscription){

                    return null;
                }

                if(!control.parent || !control.parent.get('subscriptionFree') || control.parent.get('subscriptionFree').value){

                    return null;
                }

                return (!control.value || !control.value.length) ? { isRequired: {valid: false} } : null;
            }]],
            subscriptionDiscountDuration: [null]
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'service',
                attrs: {
                    label: this._translateService.instant('promotionService.service.value'),
                    choices: this.serviceItems,
                    multiple: false,
                    required: true,
                    defaultValue: null
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'society',
                attrs: {
                    label: this._translateService.instant('promotionService.user.value'),
                    choices: [],
                    multiple: false,
                    required: true,
                    defaultValue: null
                }
            }
        });

        this.formService.submitCallback = (): void => {

            const dateStart: Moment = moment(this.form.get('dateStart').value);

            dateStart.set({
                hour: parseInt((this.form.get('timeStart').value as string).split(':')[0]),
                minute: parseInt((this.form.get('timeStart').value as string).split(':')[1])
            });

            const dateEnd: Moment = moment(this.form.get('dateEnd').value);

            dateEnd.set({
                hour: parseInt((this.form.get('timeEnd').value as string).split(':')[0]),
                minute: parseInt((this.form.get('timeEnd').value as string).split(':')[1])
            });

            const data: object = Object.assign(this.form.value, {
                society: { id: this.form.get('society').value },
                service: { id: this.form.get('service').value },
                dateStart: dateStart.format('YYYY-MM-DD HH:mm:ss'),
                dateEnd: dateEnd.format('YYYY-MM-DD HH:mm:ss'),
                admissionPriceDiscount: this.form.get('admissionPriceDiscount').value ? (parseInt(this.form.get('admissionPriceDiscount').value) / 100) : null,
                subscriptionDiscount: this.form.get('subscriptionDiscount').value ? (parseInt(this.form.get('subscriptionDiscount').value) / 100) : null
            });

            this._promotionServiceService.updateItemAPI(this.promotionService.id, data).subscribe((promotionService): void => {

                this._snackBar.open(this._translateService.instant('promotionService.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _hydrateForm(): void {

        setTimeout((): void => {

            const data: PromotionService = {...this.promotionService};

            this.form.patchValue(Object.assign(data, {
                admissionPriceDiscount: data.admissionPriceDiscount ?  data.admissionPriceDiscount * 100 : null,
                subscriptionDiscount: data.subscriptionDiscount ?  data.subscriptionDiscount * 100 : null,
                service: data.service.id,
                society: data.society.id,
                timeStart: moment(data.dateStart, DATETIME_FORMAT).format('HH:mm'),
                timeEnd: moment(data.dateEnd, DATETIME_FORMAT).format('HH:mm')
            }));

            this.form.get('quantity').disable();

            if(this.promotionService.isUsed) {
                this.form.disable();
            }

        });
    }

    private _updateDiscountValidators(controlName : string, controlToDisable: string): void {

        if(this.form.get(controlName).value){

            this.form.get(controlToDisable).clearValidators();

            this.form.get(controlToDisable).updateValueAndValidity();

        }
        else {

            this.form.get(controlToDisable).setValidators([Validators.required, Validators.pattern(REGEX_PERCENT)]);
        }

    }

    private _initEvent() {

        this.form.get('subscriptionDiscount').valueChanges.subscribe(() => {

            this._updateDiscountValidators('subscriptionDiscount', 'admissionPriceDiscount');

        });

        this.form.get('admissionPriceDiscount').valueChanges.subscribe(() => {

            this._updateDiscountValidators('admissionPriceDiscount', 'subscriptionDiscount');
        });

        this.form.get('periodicity').valueChanges.subscribe((): void => {

            this.form.get('subscriptionDiscountDuration').patchValue(null);

            this.form.get('subscriptionDiscountDuration').updateValueAndValidity();

            this._hydrateDurations();
        });

        this.form.get('admissionPriceFree').valueChanges.subscribe((): void => {

            this._updateDiscountValidators('admissionPriceFree', 'subscriptionDiscount');

            if(!this.form.get('subscriptionFree').value){

                this._updateDiscountValidators('admissionPriceFree', 'admissionPriceDiscount');
            }

        });

        this.form.get('subscriptionFree').valueChanges.subscribe((): void => {

            this._updateDiscountValidators('subscriptionFree', 'admissionPriceDiscount');

            if(!this.form.get('admissionPriceFree').value){

                this._updateDiscountValidators('subscriptionFree', 'subscriptionDiscount');
            }

        });
    }

    private _loadSocietyServices(): void {

        const params: string[] = [];

        this.service.roles.forEach((role: Role) => {

            const filter =  new ArrayFilterField('admin.roles', 'lkin', role);

            params.push(filter.serialize);

        });

        this._societyService.getItemsAPI(params).subscribe((societies: Society[]) => {

            this.societyItems = societies.map((society: Society): { id: number, name: string } => {

                return {
                    id: society.id,
                    name: `${society.admin.lastName} ${society.admin.firstName} | ${society.name}`
                };
            });

            (this.fieldCollection.getField('society') as any).config.attrs.choices = this.societyItems;

        });

    }

    public redirectToList(): void {

        this._router.navigate(['/account/service/promotion/list']);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get service(): Service {

        return this.services.find((service: Service): boolean => {

            return service.id === this.promotionService.service.id;
        });
    }

    get serviceQuantities(): Array<number> {

        const quantities = [];

        for (let quantity: number = this.service.min; quantity <= this.service.max; quantity++) {

            quantities.push(quantity);
        }

        return quantities;
    }
}
