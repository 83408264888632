import {Component, OnInit} from '@angular/core';
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SocietySubscription} from "@core/shared/models/society-subscription";

@Component({
    selector: 'app-page-subscription-payment-response',
    templateUrl: './page-subscription-payment-response.component.html',
    styleUrls: ['./page-subscription-payment-response.component.scss']
})
export class PageSubscriptionPaymentResponseComponent implements OnInit {

    public user: User;

    public subscriptions: SocietySubscription[];

    constructor(
        public _userService: UserService,
        private _router: Router,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        public  _activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {

        this.user = this._userService.currentUser.value;
    }

    public goToSubscriptions(): void {

        this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
    }

    public goToCgv(): void {

        this._router.navigate(['/account'], {fragment: 'cgv'});
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isSuccess(): boolean {

        return (this._activatedRoute.snapshot.params['response'] === 'success');
    }

    get paymentId(): string {

        return (this._activatedRoute.snapshot.params['reference']);
    }
}
