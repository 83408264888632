import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RelationshipCreateDialogComponent} from "@core/components/relationship/relationship-create-dialog/relationship-create-dialog.component";
import {RelationshipType} from "@core/shared/models/relationship";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTab, MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-core-page-relationship-list',
  templateUrl: './page-relationship-list.component.html',
  styleUrls: ['./page-relationship-list.component.scss']
})
export class PageRelationshipListComponent implements AfterViewInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('offerCreatorTab', {static: true}) offerCreatorTabRef: MatTab;

    @ViewChild('providerTab', {static: true}) providerTabRef: MatTab;

    @ViewChild('societyGroupTab', {static: true}) societyGroupTabRef: MatTab;

    @ViewChild('affiliationTab', {static: true}) affiliationTabRef: MatTab;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog,
    ) {}

    ngAfterViewInit() {

        if('targetTab' in this._activatedRoute.snapshot.queryParams){

            const targetTab: RelationshipType = this._activatedRoute.snapshot.queryParams['targetTab'];

            const callbacks: { [p in RelationshipType]: () => void } = {
                offerCreator: (): void => {

                    this.tabGroup.selectedIndex = this.offerCreatorTabRef.position;
                },
                provider: (): void => {

                    this.tabGroup.selectedIndex = this.providerTabRef.position;
                },
                societyGroup: (): void => {

                    this.tabGroup.selectedIndex = this.societyGroupTabRef.position;
                },
                affiliation: (): void => {

                    this.tabGroup.selectedIndex = this.affiliationTabRef.position;
                }
            }

            callbacks[targetTab]();
        }
    }

    public createItemDialog(): void {

        const dialogRef: MatDialogRef<RelationshipCreateDialogComponent> = this._dialog.open(RelationshipCreateDialogComponent, {
            width: '600px'
        });

        dialogRef.componentInstance.submit.subscribe((type: RelationshipType): void => {

            const callbacks: { [p in RelationshipType]: () => void } = {
                offerCreator: (): void => {

                    this._router.navigate(['account/network-offer-creator/create'], {queryParams: { origin: 'relationshipList' }});
                },
                provider: (): void => {

                    this._router.navigate(['account/offer/network-provider/create'], {queryParams: { origin: 'relationshipList' }});
                },
                societyGroup: (): void => {

                    this._router.navigate(['account/society-group/create'], {queryParams: { origin: 'relationshipList' }});
                },
                affiliation: (): void => {

                    this._router.navigate(['account/registration-affiliation/create'], {queryParams: { origin: 'relationshipList' }});
                }
            }

            callbacks[type]();
        });
    }
}
