import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField} from "@cafefrappe/ngx-intl-tel-input";
import {FormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-core-intl-tel-input',
    templateUrl: './intl-tel-input.component.html',
    styleUrls: ['./intl-tel-input.component.scss'],
})
export class IntlTelInputComponent implements OnInit, AfterViewInit {

    @Input() formGroup: UntypedFormGroup;

    @Input() controlName: string;

    @Input() customPlaceholder: string;

    @Input() preferredCountries: Array<string> = [CountryISO.France, CountryISO.Belgium, CountryISO.Canada, CountryISO.UnitedStates, CountryISO.UnitedKingdom];

    @Input() numberFormat: PhoneNumberFormat = PhoneNumberFormat.National;

    @Input() searchCountryField: SearchCountryField[] = [SearchCountryField.Iso2, SearchCountryField.Name];

    @Input() maxLength: string = '15';

    @Input() selectedCountryISO: CountryISO = CountryISO.France;

    @Input() phoneValidation: boolean = true;

    @Input() separateDialCode: boolean = true;

    @Input() enableAutoCountrySelect: boolean = true;

    @Input() enablePlaceholder: boolean = true;

    @Input() searchCountryFlag: boolean = true;

    @Input() selectFirstCountry: boolean = false;

    @Input() cssClass: string = 'custom';

    @Input() required: boolean = false;

    @ViewChild('reference', { static: true }) reference: ElementRef<HTMLDivElement>;

    @ViewChild(NgxIntlTelInputComponent) component: NgxIntlTelInputComponent;

    ngOnInit() {

        if(this.required){

            const control: FormControl = this.formGroup.get(this.controlName) as FormControl;

            control.addValidators([Validators.required]);
        }
    }

    ngAfterViewInit() {

        this.component.init();

        if(this.required){

            this.reference.nativeElement.querySelector('.iti__flag-container').classList.add('required');
        }
    }
}
