import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {SelectionModel} from "@angular/cdk/collections";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {Promotion, PromotionTranslation} from "@core/shared/models/promotion";
import {PromotionService} from "@core/shared/services/promotion.service";
import {Society} from "@core/shared/models/society";
import {PromotionTypeItem} from "@core/shared/models/promotion/promotion-type";
import {PROMOTION_PUBLIC_ITEMS, PromotionPublicItem} from "@core/shared/models/promotion/promotion-public";
import {Access} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-page-promotion-list',
    templateUrl: './page-promotion-list.component.html',
    styleUrls: ['./page-promotion-list.component.scss']
})
export class PagePromotionListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public society: Society;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'translations.name',
        'type',
        'value',
        'createdAt',
        'dateStart',
        'dateEnd',
        'public',
        'enable'
    ];

    public filterBuilder: FilterBuilder;

    public items: Promotion[] = [];

    public selection = new SelectionModel<Promotion>(true, []);

    public typeItems: PromotionTypeItem[] = [];

    public publicItems: PromotionPublicItem[] = [];

    public user: User;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _promotionService: PromotionService,
        private _userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.society = data.society;

            this._initTypeItems();

            this._initPublicItems();

            this._initFilterBuilder();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._promotionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: Promotion[]): void => {

                this.items = data;

                this.selection.clear();
            }
        );
    }

    private _initTypeItems(): void {

        this._translateService.get(['promotion.type.offer.value', 'promotion.type.code.value']).subscribe((translation: object): void => {

            this.typeItems = [
                {
                    value: 'offer',
                    label: translation['promotion.type.offer.value']
                },
                {
                    value: 'code',
                    label: translation['promotion.type.code.value']
                }
            ];
        });
    }

    private _initPublicItems(): void {

        this._translateService.get(['promotion.public.customer.value', 'promotion.public.distributor.value', 'promotion.public.networkProvider.value']).subscribe((translation: object): void => {

            this.publicItems = PROMOTION_PUBLIC_ITEMS.map((item: PromotionPublicItem): PromotionPublicItem => {

                return Object.assign({...item}, {
                    label: translation[`promotion.public.${item.value}.value`]
                });
            });
        });
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._promotionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Promotion[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._promotionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Promotion[]): void => {

            this.items = data;
        });
    }

    public getModel(item: Promotion): Promotion {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('promotion.delete.value');

        const content : string = this._translateService.instant('promotion.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._promotionService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('promotion.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._promotionService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Promotion[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/promotion/create']);
    }

    public editItem(id: number): void{

        this._router.navigate(['account/promotion/update', id]);
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Promotion>) => Promotion[] {

        return (data: Pagination<Promotion>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public hasAccessCreate(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'PROMOTION_CREATE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessEdit(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'PROMOTION_EDIT_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'PROMOTION_DELETE_IS_MINE';
        });

        return (accesses.length > 0)
    }

}
