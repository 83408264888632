import {Component, OnInit} from '@angular/core';
import {ICmsParams} from "@lib/cms/cms";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PageService} from "@core/shared/services/page.service";
import * as ClassicEditor from "@lib/ckeditor";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {FormService} from "@core/shared/services/form.service";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {FieldCollection} from "@lib/form/field";
import {Society} from "@core/shared/models/society";
import {Role} from "@core/shared/models/role";
import {Observable, of} from "rxjs";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-page-create',
    templateUrl: './page-create.component.html',
    styleUrls: ['./page-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageCreateComponent implements OnInit {

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public roles: string[] = [];

    public locales$: Observable<LocaleItem[]>;

    public dataCms = {
        content: {
            context: 'cms_content_page',
            id: 'tag',
            entityFieldName: 'content',
            entityName: 'Page',
            translations: []
        }
    }

    public cmsParams: ICmsParams = {
        context: 'cms_content_page',
        blocLabel: 'Bloc Label',
        ckeditor: {
            editor: this.editor
        },
        customButtons: [],
        entityName: 'Page',
        enableMultilingual: true,
        mode: 'create',
        tag: 'tag',
        entityFieldName: 'content',
        parentContainer: document.getElementById('admin-sidenav-content'),
        apiUrl: `${this._apiService.getApiUrl(false, true)}`,
        optionsImage: {
            enableTitle: true,
            enableSubtitle: true,
            enableAlt: true,
            enableLink: true,
            enableTargetBlank: true
        }
    };

    public fieldCollection = new FieldCollection();

    /**
     *
     * @param _formBuilder
     * @param _activatedRoute
     * @param pageService
     * @param _translateService
     * @param _translationService
     * @param _snackBar
     * @param formService
     * @param _apiService
     * @param _router
     */
    public constructor(private _formBuilder: UntypedFormBuilder, private _activatedRoute: ActivatedRoute, public pageService: PageService, private _translateService: TranslateService, private _translationService: TranslationService, private _snackBar: MatSnackBar, public formService: FormService, private _apiService: ApiService, private _router: Router) {
        this.roles = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'];
        this.formService.form = this._formBuilder.group({
            published: [''],
            content: [''],
            type_user: ['role', Validators.required],
            role: [''],
            roles: [['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']],
            societies: [[]],
            translations: new UntypedFormArray([]),
            target: ['_self', Validators.required]
        });

        this._initLocales();
        this._buildForm();
        this._initTranslationsForm();

        this.form.get('target').patchValue('_self');
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                titre: ['', [Validators.required]],
                description: ['', [Validators.required]],
                picture: this._formBuilder.group({
                    copyright: [''],
                    image: this._formBuilder.group({
                        image: ['', [Validators.required]]
                    })
                }),
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    public ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { societies: Society[] }): void => {
            data.societies.forEach((society: Society) => {
                this.societies.push(
                    {
                        id: society.id,
                        name: society.name
                    });
            });
        });

        this.form.get('locales').valueChanges.subscribe((): void => {
            this._handleTranslationControls();

            this.form.get('locales').value.forEach((locale) => {
                var exist = this.dataCms.content.translations.find((item) => {
                    return item.locale == locale
                });

                if (!exist) {
                    this.dataCms.content.translations.push({"id": null, "locale": locale, "rows": []});
                }
            });

            this.dataCms.content.translations.forEach((item, index) => {
                if (!this.form.get('locales').value.includes(item.locale)) {
                    this.dataCms.content.translations.splice(index, 1)
                }
            });
        });

        this.formService.submitCallback = (): void => {

            const data: object = this.form.value;
            this.pageService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('page.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _initLocales(): void {
        this.locales$ = of(LOCALE_ITEMS);
    }

    public patchRolesValue(role) {
        if (this.roles.indexOf(role) > -1) {
            const index: number = this.roles.indexOf(role);
            this.roles.splice(index, 1);
        } else {
            this.roles.push(role);
        }
        this.form.get('roles').setValue(this.roles);
    }

    public redirectToList(): void {
        this._router.navigate(['page/list']);
    }

    get imageConfig(): ImageConfig {
        return {
            id: 'image',
            gallery_context: 'card_contenu',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false
            }
        }
    }

    get descriptionEditorConfig(): CkeditorConfig {
        return {
            id: 'description',
            editor: this.editor,
            hasFontColor: true,
            attrs: {
                required: true
            }
        };
    }

    get publishedConfig() {
        return {
            id: 'published',
            attrs: {
                required: true,
                label: "Publié"
            }
        };
    }

    public getTranslation(index: number): UntypedFormGroup {
        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    private _buildForm(): void {
        this.form.addControl('locales', new UntypedFormControl([], [Validators.required]));

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('menu.users.title.value'),
                    required: false,
                    choices: this.societies,
                    multiple: true,
                }
            }
        });

        this.fieldCollection.addField({
            type: 'select',
            config: {
                id: 'target',
                attrs: {
                    required: true,
                    placeholder: this._translateService.instant('page.target.value'),
                    choices: [
                        {
                            '_self': 'Non',
                            '_blank': 'Oui'
                        }
                    ]
                }
            }
        });
    }

    get translationsControl(): UntypedFormArray {
        return this.form.get('translations') as UntypedFormArray;
    }

    get form(): UntypedFormGroup {
        return this.formService.form;
    }

    public changeSocieties(event) {
        this.form.get('societies').patchValue(event.value);
    }

    public hasRole(role: Role): boolean {
        return this.roles.indexOf(role) >= 0;
    }

    get selectedLocales(): string[] {
        if (!this.form || !this.form.get('locales')) {
            return [];
        }

        return this.form.get('locales').value;
    }

    private _handleTranslationControls(): void {
        const locales: string[] = this.form.get('locales').value;
        this.translationBuilder.updateItems(locales);
    }
}
