import {Component, OnInit} from '@angular/core';
import {IContent} from "./content";
import {ITranslation} from "../translation/translation";
import {TranslationService} from "../translation/translation.service";
import {ILocale} from "../translation/locale";
import {ApiService} from "../api/api.service";
import {CmsService} from "../cms.service";
import {TranslationCreateDialogComponent, TranslationCreateResult} from "../translation/translation-create-dialog/translation-create-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

    content: IContent;
    context: string = null;
    enableMultilingual: boolean = false;
    blocLabel: string = null;
    entityName: string = null;
    entityFieldName: string = null;
    form: HTMLElement = null;

    constructor(
        private _translateService: TranslateService,
        private _dialog: MatDialog,
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _translationService: TranslationService,
        private _snackbar: MatSnackBar
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {

        this.content = this._cmsService.getContent();
        this.context = this._cmsService.getContext();
        this.enableMultilingual = this._cmsService.isEnableMultilingual();
        this.blocLabel = this._cmsService.getBlocLabel();
        this.entityName = this._cmsService.getEntityName();
        this.entityFieldName = this._cmsService.getEntityFieldName();
    }

    /**
     * Retourne la liste des langues définies pour la page
     *
     * @param {boolean} orderBy
     * @returns {ITranslation[]}
     */
    getTranslations(orderBy: boolean): ITranslation[]{

        if(!orderBy){
            return this.content.translations;
        }


        const translations : ITranslation[] = [];

        // On retourne la langue par défaut en premier

        // translations.push(this._translationService.getTranslationByLocale(this._translationService.getDefaultLocale()));

        // On retourne ensuite toutes les autres langues, triées par ordre croissant sur le nom

        const others: ITranslation[] = this.content.translations.sort((a: ITranslation, b: ITranslation) : number => {

            const aLocaleName: string = this._translationService.getLocaleById(a.locale).name;
            const bLocaleName: string = this._translationService.getLocaleById(b.locale).name;

            return aLocaleName == bLocaleName ? 0 : + (aLocaleName > bLocaleName) || -1;
        });

        return translations.concat(others);
    }

    /**
     * Mise à jour de la langue affichée
     *
     * @param {ITranslation} translation
     */
    setCurrentTranslation(translation: ITranslation): void {
        this._translationService.setCurrentLocale(translation.locale);
    }

    /**
     * Retourne les classes du bouton pour une langue
     *
     * @param {ITranslation} translation
     * @returns {any}
     */
    getTranslationButtonClass(translation: ITranslation): any {

        return {
            'mat-accent': translation.locale === this._translationService.getCurrentLocale(),
            'active': translation.locale === this._translationService.getCurrentLocale()
        }
    }

    /**
     * Récupération d'une langue disponible en fonction de son ID (fr, en etc...)
     *
     * @param {string} id
     * @returns {ILocale}
     */
    getLocaleById(id: string): ILocale {
        return this._translationService.getLocaleById(id);
    }

    /**
     * Détermine si la langue passée en paramètre est celle actuellement affichée
     *
     * @param {ITranslation} translation
     * @returns {boolean}
     */
    isCurrentLocale(translation: ITranslation): boolean {
        return this._translationService.isCurrentLocale(translation.locale);
    }

    /**
     * Détermine si au moins une langue non définie pour la page est disponible
     *
     * @returns {boolean}
     */
    hasAvailableTranslation(): boolean {
        return this._translationService.getAvailableLocales(true).length > 0;
    }

    /**
     * Détermine si la langue peut être supprimée pour la page
     *
     * @param translation
     *
     * @return boolean
     */
    isDeletableTranslation(translation: ITranslation): boolean {

        return !this._translationService.isDefaultLocale(translation.locale);
    }

    /**
     * Affiche la modale pour l'ajout d'une langue
     */
    addTranslation(): void{

        const dialogRef = this._dialog.open(TranslationCreateDialogComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe((result: TranslationCreateResult) => {

            if(!result){
                return;
            }

            result.locales.map((locale: ILocale) => {

                const newTranslation: ITranslation = this._translationService.addTranslation(locale.id);

                if (result.duplicate && result.duplicateLocaleId) {

                    const duplicateTranslation: ITranslation = <ITranslation> JSON.parse(JSON.stringify(this._translationService.getTranslationByLocale(result.duplicateLocaleId)));

                    this._translationService.duplicateTranslation(newTranslation, duplicateTranslation);
                }
            });

            // On récupère la 1ère langue parmi les langues ajoutées,
            // Pour ensuite la définir comme langue actuellement affichée

            this._translationService.setCurrentLocale(result.locales[0].id);

            this._translateService.get(['translation.add.success', 'action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(messages['translation.add.success'], messages['action.close']);
            });
        });
    }

    /**
     * @param translation
     */
    deleteTranslation(translation: ITranslation): void {

        const locale: ILocale = this._translationService.getLocaleById(translation.locale);

        this._translateService.get(['language.delete.confirm.title', 'language.delete.confirm.content'], {'locale': locale.name}).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['language.delete.confirm.title'],
                content: messages['language.delete.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._translationService.deleteTranslation(translation);

                this._translateService.get(['translation.delete.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['translation.delete.success'], messages['action.close']);
                });
            });
        });
    }
}
