import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "@core/shared/models/user";
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {REGEX_ONLY_NUMBER} from "@core/shared/models/regex";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {SocietyGiftVoucherConfiguration} from "@core/shared/models/gift-voucher/society-gift-voucher-configuration";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-gift-voucher-renewal-dialog',
    templateUrl: './gift-voucher-update-grouped-dialog.html',
    styleUrls: ['./gift-voucher-update-grouped-dialog.scss'],
    providers: [FormService]
})
export class GiftVoucherUpdateGroupedDialog implements OnInit {

    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    private _giftVoucherRenewalDuration: number = 0;

    public defaultMinRenewalDuration: number = 1;

    public defaultMaxRenewalDuration: number = 18;

    public defaultMinPercentRenewal: number = 0;

    public defaultMaxPercentRenewal: number = 100;

    constructor(private _dialogRef: MatDialogRef<GiftVoucherUpdateGroupedDialog>,
                private _formBuilder: UntypedFormBuilder,
                private _giftVoucherService: GiftVoucherService,
                public formService: FormService,
                @Inject(MAT_DIALOG_DATA) public data: { society: Society, typeUpdate: ('duration'|'price'),  giftVouchers: GiftVoucher[], user:User,  }) {
    }

    ngOnInit(): void {

        this._loadConfiguration();
    }

    private _loadConfiguration(): void {

        this._giftVoucherService.getGiftVoucherConfiguration(this.data.user.society.id).subscribe((data: SocietyGiftVoucherConfiguration): void => {

            this._giftVoucherRenewalDuration = data.renewalDuration;

            this._initForm();

            const events: EventEmitter<void>[] = [
                this.create,
                this.back
            ];

            events.forEach((event: EventEmitter<void>): void => {

                event.subscribe((): void => {

                    this._dialogRef.close();
                });
            });
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            giftVouchers: [this.data.giftVouchers.map((data: GiftVoucher): { id: number } => {
                return {id: data.id}
            }), [Validators.required]]
        });

        if(this.data.typeUpdate === 'duration') {

            this.form.addControl('renewalDuration', this.renewalDurationGroup);
        }
        else if(this.data.typeUpdate === 'price') {

            this.form.addControl('renewalPercentPrice', this.renewalPriceGroup);
        }

        this.formService.submitCallback = () => {

            const data = {...this.form.value};

            if(this.data.typeUpdate === 'duration') {

                Object.assign(data, {
                    renewalDuration: parseInt(data.renewalDuration)
                });
            }

            if(this.data.typeUpdate === 'price') {

                Object.assign(data, {
                    renewalPercentPrice: (parseFloat(data.renewalPercentPrice) / 100).toFixed(4)
                });
            }

            this._giftVoucherService.updateItemsAPI(data).subscribe(() => {

                this.create.emit();
            });
        }
    }

    get renewalDurationGroup(): UntypedFormControl {

        return this._formBuilder.control(this._giftVoucherRenewalDuration, [Validators.min(this.defaultMinRenewalDuration),
            Validators.max(this.defaultMaxRenewalDuration),
            Validators.pattern(REGEX_ONLY_NUMBER)])
    }

    get renewalPriceGroup(): UntypedFormControl {

        return this._formBuilder.control(null, [
            Validators.min(this.defaultMinPercentRenewal),
            Validators.max(this.defaultMaxPercentRenewal),
            Validators.pattern(REGEX_ONLY_NUMBER),
            (control: UntypedFormControl): any => {

                if(!this.form || !control.value || !control.value.length){

                    return null;
                }

                if(this.society.isValidMangoPayAccount){

                    return null;
                }

                if(parseFloat(control.value) === 0){

                    return null;
                }

                return {
                    'isValueAllowedByMongopayAccountValidation': {
                        valid: false
                    }
                };
            }
        ]);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get society(): Society {

        return this.data.society;
    }
}
