import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SelectLocaleDialogComponent} from "@core/components/locale/select-locale-dialog/select-locale-dialog.component";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";

@Component({
    selector: 'app-core-translation-add',
    templateUrl: './translation-add.component.html',
    styleUrls: ['./translation-add.component.scss']
})
export class TranslationAddComponent implements OnInit {

    @Input() builder: TranslationBuilder;

    @Output() localeSelect: EventEmitter<LocaleItem> = new EventEmitter<LocaleItem>();

    @Input() selectedLocales = [];

    constructor(
        private _dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }

    openSelectLocaleDialog(): void {
        let localesItems = LOCALE_ITEMS;
        if(this.selectedLocales.length){
            localesItems = localesItems.filter((locale) => {
                return this.selectedLocales.includes(locale.id);
            })
        }


        const dialogRef: MatDialogRef<SelectLocaleDialogComponent> = this._dialog.open(SelectLocaleDialogComponent, {
            width: '500px',
            data: {
                items: localesItems.filter((item: LocaleItem): boolean => {

                    return this.builder.itemLocales.indexOf(item.id) < 0;
                })
            }
        });

        dialogRef.componentInstance.select.subscribe((localeItem: LocaleItem): void => {

            this.localeSelect.emit(localeItem);
        });
    }
}
