import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {Society} from "@core/shared/models/society";
import {Role, ROLE_LABELS, RoleLabel} from "@core/shared/models/role";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {SocietyService} from "@core/shared/services/society.service";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatSort} from "@angular/material/sort";
import {MatSnackBar} from '@angular/material/snack-bar';
import {DATE_UNDERSCORE_FORMAT} from "@app/data";
import {TranslationService} from "@core/shared/services/translation.service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import moment, {Moment} from "moment";

@Component({
    selector: 'app-core-page-user-list',
    templateUrl: './page-user-list.component.html',
    styleUrls: ['./page-user-list.component.scss']
})
export class PageUserListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    public totalItems: number = 0;

    private _user: User;

    public displayedColumns: string[] = [
        'actions',
        'createdAt',
        'name',
        'subscriptionUrl',
        'email',
        'phone',
        'admin.lastName',
        'admin.firstName',
        'admin.email',
        'admin.directPhone',
        'admin.cellphone',
        'admin.roles',
        'countCollaborators',
        'countChannels',
        'countOffers',
        'licences',
        'countOffersRestricted',
        'countOffersUnpublished',
        'totalMarketplaceOffers'
    ];

    filterBuilder: FilterBuilder;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public items: Society[];

    public filterRoles$:Observable<RoleLabel[]>;

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this._user = this._userService.currentUser.value;

        this._initFilterBuilder();

        this._loadFilterRoles();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._societyService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: Society[]): void => {

                this.items = data;
            });
    }

    private _loadFilterRoles(): void {

        this.filterRoles$ = this._translateService.get(['']).pipe(
            map((): RoleLabel[] => {

                const roles: Role[] = [
                    'ROLE_OFFER_CREATOR',
                    'ROLE_OFFER_DISTRIBUTOR',
                    'ROLE_PROVIDER',
                    'ROLE_INSTITUTIONAL',
                    'ROLE_FEDERATION'
                ];

                return roles.map((role: Role): RoleLabel => {

                    const match: RoleLabel = ROLE_LABELS.find((item: RoleLabel): boolean => {

                        return item.identifier === role;
                    });

                    return {
                        identifier: match.identifier,
                        label: this._translateService.instant(match.label)
                    };
                });
            })
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._societyService.getPaginationItemsAPI(this.itemsApiParams).pipe(
                map(this.mapApiResult)
            ).subscribe((data: Society[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._societyService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Society[]): void => {

            this.items = data;
        });
    }

    public getRoleLabel(roles: Role[]): string {

        const labels: string[] = roles.map((role: Role): string => {

            const match: RoleLabel = ROLE_LABELS.find((roleLabel: RoleLabel): boolean => {

                return roleLabel.identifier === role;
            });

            return this._translateService.instant(match.label);
        });

        return labels.join(', ');
    }

    public edit(id: number): void {

        this._router.navigate(['account/user/update/', id]);
    }

    public openDeleteDialog(id: number): void {

        const society: Society = this.items.find((society: Society): boolean => {

            return society.id === id;
        });

        const title : string = this._translateService.instant('user.delete.value');

        const content : string = this._translateService.instant('user.delete.description.value', {
            firstName: society.admin.firstName,
            lastName: society.admin.lastName
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.deleteItemAPI(society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._societyService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Society[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/user/create']);
    }

    public exportSocieties(): void {

        this._societyService.exportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('society.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public exportUsers(): void {

        this._userService.exportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('user.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public getModel(item: Society): Society {

        return item;
    }

    public hasRole(role: Role): boolean {

        return this._user.roles.indexOf(role) >= 0;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {
            params.push(`sort[createdAt]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Society>) => Society[] {

        return (data: Pagination<Society>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }
}
