import {Component, Input, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {UserService} from "@core/shared/services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {Moment} from "moment";
import * as moment from "moment";
import {PaymentService} from "@core/shared/services/payment.service";
import {LoaderService} from "@core/shared/services/loader.service";
import {parsePrice} from "@core/shared/utils/price";
import {Subscription} from "@core/shared/models/subscription";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'subscription-form',
    templateUrl: './subscription-form.component.html',
    styleUrls: ['./subscription-form.component.scss'],
    providers: [
        FormService
    ]
})
export class SubscriptionFormComponent implements OnInit {

    @Input() subscriptions: Subscription[] = [];

    @Input() data: SocietySubscription[] = [];

    @Input() isDisabled: boolean = false;

    @Input() dialogRef: MatDialogRef<any> = null;

    @Input() society: Society = null;

    @Input() redirectionToSubscriptionViewEnabled: boolean = false;

    public availableSubscriptions: Subscription[] = [];

    public rank: number = null;

    public hasWaitingProcess: boolean = false;

    public hasWaitingPayment: boolean = false;

    public hasWaitingDowngrade: boolean = false;

    public hasPromotion: boolean = false;

    public promotion: number = 0;

    public dateEndPromotion: string = null;

    public dateStartSubscription: string = null;

    public birthdayDate: Moment;

    public dateNextInvoice: Moment;

    public customizedSubscriptionAllowed: boolean = false;

    public customizedSubscription: boolean = false;

    public summarySocietySubscription: SocietySubscription = null;

    public isSubscriptionWithoutOffer: boolean = false;

    constructor(
        private _dialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private _subscriptionService: SubscriptionService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _router: Router,
        private _paymentService: PaymentService,
        private _loaderService: LoaderService,
        public formService: FormService
    ) {
    }

    public ngOnInit(): void {

        this.society = this.society || this._userService.currentUser.value.society;

        this._initForm();

        if (this.data && this.data.length) {

            // Au moins un abonnement est actuellement rattaché au compte

            this._handleCurrentSocietySubscriptions();

            if(this.currentValidSocietySubscription){

                this._hydrateForm();
            }

            if(!this.isDisabled && this.isCurrentValidSocietySubscriptionWithOffer){

                this._loadSummary();
            }

            if(this.isCurrentValidSocietySubscriptionWithoutOffer){

                this.isSubscriptionWithoutOffer = true;
            }
        }

        this._handleAvailableSubscriptions();

        if(this.isCurrentValidSocietySubscriptionWithOffer) {

            this.customizedSubscriptionAllowed = this.currentValidSocietySubscription.subscription.rank >= this.customizableAvailableSubscription.rank;

            this.customizedSubscription = this.currentValidSocietySubscription.subscription.rank > this.customizableAvailableSubscription.rank;
        }

        this._initEvents();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            periodicity: [null, [Validators.required]],
            subscription: [null, [Validators.required]]
        });

        this.formService.submitCallback = (): void => {

            if(this.isDisabled){

                return;
            }

            const subscription: Subscription = this.subscriptions.find((subscription: Subscription): boolean => {

                return subscription.id === this.form.get('subscription').value;
            });

            if((subscription.admissionPrice === 0) && this.currentValidSocietySubscription) {

                const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                    width: '500px',
                    data: {
                        title: this._translateService.instant('form.leaveWithoutSubmit.value'),
                        content: this._translateService.instant('subscription.downgrade.alert.description.value')
                    }
                });

                dialogRef.componentInstance.confirm.subscribe((): void => {

                    this._save();
                });

            } else {

                this._save();
            }
        }
    }

    private _initEvents(): void {

        this.form.get('periodicity').valueChanges.subscribe((): void => {

            this._handleAvailableSubscriptions();
        });

        [this.form.get('periodicity'), this.form.get('subscription')].forEach((control: UntypedFormControl): void => {

            control.valueChanges.subscribe((): void => {

                setTimeout((): void => {

                    if(this.selectedSubscription && this.selectedSubscription.maxOffer > 0){

                        this._loadSummary();
                    }
                    else{

                        this.summarySocietySubscription = null;
                    }
                });
            });
        });
    }

    private _handleCurrentSocietySubscriptions(): void {

        const now: Moment = moment();

        this.data.forEach((subscription: SocietySubscription): void => {

            if (!subscription.isValid && subscription.paymentStatus == 0) {

                this.hasWaitingProcess = true;

                if(subscription.admissionPrice){

                    this.hasWaitingPayment = true;
                }

                return;
            }

            if (subscription.paymentStatus == 2) {

                this.hasWaitingProcess = false;

                this.hasWaitingDowngrade = true;

                return;
            }

            const dateStart: Moment = moment(subscription.createdAt);

            this.dateStartSubscription = dateStart.format('YYYY-MM-DD');

            if (subscription.dateEndPromotion) {

                const dateEndPromotion: Moment = moment(subscription.dateEndPromotion);

                if (now.format('YYYY-MM-DD') < dateEndPromotion.format('YYYY-MM-DD')) {

                    this.hasPromotion = true;

                    this.promotion += subscription.promotionPrice;

                    this.dateEndPromotion = dateEndPromotion.format('YYYY-MM-DD');
                }
            }
        });

        if(this.currentValidSocietySubscription){

            this.rank = this.currentValidSocietySubscription.subscription.rank;

            this.dateNextInvoice = moment(this.currentValidSocietySubscription.dateNextInvoice);

            this.birthdayDate = moment(this.currentValidSocietySubscription.dateNextInvoice);

            this.birthdayDate = this.birthdayDate.subtract(1, 'days');
        }
    }

    private _hydrateForm(): void {

        const data = Object.assign({...this.currentValidSocietySubscription}, {
            subscription: this.currentValidSocietySubscription.subscription.id
        });

        this.form.patchValue(data);
    }

    private _save(): void {

        this._loaderService.show();

        const data: object = Object.assign(this.form.value, {
            subscription: { id : this.form.get('subscription').value },
        });

        this._subscriptionService.createItemAPI(data, this.society.id).subscribe((societySubscription): void => {

            switch (true){

                // case (this.isCurrentValidSocietySubscriptionWithoutOffer && this.isSubscriptionWithoutOffer):
                //
                //     // Abonnement sans offre
                //
                //     this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
                //
                //     this._snackBar.open(this._translateService.instant('subscription.validation.success.value'), this._translateService.instant('notification.close.action.value'), {
                //         duration: 5000
                //     });
                //
                //     break;

                case ((societySubscription.paymentStatus === 2) && this.isSubscriptionWithoutOffer):

                    // Abonnement sans offre

                    this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});

                    this._snackBar.open(this._translateService.instant('subscription.validation.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    break;

                case ((societySubscription.paymentStatus === 2) && this.isDowngrade):

                    // Downgrade d'un abonnement

                    this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});

                    this._snackBar.open(this._translateService.instant('subscription.downgrade.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    break;

                default:

                    // Upgrade d'un abonnement

                    this._router.navigate(['account/subscription/payment']);
            }

            if (this.dialogRef) {

                this.dialogRef.close();
            }

            this._loaderService.hide();
        });
    }

    private _handleAvailableSubscriptions(): void {

        if(this.isSubscriptionWithoutOffer || !this.form.get('periodicity').value){

            this.availableSubscriptions = [];

            return;
        }

        this.availableSubscriptions = this.subscriptions.filter((subscription: Subscription): boolean => {

            if(!subscription.display){

                return false;
            }

            // On affiche uniquement les abonnements disponibles ou l'abonnement actuel (quelque soit sa disponibilité)

            return subscription.available || (!!this.currentValidSocietySubscription && this.currentValidSocietySubscription.subscription.id === subscription.id);

        }).sort((a: Subscription, b: Subscription): number => {

            return a.rank - b.rank;
        });

        this.availableSubscriptions.forEach((subscription: Subscription): void => {

            subscription.customizable = (this.customizableAvailableSubscription.id === subscription.id);
        });
    }

    private _loadSummary(): void {

        const data: object = Object.assign(this.form.value, {
            subscription: {id: this.form.get('subscription').value},
        });

        this._subscriptionService.createSummaryItemAPI(data, this.society.id).subscribe((societySubscription: SocietySubscription): void => {

            this.summarySocietySubscription = societySubscription;
        });
    }

    public submit(): void {

        if(this.isDisabled){

            return;
        }

        this.formService.submit();
    }

    public parsePrice(price: number): string {

        return parsePrice(price / 100);
    }

    public formatPrice(price: number): string {

        return (price) ? parsePrice(price / 100) + ' € HT' : 'NC';
    }

    public handlePeriodicitySelection(value: ('M' | 'Y')): void {

        if(this.isDisabled){

            return;
        }

        this.form.get('periodicity').patchValue(value);
    }

    public handleSubscriptionWithoutOfferSelection(): void {

        this.form.get('periodicity').patchValue('M' as ('M'|'Y'));

        if(this.isSubscriptionWithoutOffer){

            const subscription: Subscription = this.subscriptionWithoutOffer;

            this.form.get('subscription').patchValue(subscription.id);
        }
        else{

            this.form.get('subscription').patchValue(null);
        }

        this.customizedSubscriptionAllowed = false;

        this.customizedSubscription = false;

        this._handleAvailableSubscriptions();
    }

    public handleSubscriptionSelection(subscription: Subscription): void {

        if(subscription.customizable && this.customizedSubscriptionAllowed){

            return;
        }

        if(this.isDisabled){

            return;
        }

        if(!this.isSubscriptionAllowed(subscription)){

            return;
        }

        this.customizedSubscriptionAllowed = subscription.customizable;

        this.customizedSubscription = false;

        this.form.get('subscription').patchValue(subscription.id);
    }

    public incrementCustomizableSubscription(): void {

        if(!this.customizedSubscriptionAllowed){

            return;
        }

        if(this.isMaxCustomizableSubscription()){

            return;
        }

        const nextSubscription: Subscription = this.getSubscriptionByRank(this.selectedSubscription.rank + 1);

        if(!this.isSubscriptionAllowed(nextSubscription)){

            return;
        }

        this.customizedSubscription = true;

        this.form.get('subscription').patchValue(nextSubscription.id);
    }

    public decrementCustomizableSubscription(): void {

        if(!this.customizedSubscriptionAllowed){

            return;
        }

        if(this.isMinCustomizableSubscription()){

            return;
        }

        const previousSubscription: Subscription = this.getSubscriptionByRank(this.selectedSubscription.rank - 1)

        if(!this.isSubscriptionAllowed(previousSubscription)){

            return;
        }

        this.customizedSubscription = true;

        this.form.get('subscription').patchValue(previousSubscription.id);
    }

    public getSubscriptionByRank(rank: number): Subscription {

        return this.subscriptions.find((subscription: Subscription): boolean => {

            return subscription.rank === rank;
        });
    }

    public getPeriodicityClasses(value: ('M' | 'Y')): {[p :string] : boolean} {

        return {
            tile_checked: this.form.get('periodicity').value === value,
            readonly: this.isDisabled
        };
    }

    public getSubscriptionClasses(value: Subscription): {[p :string] : boolean} {

        return {
            tile_checked: (this.form.get('subscription').value === value.id) || (value.customizable && this.customizedSubscriptionAllowed),
            disabled: !this.isSubscriptionAllowed(value),
            readonly: this.isDisabled
        };
    }

    public identifySubscription(index: number, item: Subscription): string {

        return `${item.id}`;
    }

    public isMinCustomizableSubscription(): boolean {

        return Math.min(...this.customizableMaxOffers) === this.selectedSubscription.maxOffer;
    }

    public isMaxCustomizableSubscription(): boolean {

        return Math.max(...this.customizableMaxOffers) === this.selectedSubscription.maxOffer;
    }

    public isSubscriptionAllowed(subscription: Subscription): boolean {

        return subscription ? subscription.available : false;
    }

    public redirectToList(): void {

        this._router.navigate(['account/subscription/list'], { queryParams : { targetTab: 'licenceInformations' }});
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get currentValidSocietySubscription(): SocietySubscription {

        if(!this.data){

            return null;
        }

        return this.data.find((item: SocietySubscription): boolean => {

            return item.isValid && [1, -1].includes(item.paymentStatus);
        });
    }

    get isCurrentValidSocietySubscriptionWithOffer(): boolean {

        if(!this.currentValidSocietySubscription){

            return false;
        }

        return this.currentValidSocietySubscription.subscription.maxOffer > 0;
    }

    get isCurrentValidSocietySubscriptionWithoutOffer(): boolean {

        if(!this.currentValidSocietySubscription){

            return false;
        }

        return this.currentValidSocietySubscription.subscription.maxOffer === 0;
    }

    get customizableAvailableSubscription(): Subscription {

        if(!this.availableSubscriptions.length){

            return null;
        }

        const count: number = this.availableSubscriptions.length;

        return this.availableSubscriptions[count - 1];
    }

    get selectedSubscription(): Subscription {

        if(!this.form || !this.form.get('subscription') || !this.form.get('subscription').value){

            return null;
        }

        return this.subscriptions.find((subscription: Subscription): boolean => {

            return subscription.id === this.form.get('subscription').value;
        });
    }

    get subscriptionWithoutOffer(): Subscription {

        return this.subscriptions.find((subscription: Subscription): boolean => {

            return subscription.maxOffer === 0;
        });
    }

    get customizableMaxOffers(): number[] {

        const customizableAvailableSubscription: Subscription = this.customizableAvailableSubscription;

        const subscriptions: Subscription[] = this.subscriptions.filter((subscription: Subscription): boolean => {

            return subscription.rank >= customizableAvailableSubscription.rank;
        });

        return subscriptions.map((subscription: Subscription): number => {

            return subscription.maxOffer;
        });
    }

    get incrementCustomizableSubscriptionClasses(): {[p :string] : boolean} {

        const isNextSubscriptionAllowed: boolean = this.selectedSubscription ? this.isSubscriptionAllowed(this.getSubscriptionByRank(this.selectedSubscription.rank + 1)) : true;

        return {
            disabled: !this.customizedSubscriptionAllowed || this.isMaxCustomizableSubscription() || !isNextSubscriptionAllowed
        };
    }

    get decrementCustomizableSubscriptionClasses(): {[p :string] : boolean} {

        const isPreviousSubscriptionAllowed: boolean = this.selectedSubscription ? this.isSubscriptionAllowed(this.getSubscriptionByRank(this.selectedSubscription.rank - 1)) : true;

        return {
            disabled: !this.customizedSubscriptionAllowed || this.isMinCustomizableSubscription() || !isPreviousSubscriptionAllowed
        };
    }

    get isUpgrade(): boolean {

        if(!this.selectedSubscription){

            return false;
        }

        if(!this.currentValidSocietySubscription){

            return true;
        }

        const activeSocietySubscription: SocietySubscription = this.currentValidSocietySubscription;

        const newPeriodicity: ('M'|'Y') = this.form.get('periodicity').value;

        if((this.selectedSubscription.id === activeSocietySubscription.subscription.id) && (activeSocietySubscription.periodicity === newPeriodicity)){

            return false;
        }

        return !this.isDowngrade;
    }

    get isDowngrade(): boolean {

        if(!this.selectedSubscription){

            return false;
        }

        if(!this.currentValidSocietySubscription){

            return false;
        }

        const activeSocietySubscription: SocietySubscription = this.currentValidSocietySubscription;

        const activeSubscription: Subscription = activeSocietySubscription.subscription;

        const newPeriodicity: ('M'|'Y') = this.form.get('periodicity').value;

        const newSubscription: Subscription = this.selectedSubscription;

        if((this.selectedSubscription.id === activeSocietySubscription.subscription.id) && (activeSocietySubscription.periodicity === newPeriodicity)){

            return false;
        }

        if(activeSocietySubscription.periodicity !== newPeriodicity){

            return (activeSocietySubscription.periodicity === 'Y') && (newPeriodicity === 'M');
        }

        return activeSubscription.maxOffer > newSubscription.maxOffer;
    }

    get isSubscriptionWithoutOfferUpdate(): boolean {

        return this.isCurrentValidSocietySubscriptionWithoutOffer && this.isSubscriptionWithoutOffer;
    }

    get isSubmitAllowed(): boolean {

        return this.isUpgrade || this.isDowngrade;
    }
}
