import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {Quotation} from "@core/shared/models/quotation";
import {QuotationService} from "@core/shared/services/quotation.service";
import {StatusType} from "@core/shared/models/status-type";
import {QUOTATION_STATUS} from "@core/shared/data/quotation/quotation-status";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import moment, {Moment} from "moment/moment";
import {DATE_UNDERSCORE_FORMAT} from "@app/data";
import {Access, AccessType} from "@core/shared/models/access";

@Component({
    selector: 'app-core-page-quotation-list',
    templateUrl: './page-quotation-list.component.html',
    styleUrls: ['./page-quotation-list.component.scss']
})
export class PageQuotationListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public status: StatusType[] = [];

    public customerTypologies: { id: number, label: string }[];

    public totalItems: number = 0;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: Quotation[];

    public currentUser: User;

    constructor(
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _quotationService: QuotationService,
        private _customerTypologyService: CustomerTypologyService,
        public userService: UserService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.getValue();

        this._initStatus();

        this._initCustomerTypology();

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems();
                    }),
                    map(this.mapApiResult),
                ).subscribe((data: Quotation[]): void => {

                    this.items = data;
                })
            ;
        }

        this._loadItems();
    }

    private _initStatus(): void {

        this.status = QUOTATION_STATUS.map((item: StatusType ) => {

            return {
                id: item.id,
                label: this._translateService.instant( item.label )
            };
        });
    }

    private _initCustomerTypology(): void {

        this._customerTypologyService.getItemsAPI().subscribe((customerTypologies: CustomerTypology[]): void => {

            this.customerTypologies = customerTypologies.map((item: CustomerTypology): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).name
                }
            });
        });
    }

    private _initDisplayedColumns(): void {

        this.displayedColumns.push('actions');

        this.displayedColumns.push('society');

        this.displayedColumns.push('responsibleUserLastName');

        this.displayedColumns.push('responsibleUserFirstName');

        this.displayedColumns.push('createdAt');

        this.displayedColumns.push('ownerReference');

        this.displayedColumns.push('reference');

        this.displayedColumns.push('status');

        this.displayedColumns.push('closedAt');

        this.displayedColumns.push('customerTypology');

        this.displayedColumns.push('regions');

        this.displayedColumns.push('pricing.budget');
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems().pipe(
                map(this.mapApiResult)
            ).subscribe((data: Quotation[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems(): Observable<Pagination<Quotation>> {

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {

            return this._quotationService.getPaginationItemsAPI(this.itemsApiParams);

        } else {

            return this._quotationService.getPaginationSocietyItemsAPI(this.currentUser.society.id, this.itemsApiParams);
        }
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Quotation[]): void => {

            this.items = data;
        });
    }

    public view(id: number): void {

        this._router.navigate(['account/quotation/read', id]);
    }

    public getModel(item: Quotation): Quotation {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const title : string = this._translateService.instant('quotation.delete.value');

        const content : string = this._translateService.instant('quotation.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._quotationService.deleteItemAPI(id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('quotation.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: Quotation[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public parsedItemRegions(item: Quotation): string {

        return item.configuration.regions.map((region: OfferAttribute): string => {

            return this.translationService.getFallbackTranslation(region.translations).label;

        }).join(', ');
    }

    public exportQuotation(): void {

        this._quotationService.exportItemsAPI(this.currentUser.society.id).subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('quotation.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public adminExportQuotation(): void {

        this._quotationService.adminExportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('quotation.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public hasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.currentUser.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public isAdmin(): boolean {

        return this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Quotation>) => Quotation[] {

        return (data: Pagination<Quotation>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get isQuotationUpdateAllowed(): boolean {

        return this.currentUser.accesses.some((access: Access): boolean => {

            return access.tag === 'QUOTATION_EDIT_IS_MINE';
        });
    }
}
