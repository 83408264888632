import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {Observable} from "rxjs";
import {CustomerType} from "@core/shared/models/customer/customer-type";
import {CustomerTypeService} from "@core/shared/services/customer/customer-type.service";
import {TranslationBuilder} from "@core/shared/models/translation";
import {FileService} from "@core/shared/services/file.service";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {Country} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ConditionOfUseDialogComponent} from "@core/components/condition-of-use/condition-of-use-dialog.component";
import {User} from "@core/shared/models/user";
import {tap} from "rxjs/operators";
import {SUPPORTED_LOCALES} from "@app/data";
import {Address} from "@core/shared/models/address";

export type Origin = 'booking' | 'request' | 'gift-voucher';

@Component({
    selector: 'app-core-customer-form-create',
    templateUrl: './customer-form-create.component.html',
    styleUrls: ['./customer-form-create.component.scss'],
    providers: [
        FormService
    ]
})
export class CustomerFormCreateComponent implements OnInit {

    @Input() origin: Origin;

    @Input() parentForm: UntypedFormGroup;

    @Input() controlName: string;

    @Input() termsAndConditionsFileIdentifier: number;

    @Input() locales: string[];

    @Input() currentUser: User;

    @Input() viewLanguage: string;

    @Output() controlInitialized: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

    @Output() localeUpdated: EventEmitter<string> = new EventEmitter<string>();

    public customerTypes: CustomerType[] = [];

    public countries$: Observable<Country[]>;

    public translationBuilder: TranslationBuilder;

    public spokenLanguages: string[] = SUPPORTED_LOCALES;

    public maxLengthComment: number = 3000;

    constructor(
        private _dialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private _customerTypeService: CustomerTypeService,
        private _fileService: FileService,
        private _countryService: CountryService,
        private _translateService: TranslateService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._loadCountries();

        if(this.isOneOfTheseOrigins(['booking', 'gift-voucher'])){

            this._initCustomerTypes();
        }

        this._initForm();

        this._initEvents();
    }

    private _initCustomerTypes(): void {

        this._customerTypeService.getItemsAPI().subscribe((customerTypes: CustomerType[]): void => {

            this.customerTypes = customerTypes;
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            lastName: ['', [(control: FormControl): { [key: string]: any } => {

                if(!this.isOneOfTheseOrigins(['booking', 'gift-voucher'])){

                    return null;
                }

                if (control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }
            }]],
            firstName: ['', [(control: FormControl): { [key: string]: any } => {

                if(!this.isOneOfTheseOrigins(['booking', 'gift-voucher'])){

                    return null;
                }

                if (control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }
            }]],
            locale: [this.isOneOfTheseOrigins(['booking', 'gift-voucher']) ? this.userFallbackLocale : this.viewLanguage, [Validators.required]],
            country: ['', [Validators.required]],
            comment: [null, [Validators.maxLength(this.maxLengthComment)]],
            acceptTermsAndConditions: [false, [Validators.requiredTrue]],
            acceptConditionsUse: [false, [Validators.requiredTrue]]
        });

        if(this.isOneOfTheseOrigins(['booking', 'gift-voucher'])){

            this.form.addControl('customerType', this._formBuilder.control(null, [Validators.required]));

            this.form.addControl('society', this._formBuilder.control('', [(control: UntypedFormControl): { [key: string]: any } => {

                if(!this.isSocietyRequired){

                    return null;
                }

                if(!!control.value){

                    return null;
                }

                return {
                    'societyRequired' : {
                        valid: false
                    }
                };
            }]));

            this.form.addControl('civility', this._formBuilder.control(null, [Validators.required]));

            this.form.addControl('email', this._formBuilder.control('', [Validators.pattern(REGEX_EMAIL)]));

            this.form.addControl('phone', this._formBuilder.control('', [Validators.required]));

            this.form.addControl('address', this._formBuilder.control('', [Validators.required]));

            this.form.addControl('additionalAddress', this._formBuilder.control(''));

            this.form.addControl('zipcode', this._formBuilder.control('', [Validators.required]));

            this.form.addControl('city', this._formBuilder.control('', [Validators.required]));

            this.form.addControl('region', this._formBuilder.control(''));
        }

        if(this.isOneOfTheseOrigins(['request'])){

            this.form.addControl('spokenLanguage', this._formBuilder.control(this.userFallbackLocale));

            this.form.addControl('hasOtherSpokenLanguages', this._formBuilder.control(false, [Validators.required]));

            this.form.addControl('otherSpokenLanguages', this._formBuilder.control(null, [(control: FormControl) => {

                if(!(this.form.get('hasOtherSpokenLanguages').value)){

                    return null;
                }

                if(!!control.value && Boolean(control.value.length)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };
            }]));
        }

        this.parentForm.addControl(this.controlName, this.form);

        this.controlInitialized.emit(this.form);

        this.translationBuilder = new TranslationBuilder(this._formBuilder);
    }

    private _initEvents(): void {

        if(this.isOneOfTheseOrigins(['booking', 'gift-voucher'])){

            this.form.get('customerType').valueChanges.subscribe(() : void => {

                this.form.get('society').patchValue(null);
            });
        }

        this.form.get('locale').valueChanges.subscribe((value: string) : void => {

            this.localeUpdated.emit(value);
        });

        if(this.isOneOfTheseOrigins(['request'])){

            this.form.get('spokenLanguage').valueChanges.subscribe((value: string): void => {

                if(value){

                    this.form.get('hasOtherSpokenLanguages').patchValue(false);

                    this.form.get('otherSpokenLanguages').patchValue(null);
                }
                else{

                    this.form.get('hasOtherSpokenLanguages').patchValue(true);

                    this.form.get('otherSpokenLanguages').markAsTouched();
                }
            });
        }
    }

    private _loadCountries(): void {

        this.countries$ = this._countryService.getItemsAPI().pipe(
            tap((items: Country[]): void => {

                const address: Address = this.currentUser.society.addresses.find((address: Address): boolean => {

                    return address.type === 'mailing';
                });

                if(!address || !address.country){

                    return;
                }

                const match: Country = items.find((item: Country): boolean => {

                    return item.code === address.country;
                });

                this.form.get('country').patchValue(match ? match.code : null);
            })
        );
    }

    public openTermsAndConditionsInNewTab(event: MouseEvent): void {

        event.preventDefault();

        if(!this.termsAndConditionsFileIdentifier){

            return;
        }

        this._fileService.openFileInNewTab(this.termsAndConditionsFileIdentifier);
    }

    public openConditionUseDialog(event: MouseEvent): void {

        event.preventDefault();

        this._dialog.open(ConditionOfUseDialogComponent, {
            width: '600px',
            data: {
                societyName: this.currentUser.society.name
            }
        });
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ]
    }

    public isOneOfTheseOrigins(origins: Origin[]): boolean {

        return origins.includes(this.origin);
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get userFallbackLocale(): string {

        const fallbacks: { [p: string]: string[] } = {
            'fr': ['fr', 'en', 'es', 'it', 'de', 'nl', 'pt'],
            'en': ['en', 'fr', 'es', 'it', 'de', 'nl', 'pt'],
            'es': ['es', 'en', 'fr', 'it', 'pt', 'de', 'nl'],
            'it': ['it', 'en', 'fr', 'es', 'de', 'nl', 'pt'],
            'de': ['de', 'nl', 'en', 'fr', 'es', 'it', 'pt'],
            'pt': ['pt', 'en', 'es', 'fr', 'it', 'de', 'nl'],
            'nl': ['nl', 'de', 'en', 'fr', 'es', 'it', 'pt']
        };

        const fallbackLocales: string[] = fallbacks[this.currentUser.locale];

        const existingLocales: string[] = this.locales;

        return fallbackLocales.reduce((previousValue, currentValue): string => {

            return (!previousValue && existingLocales.includes(currentValue)) ? currentValue : previousValue;

        }, null);
    }

    get isSocietyRequired(): boolean {

        if(!this.form){

            return false;
        }

        const control: FormControl = this.form.get('customerType') as FormControl;

        if(!control.value){

            return false;
        }

        const customerType: CustomerType = this.customerTypes.find((item: CustomerType): boolean => {

            return item.id === control.value;
        });

        return customerType ? customerType.societyRequired : false;
    }
}
