import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "@core/shared/models/user";
import {Service} from "@core/shared/models/service";
import {ServiceService} from "@core/shared/services/service.service";
import {Router} from "@angular/router";
import {TranslationService} from "@core/shared/services/translation.service";
import {SocietyService} from "@core/shared/models/society-service";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";

@Component({
  selector: 'app-service-subscription-delete-dialog',
  templateUrl: './service-subscription-delete-dialog.component.html',
  styleUrls: ['./service-subscription-delete-dialog.component.scss']
})
export class ServiceSubscriptionDeleteDialogComponent implements OnInit {

    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    @Output() delete: EventEmitter<void> = new EventEmitter<void>();

    constructor(
      private _dialogRef: MatDialogRef<ServiceSubscriptionDeleteDialogComponent>,
      private _router: Router,
      private _servicePlusService: ServiceService,
      private _translationService: TranslationService,
      private _translateService: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: { user: User, service: Service, societyService: SocietyService}) { }

    ngOnInit(): void {

        this.back.subscribe(() => {

            this._dialogRef.close();
        });
    }

    get serviceTranslation() {

        return this._translationService.getFallbackTranslation(this.data.service.translations);
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get birthdayDate(): Date {

        if(this.data.service.hasAdmissionPrice && !this.data.service.isWithSubscription){

            return moment().add(2, 'days').startOf('day').toDate();
        }

        return moment(this.data.societyService.dateNextInvoice).startOf('day').toDate();
    }

    get isWithSubscription(): boolean {

        return this.data.service.isWithSubscription;
    }

    get unsubscriptionDelay(): number {

        if(this.data.service.isWithSubscription) {

            return null;
        }

        return this.data.service.unsubscriptionDelay;
    }
}
