import {
    AfterViewChecked,
    Component,
    ElementRef,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {TranslationService} from "./translation/translation.service";
import {TemplateService} from "./template/template.service";
import {ElementService} from "./element/element.service";
import {ICustomButton} from "./element/element";
import {Data, ICmsParams} from "./cms";
import {ApiService} from "./api/api.service";
import {CmsService} from "./cms.service";
import {AbstractControl, UntypedFormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {TextService} from "./element/type/text/text.service";
import {ColumnService} from "./column/column.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'cms-root',
    templateUrl: './cms.component.html',
    styleUrls: [
        './cms.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

/**
 * Point d'entrée de l'application Angular
 */
export class CmsComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

    form: UntypedFormGroup;

    @Input() params: ICmsParams;

    @Input() contentFormControl: AbstractControl;

    @Input() data: Data;

    public initialized: boolean = false; // Le contenu de la page CMS n'est visible que lorsque initialized = TRUE

    constructor(
        private _apiService: ApiService,
        private _cmsService: CmsService,
        private _elementService: ElementService,
        private _columnService: ColumnService,
        private _translationService: TranslationService,
        private _templateService: TemplateService,
        private _translateService: TranslateService,
        private _textService: TextService,
        private _snackbar: MatSnackBar,
        private _elementRef: ElementRef
    ) {
    }

    public ngOnInit(): void {

        this._apiService.setApiUrl(this.params.apiUrl);
        this._cmsService.setEnableMultilingual(this.params.enableMultilingual);
        this._cmsService.setMode(this.params.mode);
        this._cmsService.setContext(this.params.context);
        this._cmsService.setBlocLabel(this.params.blocLabel);
        this._cmsService.setEntityName(this.params.entityName);
        this._cmsService.setEntityFieldName(this.params.entityFieldName);
        this._cmsService.setOptionsImage(this.params.optionsImage);

        this.params.customButtons.map((customButton: ICustomButton) => {
            this._elementService.addCustomButton(customButton);
        });

        if (this.params.mode == 'edit' && !this.data) {
            return;
        }

        if (!this.data) {

            const contentData = {
                id: this.params.tag,
                translations: [],
                context: this.params.context,
                entityName: this.params.entityName,
                entityFieldName: this.params.entityFieldName,
            };

            this.data = {
                content: contentData,
                config: null
            };


        }

        this.contentFormControl.patchValue(this.data.content);

        // input

        // Configuration de la page

        if (this._cmsService.isCreateMode()) {
            this._initCreation();
        } else if (this._cmsService.isEditMode()) {
            this._initEdition();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (this.params.mode == 'edit' && changes.data.currentValue) {
            this.data = changes.data.currentValue;

            const content : any = {
                id: changes.data.currentValue.content.id,
                translations: changes.data.currentValue.content.translations,
                context: this.params.context,
                entityName: this.params.entityName,
                entityFieldName: this.params.entityFieldName
            };

            this.data = {
                config: changes.data.currentValue.config,
                content: content
            };

            this.contentFormControl.patchValue(this.data.content);

            this._initEdition();
        }

    }

    public ngAfterViewChecked(): void {

        this._manageTemplateContainer(false);
    }

    public ngOnDestroy(): void {

        this._manageTemplateContainer(true);
    }

    private _manageTemplateContainer(reset: boolean): void {

        const parent: HTMLElement = this.params.parentContainer;

        if ((typeof parent === 'undefined')) {
            return;
        }

        const elementInDOM: boolean = document.body.contains(document.querySelector(this._elementRef.nativeElement.tagName.toLowerCase()));

        switch (true) {

            case elementInDOM && reset:

                parent.style.paddingBottom = '0';

                break;

            case elementInDOM && !reset:

                const element: HTMLElement = document.getElementById('liste-templates');

                if(element){

                    parent.style.paddingBottom = element.clientHeight + "px";
                }

                break;

            default:

                parent.style.paddingBottom = '0';
        }
    }

    private _initCreation(): void {

        // Simulation de l'API

        this._cmsService.setContent(this.data.content); // Mise à jour du contenu de la page

        this._apiService.getConfig(this._cmsService.getContext()).subscribe((data: any) => {

            this._initConfig(data, this.data.content);

            this.initialized = true; // Une fois le contenu de la page chargé, on peut l'afficher
        }, (responseError: HttpErrorResponse) => {

            // si error =>
            this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(responseError.error, messages['action.close']);
            });

        });

    }

    private _initEdition(): void {

        this._initConfig(this.data.config, this.data.content);

        this._cmsService.setContent(this.data.content); // Mise à jour du contenu de la page

        this.initialized = true; // Une fois le contenu de la page chargé, on peut l'afficher
    }

    private _initConfig(config: any, content: any): void {

        // Mise à jour de la langue par défaut
        this._translationService.setDefaultLocale(config.defaultLocale);

        // Mise à jour de la langue affichée
        this._translationService.setCurrentLocale(content.translations[0].locale);

        // Mise à jour des langues disponible sur le site
        this._translationService.setAvailableLocales(config.locales);

        // Mise à jour des templates
        this._templateService.setTemplates(config.templates);

        // Mise à jour de la configuration des éléments
        this._elementService.setConfig(config.element);

        // Mise à jour de la configuration des éléments
        this._columnService.setConfig(config.column);

        // Mise à jour de la configuration des textes
        this._textService.editor = this.params.ckeditor.editor;

        // Mise à jour de la configuration pour les images
        //this._imageService.setConfig(config.image);

        // Mise à jour de la configuration des couleurs
        //this._imageService.setColors(config.colors);

        // Mise à jour de la configuration pour les slideshow
        //this._slideshowService.setConfig(config.element.types.slideshow);
    }

}
