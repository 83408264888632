import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder, TranslationItem} from "@core/shared/models/translation";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {Currency} from "@core/shared/models/currency";
import {BehaviorSubject, Observable, of} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {OfferOptionService} from "@core/shared/services/offer/offer-option.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "@core/shared/services/api.service";
import {CurrencyService} from "@core/shared/services/currency.service";
import {FormService} from "@core/shared/services/form.service";
import {OFFER_OPTION_PRICE_PUBLICS, OFFER_OPTION_PRICE_TYPES, OfferOptionPrice, OfferOptionPricePublic, OfferOptionPricePublicType, OfferOptionPriceType, OfferOptionPriceTypeType} from "@core/shared/models/offer/offer-option/offer-option-price";
import {OFFER_OPTION_PRICE_LEVEL_INCREASE_AREAS, OFFER_OPTION_PRICE_LEVEL_TYPES, OfferOptionPriceLevel, OfferOptionPriceLevelIncreaseArea, OfferOptionPriceLevelType} from "@core/shared/models/offer/offer-option/offer-option-price-level";
import {OfferOption, OfferOptionTranslation} from "@core/shared/models/offer/offer-option";
import {Role} from "@core/shared/models/role";
import {OfferOptionPresential} from "@core/shared/models/offer/offer-option/offer-option-presential";
import {OfferOptionUniquePrice} from "@core/shared/models/offer/offer-option/offer-option-unique-price";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import * as ClassicEditor from "@lib/ckeditor";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {OfferOptionPeriodCalendarComponent} from "@core/components/offer/offer-option/offer-option-period/offer-option-period-calendar/offer-option-period-calendar.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OfferOptionPeriodCreateDialogComponent} from "@core/components/offer/offer-option/offer-option-period/offer-option-period-create/offer-option-period-create-dialog/offer-option-period-create-dialog.component";
import {OfferOptionPeriodDeleteDialogComponent} from "@core/components/offer/offer-option/offer-option-period/offer-option-period-delete/offer-option-period-delete-dialog/offer-option-period-delete-dialog.component";
import {DEFAULT_MARKUP, MIN_MARKUP} from '@core/shared/models/data';
import {REGEX_PRICE} from "@core/shared/models/regex";
import {parsePrice} from "@core/shared/utils/price";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-page-offer-option-update',
    templateUrl: './page-offer-option-update.component.html',
    styleUrls: ['./page-offer-option-update.component.scss'],
    providers: [
        FormService
    ]
})
export class PageOfferOptionUpdateComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @ViewChild(OfferOptionPeriodCalendarComponent) periodCalendar: OfferOptionPeriodCalendarComponent;

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('periodsTab', {static: false}) periodsTab: MatTab;

    public translationBuilder: TranslationBuilder;

    public user: User;

    public society: Society;

    public option: OfferOption;

    public editor = ClassicEditor;

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    public presentialMax: number = 19;

    public currencies: Currency[] = [];

    public isUniquePrice: boolean = false;

    public pricesInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public changesDetected: boolean = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _translateService: TranslateService,
        private _offerOptionService: OfferOptionService,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _currencyService: CurrencyService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { user : User, society: Society, option: OfferOption }): void => {

            this.user = data.user;

            this.society = data.society;

            this.option = data.option;

            this._initForm();

            this._hydrateForm();

            this._initCurrencies();

            this._initEvents();

            // Redirection automatique sur un onglet

            this._activatedRoute.fragment.subscribe((fragment: ('periods')): void => {

                switch (fragment) {

                    case "periods":

                        this.tabGroup.selectedIndex = 4;

                        break;
                }
            });
        });
    }

    ngAfterViewInit(): void {

        this.tabGroup.selectedTabChange.subscribe((): void => {

            if (this.periodsTab && this.periodsTab.isActive) {

                if(this.periodCalendar){

                    this._renderCalendar();
                }
            }
        });
    }

    ngAfterViewChecked(): void {

        if(!this.changesDetected){

            this._changeDetectorRef.detectChanges();

            this.changesDetected = true;
        }
    }

    private _renderCalendar(): void {

        this.periodCalendar.calendar.getApi().render();
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            translations: new UntypedFormArray([]),
            locales: [[], Validators.required],
            image: this._formBuilder.group({
                image: [null, [Validators.required]]
            }),
            availableByDay: [false, [Validators.required]],
            markup: [this.defaultMarkupPercent, [Validators.required, Validators.pattern(REGEX_PRICE), Validators.min(this.minMarkupPercent)]],
            comment: [''],
            publics: [[], Validators.required],
            presentialIndividual: this.createPresentialControl({
                id: null,
                max: null,
                adultMin: null,
                adultMax: null,
                adultIncrementalStep: null,
                adultDefault: null,
                childMin:null,
                childMax: null,
                childIncrementalStep: null,
                childDefault: null
            }, true),
            presentialGroup: this.createPresentialControl({
                id: null,
                max: null,
                adultMin: null,
                adultMax: null,
                adultIncrementalStep: null,
                adultDefault: null,
                childMin:null,
                childMax: null,
                childIncrementalStep: null,
                childDefault: null
            }, false),
            currency: [null, [Validators.required]],
            uniquePrice: this.createUniquePriceControl({
                id: null,
                currency: null,
                valueHT: null,
                valueTTC: null
            }),
            types: [['HT', 'TTC'], [(control: UntypedFormControl) => {

                if(this.isUniquePrice){

                    return null;
                }

                if(control.value.length){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };

            }]], // Champ non mappé
            priceCalculationAutomatic: [false, [Validators.required]], // Champ non mappé
            priceTypeReference: [null], // Champ non mappé
            minChildrenAge: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            maxChildrenAge: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            prices: new UntypedFormArray([]),
            vatPercent: [null, [Validators.pattern(REGEX_PRICE)]],
            priceLevels: new UntypedFormArray([])
        });

        this._initTranslationsForm();

        this.formService.submitCallback = () => {

            const data: object = Object.assign({...this.form.value}, {
                minChildrenAge: parseInt(this.form.get('minChildrenAge').value),
                maxChildrenAge: parseInt(this.form.get('maxChildrenAge').value),
                currency: {
                    id: parseInt(this.form.get('currency').value)
                },
                vatPercent: this.form.get('vatPercent').value ? (this.form.get('vatPercent').value / 100) : null,
                prices: this.isUniquePrice ? [] : (this.pricesControl.value as OfferOptionPrice[]).map((price: OfferOptionPrice): OfferOptionPrice => {

                    const clone: OfferOptionPrice = {...price};

                    return Object.assign(clone, {
                        value: parseInt((clone.value * 100).toFixed())
                    });
                }),
                priceLevels: (this.priceLevelsControl.value as OfferOptionPriceLevel[]).map((price: OfferOptionPriceLevel): OfferOptionPriceLevel => {

                    const clone: OfferOptionPriceLevel = {...price};

                    return Object.assign(clone, {
                        value: clone.type === 'price' ? (clone.value * 100) : (clone.value / 100)
                    });
                }),
                markup: this.form.get('markup').value / 100,
                uniquePrice: this.isUniquePrice ? Object.assign({...this.form.get('uniquePrice').value}, {
                    valueHT: parseInt(({...this.form.get('uniquePrice').value}.valueHT * 100).toFixed()),
                    valueTTC: parseInt(({...this.form.get('uniquePrice').value}.valueTTC * 100).toFixed())
                }) : null
            });

            this._offerOptionService.updateItemAPI(this.option.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('offer.option.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _hydrateForm(): void {

        this.isUniquePrice = (this.option.uniquePrice !== null);

        this.form.patchValue(Object.assign({...this.option}, {
            uniquePrice: {
                currency: this.option.startFromCurrency || null,
                valueHT: ((this.option.uniquePrice?.valueHT) / 100) || null,
                valueTTC: ((this.option.uniquePrice?.valueTTC) / 100) || null
            },
            currency: this.option.startFromCurrency.id
        }));

        if(this.isUniquePrice){

            (this.form.get('uniquePrice') as UntypedFormGroup).addControl('id', this._formBuilder.control(this.option.uniquePrice.id));

            const control: AbstractControl = this.form.get('uniquePrice');

            const childControlsToUpdate: AbstractControl[] = [
                control.get('valueHT'),
                control.get('valueTTC')
            ];

            childControlsToUpdate.forEach((childControl: AbstractControl): void => {

                childControl.clearValidators();

                childControl.setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);

                childControl.updateValueAndValidity();
            });
        }

        this.option.translations.forEach((translation: OfferOptionTranslation): void => {

            const group: UntypedFormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);

            group.addControl('id', this._formBuilder.control(translation.id));
        });

        this.option.priceLevels.forEach((offerOptionPriceLevel: OfferOptionPriceLevel): void => {

            this.priceLevelsControl.push(this.createPriceLevelControl({
                id: offerOptionPriceLevel.id,
                type: offerOptionPriceLevel.type,
                min: offerOptionPriceLevel.min,
                max: offerOptionPriceLevel.max,
                priceIncreaseArea: offerOptionPriceLevel.priceIncreaseArea,
                value: offerOptionPriceLevel.value
            }));
        });
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {

            this.currencies = currencies;

            this.option.prices.forEach((offerPrice: OfferOptionPrice): void => {

                this.pricesControl.push(this.createPriceControl(offerPrice));
            });

            this.pricesInitializedSubject.next(true);
        });
    }

    private _initEvents(): void {

        // Langues

        this.form.get('locales').valueChanges.subscribe((locales: string[]): void => {

            this.translationBuilder.items.forEach((item: TranslationItem): void => {

                if(!locales.includes(item.locale)){

                    this.translationBuilder.removeItem(this.translationBuilder.getItemIndexByLocale(item.locale));
                }
            });

            locales.forEach((locale: string): void => {

                if(!this.translationBuilder.getItemByLocale(locale)){

                    this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(locale));
                }
            });
        });

        // Sélection de la devise

        this.form.get('currency').valueChanges.subscribe((value: string): void => {

            this.uniquePriceControl.patchValue({
                currency: value ? {id: parseInt(value)} : null
            });

            this.handleUniquePrice();

            this.pricesControl.controls.forEach((control: UntypedFormGroup): void => {

                control.patchValue({
                    currency: value ? {id: parseInt(value)} : null
                });
            });
        });

        // Sélection du type de référence (HT / TTC) pour les tarifs

        this.form.get('priceTypeReference').valueChanges.subscribe((): void => {

            this.pricesControl.controls.forEach((control: UntypedFormGroup): void => {

                control.patchValue({
                    value: null
                });
            });
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                name: ['', [Validators.required]],
                title: ['', [Validators.required]],
                description: ['', [Validators.required]]
            });
        };
    }

    private _initPriceControls(): void {

        this.pricesInitializedSubject.next(false);

        const bufferPrices: OfferOptionPrice[] = [...this.pricesControl.value];

        this.pricesControl.clear();

        this.pricesControl.clearValidators();

        if(this.isSelectedOfferOptionPricePublic('adult')){

            if(this.isSelectedOfferOptionPriceType('HT')){

                const bufferPrice: OfferOptionPrice = bufferPrices.find((price: OfferOptionPrice): boolean => {

                    return price.public === 'adult' && price.type === 'HT';
                });

                this.pricesControl.push(this.createPriceControl({
                    id: null,
                    currency: this.getCurrencyById(parseInt(this.form.get('currency').value)),
                    public: 'adult',
                    type: 'HT',
                    value: (bufferPrice && bufferPrice.value !== null) ? bufferPrice.value : null
                }));
            }

            if(this.isSelectedOfferOptionPriceType('TTC')){

                const bufferPrice: OfferOptionPrice = bufferPrices.find((price: OfferOptionPrice): boolean => {

                    return price.public === 'adult' && price.type === 'TTC';
                });

                this.pricesControl.push(this.createPriceControl({
                    id: null,
                    currency: this.getCurrencyById(parseInt(this.form.get('currency').value)),
                    public: 'adult',
                    type: 'TTC',
                    value: (bufferPrice && bufferPrice.value !== null) ? bufferPrice.value : null
                }));
            }
        }

        if(this.isSelectedOfferOptionPricePublic('child')){

            if(this.isSelectedOfferOptionPriceType('HT')){

                const bufferPrice: OfferOptionPrice = bufferPrices.find((price: OfferOptionPrice): boolean => {

                    return price.public === 'child' && price.type === 'HT';
                });

                this.pricesControl.push(this.createPriceControl({
                    id: null,
                    currency: this.getCurrencyById(parseInt(this.form.get('currency').value)),
                    public: 'child',
                    type: 'HT',
                    value: (bufferPrice && bufferPrice.value !== null) ? bufferPrice.value : null
                }));
            }

            if(this.isSelectedOfferOptionPriceType('TTC')){

                const bufferPrice: OfferOptionPrice = bufferPrices.find((price: OfferOptionPrice): boolean => {

                    return price.public === 'child' && price.type === 'TTC';
                });

                this.pricesControl.push(this.createPriceControl({
                    id: null,
                    currency: this.getCurrencyById(parseInt(this.form.get('currency').value)),
                    public: 'child',
                    type: 'TTC',
                    value: (bufferPrice && bufferPrice.value !== null) ? bufferPrice.value : null
                }));
            }
        }

        this.pricesControl.updateValueAndValidity();

        setTimeout((): void => {

            this.pricesInitializedSubject.next(true);
        });
    }

    private _resetPriceControlValidation(control: UntypedFormGroup): void {

        const offerPublic: OfferOptionPricePublicType = control.value.public;

        if(this.isUniquePrice){

            control.get('value').setValidators([]);
        }
        else{

            if (this.isSelectedOfferOptionPricePublic(offerPublic)) {

                control.get('value').setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);

            } else {

                control.get('value').setValidators([Validators.pattern(REGEX_PRICE)]);
            }
        }

        control.get('value').updateValueAndValidity();

        control.get('value').markAsTouched();
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer/option/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public createPresentialControl(data: OfferOptionPresential, enableMax: boolean): UntypedFormGroup {

        return this._formBuilder.group({
            max: [data.max, enableMax ? [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.max(this.presentialMax)] : []],
            adultMin: [data.adultMin, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            adultMax: [data.adultMax, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            adultIncrementalStep: [data.adultIncrementalStep, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            adultDefault: [data.adultDefault, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            childMin: [data.childMin, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            childMax: [data.childMax, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            childIncrementalStep: [data.childIncrementalStep, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            childDefault: [data.childDefault, [Validators.required, Validators.pattern(/^[0-9]*$/)]]
        });
    }

    public createUniquePriceControl(data: OfferOptionUniquePrice): UntypedFormGroup {

        return this._formBuilder.group({
            currency: [data.currency],
            valueHT: [data.valueHT],
            valueTTC: [data.valueTTC]
        });
    }

    public createPriceControl(offerPrice: OfferOptionPrice): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            public: [offerPrice.public, Validators.required],
            type: [offerPrice.type, Validators.required],
            currency: [offerPrice.currency ? { id : offerPrice.currency.id } : null, [Validators.required]],
            value: [offerPrice.value !== null ? offerPrice.value : null]
        });

        if(!!offerPrice.id){

            control.addControl('id', this._formBuilder.control(offerPrice.id));
        }

        this._resetPriceControlValidation(control);

        return control;
    }

    public createPriceLevelControl(data: OfferOptionPriceLevel): UntypedFormGroup {

        const control: UntypedFormGroup = this._formBuilder.group({
            type: [data.type, Validators.required],
            priceIncreaseArea: [data.priceIncreaseArea, [Validators.required]],
            min: [data.min, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            max: [data.max, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            value: [data.value ? (data.type === 'price' ? (data.value / 100) : (data.value * 100)) : null]
        });

        if(!!data.id){

            control.addControl('id', this._formBuilder.control(data.id));
        }

        return control;
    }

    public getCurrencyById(id: string | number): Currency {

        return this.currencies.find((currency: Currency): boolean => {

            return currency.id == id;
        });
    }

    public handleOfferOptionPricePublic(event: any): void {

        const control: UntypedFormControl = this.form.get('publics') as UntypedFormControl;

        const controlValue: OfferOptionPricePublicType[] = control.value;

        const value: OfferOptionPricePublicType = event.target.value;

        if (event.target.checked) {

            controlValue.push(value);

        } else {

            const index: number = controlValue.findIndex((i: OfferOptionPricePublicType): boolean => {

                return i === value;
            });

            controlValue.splice(index, 1);
        }

        control.patchValue(controlValue);

        this._initPriceControls();

        this.updatePresentials();
    }

    public handleUniquePrice(): void {

        const control: AbstractControl = this.form.get('uniquePrice');

        const childControlsToUpdate: AbstractControl[] = [
            control.get('valueHT'),
            control.get('valueTTC')
        ];

        childControlsToUpdate.forEach((childControl: AbstractControl): void => {

            childControl.patchValue(null);

            childControl.clearValidators();

            if(this.isUniquePrice){

                childControl.setValidators([Validators.required, Validators.pattern(REGEX_PRICE)]);
            }
            else{

                childControl.setValidators([]);
            }

            childControl.updateValueAndValidity();
        });

        this.form.get('types').updateValueAndValidity();

        this._initPriceControls();
    }

    public updatePresentials(){

        const controls: UntypedFormGroup[] = [
            this.presentialIndividualControl,
            this.presentialGroupControl
        ];

        controls.forEach((control: UntypedFormGroup): void => {

            const childControlsToUpdate: AbstractControl[] = [
                control.get('adultMin'),
                control.get('adultMax'),
                control.get('adultIncrementalStep'),
                control.get('adultDefault'),
                control.get('childMin'),
                control.get('childMax'),
                control.get('childIncrementalStep'),
                control.get('childDefault')
            ];

            childControlsToUpdate.forEach((childControl: AbstractControl): void => {

                childControl.clearValidators();
            });

            if(this.form.get('publics').value.includes('adult')){

                // Valeurs

                control.get('adultMin').patchValue(null);

                control.get('adultMax').patchValue(null);

                control.get('adultIncrementalStep').patchValue(null);

                control.get('adultDefault').patchValue(null);

                // Validateurs

                control.get('adultMin').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('adultMax').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('adultIncrementalStep').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('adultDefault').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

            }
            else{

                // Valeurs

                control.get('adultMin').patchValue(0);

                control.get('adultMax').patchValue(0);

                control.get('adultIncrementalStep').patchValue(0);

                control.get('adultDefault').patchValue(0);

                // Validateurs

                control.get('adultMin').setValidators([]);

                control.get('adultMax').setValidators([]);

                control.get('adultIncrementalStep').setValidators([]);

                control.get('adultDefault').setValidators([]);
            }

            if(this.form.get('publics').value.includes('child')){

                // Valeurs

                control.get('childMin').patchValue(null);

                control.get('childMax').patchValue(null);

                control.get('childIncrementalStep').patchValue(null);

                control.get('childDefault').patchValue(null);

                // Validateurs

                control.get('childMin').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('childMax').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('childIncrementalStep').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

                control.get('childDefault').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)]);

            }
            else{

                // Valeurs

                control.get('childMin').patchValue(0);

                control.get('childMax').patchValue(0);

                control.get('childIncrementalStep').patchValue(0);

                control.get('childDefault').patchValue(0);

                // Validateurs

                control.get('childMin').setValidators([]);

                control.get('childMax').setValidators([]);

                control.get('childIncrementalStep').setValidators([]);

                control.get('childDefault').setValidators([]);
            }

            childControlsToUpdate.forEach((childControl: AbstractControl): void => {

                childControl.updateValueAndValidity();
            });
        });
    }

    public isSelectedOfferOptionPriceType(item: OfferOptionPriceTypeType): boolean {

        return this.form.get('types').value.includes(item);
    }

    public isSelectedOfferOptionPricePublic(item: OfferOptionPricePublicType): boolean {

        return this.form.get('publics').value.includes(item);
    }

    public getPriceControlIndexByFilter(offerPricePublic: OfferOptionPricePublicType, offerPriceType: OfferOptionPriceTypeType): number {

        const controls: UntypedFormGroup[] = this.pricesControl.controls as UntypedFormGroup[];

        return controls.findIndex((control: UntypedFormGroup): boolean => {

            return control.value.public === offerPricePublic && control.value.type === offerPriceType;
        });
    }

    public getPriceControlsByFilter(offerPricePublic: OfferOptionPricePublicType, offerPriceType: OfferOptionPriceTypeType): UntypedFormGroup[] {

        const controls: UntypedFormGroup[] = this.pricesControl.controls as UntypedFormGroup[];

        return controls.filter((control: UntypedFormGroup): boolean => {

            const conditions: boolean[] = [];

            if (offerPricePublic) {

                conditions.push(control.value.public === offerPricePublic);
            }

            if (offerPriceType) {

                conditions.push(control.value.type === offerPriceType);
            }

            return conditions.every((value: boolean) => {

                return value === true;
            });
        });
    }

    public getPriceControlByFilter(offerPricePublic: OfferOptionPricePublicType, offerPriceType: OfferOptionPriceTypeType): UntypedFormGroup {

        const controls: UntypedFormGroup[] = this.pricesControl.controls as UntypedFormGroup[];

        return controls.find((control: UntypedFormGroup): boolean => {

            const conditions: boolean[] = [];

            if (offerPricePublic) {

                conditions.push(control.value.public === offerPricePublic);
            }

            if (offerPriceType) {

                conditions.push(control.value.type === offerPriceType);
            }

            return conditions.every((value: boolean) => {

                return value === true;
            });
        });
    }

    public calculatePrices(): void {

        if (!this.form.get('vatPercent').value) {

            return;
        }

        const TVA: number = parseFloat(this.form.get('vatPercent').value);

        const type: OfferOptionPriceTypeType = this.form.get('priceTypeReference').value;

        const controls: UntypedFormGroup[] = this.getPriceControlsByFilter(null, type);

        switch (type) {

            case 'HT':

                controls.forEach((control: UntypedFormGroup): void => {

                    const offerPublicType: OfferOptionPricePublicType = control.get('public').value;

                    const controlReference: UntypedFormGroup = this.getPriceControlByFilter(offerPublicType, 'HT');

                    const controlToCalculate: UntypedFormGroup = this.getPriceControlByFilter(offerPublicType, 'TTC');

                    const HT: number = parseFloat(controlReference.value.value);

                    controlToCalculate.patchValue({
                        value: !!controlReference.value.value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null
                    });
                });

                break;

            case 'TTC':

                controls.forEach((control: UntypedFormGroup): void => {

                    const offerPublicType: OfferOptionPricePublicType = control.get('public').value;

                    const controlReference: UntypedFormGroup = this.getPriceControlByFilter(offerPublicType, 'TTC');

                    const controlToCalculate: UntypedFormGroup = this.getPriceControlByFilter(offerPublicType, 'HT');

                    const TTC: number = parseFloat(controlReference.value.value);

                    controlToCalculate.patchValue({
                        value: !!controlReference.value.value ? parsePrice((100 * TTC) / (100 + TVA), 2, '', '.') : null
                    });
                });

                break;
        }
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public addPriceLevel(): void {

        this.priceLevelsControl.push(this.createPriceLevelControl({
            id: null,
            type: null,
            min: null,
            max: null,
            priceIncreaseArea: null,
            value: null
        }));
    }

    public removePriceLevel(index: number) {

        this.priceLevelsControl.removeAt(index);
    }

    public getPriceLevelControl(index: number): AbstractControl {

        return this.priceLevelsControl.controls[index];
    }

    public openCreatePeriodDialog(): void {

        const dialogRef: MatDialogRef<OfferOptionPeriodCreateDialogComponent> = this._dialog.open(OfferOptionPeriodCreateDialogComponent, {
            width: '500px',
            data: {
                option: this.option
            }
        });

        dialogRef.componentInstance.create.subscribe((): void => {

            this.periodCalendar.loadEvents();
        });
    }

    public openDeletePeriodDialog(): void {

        const dialogRef: MatDialogRef<OfferOptionPeriodDeleteDialogComponent> = this._dialog.open(OfferOptionPeriodDeleteDialogComponent, {
            width: '500px',
            data: {
                option: this.option
            }
        });

        dialogRef.componentInstance.delete.subscribe((): void => {

            this.periodCalendar.loadEvents();
        });
    }

    get isAllOfferOptionTypesSelected(): boolean {

        return (this.form.get('types').value as OfferOptionPriceType[]).length === this.offerOptionPriceTypes.length;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get locales$(): Observable<LocaleItem[]> {

        return of(LOCALE_ITEMS);
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true,
                maxLength: 3500
            }
        }
    }

    get imageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'offer_option_picture',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.option.image.value')
            },
        }
    }

    get presentialIndividualControl(): UntypedFormGroup {

        return this.form.get('presentialIndividual') as UntypedFormGroup;
    }

    get presentialGroupControl(): UntypedFormGroup {

        return this.form.get('presentialGroup') as UntypedFormGroup;
    }

    get uniquePriceControl(): UntypedFormGroup {

        return this.form.get('uniquePrice') as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get offerOptionPriceTypes(): OfferOptionPriceType[] {

        return OFFER_OPTION_PRICE_TYPES;
    }

    get offerOptionPricePublics(): OfferOptionPricePublic[] {

        return OFFER_OPTION_PRICE_PUBLICS;
    }

    get offerOptionPriceLevelTypes(): OfferOptionPriceLevelType[] {

        return OFFER_OPTION_PRICE_LEVEL_TYPES;
    }

    get offerOptionPriceLevelIncreaseAreas(): OfferOptionPriceLevelIncreaseArea[] {

        return OFFER_OPTION_PRICE_LEVEL_INCREASE_AREAS;
    }

    get pricesControl(): UntypedFormArray {

        return this.form.get('prices') as UntypedFormArray;
    }

    get priceLevelsControl(): UntypedFormArray {

        return this.form.get('priceLevels') as UntypedFormArray;
    }
}
