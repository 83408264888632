import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {User} from "@core/shared/models/user";
import {Role} from "@core/shared/models/role";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {SelectionModel} from "@angular/cdk/collections";
import {Offer, OfferTranslation} from "@core/shared/models/offer";
import {forkJoin, merge, Observable, of} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {OfferService} from "@core/shared/services/offer.service";
import {Society} from "@core/shared/models/society";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MarkupUpdateGroupedDialogComponent} from "@core/components/markup/markup-update/markup-update-grouped/markup-update-grouped-dialog/markup-update-grouped-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {SocietyService} from "@core/shared/services/society.service";
import {DEFAULT_MARKUP, MIN_MARKUP} from "@core/shared/models/data";
import {parseMarkup} from "@core/shared/utils/markup";
import {parsePrice} from "@core/shared/utils/price";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-page-offer-markup-catalog-list',
    templateUrl: './page-offer-markup-catalog-list.component.html',
    styleUrls: ['./page-offer-markup-catalog-list.component.scss'],
    providers: [
        FormService
    ]
})
export class PageOfferMarkupCatalogListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'select',
        'offer.translations.name',
        'offer.society.name',
        'offer.startFromPrice',
        'startFromPrice',
        'markup'
    ];

    public filterBuilder: FilterBuilder;

    public user: User;

    public society: Society;

    public items: OfferCatalog[] = [];

    public selection = new SelectionModel<OfferCatalog>(true, []);

    public minMarkupPercent: number = MIN_MARKUP;

    public defaultMarkupPercent: number = DEFAULT_MARKUP;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _offerService: OfferService,
        private _offerCatalogService: OfferCatalogService,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _formBuilder: UntypedFormBuilder,
        private _societyService: SocietyService,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._activatedRoute.data.subscribe((data: { user: User, society: Society }): void => {

            this.user = data.user;

            this.society = data.society;

            this.form.patchValue({
                enableMarkup: this.society.enableMarkup,
                markup: this.society.markup !== null ? parseMarkup(this.society.markup * 100) : null
            });

            this._initFilterBuilder();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this.itemsSubscription;
                }),
                map(this.mapApiResult),
            ).subscribe((data: OfferCatalog[]): void => {

                this.items = data;

                this.selection.clear();
            }
        );
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            enableMarkup: [false],
            markup: [this.defaultMarkupPercent, [(control: UntypedFormControl) => {

                if (!this.form) {

                    return null;
                }

                if (!this.form.get('enableMarkup').value) {

                    return null;
                }

                if (!!control.value && control.value.length) {

                    return null;
                }

                return {
                    isRequired: {
                        valid: false
                    }
                };

            }, Validators.pattern(/\d+([.,]\d+)?/), Validators.min(this.minMarkupPercent)]],
        });

        this.form.get('markup').disable();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                markup: this.form.get('enableMarkup').value ? parseFloat(this.form.get('markup').value.replace(',', '.')) / 100 : null
            });

            this._societyService.updateItemAPI(this.society.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('form.submit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.itemsSubscription.pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                    this.items = data;
                });

                this.form.get('markup').disable();
            });
        };
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this.itemsSubscription.pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public formatPrice(price: number, symbol: string): string {

        return price ? parsePrice(price / 100) + ' ' + symbol : null;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this.itemsSubscription.pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

            this.items = data;
        });
    }

    public getModel(item: OfferCatalog): OfferCatalog {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public isCollaborator(): boolean {

        return !!!this.user.societyAdmin;
    }

    public isAllSelected(): boolean {

        const numSelected: number = this.selection.selected.length;

        const numRows: number = this.items.length;

        return numSelected === numRows;
    }

    public masterToggle(): void {

        if (this.isAllSelected()) {

            this.selection.clear();

            return;
        }

        this.selection.select(...this.items);
    }

    public openGroupedUpdateDialog(): void {

        const dialogProviderRef: MatDialogRef<MarkupUpdateGroupedDialogComponent> = this._dialog.open(MarkupUpdateGroupedDialogComponent, {
            width: '500px',
            data: {
                catalogs: this.selection.selected,
                typeMarkup: 'catalog',
                minMarkupPercent : this.minMarkupPercent,
                defaultMarkupPercent : !!this.form.get('enableMarkup').value && this.form.get('markup').value !== null ? this.form.get('markup').value : this.defaultMarkupPercent
            }
        });

        dialogProviderRef.componentInstance.create.subscribe((): void => {

            this._snackBar.open(this._translateService.instant('markup.update.grouped.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });

            this.itemsSubscription.pipe(map(this.mapApiResult)).subscribe((data: OfferCatalog[]): void => {

                this.items = data;

                this.selection.clear();
            });
        });
    }

    public parsedMarkup(markup: number): string {

        return parseMarkup(markup * 100) + '%';
    };

    public editGenericMarkup(): void {

        this.form.get('enableMarkup').patchValue(true);

        this.form.get('markup').enable();
    }

    get itemsSubscription(): Observable<Pagination<OfferCatalog>> {


        return this._offerCatalogService.getPaginationItemsAPI(this.itemsApiParams).pipe(
            map((pagination: Pagination<OfferCatalog>): Pagination<OfferCatalog> => {

                return Object.assign(pagination, {
                    items: pagination.items.map((item: OfferCatalog): OfferCatalog => {

                        return item;
                    })
                });
            })
        );

    }


    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        if(this.hasRole("ROLE_OFFER_CREATOR")){
            params.push(`offer.society.id[ne]=${this.user.society.id}`);
        }

        params.push(`status[eq]=accepted`);

        return params;
    }

    get mapApiResult(): (data: Pagination<OfferCatalog>) => OfferCatalog[] {

        return (data: Pagination<OfferCatalog>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get isMarkupAvailable(): boolean {

        if(this.isCollaborator()){

            return false;
        }

        return this.society.registration?.length > 0;
    }
}
