<h1 mat-dialog-title class="x_center">{{ 'giftVoucher.' + (data.typeUpdate === 'duration' ? 'renewal' : 'renewal.price')  + '.value' | translate }}</h1>

<mat-dialog-content>

    <ng-container *ngIf="form">

        <form [formGroup]="form">

            <ng-container *ngIf="form.get('renewalDuration')">

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="renewalDuration" id="renewalDuration">
                    <label for="renewalDuration">{{'giftVoucher.configuration.form.fields.renewal.duration.value' | translate}}</label>
                    <mat-error *ngIf="form.get('renewalDuration').invalid && (form.get('renewalDuration').dirty || form.get('renewalDuration').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('renewalDuration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('renewalDuration').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            <div *ngIf="form.get('renewalDuration').errors.min">{{ 'giftVoucher.validator.renewalDuration.min.value' | translate:{min: defaultMinRenewalDuration} }}</div>
                            <div *ngIf="form.get('renewalDuration').errors.max">{{ 'giftVoucher.validator.renewalDuration.max.value' | translate:{max: defaultMaxRenewalDuration} }}</div>
                        </div>
                    </mat-error>
                </div>

            </ng-container>

            <ng-container *ngIf="form.get('renewalPercentPrice')">

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="renewalPercentPrice" id="renewalPercentPrice" appInputTextParseMarkup [control]="form.get('renewalPercentPrice')">
                    <label for="renewalPercentPrice">{{'giftVoucher.configuration.form.fields.renewal.percentPrice.value' | translate}}</label>
                    <mat-error *ngIf="form.get('renewalPercentPrice').invalid && (form.get('renewalPercentPrice').dirty || form.get('renewalPercentPrice').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('renewalPercentPrice').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('renewalPercentPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            <div *ngIf="form.get('renewalPercentPrice').errors.max">{{ 'giftVoucher.configuration.validator.renewalPercent.max.value' | translate: {max: defaultMaxPercentRenewal} }}</div>
                            <div *ngIf="form.get('renewalPercentPrice').errors.min">{{ 'giftVoucher.configuration.validator.renewalPercent.min.value' | translate: {min: defaultMinPercentRenewal} }}</div>
                            <div *ngIf="form.get('renewalPercentPrice').errors.isValueAllowedByMongopayAccountValidation">{{ 'giftVoucher.configuration.validator.renewalPercent.isValueAllowedByMongopayAccountValidation.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>

            </ng-container>

        </form>

    </ng-container>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_green_lines" (click)="back.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
       <div class="col_content">
            <button class="btn_cta btn_little" (click)="formService.submit()">{{ 'giftVoucher.configuration.submit.' + (data.typeUpdate === 'duration' ? 'duration' : 'renewal')  + '.value' | translate }}</button>
       </div>
    </div>
</mat-dialog-actions>
