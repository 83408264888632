import {Directive, Input, OnInit, Self} from '@angular/core';
import {MatInput} from "@angular/material/input";

/**
 * Gestion de l'affichage des attribut des champs input
 */
@Directive({
    selector: '[appInput]'
})
export class InputDirective implements OnInit {

    /**
     * Attributs
     */
    @Input() attrs: object;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor(@Self() private _input: MatInput) {
    }

    /**
     * Traitement des options
     */
    ngOnInit() {

        for (const [key, value] of Object.entries(this.attrs)) {
            this._input[key] = value;
        }
    }
}
