import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {Transporter} from '@app/core/shared/models/transporter';
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {TransporterService} from "@core/shared/services/transporter.service";
import {parsePrice} from "@core/shared/utils/price";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Access, AccessType} from "@core/shared/models/access";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslationService} from "@core/shared/services/translation.service";
import {Currency} from "@core/shared/models/currency";
import {CurrencyService} from "@core/shared/services/currency.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
    selector: 'app-page-transporter-list',
    templateUrl: './page-transporter-list.component.html',
    styleUrls: ['./page-transporter-list.component.scss']
})
export class PageTransporterListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'enabled',
        'transporterName',
        'currency',
        'amountHT',
        'amountTTC',
    ];

    public filterBuilder: FilterBuilder;

    public items: Transporter[];

    public currencies: { id: number, label: string }[] = [];

    constructor(
        public translationService: TranslationService,
        private _transporterService: TransporterService,
        private _userService: UserService,
        private _translateService: TranslateService,
        private _currencyService: CurrencyService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _router: Router
    ) {}

    ngOnInit(): void {

        this._initCurrencies();

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this.dataSource;
                }),
                map(this.mapApiResult),
            ).subscribe((data: Transporter[]): void => {

                this.items = data;
            }
        );
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {
            this.currencies = currencies.map((currency: Currency) => {

                return {
                    id: currency.id,
                    label: this.translationService.getFallbackTranslation(currency.translations).label
                };
            });
        });
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: Transporter[]): void => {

                this.items = data;

            })
        };
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: Transporter[]): void => {

            this.items = data;

        })
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public addItem(): void {

        this._router.navigate(['/account/transporter/create']);
    }

    public hasAccess(tag: AccessType): boolean {

        return this.currentUser.accesses.some((access: Access) => {

            return access.tag === tag;
        });
    }

    public getAmount(item: Transporter, amount: number): string {

        return `${parsePrice(amount)} ${item.currency.symbol}`;
    }

    public getModel(item: Transporter): Transporter {

        return item;
    }

    public update(id: number): void {

        this._router.navigate(['/account/transporter/update/', id]);
    }

    public openActivateItemDialog(event, id: number): void {
        const item: Transporter = this.items.find((transporter: Transporter) => {
            return transporter.id === id;
        });

        const title: string = this._translateService.instant('transporter.activate.value');

        const content: string = this._translateService.instant('transporter.activate.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.cancel.subscribe((): void => {
            event.source.checked = item.enabled;
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._transporterService.updateItemAPI(item.id, {enabled: true}).subscribe(() => {

                this._snackBar.open(this._translateService.instant('transporter.activate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._transporterService.getPaginationItemsAPI(this.currentUser.society.id, this.itemsApiParams)
                    .pipe(map(this.mapApiResult))
                    .subscribe((data: Transporter[]): void => {
                        this.items = data;
                    });

            });
        });
    }
    public openDeleteItemDialog(event, id: number): void {

        const item: Transporter = this.items.find((transporter: Transporter) => {
            return transporter.id === id;
        });

        const title: string = this._translateService.instant('transporter.delete.value');

        const content: string = this._translateService.instant('transporter.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.cancel.subscribe((): void => {
            event.source.checked = item.enabled;
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._transporterService.updateItemAPI(item.id, {enabled: false}).subscribe(() => {

                this._snackBar.open(this._translateService.instant('transporter.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._transporterService.getPaginationItemsAPI(this.currentUser.society.id, this.itemsApiParams)
                    .pipe(map(this.mapApiResult))
                    .subscribe((data: Transporter[]): void => {

                        this.items = data;
                    });

            });
        });

    }

    public isEnable(index: number): boolean {

        return this.items[index].enabled;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get mapApiResult(): (data: Pagination<Transporter>) => Transporter[] {

        return (data: Pagination<Transporter>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get currentUser(): User {

        return this._userService.currentUser.getValue();
    }

    get dataSource(): Observable<Pagination<Transporter>> {

        return this._transporterService.getPaginationItemsAPI(this.currentUser.society.id, this.itemsApiParams);
    }


}
