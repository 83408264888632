import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {GiftVoucher} from "@core/shared/models/gift-voucher/gift-voucher";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {map, startWith, switchMap} from "rxjs/operators";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {User} from "@core/shared/models/user";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {merge, Observable} from "rxjs";
import {Access} from "@core/shared/models/access";
import {SelectionModel} from "@angular/cdk/collections";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import { GiftVoucherUpdateGroupedDialog } from "@core/components/gift-voucher/gift-voucher-update-renewal-dialog/gift-voucher-update-grouped-dialog";
import {Role} from "@core/shared/models/role";
import {parseMarkup} from "@core/shared/utils/markup";
import moment, {Moment} from "moment";
import {DATE_UNDERSCORE_FORMAT} from "@app/data";

@Component({
    selector: 'app-page-gift-voucher-list',
    templateUrl: './page-gift-voucher-list.component.html',
    styleUrls: ['./page-gift-voucher-list.component.scss']
})
export class PageGiftVoucherListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public hasData = false;

    public displayedColumns: string[] = [];

    public filterBuilder: FilterBuilder;

    public items: GiftVoucher[];

    public user: User;

    public selection = new SelectionModel<GiftVoucher>(true, []);

    public defaultGiftVouchersSelected: GiftVoucher[] = [];

    private _dialogRef: MatDialogRef<GiftVoucherUpdateGroupedDialog>;

    public status: { id: string, label: string }[] = [
        {
            id: 'not-booked',
            label: this._translateService.instant('giftVoucher.status.not-booked.value')
        },
        {
            id: 'booked',
            label: this._translateService.instant('giftVoucher.status.booked.value')
        },
        {
            id: 'expired',
            label: this._translateService.instant('giftVoucher.status.expired.value')
        },
        {
            id: 'forwarded',
            label: this._translateService.instant('giftVoucher.status.forwarded.value')
        }
    ];

    public isRenewableItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('giftVoucher.list.fields.renewable.true.value')
        },
        {
            id: 0,
            label: this._translateService.instant('giftVoucher.list.fields.renewable.false.value')
        }
    ];

    public isRenewedItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('giftVoucher.list.fields.renewed.true.value')
        },
        {
            id: 0,
            label: this._translateService.instant('giftVoucher.list.fields.renewed.false.value')
        }
    ];

    public isDetachedItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('giftVoucher.list.fields.detached.true.value')
        },
        {
            id: 0,
            label: this._translateService.instant('giftVoucher.list.fields.detached.false.value')
        }
    ];

    constructor(private _router: Router,
                private _matDialog: MatDialog,
                private _giftVoucherService: GiftVoucherService,
                private _activatedRoute: ActivatedRoute,
                private _snackBar: MatSnackBar,
                private _translateService: TranslateService,
                public userService: UserService,
                public translationService: TranslationService) {
    }

    ngOnInit(): void {

        this.user = this.userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { giftVouchers: Pagination<GiftVoucher> }): void => {

            this.hasData = data.giftVouchers.totalItems > 0;

        })

        this._initDisplayedColumns();

        this._initFilterBuilder();
    }

    ngAfterViewInit() {

        if (this.sort) {

            this.sort.sortChange.subscribe(() => {

                this._resetPagination();
            });

            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    startWith({}),
                    switchMap(() => {

                        return this._loadItems()

                    }),
                    map(this.mapApiResult),
                ).subscribe((data: GiftVoucher[]): void => {

                this.items = data;

            });
        }

    }

    private _initDisplayedColumns(): void {

        if(!this.isAdmin()) {

            this.displayedColumns.push(
                'select'
            );
        }

        this.displayedColumns.push(
            'actions'
        );

        if(this.isAdmin()) {

            this.displayedColumns.push(
                'ownerSociety.name',
                'offerCreator.name'
            );
        }

        this.displayedColumns.push(
            'reference',
            'code',
            'offer.translations.name',
            'createdAt',
            'expiryDate'
        );

        if(this.isAdmin() || this.userService.hasOneOfThisRoles(this.user, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])) {

            this.displayedColumns.push(
                'paymentId'
            );
        }

        if(!this.isAdmin()) {

            this.displayedColumns.push(
                'buyer.lastName',
                'buyer.firstName',
                'beneficiary.lastName',
                'beneficiary.firstName'
            );
        }

        this.displayedColumns.push(
            'totalPrice',
            'totalNetPrice',
            'commission',
            'commissionTTC',
            'amountCommissionTTC',
            'isPaidCommission',
            'channel.translations.name',
            'channel.marketplace',
            'status',
            'customerRenewalPrice',
            'isRenewable',
            'isRenewed',
            'isDetached',
            'detachedAt',
            'offerData.translations.name',
            'responsibleUser.name',
            'responsibleUser.service'
        );

    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._loadItems().pipe(
                map(this.mapApiResult)
            ).subscribe((data: GiftVoucher[]): void => {
                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _loadItems(): Observable<Pagination<GiftVoucher>> {

        if(this.isAdmin()) {

            return this._giftVoucherService.getPaginationItemsAPI(this.itemsApiParams);

        } else {

            return this._giftVoucherService.getPaginationSocietyItemsAPI(this.user.society.id, this.itemsApiParams);
        }
    }

    public getModel(item: GiftVoucher): GiftVoucher {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._loadItems().pipe(map(this.mapApiResult))
            .subscribe((data: GiftVoucher[]): void => {

                this.items = data;
            });
    }

    public view(id: number): void {

        this._router.navigate(['/account/gift-voucher/read', id]);
    }

    public handlePeriods(id: number): void {

        this._router.navigate(['/account/gift-voucher/periods', id]);
    }

    public getCurrencySymbol(element: GiftVoucher): string {

        if (!element.channel || !element.channel.currency) {

            return '€';
        }

        return element.channel.currency.symbol;
    }

    public isAllSelected(): boolean {

        const numSelected: number = this.selection.selected.length;

        const numRows: number = this.items.length;

        return numSelected === numRows;
    }

    public masterToggle(): void {

        if (this.isAllSelected()) {

            this.selection.clear();

            return;
        }

        this.selection.select(...this.items);
    }

    public hasAccessEdit(): boolean {
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'GIFT_VOUCHER_EDIT_IS_MINE';
        });

        return (accesses.length > 0);
    }

    public hasAccess(item: Access){
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == item.tag;
        });

        return (accesses.length > 0);
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.indexOf(role) >= 0;
    }

    public isRenewalUpdateEnabled(element: GiftVoucher): boolean {

        if(!this.hasRole('ROLE_OFFER_CREATOR')){

            return false;
        }

        return element.offerOwnerSociety ? (element.offerOwnerSociety.id === this.user.society.id) : false;
    }

    public openRenewalGiftVoucherDialog(): void {

        this._dialogRef = this._matDialog.open(GiftVoucherUpdateGroupedDialog, {
            width: '800px',
            data: {
                society: this.user.society,
                typeUpdate: 'duration',
                giftVouchers: this.selection.selected,
                user: this.user
            }
        });

        this.selection.selected.forEach(item => {
            this.defaultGiftVouchersSelected.push(Object.assign({}, item));
        });

        this._dialogRef.componentInstance.create.subscribe((): void => {

            this._snackBar.open(this._translateService.instant('giftVoucher.update.grouped.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });

            this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: GiftVoucher[]): void => {

                this.items = data;

                this.selection.clear();
            });
        });

    }

    public openRenewalPriceGiftVoucherDialog(): void {

        this._dialogRef = this._matDialog.open(GiftVoucherUpdateGroupedDialog, {
            width: '600px',
            data: {
                society: this.user.society,
                typeUpdate: 'price',
                giftVouchers: this.selection.selected,
                user: this.user
            }
        });

        this.selection.selected.forEach(item => {
            this.defaultGiftVouchersSelected.push(Object.assign({}, item));
        });

        this._dialogRef.componentInstance.create.subscribe((): void => {

            this._snackBar.open(this._translateService.instant('giftVoucher.update.renewalPrice.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });

            this._loadItems().pipe(map(this.mapApiResult)).subscribe((data: GiftVoucher[]): void => {

                this.items = data;

                this.selection.clear();
            });
        });

    }

    public hasRenewedItems(): boolean {

        return this.selection.selected.some((item) => {

            return item.isRenewed;
        });
    };

    public allowToUpdateRenewalDuration = (element: GiftVoucher) => {

        return (element.status !== 'canceled') && (element.status !== 'forwarded');
    };

    public allowToUpdateRenewalPrice = (element: GiftVoucher) => {

        return !(element.status === 'expired' || element.status === 'booked' || element.status === 'forwarded' || element.isRenewed);
    }

    public hasValidItems( callback: (item: GiftVoucher) => boolean ) {

        return this.selection.selected.every((item) => {

            return callback(item);
        });
    }

    public parsedMarkup(value: number): string {

        return !!value ? (parseMarkup(value) + '%') : '';
    }

    public isAdmin(): boolean {

        return this.userService.hasOneOfThisRoles(this.user, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    }

    public isInCustomerRelationshipCharge(giftVoucher: GiftVoucher): boolean {

        return !!this.user.society && this.user.society.id === giftVoucher.offerOwnerSociety?.id;
    }

    public getResponsibleFullName(responsibleUser: User) {

        return `${responsibleUser.lastName} ${responsibleUser.firstName}`;
    }

    public exportGiftVouchers(): void {

        this._giftVoucherService.exportItemsAPI(this.user.society.id).subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('giftVoucher.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    public hasAccessExportGiftVoucher(): boolean {

        return this.user.accesses.some((access : Access): boolean => {

            return ['GIFT_VOUCHER_LIST_IS_MINE', 'GIFT_VOUCHER_NO_SERVICE_LIST_IS_MINE'].includes(access.tag);
        });
    }

    public adminExportGiftVoucher(): void {
        this._giftVoucherService.adminExportItemsAPI().subscribe(blob => {

            const a: HTMLAnchorElement = document.createElement('a');

            const objectUrl: string = URL.createObjectURL(blob);

            const date: Moment = moment();

            a.href = objectUrl;

            a.download = `${this._translateService.instant('adminGiftVoucher.export.files.title.value')}_${date.format(DATE_UNDERSCORE_FORMAT)}.csv`;

            a.click();

            URL.revokeObjectURL(objectUrl);
        });
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[createdAt]=DESC`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get hasItems(): boolean {

        return this.selection.selected.length > 0;
    }

    get mapApiResult(): (data: Pagination<GiftVoucher>) => GiftVoucher[] {

        return (data: Pagination<GiftVoucher>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get hasOnlineSaleAccess(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }
}
