import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslationService} from "./translation.service";
import {ITranslation} from "./translation";
import {ITemplate} from "../template/template";
import {TemplateService} from "../template/template.service";
import {RowService} from "../row/row.service";
import {IRow} from "../row/row";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfirmDialogData} from "@lib/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-translation',
    templateUrl: './translation.component.html',
    styleUrls: ['./translation.component.scss']
})

/**
 * Langue définie pour la page
 */
export class TranslationComponent implements OnInit {

    @Input() translation: ITranslation; // Récupération de la langue affichée (vaut FR, EN etc...)

    @Output() delete = new EventEmitter();

    templates: ITemplate[] = [];

    /**
     * @param _translateService
     * @param _dialog
     * @param _translationService
     * @param _templateService
     * @param _rowService
     * @param _snackbar
     */
    constructor(
        private _translateService: TranslateService,
        private _dialog: MatDialog,
        private _translationService: TranslationService,
        private _templateService: TemplateService,
        private _rowService: RowService,
        private _snackbar: MatSnackBar
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {

        this.templates = this._templateService.getTemplates();
    }

    /**
     * Ajout d'une ligne (en dernière position)
     *
     * @param {ITemplate} template
     */
    addRow(template: ITemplate): void {

        this._rowService.addRow(this.translation, template);
    }

    /**
     * Retourne le style lié au template d'une langue
     *
     * @returns {any}
     */
    getTemplateStyle(): object {

        return {
            'width': ((100 - ((this.templates.length - 1) * 2)) / this.templates.length) + '%'
        }
    }

    /**
     * Détermine si une ligne peut prendre la place en dessous
     *
     * @param row
     */
    isIncreaseRowAllowed(row: IRow): boolean{

        return this._rowService.isIncreaseRowAllowed(this.translation, row);
    }

    /**
     * Détermine si une ligne peut prendre la place au dessus
     *
     * @param row
     */
    isDecreaseRowAllowed(row: IRow): boolean{

        return this._rowService.isDecreaseRowAllowed(this.translation, row);
    }

    /**
     * Décrémentation de la position d'une ligne
     * Sur l'interface la ligne prend donc la place au dessus
     *
     * @param row
     */
    decreasePositionRow(row: IRow): void{

        this._rowService.decreasePositionRow(this.translation, row);
    }

    /**
     * Incrémentation de la position d'une ligne
     * Sur l'interface la ligne prend donc la place en dessous
     *
     * @param row
     */
    increasePositionRow(row: IRow): void{

        this._rowService.increasePositionRow(this.translation, row);
    }

    /**
     * Suppression d'une ligne
     *
     * @param row
     */
    deleteRow(row: IRow): void {

        this._translateService.get(['row.delete.confirm.title', 'row.delete.confirm.content']).subscribe((messages: string[]) => {

            const data: ConfirmDialogData = {
                title : messages['row.delete.confirm.title'],
                content: messages['row.delete.confirm.content']
            };

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: data
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._rowService.deleteRow(this.translation, row);

                this._translateService.get(['row.delete.success', 'action.close']).subscribe((messages: string[]) => {
                    this._snackbar.open(messages['row.delete.success'], messages['action.close']);
                });
            });
        });
    }
}
