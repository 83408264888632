import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FilterBuilder, FilterComponent} from '@core/shared/models/filter';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceService} from '@core/shared/services/invoice.service';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from '@core/shared/models/pagination';
import {SocietyService} from '@core/shared/services/society.service';
import {User} from '@core/shared/models/user';
import {UserService} from '@core/shared/services/user.service';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {Society} from '@core/shared/models/society';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {LOCALE_ITEMS, LocaleItem} from '@core/shared/models/translation';
import {Access} from "@core/shared/models/access";

@Component({
  selector: 'app-page-collaborator-list',
  templateUrl: './page-collaborator-list.component.html',
  styleUrls: ['./page-collaborator-list.component.scss']
})
export class PageCollaboratorListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'societyName',
        'societyCity',
        'societyZipcode',
        'lastName',
        'firstName',
        'email',
        'switchboardPhone',
        'locale',
        'service',
        'comment'
    ];

    public filterBuilder: FilterBuilder;

    public user : Pagination<User>;

    public items: User[] = [];

    public society : Society;

    public collaborator: User[] = [];

    public societyAdminId: number;

    public currentUser: User;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _invoiceService: InvoiceService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        private _authenticationService: AuthenticationService,

    ) {
    }

    ngOnInit() {

        this.currentUser = this._userService.currentUser.value;

        this._initFilterBuilder();

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.societyAdminId = data.society.admin.id;
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._userService.getPaginationItemSocietyUsers(this.societyAdminId, this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: User[]): void => {

                this.items = data;
            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._userService.getPaginationItemSocietyUsers(this.societyAdminId, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._userService.getPaginationItemSocietyUsers(this.societyAdminId, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

            this.items = data;
        });
    }

    public getModel(item: User): User {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const user: User = this.items.find((item: User): boolean => {

            return item.id === id;
        });

        const title : string = this._translateService.instant('collaborator.delete.value');

        const content : string = this._translateService.instant('collaborator.delete.description.value', {
            firstName: user.firstName,
            lastName: user.lastName
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._userService.deleteItemAPI(user.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('collaborator.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._userService.getPaginationItemSocietyUsers(this.societyAdminId, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/collaborator/create']);
    }

    public editItem(id: number): void{

        this._router.navigate(['account/collaborator/update', id]);
    }

    public getLocaleItem(id: string): LocaleItem {

        return LOCALE_ITEMS.find((locale: LocaleItem): boolean => {

            return locale.id === id;
        });
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        // Filtre sur l'utilisateur connecté

        const userFilter: TextFilterField = new TextFilterField('id', 'ne', this._authenticationService.id.toString());

        params.push(userFilter.serialize);

        return params;
    }

    get mapApiResult(): (data: Pagination<User>) => User[] {

        return (data: Pagination<User>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get locales(): LocaleItem[] {
        var locales = LOCALE_ITEMS;
        locales.forEach((locale)=>{
            locale.label = this._translateService.instant(locale.label)
        });

        return locales;
    }

    public hasAccessCreate(): boolean{
        var accesses = this.currentUser.accesses.filter((access : Access) => {
            return access.tag == 'USER_CREATE';
        });

        return (accesses.length > 0)
    }

    public hasAccessEdit(): boolean{
        var accesses = this.currentUser.accesses.filter((access : Access) => {
            return access.tag == 'USER_EDIT';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean{
        var accesses = this.currentUser.accesses.filter((access : Access) => {
            return access.tag == 'USER_DELETE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    get signinUrl(): string {
        return `${window.location.origin}/${this.currentUser.locale}/signin`;
    }
}
