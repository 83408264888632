import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {FormService} from "@core/shared/services/form.service";
import {UserService} from "@core/shared/services/user.service";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'app-core-page-user-forget-password',
    templateUrl: './page-user-forget-password.component.html',
    styleUrls: ['./page-user-forget-password.component.scss'],
    providers: [
        FormService
    ]
})
export class PageUserForgetPasswordComponent implements OnInit {

    public loginLogoUrl$: Observable<SafeUrl>;

    public poweredByTywin: boolean = false;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _userService: UserService,
        public formService: FormService
    ) {}

    ngOnInit() {

        if(this._authenticationService.isAuthenticated){

            this._router.navigate(['/account']);
        }

        this._initLoginLogo();

        this._initForm();
    }

    private _initLoginLogo(): void {

        const params: string[] = [];

        if('choicePreference' in this._activatedRoute.snapshot.queryParams){

            params.push(`choicePreference=${ this._activatedRoute.snapshot.queryParams['choicePreference'] as string }`);
        }

        if('registrationAffiliation' in this._activatedRoute.snapshot.queryParams){

            params.push(`registrationAffiliation=${ this._activatedRoute.snapshot.queryParams['registrationAffiliation'] as string }`);
        }

        this.loginLogoUrl$ = Boolean(params.length) ? this._userService.getLoginLogoAPI(params).pipe(
            map((response: HttpResponse<Blob>): SafeUrl => {

                this.poweredByTywin = Boolean(parseInt(response.headers.get('Powered-By-Ty-Win')));

                const imageUrl: string = URL.createObjectURL(response.body);

                return this._sanitizer.bypassSecurityTrustUrl(imageUrl);
            })
        ) : of('assets/images/logo_tywin.png');
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]]
        });

        this.formService.submitCallback = (): void => {

            this._userService.resetAccountPasswordRequestAPI(this.form.value).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.forgetPassword.request.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['/signin']);
            });
        };
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get queryParams(): Params {

        return this._activatedRoute.snapshot.queryParams;
    }
}
