import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {FormService} from "@core/shared/services/form.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {Society} from "@core/shared/models/society";
import {FieldCollection} from "@lib/form/field";
import {PromotionSubscriptionService} from "@core/shared/services/promotion-subscription.service";
import {Subscription} from "@core/shared/models/subscription";
import {Observable, of} from "rxjs";
import {PromotionSubscription} from "@core/shared/models/promotion-subscription";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-page-promotion-subscription-read',
    templateUrl: './page-promotion-subscription-read.component.html',
    styleUrls: ['./page-promotion-subscription-read.component.scss'],
    providers: [
        FormService
    ]
})
export class PagePromotionSubscriptionReadComponent implements OnInit {

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public pages: Object[] = [];

    public roles: string[] = [];

    public fieldCollection: FieldCollection = new FieldCollection();

    public availableSubscriptions: Subscription[] = [];

    public locales$: Observable<LocaleItem[]>;

    public durationOptions: { id: number, name: string }[] = [];

    public disabled: boolean = false;

    public typeDiscountChoices: { id: string, name: string }[] = [
        {
            id: 'percent',
            name: '%'
        },
        {
            id: 'amount',
            name: '€'
        }
    ];

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public formService: FormService,
        public promotionSubscriptionService: PromotionSubscriptionService,
        public translationService: TranslationService
    ) {
    }

    public ngOnInit(): void {

        if (this._activatedRoute.snapshot.params['id']) {

            this.disabled = true;
        }
        this.initForm();

        this.form.valueChanges.subscribe((value): void => {

            if (value.admissionPriceFree) {

                if (this.form.get('admissionPriceDiscount').value) {

                    this.form.get('admissionPriceDiscount').patchValue(null);
                }

                if (this.form.get('admissionPriceTypeDiscount').value) {

                    this.form.get('admissionPriceTypeDiscount').patchValue(null);
                }
            }

            if (value.subscriptionFree) {

                if (this.form.get('subscriptionPriceDiscount').value) {

                    this.form.get('subscriptionPriceDiscount').patchValue(null);
                }

                if (this.form.get('subscriptionPriceTypeDiscount').value) {

                    this.form.get('subscriptionPriceTypeDiscount').patchValue(null);
                }
            }

            if (value.typeUser == 'society') {

                if (this.customerForm.controls[0].get('firstName').value) {

                    this.customerForm.controls[0].get('firstName').patchValue(null);
                }

                if (this.customerForm.controls[0].get('lastName').value) {

                    this.customerForm.controls[0].get('lastName').patchValue(null);
                }

                if (this.customerForm.controls[0].get('email').value) {

                    this.customerForm.controls[0].get('email').patchValue(null);
                }

                if (this.customerForm.controls[0].get('code').value) {

                    this.customerForm.controls[0].get('code').patchValue(null);
                }

                if (this.customerForm.controls[0].get('societyName').value) {

                    this.customerForm.controls[0].get('societyName').patchValue(null);
                }
            }

            if (value.typeUser == 'new') {

                if (this.form.get('society').value) {

                    this.form.get('society').patchValue(null);
                }
            }
        });

        this.resetDuration('M');

        this._loadLocales();

        this._activatedRoute.data.subscribe((data: { societies: Society[], promotion: PromotionSubscription }): void => {

            data.societies.forEach((society: Society): void => {

                this.societies.push({
                    id: society.id,
                    name: society.name
                });
            });

            this.availableSubscriptions = data.promotion.subscription ? [data.promotion.subscription] : [];

            this.resetDuration(data.promotion.periodicity);

            const dataToPatch = {
                name: data.promotion.name,
                admissionPriceFree: data.promotion.admissionPriceFree,
                admissionPriceDiscount: data.promotion.admissionPriceDiscount,
                admissionPriceTypeDiscount: (data.promotion.admissionPriceTypeDiscount) ? data.promotion.admissionPriceTypeDiscount : '',
                subscription: data.promotion.subscription ? data.promotion.subscription.id: null,
                periodicity: data.promotion.periodicity,
                subscriptionFree: data.promotion.subscriptionFree,
                subscriptionPriceDiscount: data.promotion.subscriptionPriceDiscount,
                subscriptionPriceTypeDiscount: (data.promotion.subscriptionPriceTypeDiscount) ? data.promotion.subscriptionPriceTypeDiscount : '',
                subscriptionDurationDiscount: data.promotion.subscriptionDurationDiscount,
            };

            if (data.promotion.society) {

                dataToPatch['society'] = data.promotion.society.id;
            }

            if (data.promotion.society) {

                dataToPatch['typeUser'] = "society";

            } else {

                dataToPatch['typeUser'] = "new";

                dataToPatch['customer'] = [];

                dataToPatch['customer'].push({
                    firstName: data.promotion.customer.firstName,
                    lastName: data.promotion.customer.lastName,
                    email: data.promotion.customer.email,
                    code: data.promotion.customer.code,
                    societyName: data.promotion.customer.societyName,
                    locale: data.promotion.customer.locale,
                    dateStart: data.promotion.customer.dateStart,
                    dateEnd: data.promotion.customer.dateEnd
                });
            }

            if (dataToPatch.admissionPriceTypeDiscount == 'percent') {

                dataToPatch.admissionPriceDiscount = dataToPatch.admissionPriceDiscount * 100;
            }

            if (dataToPatch.admissionPriceTypeDiscount == 'amount') {

                dataToPatch.admissionPriceDiscount = dataToPatch.admissionPriceDiscount / 100;
            }

            if (dataToPatch.subscriptionPriceTypeDiscount == 'percent') {

                dataToPatch.subscriptionPriceDiscount = dataToPatch.subscriptionPriceDiscount * 100;
            }

            if (dataToPatch.subscriptionPriceTypeDiscount == 'amount') {

                dataToPatch.subscriptionPriceDiscount = dataToPatch.subscriptionPriceDiscount / 100;
            }

            this.form.patchValue(dataToPatch);

            this.form.disable();
        });
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _buildForm(): void {

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'society',
                attrs: {
                    label: this._translateService.instant('promotionSubscription.societies.value'),
                    choices: this.societies,
                    multiple: false,
                }
            }
        });
    }

    public updateValidateUser(): void {

        this.form.get('society').updateValueAndValidity();

        this.customerForm.controls[0].get('firstName').updateValueAndValidity();
        this.customerForm.controls[0].get('lastName').updateValueAndValidity();
        this.customerForm.controls[0].get('email').updateValueAndValidity();
        this.customerForm.controls[0].get('code').updateValueAndValidity();
        this.customerForm.controls[0].get('societyName').updateValueAndValidity();
    }

    public initDuration(periodicity: string): { id: number, name: string }[] {

        return [
            {
                id: 1,
                name: '1 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.value'))
            },
            {
                id: 2,
                name: '2 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 3,
                name: '3 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 4,
                name: '4 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))

            },
            {
                id: 5,
                name: '5 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 6,
                name: '6 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 7,
                name: '7 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 8,
                name: '8 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 9,
                name: '9 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 10,
                name: '10 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 11,
                name: '11 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 12,
                name: '12 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            }
        ];
    }

    public initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: ['', Validators.required],
            society: [''],
            admissionPriceFree: [false],
            admissionPriceDiscount: [''],
            admissionPriceTypeDiscount: [''],
            periodicity: ['', Validators.required],
            subscriptionFree: [false],
            subscriptionPriceDiscount: ['', Validators.required],
            subscriptionPriceTypeDiscount: ['', Validators.required],
            subscriptionDurationDiscount: [null],
            typeUser: ['', Validators.required],
            customer: new UntypedFormArray([]),
            subscription: ['', Validators.required],
        });

        this.customerForm.push(this._formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: ['', [Validators.pattern(REGEX_EMAIL)]],
            code: [''],
            societyName: [''],
            locale: [this.localeId, Validators.required],
            dateStart: [null],
            dateEnd: [null]
        }));

        this.form.controls["admissionPriceDiscount"].setValidators([(control: UntypedFormControl) => {

            return (!this.form.get('admissionPriceFree').value && !this.form.get('admissionPriceDiscount').value) ? {required: {valid: false}} : null;
        }]);

        this.form.controls["admissionPriceTypeDiscount"].setValidators([(control: UntypedFormControl) => {

            return (!this.form.get('admissionPriceFree').value && !this.form.get('admissionPriceTypeDiscount').value) ? {required: {valid: false}} : null;
        }]);

        this.form.controls["subscriptionPriceDiscount"].setValidators([(control: UntypedFormControl) => {

            return (!this.form.get('subscriptionFree').value && !this.form.get('subscriptionPriceDiscount').value) ? {required: {valid: false}} : null;
        }]);

        this.form.controls["subscriptionPriceTypeDiscount"].setValidators([(control: UntypedFormControl) => {

            return (!this.form.get('subscriptionFree').value && !this.form.get('subscriptionPriceTypeDiscount').value) ? {required: {valid: false}} : null;
        }]);

        this.form.controls["society"].setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'society' && !this.form.get('society').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("firstName").setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('firstName').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("lastName").setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('lastName').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("email").setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('email').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("code").setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('code').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("societyName").setValidators([(control: UntypedFormControl) => {

            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('societyName').value) ? {required: {valid: false}} : null;
        }]);

        this._buildForm();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign({...this.form.value}, {
                subscription: this.form.get('subscription') ? { id: parseInt(this.form.get('subscription').value ) } : null
            });

            if (data['admissionPriceDiscount']) {

                if (data['admissionPriceTypeDiscount'] === 'percent') {

                    data['admissionPriceDiscount'] = data['admissionPriceDiscount'] / 100;
                }

                if (data['admissionPriceTypeDiscount'] === 'amount') {

                    data['admissionPriceDiscount'] = data['admissionPriceDiscount'] * 100;
                }
            }

            if (data['subscriptionPriceDiscount']) {

                if (data['subscriptionPriceTypeDiscount'] === 'percent') {

                    data['subscriptionPriceDiscount'] = data['subscriptionPriceDiscount'] / 100;
                }

                if (data['subscriptionPriceTypeDiscount'] === 'amount') {

                    data['subscriptionPriceDiscount'] = data['subscriptionPriceDiscount'] * 100;
                }
            }

            if (data['admissionPriceFree']) {

                data['admissionPriceDiscount'] = null;

                data['admissionPriceTypeDiscount'] = null;
            }

            if (data['subscriptionFree']) {

                data['subscriptionPriceDiscount'] = null;

                data['subscriptionPriceTypeDiscount'] = null;
            }

            data['customer'] = data['customer'][0];

            if (data['typeUser'] == 'society') {

                data['customer'] = null;
            }

            if (data['typeUser'] == 'new') {

                data['society'] = null;
            }

            this.promotionSubscriptionService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('promotionSubscription.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public resetDuration(periodicity: string): void {

        this.durationOptions = this.initDuration(periodicity);
    }

    public redirectToList(): void {

        this._router.navigate(['/account/subscription/promotion/list']);
    }

    public updateValidatorAdmission(): void {

        this.form.get('admissionPriceTypeDiscount').updateValueAndValidity();

        this.form.get('admissionPriceDiscount').updateValueAndValidity();
    }

    public updateValidatorSubscription(): void {

        this.form.get('subscriptionPriceTypeDiscount').updateValueAndValidity();

        this.form.get('subscriptionPriceDiscount').updateValueAndValidity();
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get customerForm(): UntypedFormArray {

        return this.form.get('customer') as UntypedFormArray;
    }
}
