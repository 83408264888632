import {Moment} from "moment";
import {Society} from "@core/shared/models/society";
import {Subscription} from "@core/shared/models/subscription";

export const PROMOTION_SUBSCRIPTION_USED = 'used';

export const PROMOTION_SUBSCRIPTION_EXPIRED = 'expired';

export interface PromotionSubscription {

    id: number;

    name: string;

    createdAt: Moment;

    admissionPriceFree: boolean;

    admissionPriceDiscount: number;

    admissionPriceTypeDiscount: string;

    society: Society;

    customer: {
        lastName: string;
        firstName: string;
        email: string;
        code: string;
        locale: string;
        societyName: string;
        dateStart: string;
        dateEnd: string;
    }

    periodicity: string;

    subscriptions: Subscription[];

    subscription: Subscription;

    subscriptionFree: boolean;

    subscriptionPriceDiscount: number;

    subscriptionPriceTypeDiscount: string;

    subscriptionDurationDiscount: string;

    status: string;

}

