import {NgModule} from "@angular/core";
import {InputTextComponent} from "./fields/input-text/input-text.component";
import {InputDirective} from "./fields/input.directive";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TextareaComponent} from "./fields/textarea/textarea.component";
import {SelectComponent} from "./fields/select/select.component";
import {SelectSearchComponent} from "./fields/select-search/select-search.component";
import {InputNumberComponent} from "./fields/input-number/input-number.component";
import {InputCheckboxComponent} from "./fields/input-checkbox/input-checkbox.component";
import {InputRadioComponent} from "./fields/input-radio/input-radio.component";
import {InputDateComponent} from "./fields/input-date/input-date.component";
import {InputDaterangeComponent} from "./fields/input-daterange/input-daterange.component";
import {InputDatetimeComponent} from "./fields/input-datetime/input-datetime.component";
import {ImageComponent} from './fields/image/image.component';
import {ImageCollectionComponent} from './fields/image-collection/image-collection.component';
import {ToggleComponent} from "./fields/toggle/toggle.component";
import {SelectDirective} from "./fields/select.directive";
import {RadioDirective} from "./fields/radio.directive";
import {CheckboxDirective} from "./fields/checkbox.directive";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {TranslateModule} from "@ngx-translate/core";
import {FormComponent} from './form/form.component';
import {FieldService} from "./field";
import {CkeditorComponent} from "./fields/ckeditor/ckeditor.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {FileComponent} from './fields/file/file.component';
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {InputPasswordComponent} from "./fields/input-password/input-password.component";
import {InputTimeComponent} from "./fields/input-time/input-time.component";
import {MediaModule} from "@lib/media/media.module";
import {MaterialModule} from "@material/material.module";

/**
 * NgModule incluant tous les modules` des formulaires
 */
@NgModule({
    declarations: [
        InputTextComponent,
        InputPasswordComponent,
        InputDirective,
        TextareaComponent,
        SelectComponent,
        SelectSearchComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        InputDateComponent,
        InputTimeComponent,
        InputNumberComponent,
        CkeditorComponent,
        InputDaterangeComponent,
        InputDatetimeComponent,
        SelectDirective,
        RadioDirective,
        CheckboxDirective,
        FormComponent,
        ToggleComponent,
        ImageComponent,
        ImageCollectionComponent,
        FileComponent
    ],
    exports: [
        InputTextComponent,
        InputPasswordComponent,
        TextareaComponent,
        SelectComponent,
        SelectSearchComponent,
        InputDaterangeComponent,
        InputCheckboxComponent,
        InputRadioComponent,
        InputDateComponent,
        InputNumberComponent,
        CkeditorComponent,
        InputDatetimeComponent,
        InputTimeComponent,
        FormsModule,
        FormComponent,
        ToggleComponent,
        ImageComponent,
        ImageCollectionComponent,
        FileComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        MediaModule,
        CKEditorModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        NgxMatSelectSearchModule,
        MatMomentDateModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        FieldService
    ]
})
export class FormModule {
}
