import {Component, OnInit} from '@angular/core';
import {FormService} from "@core/shared/services/form.service";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {ActivatedRoute, Router} from "@angular/router";
import {PROMOTION_TYPE_ITEMS, PromotionTypeItem, PromotionTypeType} from "@core/shared/models/promotion/promotion-type";
import {Observable, of, Subscription} from "rxjs";
import {PROMOTION_PUBLIC_ITEMS, PromotionPublicItem, PromotionPublicType} from "@core/shared/models/promotion/promotion-public";
import {Offer} from "@core/shared/models/offer";
import {OfferService} from "@core/shared/services/offer.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {Role} from "@core/shared/models/role";
import {User} from "@core/shared/models/user";
import {map} from "rxjs/operators";
import {OfferCatalog, OfferCatalogStatusType} from "@core/shared/models/offer/offer-catalog";
import {FilterBuilder} from "@core/shared/models/filter";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {Society} from "@core/shared/models/society";
import {SocietyService} from "@core/shared/services/society.service";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {PromotionService} from "@core/shared/services/promotion.service";
import {Promotion} from "@core/shared/models/promotion";
import {Moment} from "moment";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {NetworkProvider} from "@core/shared/models/network-provider";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {UserService} from "@core/shared/services/user.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {Access} from "@core/shared/models/access";

@Component({
    selector: 'app-core-page-promotion-create',
    templateUrl: './page-promotion-create.component.html',
    styleUrls: ['./page-promotion-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PagePromotionCreateComponent implements OnInit {

    public translationBuilder: TranslationBuilder;

    public unlimitedQuota: boolean = false;

    public allDistributors: boolean = false;

    public allCustomers: boolean = false;

    public allNetworkProviders: boolean = false;

    public distributors$: Observable<Society[]>;

    public networkProviders$: Observable<NetworkProvider[]>;

    public user: User;

    public society: Society;

    public createdOffers: {id: number, name: string}[] = [];

    public catalogOffers: {id: number, name: string}[] = [];

    public loadCreatedOffersSubscription: Subscription;

    public loadCatalogOffersSubscription: Subscription;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _offerService: OfferService,
        private _offerCatalogService: OfferCatalogService,
        private _societyService: SocietyService,
        private _networkProviderService: OfferNetworkProviderService,
        private _promotionService: PromotionService,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _snackBar: MatSnackBar,
        public formService: FormService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._initForm();

        this._hydrateForm();

        this._initEvents();

        this._activatedRoute.data.subscribe((data: { user : User, society: Society }): void => {

            this.user = data.user;

            this.society = data.society;
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            translations: new UntypedFormArray([]),
            type: [null, [Validators.required]],
            dateStart: [null, [Validators.required]],
            timeStart: ['00:00', [Validators.required]],
            dateEnd: [null, [Validators.required]],
            timeEnd: ['23:59', [Validators.required]],
            code: [null, [(control: UntypedFormControl) => {

                if(this.isType('offer')){

                    return null;
                }

                return !control.value?.length ? {isRequired : {valid: false}} : null;
            }]],
            value: [null, [Validators.required, Validators.pattern(REGEX_PRICE)]],
            public: [null, [(control: UntypedFormControl) => {

                if(this.isType('offer')){

                    return null;
                }

                return (control.value === null) ? {isRequired : {valid: false}} : null;
            }]],
            quota: [null, [Validators.pattern(/^[0-9]+$/), (control: UntypedFormControl) => {

                if(this.isType('offer') || (this.isType('code') && this.unlimitedQuota)){

                    return null;
                }

                return (!control.value?.length || control.value === 0) ? {isRequired : {valid: false}} : null;
            }]],
            comment: [''],
            enable: [null, [Validators.required]],
            isRestricted: [false, [Validators.required]],
            locales: [[], [(control: UntypedFormControl) => {

                if(this.isType('code')){

                    return null;
                }

                return !control.value.length ? {isRequired : {valid: false}} : null;
            }]],
            timezone: [this._userService.currentUser.value.timezone, [Validators.required]],
            allowAllCreatedOffers: [false],
            allowAllCatalogOffers: [false],
            createdOffers: [[], [(control: UntypedFormControl) => {

                if(!this.hasRole('ROLE_OFFER_CREATOR')){

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };
            }]],
            catalogOffers: [[], [(control: UntypedFormControl) => {

                if(!this.hasRole('ROLE_OFFER_DISTRIBUTOR')){

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };
            }]],
            distributors: [[], [(control: UntypedFormControl) => {

                if(this.isType('offer') && this.form && this.form.get('isRestricted') && this.form.get('isRestricted').value && !control.value.length){

                    return {
                        'minLengthArray': {
                            valid: false
                        }
                    };
                }

                if(!this.isPublic('distributor')){

                    return null;
                }

                if(this.allDistributors){

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };
            }]],
            networkProviders: [[], [(control: UntypedFormControl) => {

                if(!this.isPublic('networkProvider')){

                    return null;
                }

                if(this.allNetworkProviders){

                    return null;
                }

                if (control.value.length >= 1) {

                    return null;
                }

                return {
                    'minLengthArray': {
                        valid: false
                    }
                };
            }]],
            parsedCustomers: ['', [(control: UntypedFormControl) => {

                if(!this.isPublic('customer')){

                    return null;
                }

                if(this.allCustomers){

                    return null;
                }

                if(!control.value.length){

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                const pattern: RegExp = /^([-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@[a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([a-zA-Z]{2,});){0,}$/;

                if (pattern.test(`${control.value};`)) {

                    return null;
                }

                return {
                    'parsedEmailPattern': {
                        valid: false
                    }
                };
            }]],
            allowBooking: [false],
            allowGiftVoucher: [false]
        }, { validators: [(form: UntypedFormGroup) => {

                if(form.get('allowBooking').value || form.get('allowGiftVoucher').value){

                    return null;
                }

                return {
                    'isMarketingConfigurationRequired': {
                        valid: false
                    }
                };
            }]
        });


        this.form.controls["createdOffers"].setValidators([(control: UntypedFormControl) => {
            if(!this.hasRole('ROLE_OFFER_CREATOR')){
                return null;
            }

            if (control.value.length >= 1) {
                return null;
            }

            if(this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR')){
                return (
                    !this.form.get('createdOffers').value.length &&
                    !this.form.get('allowAllCreatedOffers').value &&
                    !this.form.get('allowAllCatalogOffers').value &&
                    !this.form.get('catalogOffers').value.length)

                    ? {'minLengthArray': {
                            valid: false
                        }} : null;
            }

            return {
                'minLengthArray': {
                    valid: false
                }
            };
        }]);

        this.form.controls["catalogOffers"].setValidators([(control: UntypedFormControl) => {
            if(!this.hasRole('ROLE_OFFER_DISTRIBUTOR')){

                return null;
            }

            if (control.value.length >= 1) {

                return null;
            }

            if(this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR')){
                return (
                    !this.form.get('createdOffers').value.length &&
                    !this.form.get('allowAllCreatedOffers').value &&
                    !this.form.get('allowAllCatalogOffers').value &&
                    !this.form.get('catalogOffers').value.length)

                    ? {'minLengthArray': {
                            valid: false
                        }} : null;
            }

            return {
                'minLengthArray': {
                    valid: false
                }
            };
        }]);

        this._initTranslationsForm();

        this.formService.submitCallback = () => {

            const dateStart: Moment = moment(this.form.get('dateStart').value);

            dateStart.set({
                hour: parseInt((this.form.get('timeStart').value as string).split(':')[0]),
                minute: parseInt((this.form.get('timeStart').value as string).split(':')[1])
            });

            const dateEnd: Moment = moment(this.form.get('dateEnd').value);

            dateEnd.set({
                hour: parseInt((this.form.get('timeEnd').value as string).split(':')[0]),
                minute: parseInt((this.form.get('timeEnd').value as string).split(':')[1])
            });

            const createdOfferIds: number[] = (this.hasRole('ROLE_OFFER_CREATOR') && !this.form.get('allowAllCreatedOffers').value) ? (this.form.get('createdOffers').value as number[]) : [];

            const catalogOfferIds: number[] = (this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.form.get('allowAllCatalogOffers').value) ? (this.form.get('catalogOffers').value as number[]) : [];

            const offerIds: number[] = [...createdOfferIds, ...catalogOfferIds];

            const data: object = {
                translations: this.form.get('translations').value,
                type: this.form.get('type').value,
                dateStart: dateStart.format('YYYY-MM-DD HH:mm:ss'),
                dateEnd: dateEnd.format('YYYY-MM-DD HH:mm:ss'),
                code: this.form.get('code').value,
                value: this.form.get('value').value / 100,
                public: this.isType('code') ? this.form.get('public').value : (this.form.get('isRestricted').value ? 'distributor' : null),
                quota: this.form.get('quota').value,
                comment: this.form.get('comment').value,
                enable: this.form.get('enable').value,
                locales: this.form.get('locales').value,
                timezone: this.form.get('timezone').value,
                offers: offerIds.map((id: number): { id: number } => { return { id: id } }),
                distributors: this.isType('code') ? (this.form.get('distributors').value as number[]).map((id: number): { id: number } => { return { id: id } }) : (this.form.get('distributors').value as Society[]).map((item: Society): { id: number } => { return { id: item.id } }),
                networkProviders: (this.form.get('networkProviders').value as number[]).map((id: number): { id: number } => { return { id: id } }),
                parsedCustomers: this.form.get('parsedCustomers').value,
                allowAllCreatedOffers: this.form.get('allowAllCreatedOffers').value,
                allowAllCatalogOffers: this.form.get('allowAllCatalogOffers').value,
                allowBooking: this.form.get('allowBooking').value,
                allowGiftVoucher: this.form.get('allowGiftVoucher').value
            };

            this._promotionService.createItemAPI(this.society.id, data).subscribe((item: Promotion): void => {

                this._snackBar.open(this._translateService.instant('promotion.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                if(this.isType('offer')){

                    this._router.navigate(['account/promotion/update', item.id], { fragment: 'periods' });
                }
                else{

                    this.redirectToList();
                }
            });
        };
    }

    private _hydrateForm(): void {

        this.form.get('allowBooking').patchValue(true);

        this.form.get('allowGiftVoucher').patchValue(this.hasGiftVoucherAccess);
    }

    private _initEvents(): void {

        // Changement du type de promotion

        this.form.get('type').valueChanges.subscribe((): void => {

            // Code

            this.form.get('code').patchValue(null);

            this.form.get('code').updateValueAndValidity();

            // Public

            this.form.get('public').patchValue(null);

            this.form.get('public').updateValueAndValidity();

            // Quota

            this.form.get('quota').patchValue(null);

            this.form.get('quota').updateValueAndValidity();

            // Promotion ciblée

            this.form.get('isRestricted').patchValue(false);

            this.form.get('isRestricted').updateValueAndValidity();

            // Langues des offres

            this.form.get('locales').patchValue([]);

            this.form.get('locales').updateValueAndValidity();

            // Offres

            this.resetOffers();

            // Distributeurs

            this.resetDistributors();

            // Clients

            this.resetCustomers();

            // Réseaux de prestataire

            this.resetNetworkProviders();
        });

        // Changement des langues

        this.form.get('locales').valueChanges.subscribe((): void => {

            this.form.get('allowAllCreatedOffers').patchValue(false);

            this.form.get('allowAllCatalogOffers').patchValue(false);

            this.resetOffers();

            this._loadOffers();
        });

        // Changement du public

        this.form.get('public').valueChanges.subscribe((value: PromotionPublicType): void => {

            // Offres

            this.resetOffers();

            // Distributeurs

            this.resetDistributors();

            // Clients

            this.resetCustomers();

            // Réseaux de prestataire

            this.resetNetworkProviders();

            switch (value) {

                case 'distributor':

                    this._loadDistributors();

                    break;

                case 'networkProvider':

                    this._loadNetworkProviders();

                    break;
            }
        });

        // Changement des réseaux de prestataire

        this.form.get('networkProviders').valueChanges.subscribe((): void => {

            this.resetOffers();

            this._loadOffers();
        });

        // Promotion ciblée

        this.form.get('isRestricted').valueChanges.subscribe((): void => {

            this.allDistributors = true;

            this.resetDistributors();
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                name: ['', [Validators.required]]
            });
        };

        this.translationBuilder.addDefaultItem();
    }

    private _loadOffers(): void {

        switch (true) {

            case this.hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']):

                this._loadCreatedOffers();

                this._loadCatalogOffers();

                break;

            case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                this._loadCreatedOffers();

                this.catalogOffers = [];

                break;

            case this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'):

                this._loadCatalogOffers();

                this.createdOffers = [];

                break;
        }
    }

    private _loadCreatedOffers(): void {

        const params: string[] = this.offerApiParams;

        if(this.loadCreatedOffersSubscription){

            this.loadCreatedOffersSubscription.unsubscribe();
        }

        this.loadCreatedOffersSubscription = this._offerService.getItemsSocietyAPI(this.society.id, params).subscribe((items: Offer[]): void => {


            items.forEach((offer: Offer) => {

                this.createdOffers.push(
                    {
                        id: offer.id,
                        //@ts-ignore
                        name:  this.translationService.getFallbackTranslation(offer.translations).name
                    });
            });
        });
    }

    private _loadCatalogOffers(): void {

        const params: string[] = this.offerCatalogsApiParams;

        const statusFilter: TextFilterField = new TextFilterField('status', 'eq', ('accepted' as OfferCatalogStatusType));

        const offerSocietyFilter: TextFilterField = new TextFilterField('offer.society.id', 'ne', this.society.id);

        params.push(statusFilter.serialize, offerSocietyFilter.serialize);

        if(this.loadCatalogOffersSubscription){

            this.loadCatalogOffersSubscription.unsubscribe();
        }

        this.loadCatalogOffersSubscription = this._offerCatalogService.getItemsAPI(params).pipe(
            map((items: OfferCatalog[]): Offer[] => {

                return items.map((item: OfferCatalog): Offer => {

                    return item.offer;
                });
            })
        ).subscribe((items: Offer[]): void => {

            items.forEach((offer: Offer) => {
                this.catalogOffers.push(
                    {
                        id: offer.id,
                        //@ts-ignore
                        name: this.translationService.getFallbackTranslation(offer.translations).name
                    });
            });
        });
    }

    private _loadDistributors(): void {

        this.distributors$ = this._societyService.getOfferDistributorsItemsAPI();
    }

    private _loadNetworkProviders(): void {

        this.networkProviders$ = this._networkProviderService.getNetworkProvidersAPI();
    }

    public handleAllowCreatedOffers(): void {
        this.form.get('createdOffers').updateValueAndValidity();
        this.form.get('catalogOffers').updateValueAndValidity();

        if(this.form.get('allowAllCreatedOffers').value){

            this.form.get('createdOffers').patchValue(this.createdOffers.map((item): number => {
                return item.id;
            }));

            this.form.get('createdOffers').updateValueAndValidity();
        }
        else{

            this.resetCreatedOffers();

            this._loadCreatedOffers();
        }
    }

    public handleAllowCatalogOffers(): void {
        this.form.get('createdOffers').updateValueAndValidity();
        this.form.get('catalogOffers').updateValueAndValidity();

        if(this.form.get('allowAllCatalogOffers').value){

            this.form.get('catalogOffers').patchValue(this.catalogOffers.map((item): number => {

                return item.id;
            }));

            this.form.get('catalogOffers').updateValueAndValidity();
        }
        else{
            this.resetCatalogOffers();

            this._loadCatalogOffers();
        }
    }

    public redirectToList(): void {

        this._router.navigate(['account/promotion/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public isType(value: PromotionTypeType): boolean {

        return this.form?.get('type').value === value;
    }

    public isPublic(value: PromotionPublicType): boolean {

        return this.form?.get('public').value === value;
    }

    public isOneOfThisPublics(values: PromotionPublicType[]): boolean {

        return values.some((value: PromotionPublicType): boolean => {

            return this.isPublic(value);
        });
    }

    public resetQuota(): void {

        this.form.get('quota').patchValue(null);

        this.form.get('quota').updateValueAndValidity();
    }

    public resetOffers(): void {

        this.resetCreatedOffers();

        this.resetCatalogOffers();
    }

    public resetCreatedOffers(): void {

        this.createdOffers = [];

        this.form.get('createdOffers').patchValue([]);

        this.form.get('createdOffers').updateValueAndValidity();
        this.form.get('catalogOffers').updateValueAndValidity();

    }

    public resetCatalogOffers(): void {

        this.catalogOffers = [];

        this.form.get('catalogOffers').patchValue([]);

        this.form.get('catalogOffers').updateValueAndValidity();
        this.form.get('createdOffers').updateValueAndValidity();
    }

    public resetDistributors(): void {

        this.form.get('distributors').patchValue([]);

        this.form.get('distributors').updateValueAndValidity();
    }

    public resetNetworkProviders(): void {

        this.form.get('networkProviders').patchValue([]);

        this.form.get('networkProviders').updateValueAndValidity();
    }

    public resetCustomers(): void {

        this.form.get('parsedCustomers').patchValue('');

        this.form.get('parsedCustomers').updateValueAndValidity();
    }

    public hasRole(role: Role): boolean {

        return this.user?.roles.includes(role);
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get promotionTypeItems$(): Observable<PromotionTypeItem[]> {

        return of(PROMOTION_TYPE_ITEMS);
    }

    get promotionPublicItems$(): Observable<PromotionPublicItem[]> {

        const roleFilterCallback = ((role: Role): boolean => {

            return this.hasRole(role);
        });

        return of(PROMOTION_PUBLIC_ITEMS).pipe(
            map((items: PromotionPublicItem[]): PromotionPublicItem[] => {

                return items.filter((item: PromotionPublicItem): boolean => {

                    if(item.roleFilterMode === 'every'){

                        return item.allowedRoles.every(roleFilterCallback);
                    }

                    if(item.roleFilterMode === 'some'){

                        return item.allowedRoles.some(roleFilterCallback);
                    }
                });
            })
        );
    }

    get locales$(): Observable<LocaleItem[]> {

        return of(LOCALE_ITEMS);
    }

    get offerApiParams(): string [] {

        let prefix: string;

        switch (true) {

            case this.hasRole('ROLE_OFFER_CREATOR'):

                prefix = '';

                break;

            case this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                prefix = 'offer.';

                break;
        }

        const filterBuilder: FilterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField(`${prefix}published`, 'ne', 'draft'));

        (this.form.get('locales').value as string[]).forEach((locale: string): void => {

            filterBuilder.addField(new ArrayFilterField(`${prefix}locales`, 'lkin', locale));
        });

        (this.form.get('networkProviders').value as number[]).forEach((id: number): void => {

            filterBuilder.addField(new ArrayFilterField(`${prefix}networkProviders`, 'in', id.toString()));
        });

        return filterBuilder.serializedFilters;
    }

    get offerCatalogsApiParams(): string[] {

        const params = this.offerApiParams;

        return params.map((param: string): string => {

            switch(true) {

                case !param.includes(`offer.locales`):

                    param = param.replace(/locales/g, 'offer.locales')

                    break;
            }

            return param;
        });
    }

    public changeCatalogOffers(event):void{
        this.form.get('catalogOffers').patchValue(event.value);
        this.form.get('allowAllCatalogOffers').patchValue(false)
        this.form.get('allowAllCreatedOffers').patchValue(false);
        this.form.get('createdOffers').updateValueAndValidity();
        this.form.get('catalogOffers').updateValueAndValidity();
    }

    public changeCreatedOffers(event):void{

        this.form.get('createdOffers').patchValue(event.value);
        this.form.get('allowAllCreatedOffers').patchValue(false)
        this.form.get('allowAllCatalogOffers').patchValue(false);
        this.form.get('createdOffers').updateValueAndValidity();
        this.form.get('catalogOffers').updateValueAndValidity();
    }

    public get configCreatedOffers() {
        return {
            id: 'createdOffers',
            attrs: {
                label: this._translateService.instant('promotion.offer.plural.value'),
                required: false,
                choices: this.createdOffers,
                multiple: true,
            }
        }
    }

    public get configCatalogOffers() {
        return {
            id: 'catalogOffers',
            attrs: {
                label: this._translateService.instant('promotion.offer.plural.value'),
                required: false,
                choices: this.catalogOffers,
                multiple: true,
            }
        }
    }

    get hasGiftVoucherAccess(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }
}
