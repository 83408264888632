import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMimeTypes, MimeTypes, Video, VIDEO_EXTENSIONS, VideoExtension} from "./video";
import {ApiService} from "../../../api/api.service";
import {VideoService} from "./video.service";
import {VideoDialogComponent, VideoDialogData} from "./video-dialog/video-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {FileUploadConfig, IApiFileUpload} from "@lib/media/file-upload/file-upload";

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['../../element.component.scss']
})
export class VideoComponent implements OnInit {

    @Input() content: Video;

    @Input() apiUrl: string;

    @Output() update: EventEmitter<Video> = new EventEmitter<Video>();

    configInitialized: boolean = false;

    videoExtensions: VideoExtension[];

    mimeTypes: IMimeTypes;

    constructor(
        private _apiService: ApiService,
        private _videoService: VideoService,
        private _translateService: TranslateService,
        private _snackbar: MatSnackBar,
        private _dialog: MatDialog) {

        this.videoExtensions = VIDEO_EXTENSIONS;

        this.mimeTypes = MimeTypes;
    }

    ngOnInit() {

        this._videoService.setApiUrl(this.apiUrl);

        if (!this.content) {

            this.content = {
                waitingImage: null,
                mp4: null,
                webm: null,
                ogv: null
            };
        }

        // Initialisation de la configuration pour l'upload de fichier

        if (this._videoService.configLoaded()) {

            this.configInitialized = true;
        } else {

            this._videoService.loadConfig().subscribe((data: any) => {

                    this._videoService.setConfig(data);

                    this.configInitialized = true;

                },
                (error : HttpErrorResponse) => {
                    this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                        this._snackbar.open(error.error, messages['action.close']);
                    });
                }
            );
        }
    }

    getFileUploadConfig(mimeTypes: string[]): FileUploadConfig {

        return {
            multiple: false,
            allowedTypes: mimeTypes.join(),
            maxSize: this._videoService.getUploadMaxSize(),
            uploadApi: {
                url: this._apiService.getApiUrl(true) + '/cms/api/upload/file'
            },
            uploadBtnMsg: 'element.types.file.choice.action.value'
        };
    }

    /**
     * Méthode appelée une fois l'upload de fichier terminé
     *
     * @param {IApiFileUpload} apiResult
     */
    postUploadWaitingImage(apiResult: IApiFileUpload): void {

        if (apiResult.error) {

            this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(apiResult.msg, messages['action.close']);
            });

            return;
        }

        this.content.waitingImage = apiResult.file; // On met à jour le fichier du composant

        this.setContent(this.content);
    }

    /**
     * Méthode appelée une fois l'upload de fichier terminé
     *
     * @param {VideoExtension} extension
     * @param {IApiFileUpload} apiResult
     */
    postUploadExtension(extension: VideoExtension, apiResult: IApiFileUpload): void {

        if (apiResult.error) {

            this._translateService.get(['action.close']).subscribe((messages: string[]) => {
                this._snackbar.open(apiResult.msg, messages['action.close']);
            });

            return;
        }

        this.content[extension] = apiResult.file; // On met à jour le fichier du composant

        this.setContent(this.content);
    }

    /**
     * Mise à jour du contenu de l'élément
     *
     * @param {Video} video
     */
    setContent(video: Video) {

        this.update.emit(video); // On met à jour le contenu de l'élément
    }

    displayVideo(): void {

        const data: VideoDialogData = {
            video: this.content,
            extensions: this.videoExtensions,
            width: 600
        };

        this._dialog.open(VideoDialogComponent, {
            width: '800px',
            data: data
        });
    }
}
