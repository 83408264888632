import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "@core/shared/services/user.service";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {Society} from "@core/shared/models/society";
import {SocietyProviderService} from "@core/shared/services/society/society-provider.service";
import {SocietyProvider} from "@core/shared/models/society/society-provider";
import {Address} from "@core/shared/models/address";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {
    PageSocietyProviderCreateDialogComponent
} from "@core/pages/page-society/page-society-provider/page-society-provider-create/page-society-provider-create-dialog/page-society-provider-create-dialog.component";
import {Access, AccessType} from "@core/shared/models/access";
import {
    PageSocietyProviderSendRequestDialogComponent
} from "@core/pages/page-society/page-society-provider/page-society-provider-send-request/page-society-provider-send-request-dialog/page-society-provider-send-request-dialog.component";
import {SocietyService} from "@core/shared/services/society.service";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {SocietyCommissionType} from "@core/shared/models/society-commission";

@Component({
    selector: 'app-page-society-provider-list',
    templateUrl: './page-society-provider-list.component.html',
    styleUrls: ['./page-society-provider-list.component.scss']
})
export class PageSocietyProviderListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public enableStatus: { id: number, label: string }[] = [
        {
            id: 1,
            label: this._translateService.instant('provider.enable.yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('provider.enable.no.value')
        }
    ];

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'enable',
        'provider.reference',
        'provider.name',
        'provider.admin.lastName',
        'provider.admin.firstName',
        'provider.website',
        'provider.addresses.city',
        'provider.commission.type',
        'provider.commission.incomingPayment',
        'totalMarketplaceOffers'
    ];

    public filterBuilder: FilterBuilder;

    public user: User;

    public society: Society;

    public items: SocietyProvider[] = [];

    public filterCommissionTypes: {identifier: SocietyCommissionType, label: string}[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _userService: UserService,
        private _societyService: SocietyService,
        private _societyProviderService: SocietyProviderService
    ) {
    }

    ngOnInit() {

        this.user = this._userService.currentUser.getValue();

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.society = data.society;

            this._initFilterBuilder();

            this._loadFilterCommissionTypes();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: SocietyProvider[]): void => {

                this.items = data;

            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyProvider[]): void => {

                this.items = data;
            });
        };
    }

    private _loadFilterCommissionTypes(): void {

        this.filterCommissionTypes = [
            {
                identifier: 'standard',
                label: this._translateService.instant('provider.commissionType.standard.value')
            },
            {
                identifier: 'custom',
                label: this._translateService.instant('provider.commissionType.custom.value')
            }
        ];
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    private _reloadData(): void {

        this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams)
            .pipe(map(this.mapApiResult))
            .subscribe((data: SocietyProvider[]): void => {
                this.items = data;
            });

        this._societyService.getItemSelfAPI()
            .subscribe((data: Society): void => {
                this.society = data;
            });
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyProvider[]): void => {

            this.items = data;
        });
    }

    public getModel(item: SocietyProvider): SocietyProvider {

        return item;
    }

    public getMailingAddress(item: SocietyProvider): Address {

        return item.provider.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openAddItemDialog(): void {

        if (!this.isCreationAllowed) {

            return;
        }

        const dialogRef: MatDialogRef<PageSocietyProviderCreateDialogComponent> = this._dialog.open(PageSocietyProviderCreateDialogComponent, {
            width: '500px'
        });

        dialogRef.componentInstance.submit.subscribe((data: object): void => {

            this._societyProviderService.createItemAPI(this.society.id, data).subscribe((): void => {

                dialogRef.close();

                this._snackBar.open(this._translateService.instant('society.provider.add.confirm.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyProvider[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public openSendRequestItemDialog(): void {

        if (!this.isSendRequestAllowed) {

            return;
        }

        const dialogRef: MatDialogRef<PageSocietyProviderSendRequestDialogComponent> = this._dialog.open(PageSocietyProviderSendRequestDialogComponent, {
            width: '500px'
        });

        dialogRef.componentInstance.submit.subscribe((data: object): void => {

            this._societyProviderService.sendRequestItemAPI(data).subscribe((): void => {

                dialogRef.close();

                this._snackBar.open(this._translateService.instant('society.provider.request.send.confirm.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            });
        });
    }

    public openDeleteItemDialog(item: SocietyProvider): void {

        if (!this.isDeletionAllowed) {

            return;
        }

        if (!item.isDeletable) {

            this._snackBar.open(this._translateService.instant('societyProvider.delete.disabled.value'), this._translateService.instant('error.notification.close.value'), {
                duration: 20000
            });

            return;
        }

        const title: string = this._translateService.instant('society.provider.delete.value');

        const content: string = this._translateService.instant('society.provider.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyProviderService.deleteItemAPI(item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('society.provider.delete.confirm.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._societyProviderService.getPaginationSocietyItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyProvider[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public readItem(item: SocietyProvider): void {

        if (!this.isReadAllowed) {

            return;
        }

        this._router.navigate(['/account/society/provider/read', item.id]);
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.sort.active && this.sort.direction !== '') {

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);

            if(this.filterBuilder.getFieldByKey('provider.websites.website').value) {

                const mainWebsiteFilter: TextFilterField = new TextFilterField('provider.websites.isMain', 'eq', 1);

                params.push(mainWebsiteFilter.serialize);
            }
        }

        return params;
    }

    public userHasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.user.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    public openActivateItemDialog(event, id: number): void {

        const item: SocietyProvider = this.items.find((societyProvider: SocietyProvider) => {
            return societyProvider.id === id;
        });

        const title: string = this._translateService.instant('provider.activate.value');

        const content: string = this._translateService.instant('provider.activate.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.cancel.subscribe((): void => {
            event.source.checked = item.enable;
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyProviderService.updateItemAPI(item.id, {enable: true}).subscribe(() => {

                this._snackBar.open(this._translateService.instant('provider.activate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._reloadData();

            });
        });
    }

    public openDesactivateItemDialog(event, id: number): void {

        const item: SocietyProvider = this.items.find((societyProvider: SocietyProvider) => {
            return societyProvider.id === id;
        });

        const title: string = this._translateService.instant('provider.desactivate.value');

        const content: string = this._translateService.instant('provider.desactivate.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.cancel.subscribe((): void => {
            event.source.checked = item.enable;
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyProviderService.updateItemAPI(item.id, {enable: false}).subscribe(() => {

                this._snackBar.open(this._translateService.instant('provider.desactivate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._reloadData();

            });
        });

    }

    public getCommissionTypeLabel(provider: SocietyProvider): string {

        if(!provider.provider.commission) {

            return 'N/A';
        }

        return this._translateService.instant(`provider.commissionType.${provider.provider.commission.type}.value`);
    }

    public getIncomingPaymentLabel(provider: SocietyProvider): string {

        if(!provider.provider.commission) {

            return 'N/A';
        }

        return this._translateService.instant(`${provider.provider.commission.incomingPayment ? 'yes' : 'no'}.value`);
    }

    get mapApiResult(): (data: Pagination<SocietyProvider>) => SocietyProvider[] {

        return (data: Pagination<SocietyProvider>): SocietyProvider[] => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get isCreationAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'SOCIETY_PROVIDER_CREATE_IS_MINE';
        })
    }

    get isSendRequestAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'SOCIETY_PROVIDER_REQUEST';
        })
    }

    get isReadAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'SOCIETY_PROVIDER_READ_IS_MINE';
        })
    }

    get isDeletionAllowed(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'SOCIETY_PROVIDER_DELETE_IS_MINE';
        })
    }
}
