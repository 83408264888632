<!-- Recherche des sociétés -->

<div class="ligne_form" [formGroup]="searchForm">
    <div class="form-group">
        <mat-form-field>
            <mat-label>{{'offer.restrictedSocieties.select.action.value' | translate}}</mat-label>
            <mat-select placeholder="{{'offer.restrictedSocieties.select.action.value' | translate}}" [formControlName]="'selection'" [compareWith]="compareItems" (closed)="handleClosure()" multiple="true">
                <mat-option>
                    <ngx-mat-select-search
                        #nameFilter [formControl]="optionFilterNameCtrl"
                        [placeholderLabel]="'offer.restrictedSocieties.search.action.value' | translate"
                        [noEntriesFoundLabel]="'offer.restrictedSocieties.search.noResult.value' | translate"
                    ></ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="true">{{'offer.restrictedSocieties.select.description.value' | translate}}</mat-option>
                <ng-container *ngIf="(filteredOptions | async) as options">
                    <mat-option #matOption *ngFor="let option of options" [value]="option" (click)="handleItemSelection(matOption)">
                        {{ option.name }} | {{ getMailingAddress(option)?.address }}, {{ getMailingAddress(option)?.zipcode }} {{ getMailingAddress(option)?.city }}, {{ getCountryTranslation(option)?.name }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-group" style="margin-top: 0">
        <div class="message-help">
            <mat-icon>info</mat-icon><span>{{ 'offer.restrictedSocieties.description.value' | translate }}</span>
        </div>
    </div>
    <ng-content select="[additionalMessage]"></ng-content>
</div>

<!-- Sélection actuelle -->

<div class="ligne_form">
    <div class="row marges" *ngIf="selectedItems && selectedItems.length">
        <div class="col y_items_center">
            <div class="selected_offers">
                <span>{{ 'offer.restrictedSocieties.selection.list.value' | translate }} :</span>
                <ul>
                    <li *ngFor="let item of selectedItems">{{ item.name }}<mat-icon (click)="removeItemSelection(item)">close</mat-icon></li>
                </ul>
            </div>
        </div>
    </div>
</div>
