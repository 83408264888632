import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Service} from "@core/shared/models/service";
import {TranslationService} from "@core/shared/services/translation.service";
import {parsePrice} from "@core/shared/utils/price";
import {User} from "@core/shared/models/user";
import {Role} from "@core/shared/models/role";

@Component({
  selector: 'app-about-service-dialog',
  templateUrl: './about-service-dialog.component.html',
  styleUrls: ['./about-service-dialog.component.scss']
})
export class AboutServiceDialogComponent implements OnInit {

    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    @Output() select: EventEmitter<void> = new EventEmitter<void>();

    constructor(
      private _dialogRef: MatDialogRef<AboutServiceDialogComponent>,
      private _translationService: TranslationService,
      @Inject(MAT_DIALOG_DATA) public data: { service: Service, hasValidMangoPayAccount: boolean, isRegistrationEnabled: true, user:User }
    ) {}

    ngOnInit(): void {

      this.back.subscribe((): void => {

          this._dialogRef.close();
      });
    }

    private isJson(content: string): boolean {

        try {
            return !!JSON.parse(content);
        } catch (e) {
            return false;
        }
    }

    public formatPrice(price: number, symbol: string) {

        return parsePrice(price / 100) + ` € ${symbol}`;
    }

    public hasRole(role: Role): boolean {

        return this.data.user.roles.includes(role);
    }

    get serviceTranslation() {

        return this._translationService.getFallbackTranslation(this.data.service.translations);
    }

    get serviceContent(): string {

        if (!this.isJson(this.serviceTranslation.content)){

            return this.serviceTranslation.content;
        }

        const content: object = JSON.parse(this.serviceTranslation.content);

        if(!this.hasRole('ROLE_OFFER_DISTRIBUTOR') && this.hasRole('ROLE_OFFER_CREATOR') && ('creator' in content)){

            return (content['creator']) as string;
        }

        if(this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR') && ('distributor' in content)){

            return (content['distributor']) as string;
        }

        if(this.hasRole('ROLE_OFFER_DISTRIBUTOR') && this.hasRole('ROLE_OFFER_CREATOR') && ('creatorDistributor' in content)){

            return (content['creatorDistributor']) as string;
        }

        if('default' in content){

            return (content['default']) as string;
        }

        return '';
    }
}
