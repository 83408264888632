import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Offer, OfferTranslation} from "@core/shared/models/offer";
import {CustomerTypologyTranslation} from "@core/shared/models/customer-typology";
import {OfferDurationTranslation} from "@core/shared/models/offer/offer-duration-translation";
import {featureGroup, icon, LatLngBounds, LatLngLiteral, Layer, Map, MapOptions, Marker, marker, polyline, tileLayer} from "leaflet";
import {LeafletDirective} from "@bluehalo/ngx-leaflet";
import {OfferLocation, OfferLocationTranslation} from "@core/shared/models/offer/offer-location";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OfferPictureGalleryDialogComponent} from "@core/components/offer/offer-picture/offer-picture-gallery/offer-picture-gallery-dialog/offer-picture-gallery-dialog.component";
import {OfferInterest, OfferInterestTranslation} from "@core/shared/models/offer/offer-interest";
import {OfferIncludedTranslation} from "@core/shared/models/offer/offer-included-translation";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {OfferProgram, OfferProgramTranslation} from "@core/shared/models/offer/offer-program";
import {OfferItemConfiguration} from "@core/shared/models/offer/offer-item";
import {Channel} from "@core/shared/models/channel";
import {FilterBuilder} from "@core/shared/models/filter";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog, OfferCatalogStatusType} from "@core/shared/models/offer/offer-catalog";
import {ChannelTranslation} from "@core/shared/models/channel-translation";
import {Role} from "@core/shared/models/role";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OfferService} from "@core/shared/services/offer.service";
import {Router} from "@angular/router";
import {OfferLocationService} from "@core/shared/services/offer/offer-location/offer-location-service";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {Access, AccessType} from "@core/shared/models/access";
import Swiper, {Pagination} from "swiper";
import {Hashtag} from "@core/shared/models/hashtag";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-core-offer-item',
    templateUrl: './offer-item.component.html',
    styleUrls: ['./offer-item.component.scss']
})
export class OfferItemComponent implements OnInit, AfterViewInit {

    @Input() item: Offer;

    @Input() configuration: OfferItemConfiguration;

    @Input() roles: Role[];

    @Input() isMine: boolean;

    @Output() onLocaleUpdated: EventEmitter<string> = new EventEmitter<string>(null);

    @ViewChild(LeafletDirective, { static: false }) directive: LeafletDirective;

    @ViewChild('swiper', {static: false}) swiperRef: ElementRef<HTMLElement>;

    @ViewChild('swiperPagination', {static: false}) swiperPaginationRef: ElementRef<HTMLElement>;

    @ViewChild('descriptionContainer', {static : true}) descriptionContainer : ElementRef;

    public mapOptions: MapOptions;

    public markers: Layer[] = [];

    public locations: OfferLocation[] = [];

    public fitBounds: LatLngBounds;

    public countDefaultDisplayedPictures: number = 5;

    public catalogsIncludingOffer: OfferCatalog[] = [];

    public channelsIncludingOffer: Channel[] = [];

    public locale: string;

    public user: User;

    constructor(
        private _dialog: MatDialog,
        private _offerCatalogService: OfferCatalogService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _offerService: OfferService,
        private _router: Router,
        private _userService: UserService,
        private _translationService: TranslationService,
        private _offerLocationService: OfferLocationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this.locale = this._translateService.currentLang;

        if(!this.item.translations.length){

            this.locale = this.item.translations[0].locale;
        }

        if(this.configuration.displayChannels){

            this._initCatalogsIncludingOffer();
        }

        this._initMap();

        this._offerLocationService.getOfferItemsAPI(this.item.id).subscribe((offerLocations: OfferLocation[]) => {

            this.locations = offerLocations;

            this._changeDetectorRef.detectChanges();

            this._loadMarkers();

            this._initFitBounds();
        })

    }

    ngAfterViewInit() {

        this._initSlider();
    }

    private _initCatalogsIncludingOffer(): void {

        if(!this.userHasAccess('OFFER_CATALOG_REQUEST_LIST')) {

            return;
        }

        const filterBuilder = new FilterBuilder();

        filterBuilder.addField(new TextFilterField('offer.id', 'eq', this.item.id));

        filterBuilder.addField(new TextFilterField('status', 'eq', ('accepted' as OfferCatalogStatusType)));

        this._offerCatalogService.getItemsRequestAPI(filterBuilder.serializedFilters).subscribe((items: OfferCatalog[]): void => {
            this.catalogsIncludingOffer = items;
        });

        if(this.hasRole('ROLE_OFFER_CREATOR') && this.item.society.id === this.user.society.id) {
            this._offerCatalogService.getChannelsRequestAPI(this.item.id, filterBuilder.serializedFilters).subscribe((items: Channel[]): void => {
                this.channelsIncludingOffer = items;
            });
        }
    }

    private _initSlider(): void {

        if(!this.swiperRef){

            return;
        }

        new Swiper(this.swiperRef.nativeElement, {
            loop: false,
            slidesPerView: this.slidesPerView,
            allowTouchMove: false,
            autoplay: false,
            modules: [
                Pagination
            ],
            navigation: false,
            pagination: {
                el: this.swiperPaginationRef.nativeElement,
                clickable: true
            }
        });
    }

    private _initMap(): void {

        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18
                })
            ],
            zoom: 5,
            center: {
                lat: 46.85,
                lng: 2.3518
            }
        };


    }

    private _loadMarkers(): void {

        this.markers = [];

        if (!this.locations.length) {

            return;
        }

        this.locations.forEach((item: OfferLocation): void => {

            const isCircuitStar: boolean = (this.item.circuitType === 'star');

            const layer: Layer = marker(item.latLng, {
                icon: icon({
                    iconSize: [ 29, 45 ],
                    iconAnchor: [ 16, 25 ],
                    iconUrl:  item.isMain ? 'assets/images/icons/marker-icon-first.svg' : 'assets/images/icons/marker-icon.svg',
                    shadowUrl: null
                })
            });

            layer.on('click', (): void => {

                this.map.panTo(item.latLng);
            });

            const translation: OfferLocationTranslation = item.translations.find((translation: OfferLocationTranslation): boolean => {

                return translation.locale === this.locale;

            }) || item.translations[0];

            if(!this.item.enableMultipleMainLocation){

                layer.bindTooltip(`<span>${item.position}</span>`, { permanent: true }).openTooltip();
            }

            const bindPopupElement: HTMLDivElement = document.createElement('div');

            const nestedBindPopupElements: HTMLElement[] = [];

            const coordinatesElement: HTMLParagraphElement = document.createElement('p');

            coordinatesElement.innerText = `${item.address}, ${item.zipcode} ${item.city}`;

            nestedBindPopupElements.push(coordinatesElement);

            if(translation.title){

                const titleElement: HTMLParagraphElement = document.createElement('p');

                titleElement.innerText = `${translation.title}`;

                nestedBindPopupElements.push(titleElement);
            }

            if(translation.description){

                const descriptionElement: HTMLParagraphElement = document.createElement('p');

                descriptionElement.innerText = `${translation.description}`;

                nestedBindPopupElements.push(descriptionElement);
            }

            bindPopupElement.append(...nestedBindPopupElements);

            layer.bindPopup(bindPopupElement);

            this.markers.push(layer);

            // Circuit en étoile

            if(!this.item.enableMultipleMainLocation && isCircuitStar && !item.isMain) {

                this.markers.push(this.markers[0]);
            }
        });

        if (!this.item.enableMultipleMainLocation) {

            this.handleMapDrawLine();
        }
    }

    private _initFitBounds(): void
    {
        if (!this.locations.length) {

            return;
        }

        this.fitBounds = featureGroup(this.markers).getBounds();
    }

    public openPictureGalleryDialog(): void {

        this._dialog.open(OfferPictureGalleryDialogComponent, {
            width: '500px',
            data: {
                offer: this.item
            },
            panelClass : 'modal_gallery'
        });
    }

    public setCurrentProgramDay(index: number): void {

        (this.swiperRef.nativeElement as unknown as { swiper: Swiper }).swiper.slideTo(index);
    }

    public moreDescription(): void {

        const element : HTMLDivElement = this.descriptionContainer.nativeElement;

        element.classList.add('open');
    }

    public getTranslatedChannelFields(channel: Channel): ChannelTranslation {

        if(!channel.translations.length){

            return null;
        }

        return channel.translations.find((translation: ChannelTranslation): boolean => {

            return translation.locale === this.locale;

        }) || channel.translations[0];
    }

    public handleMapDrawLine(): void {

        if (!this.markers.length)
            return;

        polyline(this.markers.map((layer: Marker): LatLngLiteral => {

            return layer.getLatLng();

        }), {className : 'line_map'}).addTo(this.map);

        this.directive.map.setMaxZoom(14);

    }

    public userHasAccess(access: AccessType): boolean {

        const tags: AccessType[] = this.user.accesses.map((access: Access): AccessType => {

            return access.tag;
        });

        return tags.includes(access);
    }

    get translatedItemFields(): OfferTranslation {

        if(!this.item.translations.length){

            return null;
        }

        return this.item.translations.find((translation: OfferTranslation): boolean => {

            return translation.locale === this.locale;

        }) || this.item.translations[0];
    }

    get translatedCustomerTypologyItemFields(): CustomerTypologyTranslation {

        if(!this.item.customerTypology.translations.length){

            return null;
        }

        return this.item.customerTypology.translations.find((translation: CustomerTypologyTranslation): boolean => {

            return translation.locale === this.locale;

        }) || this.item.customerTypology.translations[0];
    }

    get translatedDurationItemFields(): OfferDurationTranslation {

        if(!this.item.duration.translations.length){

            return null;
        }

        const res = this.item.duration.translations.find((translation: OfferDurationTranslation): boolean => {

            return translation.locale === this.locale;

        });

        return this.item.duration.translations.find((translation: OfferDurationTranslation): boolean => {

            return translation.locale === this.locale;

        }) || this.item.duration.translations[0];
    }

    get translatedInterestsItemFields(): OfferInterestTranslation[] {

        return this.item.interests.map((interest: OfferInterest): OfferInterestTranslation => {

            if(!interest.translations.length){

                return null;
            }

            return interest.translations.find((translation: OfferInterestTranslation): boolean => {

                return translation.locale === this.locale;

            }) || interest.translations[0];
        });
    }

    get translatedIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.item.included.filter((included: OfferIncluded): boolean => {

            return included.isIncluded;
        });

        return matches.map((included: OfferIncluded): OfferIncludedTranslation => {

            if(!included.translations.length){

                return null;
            }

            return included.translations.find((translation: OfferIncludedTranslation): boolean => {

                return translation.locale === this.locale;

            }) || included.translations[0];
        });
    }

    get slidesPerView(): number{

        return this.item.hasProgramsPictures ? 2 : 3;
    }

    get translatedNotIncludedsItemFields(): OfferIncludedTranslation[] {

        const matches: OfferIncluded[] = this.item.included.filter((included: OfferIncluded): boolean => {

            return !included.isIncluded;
        });

        return matches.map((included: OfferIncluded): OfferIncludedTranslation => {

            if(!included.translations.length){

                return null;
            }

            return included.translations.find((translation: OfferIncludedTranslation): boolean => {

                return translation.locale === this.locale;

            }) || included.translations[0];
        });
    }

    get translatedProgramsItemFields(): OfferProgramTranslation[] {

        return this.item.programs.map((program: OfferProgram): OfferProgramTranslation => {

            if(!program.translations.length){

                return null;
            }

            return program.translations.find((translation: OfferProgramTranslation): boolean => {

                return translation.locale === this.locale;

            }) || program.translations[0];
        });
    }

    get map(): Map {

        return this.directive.getMap();
    }

    get isMoreDescriptionEnabled(): boolean{

        const element : HTMLDivElement = this.descriptionContainer.nativeElement;

        return element.clientHeight >= 84 && !element.classList.contains('open');
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public openSignalementDialog(id: number): void {

        const offer: Offer = this.item;

        const title : string = this._translateService.instant('offer.signalement.title.value');

        const content : string = this._translateService.instant('offer.signalement.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {
            this._offerService.offerReportsProviders(offer.id).subscribe((): void => {
                this._snackBar.open(this._translateService.instant('offer.report.actions.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['account/offer/list/reservoir']);
            });
        });
    }

    get parsedHashtags(): string[] {

        return this.item.hashtags.map((item: Hashtag): string => {

            return `${ this._translationService.getFallbackTranslation(item.translations).name }`;

        }).sort((a: string, b: string): number => {

            return a.localeCompare(b);
        });
    }

    get localeId(): string {

        return this._translationService.getUserLocale();
    }
}
