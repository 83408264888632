import {Component, Inject, OnInit} from '@angular/core';
import {Video, VideoExtension} from "../video";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface VideoDialogData {
    video: Video;
    extensions: VideoExtension[];
    width: number;
}

@Component({
    selector: 'cms-video-dialog',
    templateUrl: './video-dialog.component.html',
    styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<VideoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VideoDialogData
    ) {
    }

    ngOnInit() {

    }
}
