import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {merge} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "@core/shared/services/user.service";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";

@Component({
    selector: 'app-page-administrator-list',
    templateUrl: './page-administrator-list.component.html',
    styleUrls: ['./page-administrator-list.component.scss']
})
export class PageAdministratorListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'lastName',
        'firstName',
        'email'
    ];

    public filterBuilder: FilterBuilder;

    public items: User[];

    constructor(
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._userService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: User[]): void => {

                this.items = data;
            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._userService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._userService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

            this.items = data;
        })
    }

    public getModel(item: User): User {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public addItem(): void {

        this._router.navigate(['account/administrator/create']);
    }

    public editItem(id: number): void{

        this._router.navigate(['account/administrator/update', id]);
    }

    public openDeleteItemDialog(id: number): void {

        const user: User = this.items.find((item: User): boolean => {

            return item.id === id;
        });

        const title : string = this._translateService.instant('administrator.delete.value');

        const content : string = this._translateService.instant('administrator.delete.description.value', {
            firstName: user.firstName,
            lastName: user.lastName
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._userService.deleteItemAPI(user.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('administrator.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._userService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: User[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        // Filtre sur le ROLE_ADMIN

        const roleFilter: TextFilterField = new TextFilterField('roles', 'lk', 'ROLE_ADMIN');

        params.push(roleFilter.serialize);

        return params;
    }

    get mapApiResult(): (data: Pagination<User>) => User[] {

        return (data: Pagination<User>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }
}
