import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Access, AccessType} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Router} from "@angular/router";
import {TemplateGiftVoucher} from "@core/shared/models/gift-voucher/template-gift-voucher";
import {TranslationService} from "@core/shared/services/translation.service";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {MatPaginator} from "@angular/material/paginator";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {MatSort} from "@angular/material/sort";
import {TemplateGiftVoucherService} from "@core/shared/services/template-gift-voucher.service";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";

@Component({
    selector: 'app-page-gift-voucher-list',
    templateUrl: './page-template-gift-voucher-list.component.html',
    styleUrls: ['./page-template-gift-voucher-list.component.scss']
})
export class PageTemplateGiftVoucherListComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public user: User;

    public items: TemplateGiftVoucher[];

    public filterBuilder: FilterBuilder;

    public locales: LocaleItem[] = [];

    public displayedColumns: string[] = [
        'actions',
        'translations.label',
        'type',
        'locales',
        'isDematerialized',
        'isPhysical',
        'createdAt',
    ];

    public totalItems: number = 0;

    public types: { id: string, label: string }[] = [
        {
            id: 'generic',
            label: this._translateService.instant('giftVoucher.template.form.fields.type.generic.value')
        },
        {
            id: 'multilingual',
            label: this._translateService.instant('giftVoucher.template.form.fields.type.multilingual.value')
        }
    ];

    constructor(public translationService: TranslationService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _giftVoucherService: TemplateGiftVoucherService,
                private _translateService: TranslateService,
                private _userService: UserService,
                private _router: Router) {
    }

    ngOnInit(): void {
        this.user = this._userService.currentUser.value;

        this._initFilterBuilder();

        this._initLocales();
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {
            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this.dataSource;
                }),
                map(this.mapApiResult),
            ).subscribe((data: TemplateGiftVoucher[]): void => {

                this.items = data;

            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: TemplateGiftVoucher[]): void => {

                this.items = data;

            })
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public addItem() {

        this._router.navigate(['/account/template-gift-voucher/create']);
    }

    public hasAccess(tag: AccessType): boolean {

        return this.user.accesses.some((access: Access) => {

            return access.tag === tag;
        });
    }

    public getModel(item: TemplateGiftVoucher): TemplateGiftVoucher {

        return item;
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this.dataSource.pipe(map(this.mapApiResult)).subscribe((data: TemplateGiftVoucher[]): void => {

            this.items = data;

        })
    }


    public update(id: number): void {

        this._router.navigate(['account/template-gift-voucher/update/', id]);
    }

    public openDeleteItemDialog(id: number): void {

        const item: TemplateGiftVoucher = this.items.find((templateGiftVoucher: TemplateGiftVoucher) => {
            return templateGiftVoucher.id === id;
        });

        const title: string = this._translateService.instant('giftVoucher.template.action.delete.value');

        const content: string = this._translateService.instant('giftVoucher.template.action.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._giftVoucherService.deleteItemAPI(item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('giftVoucher.template.action.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._giftVoucherService.getPaginationItemsAPI(this.itemsApiParams)
                    .pipe(map(this.mapApiResult))
                    .subscribe((data: TemplateGiftVoucher[]): void => {

                        this.items = data;
                    });

            });
        });

    }

    get mapApiResult(): (data: Pagination<TemplateGiftVoucher>) => TemplateGiftVoucher[] {

        return (data: Pagination<TemplateGiftVoucher>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if (this.filterBuilder.hasFilters) {

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }


    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get dataSource(): Observable<Pagination<TemplateGiftVoucher>> {
        return this._giftVoucherService.getPaginationItemsAPI(this.itemsApiParams);
    }

    get localeId(): string {
        return this._translateService.currentLang;
    }

    public getParsedLocales(item: TemplateGiftVoucher): string {
        return item.translations.map((translation): string => {

            return this._translateService.instant(`locale.${translation.locale}.value`);

        }).join(', ');
    }
}
