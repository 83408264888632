import {Component, OnInit, ViewChild} from '@angular/core';
import {Offer, OfferOnlineSaleConfiguration} from "@core/shared/models/offer";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslationService} from "@core/shared/services/translation.service";
import {Moment} from "moment";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {OfferService} from "@core/shared/services/offer.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OfferPeriodDeleteDialogComponent} from "@core/components/offer/offer-period/offer-period-delete/offer-period-delete-dialog/offer-period-delete-dialog.component";
import {OfferAvailabilityCalendarComponent} from "@core/components/offer/offer-availability/offer-availability-calendar/offer-availability-calendar.component";

@Component({
    selector: 'app-core-page-availability-offer-update',
    templateUrl: './page-availability-offer-update.component.html',
    styleUrls: ['./page-availability-offer-update.component.scss']
})
export class PageAvailabilityOfferUpdateComponent implements OnInit {

    @ViewChild(OfferAvailabilityCalendarComponent) periodCalendar: OfferAvailabilityCalendarComponent;

    public offer: Offer;

    public stopSale: boolean = false;

    public periodCreateConfiguration: { enable: boolean } = {
        enable: false
    };

    public dateCreateConfiguration: { enable: boolean, date: Moment } = {
        enable: false,
        date: null
    };

    public dateUpdateConfiguration: { enable: boolean, date: Moment, offerDateId: number } = {
        enable: false,
        date: null,
        offerDateId: null
    };

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _translateService: TranslateService,
        private _matDialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _offerService: OfferService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { offer: Offer }): void => {

            this.offer = data.offer;

            this.stopSale = this.offer.onlineSale ? this.offer.onlineSale.stopSale : false;
        });
    }

    private _openCreateDatePanel(date: Moment): void {

        this.closePanels();

        document.querySelector('body').classList.add('has_panel_open');

        setTimeout((): void => {

            this.dateCreateConfiguration = {
                enable: true,
                date: date
            };
        });
    }

    private _openUpdateDatePanel(date: Moment, offerDateId: number): void {

        this.closePanels();

        document.querySelector('body').classList.add('has_panel_open');

        setTimeout((): void => {

            this.dateUpdateConfiguration = {
                enable: true,
                date: date,
                offerDateId: offerDateId
            };
        });
    }

    public redirectToRead(): void {

        this._router.navigate(['account/availability/offer/read', this.offer.id]);
    }

    public redirectToOfferView(): void {

        this._router.navigate(['account/offer/read', this.offer.id],{ queryParams : { origin: 'offerAvailabilityUpdate' }});
    }

    public redirectToOfferUpdate(): void {

        this._router.navigate(['account/offer/update', this.offer.id]);
    }

    public openCreatePeriodPanel(): void {

        this.closePanels();

        document.querySelector('body').classList.add('has_panel_open');

        setTimeout((): void => {

            this.periodCreateConfiguration = {
                enable: true
            };
        });
    }

    public handleDateConfiguration(event: { date: Moment, offerDateId: number }): void {

        !!event.offerDateId ? this._openUpdateDatePanel(event.date, event.offerDateId) : this._openCreateDatePanel(event.date);
    }

    public openDeletePeriodDialog(): void {

        this.closePanels();

        const dialogRef: MatDialogRef<OfferPeriodDeleteDialogComponent> = this._dialog.open(OfferPeriodDeleteDialogComponent, {
            width: '500px',
            data: {
                offer: this.offer
            }
        });

        dialogRef.componentInstance.delete.subscribe((): void => {

            this.periodCalendar.refreshCalendar(true);
        });
    }

    public closePanels(): void {

        document.querySelector('body').classList.remove('has_panel_open');

        this.periodCreateConfiguration = {
            enable: false
        };

        this.dateCreateConfiguration = {
            enable: false,
            date: null
        };

        this.dateUpdateConfiguration = {
            enable: false,
            date: null,
            offerDateId: null
        };
    }

    public openUpdateStopSaleDialog(event: MouseEvent): void {

        event.preventDefault();

        const title: string = this._translateService.instant(this.stopSale ? 'sale.stop.no.confirmation.title.value' : 'sale.stop.yes.confirmation.title.value');

        const content: string = this._translateService.instant(this.stopSale ? 'sale.stop.no.confirmation.content.value' : 'sale.stop.yes.confirmation.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._matDialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            const data: Partial<Offer> = {
                onlineSale: Object.assign({...this.offer.onlineSale}, {
                    stopSale: !this.stopSale
                } as Partial<OfferOnlineSaleConfiguration>)
            };

            this._offerService.updateItemAPI(this.offer.id, data).subscribe((item: Offer): void => {

                this.offer.onlineSale = item.onlineSale;

                this.stopSale = this.offer.onlineSale.stopSale;

                this._snackBar.open(this._translateService.instant(this.stopSale ? 'sale.stop.yes.confirmation.success.value' : 'sale.stop.no.confirmation.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            });
        });
    }

    public handlePeriodCreated(): void {

        this._snackBar.open(this._translateService.instant('offer.period.update.success.value'), this._translateService.instant('notification.close.action.value'), {
            duration: 5000
        });

        this.closePanels();

        this.periodCalendar.refreshCalendar(true);
    }

    public handleDateCreated(): void {

        this._snackBar.open(this._translateService.instant('offer.period.update.success.value'), this._translateService.instant('notification.close.action.value'), {
            duration: 5000
        });

        this.closePanels();

        this.periodCalendar.refreshCalendar(true);
    }

    public handleDateUpdated(): void {

        this._snackBar.open(this._translateService.instant('offer.period.update.success.value'), this._translateService.instant('notification.close.action.value'), {
            duration: 5000
        });

        this.closePanels();

        this.periodCalendar.refreshCalendar(true);
    }
}
