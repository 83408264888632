import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FilterBuilder, FilterComponent} from '@core/shared/models/filter';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from '@core/shared/models/pagination';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {OfferAttributeService} from '@core/shared/services/offer-attribute.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferAttributeType} from "@core//shared/models/offer-attribute-type";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
  selector: 'app-page-offer-list',
  templateUrl: './page-offer-attribute-list.component.html',
  styleUrls: ['./page-offer-attribute-list.component.scss']
})
export class PageOfferAttributeListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'type.id',
        'translations.label'
    ];

    public filterBuilder: FilterBuilder;

    public items: OfferAttribute[] = [];

    public offerAttributeTypeFilterItems: { id: number, label: string}[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _offerAttributeService: OfferAttributeService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this._activatedRoute.data.subscribe((data: { offerAttributeTypes: OfferAttributeType[] }): void => {

            // Récupération des données pour le filtre sur les caractéristiques

            this.offerAttributeTypeFilterItems = data.offerAttributeTypes.map((item: OfferAttributeType): any =>{

                return {
                    id: item.id,
                    label: this.translationService.getFallbackTranslation(item.translations).label
                }
            });

            // Initialisation des filtres

            this._initFilterBuilder();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._offerAttributeService.getPaginationItemsAPI(this.itemsApiParams);
                }),
                map(this.mapApiResult),
            ).subscribe((data: OfferAttribute[]): void => {

                this.items = data;
            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._offerAttributeService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferAttribute[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._offerAttributeService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferAttribute[]): void => {

            this.items = data;
        });
    }

    public getModel(item: OfferAttribute): OfferAttribute {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public editItem(id: number): void {
        this._router.navigate(['account/offer/attribute/update/', id]);
    }

    public addItem(): void {
        this._router.navigate(['account/offer/attribute/create']);
    }

    public openDeleteItemDialog(offerAttribute: OfferAttribute): void {

        if (!offerAttribute.isDeletable){

            this._snackBar.open(this._translateService.instant('offer.attribute.delete.alreadyUsed.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });
        }
        else {

            const title : string = this._translateService.instant('offer.attribute.delete.value');

            const content : string = this._translateService.instant('offer.attribute.delete.description.value', {
                attribute: this.translationService.getFallbackTranslation(offerAttribute.translations).label
            });

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title: title,
                    content: content
                }
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._offerAttributeService.deleteItemAPI(offerAttribute.id).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('offer.attribute.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this._resetPagination();

                    this._offerAttributeService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: OfferAttribute[]): void => {

                        this.items = data;
                    });
                });
            });
        }

    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);

        } else {

            params.push(`sort[type.translations.label]=ASC&sort[translations.label]=ASC`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<OfferAttribute>) => OfferAttribute[] {

        return (data: Pagination<OfferAttribute>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }
}
