import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslationService} from "@core/shared/services/translation.service";
import {User} from "@core/shared/models/user";
import {Router} from "@angular/router";

@Component({
  selector: 'app-request-document-dialog',
  templateUrl: './service-request-document-dialog.component.html',
  styleUrls: ['./service-request-document-dialog.component.scss']
})
export class ServiceRequestDocumentDialogComponent implements OnInit {

    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _dialogRef: MatDialogRef<ServiceRequestDocumentDialogComponent>,
        private _translationService: TranslationService,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: { user:User }
    ) {}

  ngOnInit(): void {

      this.back.subscribe((): void => {

          this._dialogRef.close();
      });
  }

  public goToDocuments(): void {

        this._dialogRef.close();
        this._router.navigate(['account/service/list'], { queryParams : { targetTab: 'document' }});
  }

}
