import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Society} from "@core/shared/models/society";

@Component({
    selector: 'app-core-offer-creator-picture-gallery-dialog',
    templateUrl: './offer-creator-picture-gallery-dialog.component.html',
    styleUrls: ['./offer-creator-picture-gallery-dialog.component.scss']
})
export class OfferCreatorPictureGalleryDialogComponent {

    constructor(
        private _dialogRef: MatDialogRef<OfferCreatorPictureGalleryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offerCreator: Society }
    ) {
    }

    public close(): void {

        this._dialogRef.close();
    }

    get offerCreator(): Society {

        return this.data.offerCreator;
    }
}
