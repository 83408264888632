import {Component, OnInit} from '@angular/core';
import {IContent} from "../../content/content";
import {TranslationService} from "../translation.service";
import {ILocale} from "../locale";
import {CmsService} from "../../cms.service";
import {MatDialogRef} from "@angular/material/dialog";

export interface TranslationCreateResult {
    duplicate: boolean;
    duplicateLocaleId: string;
    locales: ILocale[];
}

@Component({
  selector: 'translation-create-dialog',
  templateUrl: './translation-create-dialog.component.html',
  styleUrls: ['./translation-create-dialog.component.scss']
})
export class TranslationCreateDialogComponent implements OnInit {

    availableLocales: ILocale[] = [];

    content: IContent = null;

    duplicate: boolean = false;

    duplicateLocaleId: string = null;

    /**
     * @param dialogRef
     * @param _translationService
     * @param _cmsService
     */
    constructor(
        public dialogRef: MatDialogRef<TranslationCreateDialogComponent>,
        private _translationService: TranslationService,
        private _cmsService: CmsService) {
    }

    /**
     * Initialisation de la vue
     */
    ngOnInit(): void {

        this.content = this._cmsService.getContent();

        this.duplicate = false;

        this.duplicateLocaleId = null;

        const availableLocales: ILocale[] = this._translationService.getAvailableLocales(true);

        availableLocales.map((locale: ILocale) => {
            locale.checked = false;
        });

        this.availableLocales = availableLocales;
    }

    /**
     * Retourne la liste des langues sélectionnées
     *
     * @returns {ILocale[]}
     */
    getCheckedLocales(): ILocale[] {

        return this.availableLocales.filter((locale: ILocale) => {
            return locale.checked;
        });
    }

    /**
     * Retourne la locale en fonction de son ID
     *
     * @param id
     * @returns {ILocale}
     */
    getLocaleById(id): ILocale {

        return this._translationService.getLocaleById(id);
    }

    /**
     * Coche la case "dupliquer" si elle n'est pas cochée au moment où une langue est sélectionnée
     */
    checkedDuplicate(): void {

        if (this.duplicateLocaleId && !this.duplicate){
            this.duplicate = true;
        }

    }

    /**
     * Ajout de chaque langue sélectionnée pour la page
     */
    save(): void {

        if (!this.isRegistrable()) {
            return;
        }

        const dialogResult: TranslationCreateResult = {
            duplicate: this.duplicate,
            duplicateLocaleId: this.duplicateLocaleId,
            locales: this.getCheckedLocales()
        };

        this.dialogRef.close(dialogResult);
    }

    public close(): void {

        this.dialogRef.close();
    }

    /**
     * Détermine si le bouton de confirmation de la modale est disponible
     *
     * @returns {boolean}
     */
    isRegistrable(): boolean {

        return this.getCheckedLocales().length > 0 && ((this.duplicate && this.duplicateLocaleId !== null) || !this.duplicate);
    }

}
