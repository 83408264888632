import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CrudAction} from "@core/shared/models/crud";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder, TranslationItem} from "@core/shared/models/translation";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {Currency} from "@core/shared/models/currency";
import {OfferPermanentOptionPriceLevel, OfferPermanentOptionPriceLevelBuilder} from "@core/shared/models/offer/offer-permanent-option/offer-permanent-option-price-level";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {OfferPermanentOptionService} from "@core/shared/services/offer/offer-permanent-option.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "@core/shared/services/api.service";
import {CurrencyService} from "@core/shared/services/currency.service";
import {ImageService} from "@lib/media/image/image.service";
import {ImageEventService} from "@lib/media/image/image.event.service";
import {FormService} from "@core/shared/services/form.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {
    OfferPermanentOption, OfferPermanentOptionOffer,
    OfferPermanentOptionOfferCatalog,
    OfferPermanentOptionPrice,
    OfferPermanentOptionPriceLevelType,
    OfferPermanentOptionTranslation
} from "@core/shared/models/offer/offer-permanent-option";
import {TranslationPictureType} from "@core/shared/models/translation/translation-picture";
import {REGEX_PRICE} from "@core/shared/models/regex";
import {Image} from "@lib/media/image/image";
import {OfferProgramTranslationPicture} from "@core/shared/models/offer/offer-program";
import {Role} from "@core/shared/models/role";
import {OfferPermanentOptionPriceTypeType} from "@core/shared/models/offer/offer-permanent-option/offer-permanent-option-price";
import {parsePrice} from "@core/shared/utils/price";
import {PriceArea, PriceType, PricingType} from "@core/shared/models/price";
import {Observable, of} from "rxjs";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {FileConfig} from "@lib/form/fields/file/file.component";
import * as ClassicEditor from "@lib/ckeditor";
import {plainToClass} from "class-transformer";
import {Offer} from "@core/shared/models/offer";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {
    FormTabValidationItem,
    OfferFormTabValidationService
} from "@core/shared/services/form/form-tab-validation.service";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
    selector: 'app-core-page-offer-permanent-option-form',
    templateUrl: './page-offer-permanent-option-form.component.html',
    styleUrls: ['./page-offer-permanent-option-form.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})
export class PageOfferPermanentOptionFormComponent implements OnInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('generalData', {static: true}) generalDataRef: TemplateRef<any>;

    @ViewChild('priceData', {static: true}) priceDataRef: TemplateRef<any>;

    @ViewChild('offerIsMineData', {static: true}) offerIsMineDataRef: TemplateRef<any>;

    @ViewChild('offerCatalogData', {static: true}) offerCatalogDataRef: TemplateRef<any>;

    public crudAction: CrudAction;

    public translationBuilder: TranslationBuilder;

    public user: User;

    public society: Society;

    public option: OfferPermanentOption;

    public editor = ClassicEditor;

    public currencies: Currency[] = [];

    public offerPermanentOptionPriceLevelBuilder: OfferPermanentOptionPriceLevelBuilder;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _translateService: TranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _offerPermanentOptionService: OfferPermanentOptionService,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _currencyService: CurrencyService,
        private _imageService: ImageService,
        private _imageEventService: ImageEventService,
        public formService: FormService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {}

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { crudAction: CrudAction, user : User, society: Society, option?: OfferPermanentOption }): void => {

            this.crudAction = data.crudAction;

            this.user = data.user;

            this.society = data.society;

            this._initCurrencies();

            this._initForm();

            if(this.isCrudAction('update')){

                this.option = data.option;

                this._hydrateForm();
            }

            this._initTabItems();

            this._initEvents();

            this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
        });
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            reference: [null, [Validators.required]],
            translationPictureType: ['common' as TranslationPictureType, [Validators.required]],
            translations: new UntypedFormArray([]),
            locales: [[], Validators.required],
            comment: [''],
            providerName: [null, [Validators.required]],
            currency: [null, [Validators.required]],
            price: this._formBuilder.group({
                priceArea: [null, [Validators.required]],
                pricingType: [null, [Validators.required]],
                priceType: [null, [Validators.required]],
                priceLevelType: [null, [(control: UntypedFormControl) => {

                    if(!this.form || !this.priceControl){

                        return null;
                    }

                    if(this.priceControl.get('pricingType').invalid){

                        return null;
                    }

                    const requiredPriceLevels: boolean = this.isPricingType('variable');

                    const hasPriceLevelType: boolean = !!control.value;

                    if(requiredPriceLevels && !hasPriceLevelType){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                priceHT: [null, [Validators.pattern(REGEX_PRICE), (control: UntypedFormControl) => {

                    if(!this.form || !this.priceControl){

                        return null;
                    }

                    const nullablePrice: boolean = this.isPricingType('variable') && this.isPriceType('amount');

                    const hasPrice: boolean = (control.value !== null) && (control.value.length > 0);

                    if(nullablePrice && hasPrice){

                        return {
                            'isDefined': {
                                valid: false
                            }
                        };
                    }

                    if(!nullablePrice && !hasPrice){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                priceTTC: [null, [Validators.pattern(REGEX_PRICE), (control: UntypedFormControl) => {

                    if(!this.form || !this.priceControl){

                        return null;
                    }

                    const nullablePrice: boolean = this.isPricingType('variable') && this.isPriceType('amount');

                    const hasPrice: boolean = (control.value !== null) && (control.value.length > 0);

                    if(nullablePrice && hasPrice){

                        return {
                            'isDefined': {
                                valid: false
                            }
                        };
                    }

                    if(!nullablePrice && !hasPrice){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                percent: [null, [Validators.pattern(REGEX_PRICE), (control: UntypedFormControl) => {

                    if(!this.form || !this.priceControl){

                        return null;
                    }

                    const requiredPercent: boolean = this.isPricingType('fixed') && this.isPriceType('percent');

                    const hasPercent: boolean = (control.value !== null) && (control.value.length > 0);

                    if(!requiredPercent && hasPercent){

                        return {
                            'isDefined': {
                                valid: false
                            }
                        };
                    }

                    if(requiredPercent && !hasPercent){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                priceCalculationAutomatic: [false, [Validators.required]],
                priceTypeReference: [null],
                vatPercent: [null, [Validators.pattern(REGEX_PRICE)]]
            }),
            priceLevels: new UntypedFormArray([], [(control: UntypedFormArray) => {

                if(!this.form || !this.priceControl){

                    return null;
                }

                const requiredPriceLevels: boolean = this.isPricingType('variable');

                const hasPriceLevels: boolean = control.controls.length > 0;

                if(!requiredPriceLevels && hasPriceLevels){

                    return {
                        'isDefined': {
                            valid: false
                        }
                    };
                }

                if(requiredPriceLevels){

                    if(!hasPriceLevels){

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }
                }

                return null;
            }]),
            offers: [[]],
            offerCatalogs: [[]]
        });

        this._initTranslationsForm();

        this._initPriceLevelBuilder();

        this.formService.submitCallback = () => {

            const data: object = Object.assign({...this.form.value}, {
                currency: this.form.get('currency').value ? { id: parseInt(this.form.get('currency').value) } : null,
                providerName: this.form.get('providerName').value ?? null,
                price: Object.assign({...this.priceControl.value}, {
                    priceHT: this.priceControl.get('priceHT').value !== null ? (this.priceControl.get('priceHT').value * 100) : null,
                    priceTTC: this.priceControl.get('priceTTC').value !== null ? (this.priceControl.get('priceTTC').value * 100) : null,
                    percent: this.priceControl.get('percent').value !== null ? (this.priceControl.get('percent').value / 100) : null
                }),
                priceLevels: this.priceLevelsControl.controls.map((control: UntypedFormGroup): OfferPermanentOptionPriceLevel => {

                    return Object.assign({...control.getRawValue()}, {
                        min: this.isPriceLevelType('amount') ? (control.get('min').value * 100) : parseInt(control.get('min').value),
                        max: control.get('max').value !== null ? (this.isPriceLevelType('amount') ? (control.get('max').value * 100) : parseInt(control.get('max').value)) : null,
                        value: this.isPriceType('amount') ? (control.get('value').value * 100) : (control.get('value').value / 100),
                        additionalValue: control.get('additionalValue').value !== null ? (this.isPriceType('amount') ? (control.get('additionalValue').value * 100) : (control.get('additionalValue').value / 100)) : null
                    });
                }),
                offers: (this.form.get('offers').value as { id: number }[]).map((item: {id: number}): { offer: { id: number } } => {

                    return {
                        offer: item
                    };
                }),
                offerCatalogs: (this.form.get('offerCatalogs').value as { id: number }[]).map((item: {id: number}): { offerCatalog: { id: number } } => {

                    return {
                        offerCatalog: item
                    };
                })
            });

            const observable: Observable<OfferPermanentOption> = this.isCrudAction('create') ? this._offerPermanentOptionService.createItemAPI(this.society.id, data) : this._offerPermanentOptionService.updateItemAPI(this.option.id, data);

            observable.subscribe((): void => {

                this._snackBar.open(this._translateService.instant(this.isCrudAction('create') ? 'offer.permanentOption.add.success.value' : 'offer.permanentOption.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _hydrateForm(): void {

        this.offerPermanentOptionPriceLevelBuilder.priceLevelType = this.option.price.priceLevelType;

        this.form.patchValue(Object.assign({...this.option}, {
            currency: this.option.currency.id,
            offers: [],
            offerCatalogs: []
        }));

        this.option.translations.forEach((translation: OfferPermanentOptionTranslation): void => {

            const group: UntypedFormGroup = this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);

            group.addControl('id', this._formBuilder.control(translation.id));
        });

        this.option.priceLevels.forEach((offerOptionPriceLevel: OfferPermanentOptionPriceLevel): void => {

            this.priceLevelsControl.push(this.createPriceLevelControl(offerOptionPriceLevel));
        });
    }

    private _initCurrencies(): void {

        this._currencyService.getItemsAPI().subscribe((currencies: Currency[]): void => {

            this.currencies = currencies.filter((currency: Currency): boolean => {

                return !['TWD','AED'].includes(currency.code);
            });
        });
    }

    private _initTabItems(): void {

        const items: { tag: string, label: string, template: TemplateRef<any> }[] = [];

        items.push({
            tag: 'generalData',
            label: 'offer.permanentOption.generalData.value',
            template: this.generalDataRef
        });

        items.push({
            tag: 'priceData',
            label: 'offer.permanentOption.configuration.value',
            template: this.priceDataRef
        });

        if(this.hasRole('ROLE_OFFER_CREATOR')) {

            items.push({
                tag: 'offerIsMineData',
                label: 'offer.list.isMine.selection.value',
                template: this.offerIsMineDataRef
            });
        }

        if(this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

            items.push({
                tag: 'offerCatalogData',
                label: 'offer.list.catalog.isMine.selection.value',
                template: this.offerCatalogDataRef
            });
        }

        this.tabItems = items;
    }

    private _initEvents(): void {

        // Langues

        this.form.get('locales').valueChanges.subscribe((locales: string[]): void => {

            // Suppression des translations obsolètes

            const translationsToDelete: TranslationItem[] = this.translationBuilder.items.filter((translation: TranslationItem): boolean => {

                return !locales.includes(translation.locale);
            });

            translationsToDelete.forEach((translation: TranslationItem): void => {

                const index: number = this.translationBuilder.getItemIndexByLocale(translation.locale);

                this.translationBuilder.removeItem(index);
            });

            // Création des nouvelles translations

            const localesToAdd: string[] = locales.filter((locale: string): boolean => {

                return !this.translationBuilder.getItemByLocale(locale);
            });

            localesToAdd.forEach((locale: string): void => {

                this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(locale));
            });

            // Si on a moins de deux langues sur l'option, on réinitialise la gestion des visuels en commun

            if(locales.length <= 1) {

                this.form.get('translationPictureType').patchValue('common');

                this.form.get('translationPictureType').updateValueAndValidity();
            }

            // Mise à jour des images si celles-ci sont communes à chaque langue

            if(this.isTranslationPictureType('common')){

                const locales: string[] = this.form.get('locales').value.filter((locale: string): boolean => {

                    return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                });

                locales.forEach((locale: string): void => {

                    const sourcePictureControl: UntypedFormGroup = this.translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

                    const destinationPictureControl: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                    if(sourcePictureControl.get('image').valid && destinationPictureControl){

                        this._imageService.getCloneImage(sourcePictureControl.get('image').value.reference).subscribe((cloneImage: Image): void => {

                            destinationPictureControl.get('image').patchValue(cloneImage);

                            const destinationImage: Image = Object.assign(destinationPictureControl.get('image').value, {
                                formats: sourcePictureControl.get('image').value.formats
                            });

                            this._imageService.crop(destinationImage).subscribe((): void => {});
                        });
                    }
                });

                this._handleCommonPicturesEvents();
            }
        });

        // Visuel commun ou propre à chaque langue

        this.form.get('translationPictureType').valueChanges.subscribe((): void => {

            if(this.isTranslationPictureType('dissociated')) {

                return;
            }

            const locales: string[] = this.form.get('locales').value.filter((locale: string): boolean => {

                return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
            });

            locales.forEach((locale: string): void => {

                const sourcePictureControl: UntypedFormGroup = this.translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

                const destinationPictureControl: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                if(destinationPictureControl){

                    if(destinationPictureControl.get('image')){

                        destinationPictureControl.get('image').patchValue(null);
                    }

                    if(sourcePictureControl.get('image').valid){

                        this._imageService.getCloneImage(sourcePictureControl.get('image').value.reference).subscribe((cloneImage: Image): void => {

                            destinationPictureControl.get('image').patchValue(cloneImage);

                            const destinationImage: Image = Object.assign(destinationPictureControl.get('image').value, {
                                formats: sourcePictureControl.get('image').value.formats
                            });

                            this._imageService.crop(destinationImage).subscribe((): void => {

                                destinationPictureControl.updateValueAndValidity();
                            });
                        });
                    }
                }
            });

            this._handleCommonPicturesEvents();
        });

        // Type d'option assurance | Type de tarification | Type de tarif

        const priceControlTypes: UntypedFormControl[] = [
            this.priceControl.get('priceArea') as UntypedFormControl,
            this.priceControl.get('pricingType') as UntypedFormControl,
            this.priceControl.get('priceType') as UntypedFormControl
        ];

        priceControlTypes.forEach((control: UntypedFormControl): void => {

            control.valueChanges.subscribe((): void => {

                const nullablePrice: boolean = this.isPricingType('variable') && this.isPriceType('amount');

                // Reinitialisation du prix HT

                this.priceControl.get('priceHT').patchValue(nullablePrice ? null : 0);

                // Reinitialisation du prix TTC

                this.priceControl.get('priceTTC').patchValue(nullablePrice ? null : 0);

                // Reinitialisation du pourcentage

                this.priceControl.get('percent').patchValue(null);

                // Réinitialisation du type de tarif palier
                // Si on est sur un tarif sur le séjour avec palier, on force la valeur à montant (non modifiable)

                if(control !== (this.priceControl.get('priceType') as UntypedFormControl)) {

                    if(this.isPricingType('variable') && this.isPriceArea('travel')) {

                        this.priceControl.get('priceLevelType').patchValue('amount');

                    } else {

                        this.priceControl.get('priceLevelType').patchValue(null);
                    }

                }

                // Réinitialisation des tarifs paliers

                this.priceLevelsControl.clear();

                if(this.isPricingType('variable')){

                    // Création du premier palier par défaut

                    this.addPriceLevel();
                }

                this.priceLevelsControl.updateValueAndValidity();

                // Réinitialisation des champs pour le calcul automatique des tarifs

                this.priceControl.get('priceCalculationAutomatic').patchValue(false);

                this.priceControl.get('priceTypeReference').patchValue(null);

                this.priceControl.get('vatPercent').patchValue(null);
            });
        });

        this.priceControl.get('priceLevelType').valueChanges.subscribe((value: OfferPermanentOptionPriceLevelType): void => {

            this.offerPermanentOptionPriceLevelBuilder.priceLevelType = value;

            // Réinitialisation des tarifs paliers

            this.priceLevelsControl.clear();

            if(this.isPricingType('variable')){

                // Création du premier palier par défaut

                this.addPriceLevel();
            }

            this.priceLevelsControl.updateValueAndValidity();
        });

        this.priceControl.get('priceArea').valueChanges.subscribe((value: PriceArea): void => {

            // Si le type d'option assurance est sur le prix total du séjour, dans le cas d'un tarif variable, le type de prix du tarif palier est forcément montant

            if(!this.isPricingType('variable')) {

                return;
            }

            if(value === 'travel') {

                this.priceControl.get('priceLevelType').patchValue('amount');

                this.priceControl.updateValueAndValidity();

            }
        });

        // Redimensionnement des images si celles-ci sont communes à chaque langue

        this._imageEventService.imageCropped.subscribe((image: Image): void => {

            if(this.isTranslationPictureType('dissociated')){

                return;
            }

            const item: OfferProgramTranslationPicture = this.translationBuilder.itemsControl.at(0).get('picture').value;

            if(!item.image){

                return;
            }

            if((item.image as unknown as Image).id !== image.id){

                return;
            }

            const locales: string[] = this.form.get('locales').value.filter((locale: string): boolean => {

                return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
            });

            locales.forEach((locale: string): void => {

                const destinationPictureControl: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                if(destinationPictureControl){

                    const destinationImage: Image = Object.assign(destinationPictureControl.get('image').value, {
                        formats: image.formats
                    });

                    this._imageService.crop(destinationImage).subscribe((): void => {

                        this._changeDetectorRef.detectChanges();
                    });
                }
            });
        });
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                title: ['', [Validators.required]],
                description: ['', [Validators.required]],
                picture: this.translationPictureControl,
                termsAndConditions: [null ,[Validators.required]],
                additionalDocument: [null]
            });
        };
    }

    private _initPriceLevelBuilder(): void {

        this.offerPermanentOptionPriceLevelBuilder = new OfferPermanentOptionPriceLevelBuilder();

        this.offerPermanentOptionPriceLevelBuilder.form = this.priceLevelsControl;
    }

    private _handleCommonPicturesEvents(): void {

        const translationControls = this.translationBuilder.itemsControl.controls;

        translationControls.forEach(() => {

            const sourcePictureControl: UntypedFormGroup = this.translationBuilder.itemsControl.at(0).get('picture') as UntypedFormGroup;

            sourcePictureControl.get('image').valueChanges.subscribe((data: Image): void => {

                if(this.isTranslationPictureType('dissociated')){

                    return;
                }

                setTimeout((): void => {

                    if(!data){

                        return;
                    }

                    const locales: string[] = this.form.get('locales').value.filter((locale: string): boolean => {

                        return this.translationBuilder.itemsControl.at(0).get('locale').value !== locale;
                    });

                    locales.forEach((locale: string): void => {

                        const destinationPictureControl: UntypedFormGroup = this.translationBuilder.getItemControlByLocale(locale).get('picture') as UntypedFormGroup;

                        if(sourcePictureControl.get('image').valid && destinationPictureControl && destinationPictureControl.get('image')){

                            this._imageService.getCloneImage(sourcePictureControl.get('image').value.reference).subscribe((cloneImage: Image): void => {

                                destinationPictureControl.get('image').patchValue(cloneImage);

                                destinationPictureControl.updateValueAndValidity();
                            });
                        }
                    });
                });
            });
        });
    }

    public isCrudAction(value: CrudAction): boolean {

        return this.crudAction === value;
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer/permanent-option/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public createPriceLevelControl(data: Partial<OfferPermanentOptionPriceLevel>): UntypedFormGroup {

        const priceConfiguration: OfferPermanentOptionPrice = plainToClass(OfferPermanentOptionPrice, this.priceControl.value);

        const group: UntypedFormGroup = this._formBuilder.group({
            min: [(data.min === null ? this.offerPermanentOptionPriceLevelBuilder.nextMinDefaultValue : data.getFormValueMin(priceConfiguration)), [Validators.required, Validators.pattern(/^[0-9]*$/)]],
            max: [data.getFormValueMax(priceConfiguration)],
            value: [data.getFormValueValue(priceConfiguration), [Validators.pattern(REGEX_PRICE), Validators.required]],
            additionalValue: [data.getFormValueAdditionalValue(priceConfiguration), [Validators.pattern(REGEX_PRICE), (control: AbstractControl) => {

                if(this.isPriceType('percent')){

                    return null;
                }

                if((control.value !== null) && (control.value.length > 0)){

                    return null;
                }

                return {
                    'isRequired': {
                        valid: false
                    }
                };

            }]],
            maxUnlimited: [!!data.id ? (data.max === null) : false, [Validators.required]]
        });

        if(!!data.id){

            group.addControl('id', this._formBuilder.control(data.id));
        }

        // Validation du maximum

        const maxValidators: ValidatorFn[] = [() => {

            if(group.get('maxUnlimited').value){

                return null;
            }

            const value: string = group.getRawValue().max === null ? null : group.getRawValue().max.toString();

            if((value !== null) && (value.length > 0)){

                return null;
            }

            return {
                'isRequired': {
                    valid: false
                }
            };

        }, Validators.pattern(/^[0-9]*$/)];

        group.get('max').setValidators(maxValidators);

        // Validation du tarif palier

        group.setValidators([(control: UntypedFormGroup) => {

            const controlValue = control.getRawValue();

            if(controlValue.maxUnlimited){

                return null;
            }

            const minValue: string = controlValue.min === null ? null : controlValue.min.toString();

            const maxValue: string = controlValue.max === null ? null : controlValue.max.toString();

            const minDefined: boolean = (minValue !== null) && (minValue.length > 0);

            const maxDefined: boolean = (maxValue !== null) && (maxValue.length > 0);

            if(!minDefined || !maxDefined){

                return null;
            }

            if(parseFloat(minValue) < parseFloat(maxValue)){

                return null;
            }

            return {
                'isIntervalValueInvalid': {
                    valid: false
                }
            };
        }]);

        // Déclenchement automatique de la validation du tarif palier

        group.markAsTouched();

        // Mise à jour du maximum illimité

        group.get('maxUnlimited').valueChanges.subscribe((): void => {

            group.get('max').patchValue(null);
        });

        return group;
    }

    public getCurrencyById(id: number): Currency {

        return this.currencies.find((currency: Currency): boolean => {

            return currency.id === id;
        });
    }

    public calculatePrices(): void {

        if (!this.priceControl.get('vatPercent').value || this.priceControl.get('vatPercent').invalid) {

            return;
        }

        const TVA: number = parseFloat(this.priceControl.get('vatPercent').value);

        const type: OfferPermanentOptionPriceTypeType = this.priceControl.get('priceTypeReference').value;

        const controlReferences: {[p in OfferPermanentOptionPriceTypeType] : UntypedFormControl } = {
            'HT': this.priceControl.get('priceHT') as UntypedFormControl,
            'TTC': this.priceControl.get('priceTTC') as UntypedFormControl
        };

        const controlCalculates: {[p in OfferPermanentOptionPriceTypeType] : UntypedFormControl } = {
            'HT': this.priceControl.get('priceTTC') as UntypedFormControl,
            'TTC': this.priceControl.get('priceHT') as UntypedFormControl
        };

        switch (type) {

            case 'HT':

                const HT: number = parseFloat(controlReferences['HT'].value);

                controlCalculates['HT'].patchValue(!!controlReferences['HT'].value ? parsePrice(HT + (HT * TVA / 100), 2, '', '.') : null);

                controlCalculates['HT'].updateValueAndValidity();

                break;

            case 'TTC':

                const TTC: number = parseFloat(controlReferences['TTC'].value);

                controlCalculates['TTC'].patchValue(!!controlReferences['TTC'].value ? parsePrice(((100 * TTC) / (100 + TVA)), 2, '', '.') : null);

                controlCalculates['TTC'].updateValueAndValidity();

                break;
        }
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public addPriceLevel(): void {

        this.priceLevelsControl.push(this.createPriceLevelControl(plainToClass(OfferPermanentOptionPriceLevel, {
            id: null,
            min: null,
            max: null,
            value: null,
            additionalValue: null
        })));
    }

    public removePriceLevel(index: number) {

        this.priceLevelsControl.removeAt(index);
    }

    public getPriceLevelControl(index: number): AbstractControl {

        return this.priceLevelsControl.controls[index];
    }

    public isTranslationPictureType(value: TranslationPictureType): boolean {

        return this.form.get('translationPictureType').value === value;
    }

    public isPriceArea(value: PriceArea): boolean {

        return this.priceControl.get('priceArea').value === value;
    }

    public isPricingType(value: PricingType): boolean {

        return this.priceControl.get('pricingType').value === value;
    }

    public isPriceType(value: PriceType): boolean {

        return this.priceControl.get('priceType').value === value;
    }

    public isPriceLevelType(value: OfferPermanentOptionPriceLevelType): boolean {

        return this.priceControl.get('priceLevelType').value === value;
    }

    public defaultOfferSelection(mode: ModeType): (Offer|OfferCatalog)[] {

        if(this.isCrudAction('create')){

            return [];
        }

        switch (mode){

            case 'offer-permanent-option-personnal-offers':

                return this.option.offers.map((item: OfferPermanentOptionOffer): Offer => {

                    return item.offer;
                });

            case 'offer-permanent-option-catalog':

                return this.option.offerCatalogs.map((item: OfferPermanentOptionOfferCatalog): OfferCatalog => {

                    return item.offerCatalog;
                });

            default:

                return [];
        }
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get locales$(): Observable<LocaleItem[]> {

        return of(LOCALE_ITEMS);
    }

    get descriptionEditorConfig(): CkeditorConfig {

        return {
            id: 'description',
            editor: this.editor,
            attrs: {
                required: true,
                maxLength: 3500
            }
        }
    }

    get imageConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'offer_permanent_option_picture',
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.permanentOption.image.value')
            },
        }
    }

    get termsAndConditionsConfig(): FileConfig {

        return {
            id: 'termsAndConditions',
            gallery: {
                id: null,
                type: 'file',
                context: 'offer_permanent_option_file'
            },
            required: true,
            uploadApiUrl: this._apiService.getApiUrl(false, true)
        }
    };

    get additionalDocumentConfig(): FileConfig {

        return {
            id: 'additionalDocument',
            gallery: {
                id: null,
                type: 'file',
                context: 'offer_permanent_option_file'
            },
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true)
        }
    };

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get priceControl(): UntypedFormGroup {

        return this.form.get('price') as UntypedFormGroup;
    }

    get priceLevelsControl(): UntypedFormArray {

        return this.form.get('priceLevels') as UntypedFormArray;
    }

    get translationPictureControl(): UntypedFormGroup {

        return this._formBuilder.group({
            image: ['', [Validators.required]]
        });
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items = [];

            items.push({
                tag: 'generalData',
                controls: [
                    this.form.get('reference'),
                    this.form.get('locales'),
                    this.form.get('providerName'),
                    this.form.get('translationPictureType'),
                    this.translationsControl,
                    this.form.get('comment'),
                    this.priceControl.get('priceArea'),
                    this.priceControl.get('pricingType'),
                    this.priceControl.get('priceType'),
                    this.priceControl.get('priceLevelType')
                ]
            });

            items.push({
                tag: 'priceData',
                controls: [
                    this.form.get('currency'),
                    this.priceControl.get('priceHT'),
                    this.priceControl.get('priceTTC'),
                    this.priceControl.get('percent'),
                    this.priceControl.get('priceCalculationAutomatic'),
                    this.priceControl.get('priceTypeReference'),
                    this.priceControl.get('vatPercent'),
                    this.form.get('priceLevels')
                ]
            });

            if(this.hasRole('ROLE_OFFER_CREATOR')) {

                items.push({
                    tag: 'offerIsMineData',
                    controls: [
                        this.form.get('offers')
                    ]
                });
            }

            if(this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

                items.push({
                    tag: 'offerCatalogData',
                    controls: [
                        this.form.get('offerCatalogs')
                    ]
                });
            }

            return items.map((item: Partial<FormTabValidationItem>, index: number): FormTabValidationItem => {

                return Object.assign(item, {
                    position: index + 1
                } as FormTabValidationItem);
            });
        };
    }
}
