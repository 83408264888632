import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FormService} from "@core/shared/services/form.service";
import {UserService} from "@core/shared/services/user.service";
import {Address} from "@core/shared/models/address";
import {User} from "@core/shared/models/user";
import {TranslationService} from "@core/shared/services/translation.service";
import {Observable} from "rxjs";
import {Country} from "@core/shared/models/country";
import {CountryService} from "@core/shared/services/country.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SocietyService} from "@core/shared/services/society.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {REGEX_WHITESPACE} from "@core/shared/models/regex";
import {GIFT_VOUCHER_URL_ORIGIN, Service} from "@core/shared/models/service";

@Component({
    selector: 'app-page-service-gift-voucher',
    templateUrl: './page-service-gift-voucher.component.html',
    styleUrls: ['./page-service-gift-voucher.component.scss'],
    providers: [
        FormService
    ]
})
export class PageServiceGiftVoucherComponent implements OnInit {

    public user: User;

    public service: Service;

    public countries$: Observable<Country[]>;

    public submitLoading: boolean = false;

    constructor(public formService: FormService,
                public userService: UserService,
                public translationService: TranslationService,
                private _activatedRoute: ActivatedRoute,
                private _societyService: SocietyService,
                private _snackBar: MatSnackBar,
                private _router: Router,
                private _translateService: TranslateService,
                private _countryService: CountryService,
                private _formBuilder: UntypedFormBuilder,) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { service: Service }): void => {

            this.service = data.service;

            this.user = this.userService.currentUser.value;

            this._loadCountries();

            this._initForm();

            this._initEvent();

        });

    }

    private _initForm(): void {

        const billingAddress: Address = this.user.society.addresses.find((address: Address) => address.type === "billing");

        this.formService.form = this._formBuilder.group({
            society: this._formBuilder.group({
                name: [''],
                vatNumber: [''],
                identificationNumber: [''],
                registration: [''],
                corporateName: [''],
                capital: [''],
                businessActivityCode: [''],
                rcsRegistration: [''],
            }),
            enableCustomInvoiceLegalInformation: [false],
            customInvoiceLegalInformation: [''],
            societyTermsAndConditionsIncludeOnlineSale: [false, [Validators.requiredTrue]]
        });

        this.form.patchValue(this.user);

        // Mise à jour de l'adresse de facturation

        if (billingAddress) {

            const addressForm: UntypedFormGroup = this._formBuilder.group({
                id: [billingAddress.id],
                type: [billingAddress.type, [Validators.required]],
                address: [billingAddress.address, [Validators.required]],
                additionalAddress: [billingAddress.additionalAddress],
                zipcode: [billingAddress.zipcode, [Validators.required]],
                city: [billingAddress.city, [Validators.required]],
                region: [billingAddress.region, [Validators.required]],
                country: [billingAddress.country, [Validators.required]]
            });

            this.societyForm.addControl('address', addressForm);

            addressForm.get('region').markAsTouched();
            addressForm.get('region').updateValueAndValidity();
        }

        this._handleFormState();

        this.formService.submitCallback = (): void => {

            this.submitLoading = true;

            const data: object = Object.assign({}, {
                customInvoiceLegalInformation: this.form.get('enableCustomInvoiceLegalInformation').value ? this.form.get('customInvoiceLegalInformation').value.trim() : null,
                societyTermsAndConditionsIncludeOnlineSale: this.form.get('societyTermsAndConditionsIncludeOnlineSale').value,
            });

            this._societyService.updateItemAPI(this.user.society.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('form.submit.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._router.navigate(['account/service/subscription/create', this.service.id], {queryParams: {origin: GIFT_VOUCHER_URL_ORIGIN}});

            }, () => {

                this.submitLoading = false;
            });

        }
    }

    private _handleFormState(): void {

        this.societyForm.get('name').disable();

        this.societyForm.get('vatNumber').disable();

        this.societyForm.get('identificationNumber').disable();

        this.societyForm.get('registration').disable();

        this.societyForm.get('corporateName').disable();

        this.societyForm.get('businessActivityCode').disable();

        this.societyForm.get('rcsRegistration').disable();

        this.societyForm.get('capital').disable();

        this.addressesForm.get('address').disable();

        this.addressesForm.get('additionalAddress').disable();

        this.addressesForm.get('zipcode').disable();

        this.addressesForm.get('city').disable();

        this.addressesForm.get('region').disable();

        this.addressesForm.get('country').disable();
    }

    private _initEvent() {

        this.form.get('enableCustomInvoiceLegalInformation').valueChanges.subscribe((data) => {

            if (data) {

                this.form.get('customInvoiceLegalInformation').setValidators([Validators.required, Validators.pattern(REGEX_WHITESPACE)]);

                this.addressesForm.get('region').setValidators([]);
            } else {

                this.form.get('customInvoiceLegalInformation').setValidators([]);

                this.addressesForm.get('region').setValidators([Validators.required]);
            }

            this.form.get('customInvoiceLegalInformation').updateValueAndValidity();

            this.addressesForm.get('region').updateValueAndValidity();
        })
    }

    private _loadCountries(): void {

        this.countries$ = this._countryService.getItemsAPI();
    }

    public handleBackToServices(): void {

        this._router.navigate(['account/service/list']);
    }

    get societyForm(): UntypedFormGroup {

        return this.form.get('society') as UntypedFormGroup;
    }

    get addressesForm(): UntypedFormGroup {

        return this.societyForm.get('address') as UntypedFormGroup;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get localeId(): string {
        return this._translateService.currentLang;
    }

}
