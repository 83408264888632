import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OfferPeriodCreateConfiguration} from "@core/shared/models/offer/offer-period/offer-period-create";
import {OfferOptionPeriodCreateComponent} from "@core/components/offer/offer-option/offer-option-period/offer-option-period-create/offer-option-period-create.component";
import {OfferOption} from "@core/shared/models/offer/offer-option";

@Component({
    selector: 'app-core-offer-option-period-create-dialog',
    templateUrl: './offer-option-period-create-dialog.component.html',
    styleUrls: ['./offer-option-period-create-dialog.component.scss']
})
export class OfferOptionPeriodCreateDialogComponent implements OnInit {

    @Output() create: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(OfferOptionPeriodCreateComponent) offerOptionPeriodCreateComponent: OfferOptionPeriodCreateComponent;

    constructor(
        private _dialogRef: MatDialogRef<OfferOptionPeriodCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { option: OfferOption }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.create
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.offerOptionPeriodCreateComponent.formService.submit();
    }

    get option(): OfferOption {

        return this.data.option;
    }

    get configuration(): OfferPeriodCreateConfiguration {

        return {
            showSubmitButton: false
        };
    }

}
