import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Society} from "@core/shared/models/society";
import {Access} from "@core/shared/models/access";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-core-page-offer-creator-read',
    templateUrl: './page-offer-creator-read.component.html',
    styleUrls: ['./page-offer-creator-read.component.scss']
})
export class PageOfferCreatorReadComponent implements OnInit {

    public item: Society;

    public listRedirectionAllowed: boolean = true;

    public currentUser: User = null;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _userService: UserService,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _router: Router
    ) {}

    ngOnInit() {

        this.currentUser = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { offerCreator: Society }): void => {

            this._handleListRedirection();

            this.item = data.offerCreator;
        });
    }

    private _handleListRedirection(): void {

        if(!this._activatedRoute.snapshot.queryParams['list-redirection-allowed']){

            return;
        }

        this.listRedirectionAllowed = Boolean(parseInt(this._activatedRoute.snapshot.queryParams['list-redirection-allowed']));
    }

    public openCreateQuotationDialog(): void {

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: this._translateService.instant('quotation.value'),
                content: `<p>${this._translateService.instant('quotation.question.description.value')}</p>`
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._router.navigate(['account/quotation/create'], { queryParams: { origin: 'offerCreatorRead', offerCreatorId: this.item.id } });
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/offer-creator/list']);
    }

    get isQuotationCreationAllowed(): boolean {

        const conditions: boolean[] = [
            //this._userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']),
            this._userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR']),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'OFFER_CREATOR_LIST';
            }),
            this.currentUser.accesses.some((access: Access): boolean => {

                return access.tag === 'QUOTATION_CREATE';
            })
        ];

        return conditions.every((value: boolean) => {

            return value === true;
        });
    }
}
