<ng-container *ngIf="isActiveView('form')">

    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="page_title">
            <div class="row marges">

                <div class="col y_items_center">
                    <h1 class="title">{{ 'tender.value' | translate }}</h1>
                </div>

                <!-- Actions -->

                <div class="col_content y_items_center">

                    <ng-container *ngIf="origin">

                        <!-- Retour à l'origine -->

                        <button class="btn_cta btn_lines btn_little" type="button" (click)="openLeaveWithoutSubmitDialog()">
                            {{ origin.backLabel | translate }}
                        </button>

                    </ng-container>

                    <ng-container *ngIf="offerFormTabValidationService.currentItemIsLastItem">

                        <!-- Enregistrer -->

                        <button class="btn_cta btn_little" type="submit">
                            {{ 'tender.mine.send.action.value'| translate }}
                        </button>

                    </ng-container>

                    <ng-container *ngIf="!offerFormTabValidationService.currentItemIsLastItem">

                        <!-- Passer à l'étape suivante -->

                        <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isCurrentItemValid" (click)="offerFormTabValidationService.goToNextItem()">
                            {{ 'form.tab.next.value' | translate }}
                        </button>

                    </ng-container>

                </div>
            </div>
        </div>

        <!-- Gestion des onglets -->

        <mat-tab-group #tabGroup>

            <ng-container *ngFor="let tabItem of tabItems">

                <mat-tab [disabled]="!offerFormTabValidationService.isFirstItem(tabItem.tag) && !offerFormTabValidationService.isPreviousItemValid(tabItem.tag)">
                    <ng-template mat-tab-label>
                        <span>{{ tabItem.label | translate }}</span>
                        <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
                </mat-tab>

            </ng-container>

        </mat-tab-group>

    </form>

</ng-container>

<ng-container *ngIf="isActiveView('confirmation')">

    <ng-container *ngTemplateOutlet="confirmationTemplate"></ng-container>

</ng-container>

<!-- Sélection des créateurs d'offres -->

<ng-template #offerCreators>

    <ng-container [formGroup]="form">

        <div class="tab_content_overflow">

            <!-- Créateurs d'offres sélectionnés -->

            <ng-container *ngIf="totalOfferCreators > 0">

                <div class="row marges ligne_form" [ngStyle]="{ 'margin-bottom': offerCreatorSearchService.selectedOfferCreators$.value.length ? '0' : '24px' }">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox">
                                <input type="checkbox" id="allOfferCreators" [checked]="form.get('allOfferCreators').value" (click)="openAllOfferCreatorsSelection($event)">
                                <label for="allOfferCreators">{{ (totalOfferCreators > 1 ? 'tender.offerCreator.selection.all.plural.action.value' : 'tender.offerCreator.selection.all.single.action.value') | translate:{count: totalOfferCreators} }}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="offerCreatorSearchService.selectedOfferCreators$.value.length">

                <div class="row marges">

                    <div class="col y_items_center">

                        <div class="selected_offers">

                            <span>{{ 'offerCreator.list.selected.value' | translate }} :</span>

                            <ul>
                                <li *ngFor="let offerCreator of offerCreatorSearchService.selectedOfferCreators$.value">{{ offerCreator.name }}<mat-icon (click)="offerCreatorSearchService.unselectOfferCreator(offerCreator)">close</mat-icon></li>
                            </ul>
                        </div>

                    </div>

                </div>

            </ng-container>

            <!-- Liste des créateurs d'offres -->

            <app-core-offer-creator-search
                [mode]="'tender'"
                [loadItemsSourceCallback]="loadItemsSourceCallback"
            ></app-core-offer-creator-search>

        </div>

    </ng-container>

</ng-template>

<ng-template #formData>

    <ng-container [formGroup]="form">

        <div class="form_part limited">

            <div class="row marges ligne_form">

                <!-- Référence interne -->

                <div class="col_12 col_md_4">

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="ownerReference" id="ownerReference">
                        <label class="required" for="ownerReference">{{ 'offer.form.fields.reference.value' | translate }}</label>
                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthOwnerReference } }}</span>
                        <mat-error *ngIf="form.get('ownerReference').errors && (form.get('ownerReference').dirty || form.get('ownerReference').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('ownerReference').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('ownerReference').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthOwnerReference } }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>
            </div>

            <div class="row marges ligne_form">

                <!-- Objectif de la demande -->

                <div class="col_12">

                    <div class="form-group">
                        <label class="required" for="purposeRequest">{{ 'purpose.request.mine.value' | translate }}</label>
                        <textarea placeholder="{{ 'tender.purpose.request.value' | translate }}" class="form-control" formControlName="purposeRequest" id="purposeRequest"></textarea>
                        <div class="form-group col_content y_items_center">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: purposeRequestMaxLength } }}
                            </span>
                        </div>
                        <mat-error *ngIf="form.get('purposeRequest').errors && (form.get('purposeRequest').dirty || form.get('purposeRequest').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('purposeRequest').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('purposeRequest').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : purposeRequestMaxLength } }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>
            </div>

        </div>

        <div class="form_part limited">

            <div class="title_part gradient form_panel" [ngClass]="{ 'open': isOpenPanel('generalData'), 'closed': !isOpenPanel('generalData') }" (click)="togglePanelOpening('generalData')">
                <div [ngClass]="{ 'valid': isValidPanel('generalData'), 'invalid': !isValidPanel('generalData') }"></div>
                {{ 'generalData.value' | translate }}
            </div>

            <ng-container *ngIf="isOpenPanel('generalData')">

                <div class="row marges ligne_form">

                    <!-- Cible de clientèle -->

                    <div class="col_12 col_md_4">

                        <div class="form-group" formGroupName="configuration">
                            <mat-select [compareWith]="compareById" formControlName="customerTypology">
                                <mat-option *ngFor="let customerTypology of customerTypologies" [value]="customerTypology">
                                    {{ translationService.getFallbackTranslation(customerTypology.translations).name }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'customer.target.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('customerTypology').errors && (configurationControl.get('customerTypology').dirty || configurationControl.get('customerTypology').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('customerTypology').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                </div>

                <div class="row marges ligne_form">

                    <div class="col_12">

                        <!-- Type de public -->

                        <div class="form-group" formGroupName="composition">
                            <label class="required">{{ 'public.type.value' | translate }}</label>
                            <div class="checkbox inline">
                                <input type="checkbox" id="public-adult" value="adult" [checked]="isSelectedPublic('adult')" (change)="handlePublicSelection($event)">
                                <label for="public-adult">{{ 'adult.value' | translate }}</label>
                            </div>
                            <div class="checkbox inline">
                                <input type="checkbox" id="public-child" value="child" [checked]="isSelectedPublic('child')" (change)="handlePublicSelection($event)">
                                <label for="public-child">{{ 'child.value' | translate }}</label>
                            </div>

                            <mat-error *ngIf="compositionControl.get('publics').invalid && (compositionControl.get('publics').dirty || compositionControl.get('publics').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="compositionControl.get('publics').errors.minLengthArray">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="compositionControl.get('publics').valid">

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <div class="row marges ligne_form little_margin">
                                <div class="col_12 col_md_4">
                                    <div class="form-group">
                                        <label>{{ 'participant.plural.count.value' | translate }}</label>
                                    </div>
                                </div>
                            </div>


                            <ng-container *ngIf="isSelectedPublic('adult')">

                                <div class="row marges ligne_form little_margin">

                                    <div class="col_12 col_md_4">

                                        <!-- Nombre d'adulte minimum -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="minNbAdult" id="minNbAdult">
                                            <label class="required" for="minNbAdult">{{ 'adult.value' | translate }} - {{ 'count.minimum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('minNbAdult').invalid && (compositionControl.get('minNbAdult').dirty || compositionControl.get('minNbAdult').touched)">
                                                    <div *ngIf="compositionControl.get('minNbAdult').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('minNbAdult').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>

                                    <div class="col_12 col_md_4">

                                        <!-- Nombre d'adulte maximum -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="maxNbAdult" id="maxNbAdult">
                                            <label class="required" for="maxNbAdult">{{ 'adult.value' | translate }} - {{ 'count.maximum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('maxNbAdult').invalid && (compositionControl.get('maxNbAdult').dirty || compositionControl.get('maxNbAdult').touched)">
                                                    <div *ngIf="compositionControl.get('maxNbAdult').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('maxNbAdult').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>
                                </div>

                                <ng-container *ngIf="compositionControl.get('minNbAdult').errors?.isInvalidInterval || compositionControl.get('maxNbAdult').errors?.isInvalidInterval">

                                    <div class="col_12" style="margin-bottom: 8px;">
                                        <div class="form-group">
                                            <mat-error>
                                                <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                            </mat-error>
                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <ng-container *ngIf="isSelectedPublic('child')">

                                <div class="row marges ligne_form little_margin">

                                    <div class="col_12 col_md_4">

                                        <!-- Nombre d'enfant minimum -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="minNbChild" id="minNbChild">
                                            <label class="required" for="minNbChild">{{ 'child.value' | translate }} - {{ 'count.minimum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('minNbChild').invalid && (compositionControl.get('minNbChild').dirty || compositionControl.get('minNbChild').touched)">
                                                    <div *ngIf="compositionControl.get('minNbChild').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('minNbChild').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>

                                    <div class="col_12 col_md_4">

                                        <!-- Nombre d'enfant maximum -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="maxNbChild" id="maxNbChild">
                                            <label class="required" for="maxNbChild">{{ 'child.value' | translate }} - {{ 'count.maximum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('maxNbChild').invalid && (compositionControl.get('maxNbChild').dirty || compositionControl.get('maxNbChild').touched)">
                                                    <div *ngIf="compositionControl.get('maxNbChild').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('maxNbChild').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>
                                </div>

                                <ng-container *ngIf="compositionControl.get('minNbChild').errors?.isInvalidInterval || compositionControl.get('maxNbChild').errors?.isInvalidInterval">

                                    <div class="col_12" style="margin-bottom: 8px;">
                                        <div class="form-group">
                                            <mat-error>
                                                <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                            </mat-error>
                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <ng-container *ngIf="isSelectedPublic('child')">

                                <div class="row marges ligne_form">
                                    <div class="col_12">
                                        <div class="form-group">
                                            <label>{{ 'child.plural.age.value' | translate }}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row marges ligne_form little_margin">

                                    <div class="col_12 col_md_4">

                                        <!-- Âge minimum des enfants -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="minChildrenAge" id="minChildrenAge">
                                            <label class="required" for="minChildrenAge">{{ 'age.minimum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('minChildrenAge').invalid && (compositionControl.get('minChildrenAge').dirty || compositionControl.get('minChildrenAge').touched)">
                                                    <div *ngIf="compositionControl.get('minChildrenAge').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('minChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>

                                    <div class="col_12 col_md_4">

                                        <!-- Âge maximum des enfants -->

                                        <div class="form-group" formGroupName="composition">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="maxChildrenAge" id="maxChildrenAge">
                                            <label class="required" for="maxChildrenAge">{{ 'age.maximum.value' | translate }}</label>
                                            <mat-error>
                                                <div *ngIf="compositionControl.get('maxChildrenAge').invalid && (compositionControl.get('maxChildrenAge').dirty || compositionControl.get('maxChildrenAge').touched)">
                                                    <div *ngIf="compositionControl.get('maxChildrenAge').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                    <div *ngIf="compositionControl.get('maxChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>
                                </div>

                                <ng-container *ngIf="compositionControl.get('minChildrenAge').errors?.isInvalidInterval || compositionControl.get('maxChildrenAge').errors?.isInvalidInterval">

                                    <div class="col_12" style="margin-bottom: 8px;">
                                        <div class="form-group">
                                            <mat-error>
                                                <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                            </mat-error>
                                        </div>
                                    </div>

                                </ng-container>

                            </ng-container>

                        </div>
                    </div>

                </ng-container>

            </ng-container>

        </div>

        <div class="form_part limited">

            <div class="title_part gradient form_panel" [ngClass]="{ 'open': isOpenPanel('offerContent'), 'closed': !isOpenPanel('offerContent') }" (click)="togglePanelOpening('offerContent')">
                <div [ngClass]="{ 'valid': isValidPanel('offerContent'), 'invalid': !isValidPanel('offerContent') }"></div>
                {{ 'offer.content.value' | translate }}
            </div>

            <ng-container *ngIf="isOpenPanel('offerContent')">

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'stay.duration.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_4">

                        <!-- Durée minimum du séjour -->

                        <div class="form-group" formGroupName="composition">
                            <mat-select [compareWith]="compareById" formControlName="minDuration">
                                <mat-option *ngFor="let duration of durations$ | async" [value]="duration">
                                    {{ translationService.getFallbackTranslation(duration.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'duration.minimum.value' | translate }}</mat-label>
                            <mat-error *ngIf="compositionControl.get('minDuration').invalid && (compositionControl.get('minDuration').dirty || compositionControl.get('minDuration').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="compositionControl.get('minDuration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <div class="col_12 col_md_4">

                        <!-- Durée maximum du séjour -->

                        <div class="form-group" formGroupName="composition">
                            <mat-select [compareWith]="compareById" formControlName="maxDuration">
                                <mat-option *ngFor="let duration of durations$ | async" [value]="duration">
                                    {{ translationService.getFallbackTranslation(duration.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'duration.maximum.value' | translate }}</mat-label>
                            <mat-error *ngIf="compositionControl.get('maxDuration').invalid && (compositionControl.get('maxDuration').dirty || compositionControl.get('maxDuration').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="compositionControl.get('maxDuration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>
                </div>

                <ng-container *ngIf="compositionControl.get('minDuration').errors?.isInvalidInterval || compositionControl.get('maxDuration').errors?.isInvalidInterval">

                    <div class="col_12" style="margin-bottom: 8px;">
                        <div class="form-group">
                            <mat-error>
                                <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                            </mat-error>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'feature.plural.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_4">

                        <!-- Destinations -->

                        <div class="form-group" formGroupName="configuration">
                            <mat-select multiple [compareWith]="compareById" formControlName="regions">
                                <mat-option #allRegionsOption [value]="{ id: null }" (click)="handleRegion(allRegionsOption)"><span style="font-weight: bold">{{ 'france.all.value' | translate }}</span></mat-option>
                                <mat-option #regionOption *ngFor="let region of getAttributeObservable( 'region') | async" [value]="region" (click)="handleRegion(regionOption)">
                                    {{ translationService.getFallbackTranslation(region.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'destination.yours.plural.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('regions').errors && (configurationControl.get('regions').dirty || configurationControl.get('regions').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('regions').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                    <!-- Commentaire destination -->

                    <div class="col_12 col_md_8">
                        <div class="form-group" formGroupName="configuration">
                            <textarea placeholder=" " class="form-control" formControlName="destinationsComment" id="destinationsComment"></textarea>
                            <label for="destinationsComment">{{ 'tender.destination.comment.value' | translate }}</label>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: destinationsCommentMaxLength } }}
                                </span>
                            </div>
                            <mat-error *ngIf="configurationControl.get('destinationsComment').errors && (configurationControl.get('destinationsComment').dirty || configurationControl.get('destinationsComment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('destinationsComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : destinationsCommentMaxLength } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_6">

                        <!-- Types de séjours -->

                        <div class="form-group" formGroupName="configuration">
                            <mat-select multiple [compareWith]="compareById" formControlName="types">
                                <mat-option *ngFor="let type of getAttributeObservable( 'types') | async" [value]="type">
                                    {{ translationService.getFallbackTranslation(type.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'stay.plural.type.plural.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('types').errors && (configurationControl.get('types').dirty || configurationControl.get('types').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('types').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                    <div class="col_12 col_md_6">

                        <!-- Thématiques -->

                        <div class="form-group" formGroupName="configuration">
                            <mat-select multiple [compareWith]="compareById" formControlName="themes">
                                <mat-option *ngFor="let theme of getAttributeObservable( 'themes') | async" [value]="theme">
                                    {{ translationService.getFallbackTranslation(theme.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'offer.list.filter.themes.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('themes').errors && (configurationControl.get('themes').dirty || configurationControl.get('themes').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('themes').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>
                </div>

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_6">

                        <!-- Activités payantes souhaitées -->

                        <div class="form-group" formGroupName="configuration">
                            <mat-select multiple [compareWith]="compareById" formControlName="activities">
                                <mat-option *ngFor="let activity of getAttributeObservable( 'activities') | async" [value]="activity">
                                    {{ translationService.getFallbackTranslation(activity.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ 'activity.plural.payable.wish.value' | translate }}</mat-label>
                        </div>

                    </div>

                    <!-- Langue de l'offre -->

                    <div class="col_12 col_md_6">

                        <div class="form-group" formGroupName="configuration">
                            <mat-select formControlName="locales" [multiple]="true">
                                <mat-option *ngFor="let locale of locales" [value]="locale">{{ ('locale.' + locale + '.value') | translate }}</mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'offer.language.plural.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('locales').errors && (configurationControl.get('locales').dirty || configurationControl.get('locales').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>
                </div>

                <ng-container *ngIf="nightIncluded">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'accommodation.value' | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form little_margin">

                        <!-- Hébergements -->

                        <div class="col_12 col_md_4">

                            <div class="form-group" formGroupName="configuration">
                                <mat-select multiple [compareWith]="compareById" formControlName="accommodations">
                                    <mat-option #noneAccommodationOption [value]="{ id: null }" (click)="handleAccommodation(noneAccommodationOption)"><span style="font-weight: bold">{{ 'withoutPreference.value' | translate }}</span></mat-option>
                                    <mat-option #accommodationOption *ngFor="let accommodation of getAttributeObservable( 'accommodations') | async" [value]="accommodation" (click)="handleAccommodation(accommodationOption)">
                                        {{ translationService.getFallbackTranslation(accommodation.translations).label }}
                                    </mat-option>
                                </mat-select>
                                <mat-label class="required">{{ 'accommodation.type.plural.value' | translate }}</mat-label>
                                <mat-error *ngIf="configurationControl.get('accommodations').errors && (configurationControl.get('accommodations').dirty || configurationControl.get('accommodations').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="configurationControl.get('accommodations').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                        <!-- Commentaire hébergement (type de chambre, niveau de confort, situation…) -->

                        <div class="col_12 col_md_8">
                            <div class="form-group" formGroupName="configuration">
                                <textarea placeholder=" " class="form-control" formControlName="accommodationsComment" id="accommodationsComment"></textarea>
                                <label for="accommodationsComment">{{ 'tender.accommodation.comment.value' | translate }}</label>
                                <div class="form-group col_content y_items_center">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: accommodationsCommentMaxLength } }}
                                    </span>
                                </div>
                                <mat-error *ngIf="configurationControl.get('accommodationsComment').errors && (configurationControl.get('accommodationsComment').dirty || configurationControl.get('accommodationsComment').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="configurationControl.get('accommodationsComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : accommodationsCommentMaxLength } }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'restoration.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form little_margin">

                    <!-- Restaurations -->

                    <div class="col_12 col_md_4">

                        <div class="form-group" formGroupName="configuration">
                            <mat-select multiple [compareWith]="compareById" formControlName="restorations">
                                <mat-option #noneRestaurationOption [value]="{ id: null }" (click)="handleRestoration(noneRestaurationOption)"><span style="font-weight: bold">{{ 'withoutPreference.value' | translate }}</span></mat-option>
                                <mat-option #restorationOption *ngFor="let restoration of getAttributeObservable( 'restoration') | async" [value]="restoration" (click)="handleRestoration(restorationOption)">
                                    {{ translationService.getFallbackTranslation(restoration.translations).label }}
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'restoration.type.plural.value' | translate }}</mat-label>
                            <mat-error *ngIf="configurationControl.get('restorations').errors && (configurationControl.get('restorations').dirty || configurationControl.get('restorations').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('restorations').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                    <!-- Commentaire restauration -->

                    <div class="col_12 col_md_8">
                        <div class="form-group" formGroupName="configuration">
                            <textarea placeholder=" " class="form-control" formControlName="restorationsComment" id="restorationsComment"></textarea>
                            <label for="restorationsComment">{{ 'tender.restoration.comment.value' | translate }}</label>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: restorationsCommentMaxLength } }}
                                </span>
                            </div>
                            <mat-error *ngIf="configurationControl.get('restorationsComment').errors && (configurationControl.get('restorationsComment').dirty || configurationControl.get('restorationsComment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('restorationsComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : restorationsCommentMaxLength } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'tender.stay.comment.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form little_margin">

                    <!-- Commentaire : programme, compris dans l'offre -->

                    <div class="col_12">
                        <div class="form-group" formGroupName="configuration">
                            <textarea placeholder=" " class="form-control" formControlName="contentOfferComment" id="contentOfferComment"></textarea>
                            <label for="contentOfferComment">{{ 'tender.offer.content.comment.value' | translate }}</label>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: contentOfferCommentMaxLength } }}
                                </span>
                            </div>
                            <mat-error *ngIf="configurationControl.get('contentOfferComment').errors && (configurationControl.get('contentOfferComment').dirty || configurationControl.get('contentOfferComment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('contentOfferComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : contentOfferCommentMaxLength } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

            </ng-container>
        </div>

        <div class="form_part limited">

            <div class="title_part gradient form_panel" [ngClass]="{ 'open': isOpenPanel('marketingParameter'), 'closed': !isOpenPanel('marketingParameter') }" (click)="togglePanelOpening('marketingParameter')">
                <div [ngClass]="{ 'valid': isValidPanel('marketingParameter'), 'invalid': !isValidPanel('marketingParameter') }"></div>
                {{ 'marketing.param.plural.value' | translate }}
            </div>

            <ng-container *ngIf="isOpenPanel('marketingParameter')">

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_8">

                        <!-- Modes de commercialisation -->

                        <div class="form-group" formGroupName="pricing">
                            <div class="row">
                                <label>{{ 'tender.marketing.mode.plural.value' | translate }}</label>
                            </div>
                            <div class="checkbox min-size" *ngFor="let marketingMode of marketingModes">
                                <input type="checkbox" id="marketingMode-{{marketingMode}}" #marketingModeInput [value]="marketingMode" [checked]="isSelectedMarketingMode(marketingMode)" (change)="handleMarketingMode(marketingModeInput.value, marketingModeInput.checked)">
                                <label for="marketingMode-{{marketingMode}}">{{ ('tender.marketing.mode.' + marketingMode + '.value') | translate }}</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <mat-error style="padding: 0" *ngIf="pricingControl.get('marketingModes').invalid && (pricingControl.get('marketingModes').dirty || pricingControl.get('marketingModes').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="pricingControl.get('marketingModes').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">

                    <div class="col_12 col_md_8">

                        <!-- Devise -->

                        <div class="form-group" formGroupName="pricing">
                            <mat-select [compareWith]="compareById" class="form-control" formControlName="currency">
                                <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
                                    {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.symbol }})
                                </mat-option>
                            </mat-select>
                            <mat-label class="required">{{ 'currency.value' | translate }}</mat-label>
                            <mat-error *ngIf="pricingControl.get('currency').errors && (pricingControl.get('currency').dirty || pricingControl.get('currency').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="pricingControl.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                </div>

                <ng-container *ngIf="isOneOfTheseMarketingModes(['onlineSale', 'booking'])">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_DISTRIBUTOR')">
                                    <label>{{ 'price.plural.net.desired.value' | translate }}</label>
                                </ng-container>
                                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                                    <label>{{ 'price.plural.public.desired.value' | translate }}</label>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form little_margin" style="margin-bottom: 24px">

                        <!-- Tarif HT / TTC  -->

                        <div class="col_12">
                            <div class="form-group" formGroupName="pricing">
                                <div class="radio">
                                    <input type="radio" [value]="'HT'" formControlName="priceType" class="form-control" id="priceTypeHT">
                                    <label for="priceTypeHT">{{'price.ht.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="'TTC'" formControlName="priceType" class="form-control" id="priceTypeTTC">
                                    <label for="priceTypeTTC">{{'price.ttc.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="pricingControl.get('priceType').errors && (pricingControl.get('priceType').dirty || pricingControl.get('priceType').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="pricingControl.get('priceType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="isSelectedPublic('adult')">

                        <div class="row marges ligne_form little_margin">

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="minAdultPrice" id="minAdultPrice">
                                    <label class="required" for="minAdultPrice">{{ 'adult.value' | translate }} - {{ 'price.minimum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('minAdultPrice').errors && (pricingControl.get('minAdultPrice').dirty || pricingControl.get('minAdultPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('minAdultPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('minAdultPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="maxAdultPrice" id="maxAdultPrice">
                                    <label class="required" for="maxAdultPrice">{{ 'adult.value' | translate }} - {{ 'price.maximum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('maxAdultPrice').errors && (pricingControl.get('maxAdultPrice').dirty || pricingControl.get('maxAdultPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('maxAdultPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('maxAdultPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <ng-container *ngIf="pricingControl.get('minAdultPrice').errors?.isInvalidInterval || pricingControl.get('maxAdultPrice').errors?.isInvalidInterval">

                                <div class="col_12" style="margin-bottom: 8px;">
                                    <div class="form-group">
                                        <mat-error>
                                            <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>

                    </ng-container>

                    <ng-container *ngIf="isSelectedPublic('child')">

                        <div class="row marges ligne_form little_margin">

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="minChildPrice" id="minChildPrice">
                                    <label class="required" for="minAdultPrice">{{ 'child.value' | translate }} - {{ 'price.minimum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('minChildPrice').errors && (pricingControl.get('minChildPrice').dirty || pricingControl.get('minChildPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('minChildPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('minChildPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="maxChildPrice" id="maxChildPrice">
                                    <label class="required" for="maxAdultPrice">{{ 'child.value' | translate }} - {{ 'price.maximum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('maxChildPrice').errors && (pricingControl.get('maxChildPrice').dirty || pricingControl.get('maxChildPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('maxChildPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('maxChildPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>
                        </div>

                        <ng-container *ngIf="pricingControl.get('minChildPrice').errors?.isInvalidInterval || pricingControl.get('maxChildPrice').errors?.isInvalidInterval">

                            <div class="col_12" style="margin-bottom: 8px;">
                                <div class="form-group">
                                    <mat-error>
                                        <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                </ng-container>

                <ng-container *ngIf="isOneOfTheseMarketingModes(['giftVoucher'])">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'giftVoucher.price.plural.ttc.desired.value' | translate }}</label>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="isSelectedPublic('adult')">

                        <div class="row marges ligne_form little_margin">

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="minAdultGiftVoucherPrice" id="minAdultGiftVoucherPrice">
                                    <label class="required" for="minAdultGiftVoucherPrice">{{ 'adult.value' | translate }} - {{ 'price.minimum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('minAdultGiftVoucherPrice').errors && (pricingControl.get('minAdultGiftVoucherPrice').dirty || pricingControl.get('minAdultGiftVoucherPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('minAdultGiftVoucherPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('minAdultGiftVoucherPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="maxAdultGiftVoucherPrice" id="maxAdultGiftVoucherPrice">
                                    <label class="required" for="maxAdultGiftVoucherPrice">{{ 'adult.value' | translate }} - {{ 'price.maximum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('maxAdultGiftVoucherPrice').errors && (pricingControl.get('maxAdultGiftVoucherPrice').dirty || pricingControl.get('maxAdultGiftVoucherPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('maxAdultGiftVoucherPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('maxAdultGiftVoucherPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <ng-container *ngIf="pricingControl.get('minAdultGiftVoucherPrice').errors?.isInvalidInterval || pricingControl.get('maxAdultGiftVoucherPrice').errors?.isInvalidInterval">

                                <div class="col_12" style="margin-bottom: 8px;">
                                    <div class="form-group">
                                        <mat-error>
                                            <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>

                    </ng-container>

                    <ng-container *ngIf="isSelectedPublic('child')">

                        <div class="row marges ligne_form little_margin">

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="minChildGiftVoucherPrice" id="minChildGiftVoucherPrice">
                                    <label class="required" for="minChildGiftVoucherPrice">{{ 'child.value' | translate }} - {{ 'price.minimum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('minChildGiftVoucherPrice').errors && (pricingControl.get('minChildGiftVoucherPrice').dirty || pricingControl.get('minChildGiftVoucherPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('minChildGiftVoucherPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('minChildGiftVoucherPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="maxChildGiftVoucherPrice" id="maxChildGiftVoucherPrice">
                                    <label class="required" for="maxChildGiftVoucherPrice">{{ 'child.value' | translate }} - {{ 'price.maximum.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('maxChildGiftVoucherPrice').errors && (pricingControl.get('maxChildGiftVoucherPrice').dirty || pricingControl.get('maxChildGiftVoucherPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('maxChildGiftVoucherPrice').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('maxChildGiftVoucherPrice').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>
                        </div>

                        <ng-container *ngIf="pricingControl.get('minChildGiftVoucherPrice').errors?.isInvalidInterval || pricingControl.get('maxChildGiftVoucherPrice').errors?.isInvalidInterval">

                            <div class="col_12" style="margin-bottom: 8px;">
                                <div class="form-group">
                                    <mat-error>
                                        <div>{{ 'form.control.error.isInvalidInterval.value' | translate }}</div>
                                    </mat-error>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'stay.interval.plural.value' | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form little_margin" style="margin-bottom: 24px;">
                    <div class="col_4">
                        <div class="form-group">
                            <button class="btn_cta btn_little" type="button" (click)="createDate()">{{ 'stay.interval.add.action.value' | translate }}</button>
                        </div>
                    </div>
                </div>

                <ng-container formGroupName="composition">

                    <div formArrayName="dates" *ngFor="let _ of datesControl.controls; index as i" class="ligne_form form-group dates">

                        <ng-container [formGroupName]="indexAsString(i)">

                            <div class="row marges ligne_form little_margin">

                                <div class="col_12 col_md_4">
                                    <div class="form-group">
                                        <input type="text" class="required datetimepicker" id="dateStart-{{i}}" placeholder=" " matInput formControlName="dateStart" [matDatepicker]="dateStartPicker" (click)="dateStartPicker.open()" (dateChange)="getDateControl(i).get('dateEnd').patchValue(null); dateEndPicker.open()">
                                        <label for="dateStart-{{i}}" class="required">{{ 'filter.dateInterval.start.value' | translate }}</label>
                                        <mat-datepicker #dateStartPicker></mat-datepicker>
                                        <mat-error *ngIf="getDateControl(i).get('dateStart').errors && (getDateControl(i).get('dateStart').dirty || getDateControl(i).get('dateStart').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="getDateControl(i).get('dateStart').errors.required">{{ 'form.validation.error.required' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                                <div class="col_12 col_md_4">
                                    <div class="form-group">
                                        <input type="text" class="datetimepicker" id="dateEnd-{{i}}" placeholder=" " matInput formControlName="dateEnd" [matDatepicker]="dateEndPicker" [min]="getDateControl(i).get('dateStart').value" (click)="dateEndPicker.open()">
                                        <label for="dateEnd-{{i}}">{{ 'filter.dateInterval.end.value' | translate }}</label>
                                        <mat-datepicker #dateEndPicker></mat-datepicker>
                                    </div>
                                </div>

                                <div class="col_content">
                                    <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteDate(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>

                        </ng-container>
                    </div>

                    <div class="row marges ligne_form" *ngIf="compositionControl.get('dates').errors && compositionControl.get('dates').errors.minLengthArray">
                        <div class="form-group">
                            <mat-error>
                                <div class="invalid-feedback">
                                    <div>{{ 'tender.stay.interval.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">

                    <!-- Commentaire commercialisation -->

                    <div class="col_12 col_md_8">
                        <div class="form-group" formGroupName="pricing">
                            <textarea placeholder=" " class="form-control" formControlName="comment" id="pricingComment"></textarea>
                            <label for="pricingComment">{{ 'tender.marketing.comment.value' | translate }}</label>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: commentMaxLength } }}
                                </span>
                            </div>
                            <mat-error *ngIf="pricingControl.get('comment').errors && (pricingControl.get('comment').dirty || pricingControl.get('comment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="pricingControl.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : commentMaxLength } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

            </ng-container>
        </div>

        <div class="form_part limited">

            <div class="title_part gradient form_panel" [ngClass]="{ 'open': isOpenPanel('insurance'), 'closed': !isOpenPanel('insurance') }" (click)="togglePanelOpening('insurance')">
                <div [ngClass]="{ 'valid': isValidPanel('insurance'), 'invalid': !isValidPanel('insurance') }"></div>
                {{ 'assurance.value' | translate }}
            </div>

            <ng-container *ngIf="isOpenPanel('insurance')">

                <div class="row marges ligne_form">

                    <!-- Assurance -->

                    <div class="col_12">

                        <div class="form-group" formGroupName="configuration">
                            <label>{{'tender.insurance.travel.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="hasInsurance" class="form-control" id="hasInsuranceYes">
                                <label for="hasInsuranceYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="hasInsurance" class="form-control" id="hasInsuranceNo">
                                <label for="hasInsuranceNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="configurationControl.get('hasInsurance').value">

                    <div class="row marges ligne_form little_margin">

                        <!-- Type assurance -->

                        <div class="col_12 col_md_4">
                            <div class="form-group" formGroupName="configuration">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="insuranceComment" id="insuranceComment">
                                <label class="required" for="insuranceComment">{{ 'insurance.type.value' | translate }}</label>
                                <mat-error *ngIf="configurationControl.get('insuranceComment').errors && (configurationControl.get('insuranceComment').dirty || configurationControl.get('insuranceComment').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="configurationControl.get('insuranceComment').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                    </div>

                </ng-container>

            </ng-container>
        </div>

        <div class="form_part limited">

            <div class="title_part gradient form_panel" [ngClass]="{ 'open': isOpenPanel('tenderParameter'), 'closed': !isOpenPanel('tenderParameter') }" (click)="togglePanelOpening('tenderParameter')">
                <div [ngClass]="{ 'valid': isValidPanel('tenderParameter'), 'invalid': !isValidPanel('tenderParameter') }"></div>
                {{ 'tender.mine.param.plural.value' | translate }}
            </div>

            <ng-container *ngIf="isOpenPanel('tenderParameter')">

                <div class="row marges ligne_form little_margin">

                    <!-- Date limite de réponse -->

                    <div class="col_12 col_md_4">

                        <div class="form-group">
                            <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="closedAtPicker" [min]="minClosedAt" [max]="maxClosedAt" formControlName="closedAt" (click)="closedAtPicker.open()" id="closedAt" placeholder=" ">
                            <label for="closedAt">{{ 'response.date.limit.value' | translate }}</label>
                            <mat-datepicker #closedAtPicker></mat-datepicker>
                            <mat-error *ngIf="form.get('closedAt').errors && (form.get('closedAt').dirty || form.get('closedAt').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('closedAt').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                    <div class="col_12 col_md_8">

                        <div class="form-group">
                            <div class="checkbox" style="margin-top: 14px;">
                                <input type="checkbox" id="remindBeforeExpire" formControlName="remindBeforeExpire">
                                <label for="remindBeforeExpire">{{ 'tender.remindBeforeExpire.value' | translate }}</label>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="row marges ligne_form little_margin">

                    <div class="col_12 col_md_4">

                        <div class="form-group">
                            <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="marketingDateStartPicker" formControlName="marketingDateStart" (click)="marketingDateStartPicker.open()" id="marketingDateStart" placeholder=" ">
                            <label for="marketingDateStart">{{ 'tender.marketing.date.start.value' | translate }}</label>
                            <mat-datepicker #marketingDateStartPicker></mat-datepicker>
                        </div>

                    </div>
                </div>

                <div class="row marges ligne_form" style="margin-bottom: 24px;">
                    <div class="col_4">
                        <div class="form-group">
                            <button class="btn_cta btn_little" type="button" (click)="createDocument()">{{ 'tender.document.attachment.action.value' | translate }}</button>
                        </div>
                    </div>
                </div>

                <div formArrayName="documents" *ngFor="let _ of documentsControl.controls; index as i" class="row marges ligne_form little_margin">

                    <ng-container [formGroupName]="indexAsString(i)">

                        <div class="col_12 col_content_md">

                            <div class="form-group">
                                <form-field-file [config]="documentConfig" [form]="getDocumentControl(i)" [file]="getDocumentControl(i).get('file').value"></form-field-file>
                                <mat-error *ngIf="getDocumentControl(i).get('file').invalid" style="padding: 0">
                                    <div class="invalid-feedback">
                                        <div *ngIf="getDocumentControl(i).get('file').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_content">
                            <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="deleteDocument(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>

                    </ng-container>
                </div>

                <div class="row marges ligne_form">

                    <div class="col_12">
                        <div class="form-group">
                            <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                            <label for="comment">{{ 'tender.offerCreator.comment.value' | translate }}</label>
                            <div class="form-group col_content y_items_center">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: commentMaxLength } }}
                                </span>
                            </div>
                            <mat-error *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : commentMaxLength } }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>

    </ng-container>

</ng-template>

<ng-template #summary>

    <div class="form_part limited">

        <div class="row marges ligne_form">

            <!-- Référence -->

            <div class="col_12" *ngIf="tender.ownerReference">
                <div class="bloc_recap">
                    <div class="ck-content">
                        <p class="titre_h4 highlight">{{ 'reference.value' | translate }}</p>
                        <p>{{ tender.ownerReference }}</p>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="form_part limited">

        <!-- Créateurs d'offre sélectionnés -->

        <div class="bloc_recap lightgreen">

            <div class="ck-content">

                <p class="titre_h3 highlight">{{ 'offerCreator.plural.selected.value' | translate}}</p>

                <ng-container *ngIf="tender.allOfferCreators; else selectedOfferCreatorList">
                    <p>{{ 'tender.offerCreators.all.description.value' | translate }}</p>
                </ng-container>

                <ng-template #selectedOfferCreatorList>

                    <ul >
                        <li *ngFor="let offerCreator of selectedOfferCreators">{{ offerCreator.name }}</li>
                    </ul>

                </ng-template>

                <br>

                <p class="titre_h3 highlight">{{ 'purpose.request.mine.value' | translate}}</p>

                <p>{{ tender.purposeRequest }}</p>
            </div>

        </div>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'generalData.value' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <div class="row marges ligne_form">

                        <!-- Cible de clientèle -->

                        <div class="col_12 col_md_6" *ngIf="tender.configuration.customerTypology">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'customer.target.value' | translate }}</p>
                                    <p>{{ translationService.getFallbackTranslation(tender.configuration.customerTypology.translations).name }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Type de public -->

                        <div class="col_12 col_md_6">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'public.type.value' | translate }}</p>
                                    <p>{{ parsedPublics }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12" [ngClass]="{ 'col_md_6': tender.composition.publics.includes('child') }">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'participant.plural.count.value' | translate }}</p>
                                    <p>{{ parsedParticipants }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col_12 col_md_6" *ngIf="tender.composition.publics.includes('child')">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'child.plural.age.value' | translate }}</p>
                                    <p>{{ 'children.age.interval.value' | translate: { min: tender.composition.minChildrenAge, max: tender.composition.maxChildrenAge } }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'offer.content.value' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form" *ngIf="tender.composition.minDuration && tender.composition.maxDuration">

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'stay.duration.value' | translate }}</p>
                                    <p>{{ 'stay.duration.interval.value' | translate:{ min: translationService.getFallbackTranslation(tender.composition.minDuration.translations).label, max: translationService.getFallbackTranslation(tender.composition.maxDuration.translations).label } }}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div [ngClass]="tender.configuration.destinationsComment ? 'col_6' : 'col_12'">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'destination.plural.value' | translate }}</p>
                                    <p *ngIf="allRegionsSelected; else selectedRegions">{{ 'france.all.value' | translate }}</p>
                                    <ng-template #selectedRegions>
                                        <p>{{ parseAttributes(tender.configuration.regions) }}</p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <div class="col_6" *ngIf="tender.configuration.destinationsComment">

                            <!-- Commentaire destinations -->

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.destination.comment.value' | translate }}</p>
                                    <p>{{ tender.configuration.destinationsComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'stay.plural.type.plural.value' | translate }}</p>
                                    <p>{{ parseAttributes(tender.configuration.types) }}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'offer.list.filter.themes.value' | translate }}</p>
                                    <p>{{ parseAttributes(tender.configuration.themes) }}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.configuration.accommodations.length > 0">

                        <div [ngClass]="tender.configuration.accommodationsComment ? 'col_6' : 'col_12'">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'accommodation.type.plural.value' | translate }}</p>
                                    <p>{{ parseAttributes(tender.configuration.accommodations) }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col_6" *ngIf="tender.configuration.accommodationsComment">

                            <!-- Commentaire hébergement -->

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.accommodation.comment.value' | translate }}</p>
                                    <p>{{ tender.configuration.accommodationsComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div [ngClass]="tender.configuration.restorationsComment ? 'col_6' : 'col_12'">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'restoration.type.plural.value' | translate }}</p>
                                    <p>{{ parseAttributes(tender.configuration.restorations) }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col_6" *ngIf="tender.configuration.restorationsComment">

                            <!-- Commentaire restauration -->

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.restoration.comment.value' | translate }}</p>
                                    <p>{{ tender.configuration.restorationsComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'activity.plural.payable.wish.value' | translate }}</p>
                                    <ng-container *ngIf="tender.configuration.activities.length > 0; else uninformed">
                                        <p>{{ parseAttributes(tender.configuration.activities) }}</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.configuration.locales.length">

                        <!-- Langues de l'offre -->

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'offer.language.plural.value' | translate }}</p>
                                    <p>{{ parsedLocales }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.configuration.contentOfferComment">

                        <!-- Commentaire sur le séjour -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.stay.comment.value' | translate }}</p>
                                    <p>{{ tender.configuration.contentOfferComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'marketing.param.plural.value' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form">

                        <!-- Mode de commercialisation -->

                        <div class="col_md_6">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'marketing.mode.plural.value' | translate }}</p>
                                    <p>{{ parsedMarketingModes }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Devise -->

                        <div class="col_12 col_md_6" *ngIf="tender.pricing.currency">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'currency.value' | translate }}</p>
                                    <p>{{ translationService.getFallbackTranslation(tender.pricing.currency.translations).label }} ({{ tender.pricing.currency.symbol }})</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.pricing.comment">

                        <!-- Commentaire commercialisation -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.marketing.comment.value' | translate }}</p>
                                    <p>{{ tender.pricing.comment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.pricing.priceType">

                        <!-- Type de tarif -->

                        <div class="col_12" [ngClass]="{ 'col_md_4': isOneOfTheseMarketingModes(['giftVoucher']), 'col_md_6': !isOneOfTheseMarketingModes(['giftVoucher']) }">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'price.type.value' | translate }}</p>
                                    <p>{{ ('price.' + tender.pricing.priceType.toLowerCase() + '.value') | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Tarif -->

                        <div class="col_12" *ngIf="isOneOfTheseMarketingModes(['onlineSale', 'booking'])" [ngClass]="{ 'col_md_4': isOneOfTheseMarketingModes(['giftVoucher']), 'col_md_6': !isOneOfTheseMarketingModes(['giftVoucher']) }">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.price.value' | translate }}</p>
                                    <p>{{ parsedPrices }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Tarif bon cadeau -->

                        <div class="col_12 col_md_4" *ngIf="isOneOfTheseMarketingModes(['giftVoucher'])">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'giftVoucher.price.value' | translate }}</p>
                                    <p>{{ parsedGifVoucherPrices }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'stay.interval.plural.value' | translate }}</p>
                                    <ng-container *ngIf="tender.composition.dates.length > 0; else uninformed">
                                        <p *ngFor="let item of tender.composition.dates">
                                            <ng-container *ngIf="item.dateEnd; else noPeriod">
                                                {{ 'date.interval.value' | translate:{ dateStart: moment(item.dateStart).format('L'), dateEnd: moment(item.dateEnd).format('L') } }}
                                            </ng-container>
                                            <ng-template #noPeriod>
                                                {{ moment(item.dateStart).format('L') }}
                                            </ng-template>
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'assurance.value' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form">

                        <!-- Assurance -->

                        <div class="col_12" [ngClass]="{ 'col_md_6': tender.configuration.hasInsurance }">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.insurance.travel.value' | translate }}</p>
                                    <p>{{ (tender.configuration.hasInsurance ? 'yes.value' : 'no.value') | translate }}</p>
                                </div>
                            </div>

                        </div>

                        <!-- Type assurance -->

                        <div class="col_12 col_md_6" *ngIf="tender.configuration.hasInsurance">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'insurance.type.value' | translate }}</p>
                                    <p>{{ tender.configuration.insuranceComment ? tender.configuration.insuranceComment : ('uninformed.value' | translate) }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'tender.mine.param.plural.value' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form">

                        <!-- Date limite de réponse -->

                        <div class="col_12" [ngClass]="{ 'col_md_6': tender.marketingDateStart }">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'response.date.limit.value' | translate }}</p>
                                    <p>{{ moment(tender.closedAt).format('L') }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Date de début de commercialisation de l'offre -->

                        <div class="col_12 col_md_6" *ngIf="tender.marketingDateStart">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.marketing.date.start.value' | translate }}</p>
                                    <p>{{ moment(tender.marketingDateStart).format('L') }}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.documents.length">

                        <!-- Documents à joindre -->

                        <div class="col_12">
                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'document.plural.attachment.value' | translate }}</p>
                                    <p class="cliquable_file" *ngFor="let document of tender.documents" (click)="downloadDocument(document)">
                                        {{ document.file?.name }}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="tender.comment">

                        <!-- Commentaire pour le créateur d'offres -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'tender.offerCreator.comment.value' | translate }}</p>
                                    <p>{{ tender.comment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</ng-template>

<ng-template #confirmationTemplate>

    <div class="page_offer">

        <div class="txt_offer confirmation">

            <p class="title">{{ 'tender.creation.success.title.value' | translate }}</p>

            <p>{{ 'tender.creation.success.description.value' | translate }}</p>

            <p>{{ 'tender.creation.success.redirection.description.value' | translate }}</p>

        </div>

        <div class="col_content x_center">

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                {{ 'tender.list.mine.management.value' | translate }}
            </button>
        </div>

    </div>

</ng-template>

<ng-template #uninformed>
    <p>{{ 'uninformed.value' | translate }}</p>
</ng-template>
