import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SocietyService} from '@core/shared/services/society.service';
import {REGEX_EMAIL, REGEX_WEBSITE} from '@core/shared/models/regex';
import {UserService} from '@core/shared/services/user.service';
import {ApiService} from '@core/shared/services/api.service';
import {FileConfig} from '@lib/form/fields/file/file.component';
import {Observable, of} from 'rxjs';
import {Role} from '@core/shared/models/role';
import {LOCALE_ITEMS, LocaleItem} from '@core/shared/models/translation';
import {FormService} from '@core/shared/services/form.service';
import {Country} from '@core/shared/models/country';
import {CountryService} from '@core/shared/services/country.service';
import {DATE_FORMAT} from '@app/data';
import {Address, AddressType} from '@core/shared/models/address';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Society} from '@core/shared/models/society';
import {User} from '@core/shared/models/user';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LocationField, LocationFieldType} from '@core/shared/models/location';
import {MatDialog} from '@angular/material/dialog';
import {InputDateConfig} from '@lib/form/fields/input-date/input-date.component';
import {FormTabValidationItem, OfferFormTabValidationService} from '@core/shared/services/form/form-tab-validation.service';
import {MatTabGroup} from '@angular/material/tabs';
import {TermsAndCondition, TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {TranslationService} from "@core/shared/services/translation.service";
import {restrictLocales} from "@core/shared/utils/locale";
import * as _moment from 'moment';
import {SOCIETY_TYPE_ITEMS, SocietyTypeItem} from "@core/shared/models/society-type-item";
const moment:any = _moment;

@Component({
    selector: 'app-core-page-user-create',
    templateUrl: './page-user-create.component.html',
    styleUrls: ['./page-user-create.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})

export class PageUserCreateComponent implements OnInit {

    @ViewChild('tabGroup', {static: false}) tabGroup: MatTabGroup;

    @ViewChild('societyInformations', {static: false}) societyInformationsRef: TemplateRef<any>;

    @ViewChild('legalRepresentative', {static: false}) legalRepresentativeRef: TemplateRef<any>;

    @ViewChild('personnalInformations', {static: false}) personnalInformationsRef: TemplateRef<any>;

    public societyLogoConfig: FileConfig;

    public societyAdministrationLogoConfig: FileConfig;

    public role: Role;

    public countries$: Observable<Country[]>;

    public locales$: Observable<LocaleItem[]>;

    public types$: Observable<SocietyTypeItem[]>;

    public differentBillingAddress = false;

    public differentBillingEmail = false;

    public disableAddress = true;

    public disableAddressBilling = true;

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public requiredVatNumberCountryCodes: string[] = [];

    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _userService: UserService,
        private _apiService: ApiService,
        private _countryService: CountryService,
        public formService: FormService,
        public offerFormTabValidationService: OfferFormTabValidationService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._loadCountries();

        this._loadLocales();

        this._loadTypes();

        this._initForm();

        this._initFileConfigs();

        this._initEvents();
    }

    private _loadCountries(): void {

        const params: string[] = [
            `paymentSupported[eq]=1`
        ];

        this.countries$ = this._countryService.getItemsAPI(params).pipe(tap((countries: Country[]): void => {

            this.requiredVatNumberCountryCodes = countries.filter((country: Country) => {

                return country.requireSocietyVatNumber;

            }).map((country: Country) => {

                return country.code;

            });
        }));
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS).pipe(
            map((localeItems: LocaleItem[]): LocaleItem[] => restrictLocales(localeItems))
        );
    }

    private _loadTypes(): void {

        this.types$ = of(SOCIETY_TYPE_ITEMS);
    }

    private _initTabItems(): void {

        this.tabItems = [
            {
                tag: 'societyInformations',
                label: this.societyInformationsLabel,
                template: this.societyInformationsRef
            },
            {
                tag: 'legalRepresentative',
                label: this.legalRepresentativeLabel,
                template: this.legalRepresentativeRef
            },
            {
                tag: 'personnalInformations',
                label: this.personnalInformationsLabel,
                template: this.personnalInformationsRef
            }
        ];
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            society: this._formBuilder.group({
                admin: this._formBuilder.group({
                    address: this._formBuilder.group({
                        address: [''],
                        additionalAddress: [''],
                        zipcode: [''],
                        city: [''],
                        region: [''],
                        country: ['']
                    }),
                    civility: ['', Validators.required],
                    username: [''],
                    lastName: ['', Validators.required],
                    firstName: ['', Validators.required],
                    email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                    switchboardPhone: [null, [Validators.required]],
                    directPhone: [null],
                    cellphone: [null],
                    service: [''],
                    comment: [''],
                    society: [''],
                    societyAdmin: [],
                    roles: [''],
                    locale: [this.localeId, Validators.required],
                    timezone: [null, [Validators.required]]
                }),
                addresses: new UntypedFormArray([]),
                locale: [this.localeId, [Validators.required]],
                name: ['', Validators.required],
                mainWebsite: ['', [Validators.required, Validators.pattern(REGEX_WEBSITE)]],
                identificationNumber: ['', [Validators.required]],
                registration: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                corporateName: ['', Validators.required],
                type: ['', Validators.required],
                capital: [''],
                businessActivityCode: [''],
                rcsRegistration: [''],
                financialGuarantee: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                insurancePolicy: ['', [(control: UntypedFormControl) => {

                    return (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && (!control.value || !control.value.length)) ? {isRequired: {valid: false}} : null;
                }]],
                vatNumber: ['', [(control: AbstractControl): { [key: string]: any } => {

                    if (!this.form) {

                        return null;
                    }

                    const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

                    const countryCode: string = mailingGroup.get('country').value;

                    if (this.requiredVatNumberCountryCodes.includes(countryCode) && !control.value.length) {

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                logo: [null],
                administrationLogo: [null],
                email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                billingEmail: ['', [Validators.pattern(REGEX_EMAIL), (control: UntypedFormControl) => {
                    return (this.differentBillingEmail && !control.value.length) ? {isRequired: {valid: false}} : null;
                }]],
                phone: ['', [Validators.required]],
                termsAndConditions: new UntypedFormArray([], [Validators.nullValidator]),
                hasAccessPublishedOffer: [true],
                hasAccessRestrictedOffer: [false],
                certifyHoldInsuranceAndRegistration: [false],
                legalRepresentative: this._formBuilder.group({
                    civility: ['', Validators.required],
                    firstName: ['', [Validators.required]],
                    lastName: ['', [Validators.required]],
                    birthDay: ['', [Validators.required]],
                    nationality: ['', [Validators.required]],
                    address: ['', [Validators.required]],
                    additionalAddress: [''],
                    zipcode: ['', [Validators.required]],
                    city: ['', [Validators.required]],
                    region: ['', [Validators.required]],
                    country: ['', [Validators.required]],
                    phone: [null]
                }),
                commission: [{}]
            })
        });

        this.societyForm.get('legalRepresentative').get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('legalRepresentative').get('region').markAsTouched();
            this.societyForm.get('legalRepresentative').get('region').updateValueAndValidity();
        });

        this.resetAddresses();

        this.formService.submitCallback = (): void => {

            if (!this.differentBillingEmail) {

                this.societyForm.get('billingEmail').patchValue(this.societyForm.get('email').value);
            }

            this.adminForm.get('address').patchValue((this.societyForm.get('addresses').value as Address[]).find((address: Address): boolean => {

                return address.type === 'mailing';
            }));

            if (this.adminForm.get('roles').value.includes('ROLE_OFFER_DISTRIBUTOR')) {

                this.societyForm.get('certifyHoldInsuranceAndRegistration').patchValue(true);
            }

            const dataSociety = this.societyForm.value;

            dataSociety.termsAndConditions.forEach((termsAndCondition: TermsAndCondition): void => {
                termsAndCondition.translations.forEach((termsAndConditionTranslation: TermsAndConditionTranslation): void => {
                    //@ts-ignore
                    termsAndConditionTranslation.phone = (termsAndConditionTranslation.phone?.e164Number) ? termsAndConditionTranslation.phone?.e164Number : termsAndConditionTranslation.phone;
                });
            });

            dataSociety.phone = (this.societyForm.get('phone').value?.e164Number) ? this.societyForm.get('phone').value?.e164Number : this.societyForm.get('phone').value;

            dataSociety.legalRepresentative.phone = (this.societyLegalRepresentativeControl.get('phone').value?.e164Number) ? this.societyLegalRepresentativeControl.get('phone').value?.e164Number : this.societyLegalRepresentativeControl.get('phone').value;

            this._societyService.createItemAPI(dataSociety).pipe(
                mergeMap((society: Society): Observable<User> => {

                    this.adminForm.patchValue({
                        username: this.adminForm.get('email').value,
                        society: {
                            id: society.id
                        },
                        societyAdmin: {
                            id: society.id
                        },
                        roles: [this.role]
                    });

                    let dataAdmin = this.adminForm.value;
                    dataAdmin.directPhone = (this.adminForm.get('directPhone').value?.e164Number) ? this.adminForm.get('directPhone').value?.e164Number : this.adminForm.get('directPhone').value;
                    dataAdmin.cellphone = (this.adminForm.get('cellphone').value?.e164Number) ? this.adminForm.get('cellphone').value?.e164Number : this.adminForm.get('cellphone').value;
                    dataAdmin.switchboardPhone = (this.adminForm.get('switchboardPhone').value?.e164Number) ? this.adminForm.get('switchboardPhone').value?.e164Number : this.adminForm.get('switchboardPhone').value;


                    return this._userService.createItemAPI(dataAdmin);
                })
            ).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _initEvents(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        const items: { origin: AbstractControl, destination: AbstractControl }[] = [
            {
                origin: mailingGroup.get('address'),
                destination: billingGroup.get('address')
            },
            {
                origin: mailingGroup.get('additionalAddress'),
                destination: billingGroup.get('additionalAddress')
            },
            {
                origin: mailingGroup.get('zipcode'),
                destination: billingGroup.get('zipcode')
            },
            {
                origin: mailingGroup.get('city'),
                destination: billingGroup.get('city')
            },
            {
                origin: mailingGroup.get('region'),
                destination: billingGroup.get('region')
            },
            {
                origin: mailingGroup.get('country'),
                destination: billingGroup.get('country')
            }
        ];

        items.forEach((item: { origin: AbstractControl, destination: AbstractControl }): void => {

            item.origin.valueChanges.subscribe((value: string): void => {

                if (!this.differentBillingAddress) {

                    item.destination.patchValue(value);

                    item.destination.updateValueAndValidity();
                }
            });
        });

        mailingGroup.get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('vatNumber').updateValueAndValidity();

            this.societyForm.get('vatNumber').markAsTouched();

        });
    }

    private _initFileConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg'}).subscribe((help: string): void => {

            this.societyLogoConfig = {
                id: 'logo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help
            };
        });

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.societyAdministrationLogoConfig = {
                id: 'administrationLogo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_administration_logo'
                },
                uploadMaxFileSize: 100000,
                required: false,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: this._translateService.instant('society.administrationLogo.form.image.help.value')
            };
        });
    }

    public handleBillingAddress(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        const childControlsToUpdate: AbstractControl[] = [
            billingGroup.get('address'),
            billingGroup.get('additionalAddress'),
            billingGroup.get('zipcode'),
            billingGroup.get('city'),
            billingGroup.get('region'),
            billingGroup.get('country')
        ];

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.clearValidators();
        });

        if (this.differentBillingAddress) {

            billingGroup.get('address').setValidators([Validators.required]);
            billingGroup.get('zipcode').setValidators([Validators.required]);
            billingGroup.get('city').setValidators([Validators.required]);
            billingGroup.get('region').setValidators([Validators.required]);
            billingGroup.get('country').setValidators([Validators.required]);

            billingGroup.patchValue({
                address: '',
                additionalAddress: '',
                zipcode: '',
                city: '',
                region: '',
                country: ''
            });
        } else {

            billingGroup.patchValue({
                address: mailingGroup.value.address,
                additionalAddress: mailingGroup.value.additionalAddress,
                zipcode: mailingGroup.value.zipcode,
                city: mailingGroup.value.city,
                region: mailingGroup.value.region,
                country: mailingGroup.value.country
            });
        }

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.updateValueAndValidity();

        });
    }

    public resetAddresses(): void {

        this.addressesSocietyForm.clear();

        const addressMailing: UntypedFormGroup = this._formBuilder.group({
            type: ['mailing', [Validators.required]],
            address: ['', [Validators.required]],
            additionalAddress: [''],
            zipcode: ['', [Validators.required]],
            city: ['', [Validators.required]],
            region: ['', [Validators.required]],
            country: ['', [Validators.required]]
        });

        this.addressesSocietyForm.push(addressMailing);

        addressMailing.get('country').valueChanges.subscribe((): void => {

            addressMailing.get('region').markAsTouched();
            addressMailing.get('region').updateValueAndValidity();
        });

        const addressBilling: UntypedFormGroup = this._formBuilder.group({
            type: ['billing'],
            address: [''],
            additionalAddress: [''],
            zipcode: [''],
            city: [''],
            region: [''],
            country: ['']
        });

        this.addressesSocietyForm.push(addressBilling);

        addressBilling.get('country').valueChanges.subscribe((): void => {

            addressBilling.get('region').markAsTouched();
            addressBilling.get('region').updateValueAndValidity();
        });

        this.handleBillingAddress();
    }

    public registrationValidator(): void {
        if (this.role !== 'ROLE_OFFER_DISTRIBUTOR') {
            setTimeout(() => {
                this.societyForm.controls.registration.setErrors(null);
            });
        }

        setTimeout((): void => {

            this._initTabItems();

            this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);
        });
    }

    public selectAddress(index: number): void {

        if ((this.getAddressControl(index).get('type').value as AddressType) === 'mailing') {
            this.disableAddress = false;
        } else {
            this.disableAddressBilling = false;
        }
    }

    public isAddressToDisplay(type: AddressType): boolean {

        switch (type) {

            case 'mailing':

                return true;

            case 'billing':

                return this.differentBillingAddress;

            default:

                return false;
        }
    }

    public getAddressControlByType(type: AddressType): AbstractControl {

        return this.addressesForm.controls.find((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public getAddressControlIndexByType(type: AddressType): number {

        return this.addressesForm.controls.findIndex((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getAddressControl(index: number): AbstractControl {

        return this.addressesSocietyForm.controls[index];
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ];
    }

    public redirectToList(): void {

        this._router.navigate(['account/user/list']);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasRole(role: Role): boolean {

        return this.role === role;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get societyForm(): UntypedFormGroup {

        return this.form.get('society') as UntypedFormGroup;
    }

    get adminForm(): UntypedFormGroup {

        return this.societyForm.get('admin') as UntypedFormGroup;
    }

    get addressesSocietyForm(): UntypedFormArray {

        return this.societyForm.get('addresses') as UntypedFormArray;
    }

    get addressesForm(): UntypedFormArray {

        return this.societyForm.get('addresses') as UntypedFormArray;
    }

    get societyLegalRepresentativeControl(): UntypedFormGroup {

        return this.societyForm.get('legalRepresentative') as UntypedFormGroup;
    }

    get birthDayConfig(): InputDateConfig {

        return {
            id: 'birthDay',
            enableReset: false,
            attrs: {
                required: true,
                placeholder: this._translateService.instant('form.user.fields.birthDay.value'),
                disabled: false,
                max: this.adultAge
            },
            format: DATE_FORMAT
        };
    }

    get adultAge(): string {
        return moment().subtract(18, 'years').format('YYYY-MM-DD');
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items = [];

            items.push({
                tag: 'societyInformations',
                position: 1,
                controls: [
                    this.societyForm.get('name'),
                    this.societyForm.get('corporateName'),
                    this.societyForm.get('type'),
                    this.societyForm.get('mainWebsite'),
                    this.societyForm.get('locale'),
                    this.societyForm.get('registration'),
                    this.societyForm.get('identificationNumber'),
                    this.societyForm.get('vatNumber'),
                    this.societyForm.get('logo'),
                    this.societyForm.get('administrationLogo'),
                    this.societyForm.get('email'),
                    this.societyForm.get('phone'),
                    this.societyForm.get('billingEmail'),
                    this.societyForm.get('termsAndConditions'),
                    this.addressesForm
                ]
            });

            items.push({
                tag: 'legalRepresentative',
                position: 2,
                controls: [
                    this.societyLegalRepresentativeControl
                ]
            });

            items.push({
                tag: 'personnalInformations',
                position: 3,
                controls: [
                    this.adminForm
                ]
            });

            return items;
        };
    }

    get societyInformationsLabel(): string {

        switch (this.role) {

            case 'ROLE_OFFER_DISTRIBUTOR':

                return 'form.user.offerDistributor.title.societyInfo.value';

            case 'ROLE_PROVIDER':

                return 'form.user.provider.title.societyInfo.value';

            default:

                return null;
        }
    }

    get legalRepresentativeLabel(): string {

        switch (this.role) {

            case 'ROLE_OFFER_DISTRIBUTOR':

                return 'society.legalRepresentative.value';

            case 'ROLE_PROVIDER':

                return 'society.legalRepresentative.value';

            default:

                return null;
        }
    }

    get personnalInformationsLabel(): string {

        switch (this.role) {

            case 'ROLE_OFFER_DISTRIBUTOR':

                return 'form.user.offerDistributor.title.contactInfo.value';

            case 'ROLE_PROVIDER':

                return 'form.user.provider.title.contactInfo.value';

            default:

                return null;
        }
    }
}
