import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Society} from "@core/shared/models/society";
import {OfferCreatorPictureGalleryDialogComponent} from "@core/components/offer-creator/offer-creator-picture/offer-creator-picture-gallery/offer-creator-picture-gallery-dialog/offer-creator-picture-gallery-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {SocietyDetailsOfferCountTranslation} from "@core/shared/models/society/society-details/society-details-offer-count-translation";
import {TranslationService} from "@core/shared/services/translation.service";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {FileService} from "@core/shared/services/file.service";
import {FormService} from "@core/shared/services/form.service";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {OfferCreatorSocietyAnnotationService} from "@core/shared/services/offer-creator/offer-creator-society/offer-creator-society-annotation.service";
import {OfferCreatorSocietyAnnotation} from "@core/shared/models/offer-creator/offer-creator-society/offer-creator-society-annotation";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {LocaleItem, TranslationItem} from "@core/shared/models/translation";

@Component({
    selector: 'app-core-offer-creator-item',
    templateUrl: './offer-creator-item.component.html',
    styleUrls: ['./offer-creator-item.component.scss'],
    providers: [
        FormService
    ]
})
export class OfferCreatorItemComponent implements OnInit {

    @Input() item: Society;

    @Output() onLocaleUpdated: EventEmitter<string> = new EventEmitter<string>(null);

    public currentUser: User = null;

    public defaultVisualUrl: string = 'assets/images/offer/preview/1.jpg';

    public countDefaultDisplayedPictures: number = 5;

    public marketplaceOfferCountTranslations: SocietyDetailsOfferCountTranslation[] = [];

    public catalogOfferCountTranslations: SocietyDetailsOfferCountTranslation[] = [];

    public offerTypes: OfferDurationType[] = [];

    public societyAnnotation: OfferCreatorSocietyAnnotation;

    public locale: string;

    public locales: LocaleItem[] = [];

    constructor(
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _fileService: FileService,
        private _translateService: TranslateService,
        private _offerCreatorSocietyAnnotationService: OfferCreatorSocietyAnnotationService,
        public formService: FormService,
        public translationService: TranslationService,
        public userService: UserService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this.locale = this._translateService.currentLang;

        this._initForm();

        this._initLocales();

        this._initMarketplaceOfferCountTranslations();

        this._initCatalogOfferCountTranslations();

        this._initOfferTypes();

        if(!this.isMine && this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            this._loadSocietyAnnotation();
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({});

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            this.form.addControl('comment', this._formBuilder.control(''));
        }

        this.formService.submitCallback = (): void => {

            const observable: Observable<OfferCreatorSocietyAnnotation> = ('id' in this.societyAnnotation)
                ? this._offerCreatorSocietyAnnotationService.updateItemAPI(this.item.id, this.form.value)
                : this._offerCreatorSocietyAnnotationService.createItemAPI(this.item.id, this.form.value);

            observable.subscribe((): void => {

                this._snackBar.open(this._translateService.instant('offer.form.action.modified.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 20000
                });

                this._router.navigate(['account/offer-creator/list']);
            });
        };
    }

    private _initMarketplaceOfferCountTranslations(): void {

        this.marketplaceOfferCountTranslations = this.item.details.offerCountTranslations.filter((item: SocietyDetailsOfferCountTranslation): boolean => {

            return item.marketplace > 0;
        });
    }

    private _initCatalogOfferCountTranslations(): void {

        this.catalogOfferCountTranslations = this.item.details.offerCountTranslations.filter((item: SocietyDetailsOfferCountTranslation): boolean => {

            return item.catalog > 0;
        });
    }

    private _initOfferTypes(): void {

        this.offerTypes = this.item.details.durationTypes;
    }

    private _loadSocietyAnnotation(): void {

        this._offerCreatorSocietyAnnotationService.getItemAPI(this.item.id).subscribe((societyAnnotation: OfferCreatorSocietyAnnotation): void => {

            this.societyAnnotation = societyAnnotation;

            this.form.patchValue(this.societyAnnotation);
        });
    }

    private _initLocales(): void {

        this.locales = [
            {
                id: 'fr',
                label: 'locale.fr.value'
            },
            {
                id: 'en',
                label: 'locale.en.value'
            }
        ];
    }

    public openPictureGalleryDialog(): void {

        this._dialog.open(OfferCreatorPictureGalleryDialogComponent, {
            width: '500px',
            data: {
                offerCreator: this.item
            },
            panelClass: 'modal_gallery'
        });
    }

    public downloadTermsAndCondition(termsAndConditionTranslation: TermsAndConditionTranslation): void {

        this._fileService.openFileInNewTab(termsAndConditionTranslation.file.id);
    }

    public redirectToMarketplace(): void {

        this._router.navigate(['account/offer/list/reservoir'], { queryParams: {
            filters: `society.id[in][]=${ this.item.id }`,
            origin: 'offerCreatorRead',
            societyId: this.item.id
        }});
    }

    public redirectToMarketplaceByLocale(locale: string): void {

        this._router.navigate(['account/offer/list/reservoir'], { queryParams: {
            filters: `society.id[in][]=${ this.item.id }|locales[lkin][]=${locale}`,
            origin: 'offerCreatorRead',
            societyId: this.item.id
        }});
    }

    public redirectToCatalog(): void {

        this._router.navigate(['account/offer/list/catalog'], { queryParams: {
            filters: `society.id[in][]=${ this.item.id }`,
            origin: 'offerCreatorRead',
            societyId: this.item.id
        }});
    }

    public redirectToCatalogByLocale(locale: string): void {

        this._router.navigate(['account/offer/list/catalog'], { queryParams: {
            filters: `society.id[in][]=${ this.item.id }|locales[lkin][]=${locale}`,
            origin: 'offerCreatorRead',
            societyId: this.item.id
        }});
    }

    public getTranslation<T extends TranslationItem>(translations: Array<T>): T {

        return translations.find((translation: T): boolean => {

            return translation.locale === this.locale;

        }) || translations[0];
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get isMine(): boolean {

        if(!this.currentUser.society){

            return false;
        }

        return this.item.id === this.currentUser.society.id;
    }

    get hasAccessIntlView(): boolean {

        return this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) || this.isMine;
    }
}
