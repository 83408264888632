import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge, Observable} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from "@core/shared/models/pagination";
import {SocietyDistributor} from "@core/shared/models/society/society-distributor";
import {SocietyDistributorService} from "@core/shared/services/society/society-distributor.service";
import {Society} from "@core/shared/models/society";
import {Address} from "@core/shared/models/address";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {Access} from "@core/shared/models/access";
import {User} from "@core/shared/models/user";
import {UserService} from "@core/shared/services/user.service";
import {Role} from "@core/shared/models/role";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {SocietyCommissionType} from "@core/shared/models/society-commission";

@Component({
    selector: 'app-core-page-society-distributor-list',
    templateUrl: './page-society-distributor-list.component.html',
    styleUrls: ['./page-society-distributor-list.component.scss']
})
export class PageSocietyDistributorListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public society: Society;

    public totalItems: number = 0;

    public user: User;

    public displayedColumns: string[] = [
        'actions',
        'distributor.name',
        'distributor.admin.roles',
        'distributor.admin.firstName',
        'distributor.admin.lastName',
        'distributor.website',
        'distributor.addresses.city',
        'distributor.commission.type',
        'distributor.commission.incomingPayment',
        'automaticCatalogOffersAcceptance',
        'totalMarketplaceOffers'
    ];

    public filterBuilder: FilterBuilder;

    public items: SocietyDistributor[] = [];

    public filterRoles: {identifier: Role[], label: string}[];

    public filterCommissionTypes: {identifier: SocietyCommissionType, label: string}[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _societyDistributorService: SocietyDistributorService,
        private _snackBar: MatSnackBar,
        private _userService: UserService
    ) {
    }

    ngOnInit() {

        this.user = this._userService.currentUser.value;

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.society = data.society;

            this._initFilterBuilder();

            this._loadFilterRoles();

            this._loadFilterCommissionTypes();
        });
    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._societyDistributorService.getPaginationItemsAPI(this.society.id, this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: SocietyDistributor[]): void => {

                this.items = data;

            }
        );
    }

    private _loadFilterRoles(): void {

        this.filterRoles = [
            {
                identifier: ['ROLE_OFFER_DISTRIBUTOR'],
                label: this._translateService.instant('distributor.roles.travelAgency.value')
            },
            {
                identifier: ['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER'],
                label: this._translateService.instant('distributor.roles.businessProvider.value')
            }
        ];
    }

    private _loadFilterCommissionTypes(): void {

        this.filterCommissionTypes = [
            {
                identifier: 'standard',
                label: this._translateService.instant('distributor.commissionType.standard.value')
            },
            {
                identifier: 'custom',
                label: this._translateService.instant('distributor.commissionType.custom.value')
            }
        ];
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._societyDistributorService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyDistributor[]): void => {

                this.items = data;
            });
        };
    }

    private _rewriteRoleFilters(params: string[]): string[] {

        const identifier: string = 'distributor.admin.roles[andlkin][]';

        const roleParams: string[] = [...params].filter((param: string): boolean => {

            return param.includes(identifier);
        });

        params = params.filter((param: string): boolean => {

            return !param.includes(identifier);
        });

        roleParams.forEach((roleParam: string): void => {

            const split: string[] = roleParam.split('=');

            const roles: string[] = split[1].split(',');

            roles.forEach((role: string): void => {

                params.push(`${identifier}=${role}`);
            });
        });

        return params;
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._societyDistributorService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyDistributor[]): void => {

            this.items = data;
        });
    }

    public getModel(item: SocietyDistributor): SocietyDistributor {

        return item;
    }

    public editItem(item: SocietyDistributor): void{

        this._router.navigate(['account/society/distributor/update', item.id]);
    }

    public openDeleteItemDialog(item: SocietyDistributor): void {

        const title : string = this._translateService.instant('society.distributor.delete.value');

        const content : string = this._translateService.instant('society.distributor.delete.description.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyDistributorService.deleteItemAPI(item.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('society.distributor.delete.confirm.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._societyDistributorService.getPaginationItemsAPI(this.society.id, this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: SocietyDistributor[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public getAddressFields(item: SocietyDistributor): Address {

        return item.distributor.addresses.find((address: Address): boolean => {

            return address.type === 'mailing';

        }) || item.distributor.addresses[0];
    }

    public hasAccessEdit(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'SOCIETY_DISTRIBUTOR_EDIT_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessDelete(): boolean{
        var accesses = this.user.accesses.filter((access : Access) => {
            return access.tag == 'SOCIETY_DISTRIBUTOR_DELETE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public itemHasRole(role: Role, source: Role[]): boolean {

        return source.includes(role);
    }

    public itemHasOneOfThisRoles(roles: Role[], source: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.itemHasRole(role, source);
        });
    }

    public getRoleLabel(roles: Role[]): string {

        switch (true) {

            case this.itemHasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR'], roles):

                return this._translateService.instant('distributor.roles.travelAgency.value');

            case this.itemHasOneOfThisRoles(['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER'], roles):

                return this._translateService.instant('distributor.roles.businessProvider.value');

            default:

                return null;
        }
    }

    public getCommissionTypeLabel(distributor: SocietyDistributor): string {

        if(!distributor.distributor.commission) {

            return 'N/A';
        }

        return this._translateService.instant(`distributor.commissionType.${distributor.distributor.commission.type}.value`);
    }

    public getIncomingPaymentLabel(distributor: SocietyDistributor): string {

        if(!distributor.distributor.commission) {

            return 'N/A';
        }

        return this._translateService.instant(`${distributor.distributor.commission.incomingPayment ? 'yes' : 'no'}.value`);
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    get itemsApiParams(): string[] {

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`
        ];


        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }else{
            params.push(`sort[createdAt]=DESC`);
        }

        if(this.filterBuilder.hasFilters){

            let explodeParams = this.filterBuilder.serializedRequest.split('&');

            // Traitement des rôles

            explodeParams = this._rewriteRoleFilters(explodeParams);

            params.push(explodeParams.filter((param: string): boolean => {

                return param.length > 0;

            }).join('&'));

            if(this.filterBuilder.getFieldByKey('distributor.websites.website').value) {

                const mainWebsiteFilter: TextFilterField = new TextFilterField('distributor.websites.isMain', 'eq', 1);

                params.push(mainWebsiteFilter.serialize);
            }
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<SocietyDistributor>) => SocietyDistributor[] {

        return (data: Pagination<SocietyDistributor>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }
}
