import {AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {User} from '@core/shared/models/user';
import {UserService} from '@core/shared/services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {FormService} from '@core/shared/services/form.service';
import {Access} from "@core/shared/models/access";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {isRequired} from "@core/shared/models/society/required-region-country";
import {BehaviorSubject, Observable} from "rxjs";
import {Country} from "@core/shared/models/country";
import {tap} from "rxjs/operators";
import {CountryService} from "@core/shared/services/country.service";
import {TranslationService} from "@core/shared/services/translation.service";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {InputDateConfig} from "@lib/form/fields/input-date/input-date.component";
import {DATE_FORMAT} from "@app/data";
import {ServiceService} from "@core/shared/services/service.service";
import {BankAccount} from "@core/shared/models/bankAccount";
import {ApiService} from "@core/shared/services/api.service";
import {Kyc} from "@core/shared/models/Kyc";
import {Ubos} from "@core/shared/models/Ubo";
import {FormErrorService} from "@core/shared/services/form.error.service";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {SocietyDocumentType} from "@core/shared/models/society";
import {SocietyType} from "@core/shared/models/society-type-item";

@Component({
    selector: 'app-page-service-update',
    templateUrl: './page-service-list.component.html',
    styleUrls: ['./page-service-list.component.scss'],
    providers: [
        FormService
    ]
})
export class PageServiceListComponent implements OnInit, AfterViewInit, AfterContentInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('documentTab', {static: false}) documentTabRef: MatTab;

    public user: BehaviorSubject<User> = new BehaviorSubject(null);

    public selectedIndex: number;

    public countries$: Observable<Country[]>;

    public requiredVatNumberCountryCodes: string[] = [];

    public requiredDocuments: SocietyDocumentType[] = [];

    public bankAccountType: string[] = ['IBAN', 'CA', 'US', 'GB', 'OTHER'];

    public fileToUpload: File | null = null;

    public fileKbisToUpload: File | null = null;

    public fileStatusToUpload: File | null = null;

    public fileShareholderToUpload: File | null = null;

    public statusIdCard: string = "waiting";

    public errorMessageIdCard: string;

    public errorStatusIdCard: string;

    public statusKbis: string = "waiting";

    public errorMessageKbis: string;

    public errorStatusKbis: string;

    public statusStatus: string = "waiting";

    public errorMessageStatus: string;

    public errorStatusStatus: string;

    public statusShareholder: string = "waiting";

    public errorMessageShareholder: string;

    public errorStatusShareholder: string;

    public statusUbo: string = "waiting";

    public errorStatusUbo: string;

    public errorMessageUbo: string;

    public maxUboCount: number = 4;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _countryService: CountryService,
        private _userService: UserService,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public formService: FormService,
        private _formBuilder: UntypedFormBuilder,
        public translationService: TranslationService,
        public _serviceService: ServiceService,
        private _apiService: ApiService,
        private _formErrorService: FormErrorService
    ) {}

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { user: User }): void => {

            this.user.next(data.user);

            if(this.isAccountAdmin()) {

                this._loadRequiredDocuments();
            }

            this._loadCountries();

            this._initForm();

            this._loadServices();

            this.form.patchValue(data.user);

            this.societyLegalRepresentativeControl.disable();
        });
    }

    ngAfterViewInit() {

        if('targetTab' in this._activatedRoute.snapshot.queryParams){

            switch (this._activatedRoute.snapshot.queryParams['targetTab']){

                case 'document':

                    this.tabGroup.selectedIndex = this.documentTabRef.position;

                    break;
            }
        }

    }

    ngAfterContentInit() {

        this._changeDetectorRef.detectChanges();
    }

    private _loadRequiredDocuments(): void {

        this.requiredDocuments = this.user.value.society.requiredMangoPayDocuments;
    }

    private _loadCountries(): void {

        this.countries$ = this._countryService.getItemsAPI().pipe(tap((countries: Country[]): void => {

            this.requiredVatNumberCountryCodes = countries.filter((country: Country): boolean => {

                return country.requireSocietyVatNumber;

            }).map((country: Country): string => {

                return country.code;
            });
        }));
    }

    private _loadServices(): void {

        if(this.isRequiredDocument('bank-account')) {

            this._serviceService.getBankAccountItemAPI(this.user.value.society.id).subscribe((data: BankAccount): void => {

                if(data.details){

                    switch (data.details.type) {

                        case 'IBAN':

                            this.bankAccountDetailsIbanControl.patchValue(data.details);
                            break;
                        case 'CA':

                            this.bankAccountDetailsCaControl.patchValue(data.details);
                            break;
                        case 'US':

                            this.bankAccountDetailsUsControl.patchValue(data.details);
                            break;
                        case 'GB':

                            this.bankAccountDetailsGbControl.patchValue(data.details);
                            break;
                        case 'OTHER':

                            this.bankAccountDetailsOtherControl.patchValue(data.details);
                            break;
                    }

                    this.bankAccountControl.patchValue(data);
                }
            });
        }

        if(this.isRequiredDocument('identity')) {

            this._serviceService.getKycItemAPI(this.user.value.society.id, 'identity').subscribe((data: Kyc): void => {

                this.statusIdCard = data.status;

                this.errorMessageIdCard = this.isRefused(data.status) ? data.errorMessage : null;

                this.errorStatusIdCard = this.isRefused(data.status) ? data.errorStatus : null;

            });
        }

        if(this.isRequiredDocument('registration')) {

            this._serviceService.getKycItemAPI(this.user.value.society.id, 'registration').subscribe((data: Kyc): void => {

                this.statusKbis = data.status;

                this.errorMessageKbis = this.isRefused(data.status) ? data.errorMessage : null;

                this.errorStatusKbis = this.isRefused(data.status) ? data.errorStatus : null;

            });
        }

        if(this.isRequiredDocument('association')) {

            this._serviceService.getKycItemAPI(this.user.value.society.id, 'association').subscribe((data: Kyc): void => {

                this.statusStatus = data.status;

                this.errorMessageStatus = this.isRefused(data.status) ? data.errorMessage : null;

                this.errorStatusStatus = this.isRefused(data.status) ? data.errorStatus : null;

            });
        }

        if(this.isRequiredDocument('shareholder')) {

            this._serviceService.getKycItemAPI(this.user.value.society.id, 'shareholder').subscribe((data: Kyc): void => {

                this.statusShareholder = data.status;

                this.errorMessageShareholder = this.isRefused(data.status) ? data.errorMessage : null;

                this.errorStatusShareholder = this.isRefused(data.status) ? data.errorStatus : null;

            });
        }

        if(this.isRequiredDocument('ubo-declaration')) {

            this._serviceService.getUboItemAPI(this.user.value.society.id).subscribe((data: Ubos): void => {

                this.statusUbo = data.status;

                this.errorStatusUbo = data.errorStatus;

                this.errorMessageUbo = data.errorMessage;

                data.ubos.forEach((): void => {

                    this.addUbo(this.uboControls);
                });

                this.uboControls.patchValue(data.ubos);
            });
        }
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            society: this._formBuilder.group({
                legalRepresentative: this._formBuilder.group({
                    id: [null],
                    civility: [null, [Validators.required]],
                    firstName: ['', [Validators.required]],
                    lastName: ['', [Validators.required]],
                    birthDay: ['', [Validators.required]],
                    nationality: ['', [Validators.required]],
                    phone: [{ value: '', disabled: true }],
                    address: ['', [Validators.required]],
                    additionalAddress: [''],
                    zipcode: ['', [Validators.required]],
                    city: ['', [Validators.required]],
                    region: ['', [(control: UntypedFormControl) => {

                        if (!control.parent || !control.parent.get('country').value.length)
                            return;

                        const country: string = control.parent.get('country').value;

                        return (!control.value || !control.value.length) && isRequired(country) ? {isRequired: {valid: false}} : null;
                    }]],
                    country: ['', [Validators.required]]
                }),
                bankAccount: this._formBuilder.group({

                    ownerName: ['', [Validators.required]],
                    address: this._formBuilder.group({
                        address: ['', [Validators.required]],
                        additionalAddress: [''],
                        city: ['', [Validators.required]],
                        region: ['', [Validators.required]],
                        zipcode: ['', [Validators.required]],
                        country: ['', [Validators.required]],
                    }),
                    details: this._formBuilder.group({
                        type: ['', [Validators.required]],
                        iban: this._formBuilder.group({
                            iban: [''],
                            bic: ['']
                        }),
                        ca: this._formBuilder.group({
                            bankName: [''],
                            institutionNumber: [''],
                            branchCode: [''],
                            accountNumber: ['']
                        }),
                        gb: this._formBuilder.group({
                            accountNumber: [''],
                            sortCode: [''],
                        }),
                        us: this._formBuilder.group({
                            accountNumber: [''],
                            aba: ['']
                        }),
                        other: this._formBuilder.group({
                            accountNumber: [''],
                            bic: [''],
                            country: ['']
                        })
                    })
                }),
                kyc: this._formBuilder.group({
                    'idCard': this._formBuilder.group({
                        'file': ['', [Validators.required]],
                        'type': ['identity']
                    }),
                    'kbis': this._formBuilder.group({
                        'file': ['', [Validators.required]],
                        'type': ['registration']
                    }),
                    'status': this._formBuilder.group({
                        'file': ['', [Validators.required]],
                        'type': ['association']
                    }),
                    'shareholder': this._formBuilder.group({
                        'file': ['', [Validators.required]],
                        'type': ['shareholder']
                    })
                }),
                ubo: new UntypedFormArray([], [Validators.nullValidator])
            })
        });

    }

    private __addBankDetailsValidators(form: UntypedFormGroup, country: string): void {

        const validationBankType = {
            iban: {
                'iban': [],
                'bic': []
            },
            ca: {
                'bankName': [],
                'institutionNumber': [Validators.pattern(/^[0-9]{3}$/)],
                'branchCode': [Validators.pattern(/^[0-9]{5}$/)],
                'accountNumber': [Validators.pattern(/^[0-9]{7,35}$/)]
            },
            gb: {
                'accountNumber': [],
                'sortCode': [],
            },
            us: {
                'accountNumber': [],
                'aba': [Validators.pattern(/^[0-9]{9}$/)],
            },
            other: {
                'accountNumber':[],
                'bic': [],
                'country': [],
            }

        }

        for (const key in form.controls) {

            form.get(key).setValidators([...validationBankType[country][key], (control: UntypedFormControl) => {

                return (this.bankAccountDetailsControl.get('type').value == country.toUpperCase() && !control.value) ? {isRequired: {valid: false}} : null;
            }]);

            form.get(key).updateValueAndValidity();
        }

    }

    private _removeBankDetailsValidators(form: UntypedFormGroup): void {

        for (const key in form.controls) {

            const controls = (form.controls[key] as UntypedFormGroup);

            if (key !== 'type') {

                for (const control in controls.controls) {

                    controls.get(control).clearValidators();
                    controls.get(control).updateValueAndValidity();
                }

            }
        }
    }

    public handleFileInput(files: FileList): void {

        this.fileToUpload = files.item(0);
    }

    public handleFileKbisInput(files: FileList): void {

        this.fileKbisToUpload = files.item(0);
    }

    public handleFileStatusInput(files: FileList): void {

        this.fileStatusToUpload = files.item(0);
    }

    public handleFileShareholderInput(files: FileList): void {

        this.fileShareholderToUpload = files.item(0);
    }

    public accountSubmit(): void {

        this.formService.showValidationMessages(this.bankAccountControl);

        if (!this.bankAccountControl.invalid) {

            let data = this.bankAccountControl.value;

            switch (this.bankAccountDetailsControl.get('type').value) {

                case 'IBAN':
                    data.details = Object.assign(data.details, data.details.iban);
                    break;
                case 'CA':
                    data.details = Object.assign(data.details, data.details.ca);
                    break;
                case 'US':
                    data.details = Object.assign(data.details, data.details.us);
                    break;
                case 'GB':
                    data.details = Object.assign(data.details, data.details.gb);
                    break;
                case 'OTHER':
                    data.details = Object.assign(data.details, data.details.other);
                    break;
            }

            this._serviceService.createBankAccountItemAPI(data, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.bank_account.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadServices();

            });

        } else{

            this._formErrorService.handleErrors(this.bankAccountControl);
        }
    }

    public submitIdCard(): void {

        let data = this.kycIdCardControl.value;

        this.formService.showValidationMessages(this.kycIdCardControl);

        if (!this.kycIdCardControl.invalid) {

            this._serviceService.createKycItemAPI(data, this.fileToUpload, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.kyc.idCard.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadServices();
            });
        }
    }

    public submitKbis(): void {

        let data = this.kycKbisControl.value;

        this.formService.showValidationMessages(this.kycKbisControl);

        if (!this.kycKbisControl.invalid) {

            this._serviceService.createKycItemAPI(data, this.fileKbisToUpload, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.kyc.kbis.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

              this._loadServices();
            });
        }
    }

    public submitStatus(): void {

        let data = this.kycStatusControl.value;

        this.formService.showValidationMessages(this.kycStatusControl);

        if (!this.kycStatusControl.invalid) {

            this._serviceService.createKycItemAPI(data, this.fileStatusToUpload, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.kyc.status.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

              this._loadServices();
            });
        }
    }

    public submitShareholder(): void {

        let data = this.kycShareholderControl.value;

        this.formService.showValidationMessages(this.kycShareholderControl);

        if (!this.kycShareholderControl.invalid) {

            this._serviceService.createKycItemAPI(data, this.fileShareholderToUpload, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.kyc.shareholder.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadServices();
            });
        }
    }

    public submitUbo(): void {

        let data = this.uboControls.value;

        this.uboControls.controls.forEach((formGroup: AbstractControl) => {
            this.formService.showValidationMessages(formGroup as UntypedFormGroup);
        });

        if (!this.uboControls.invalid) {

            this.statusUbo = 'waiting';

            this.errorStatusUbo = null;

            this.errorMessageUbo = null;

            this._serviceService.createUboItemAPI(data, this.user.value.society.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('services.form.file.ubo.add.confirmation.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadServices();
            });
        }
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public hasAccessListFile() {

        const accesses = this.user.value.accesses.filter((access: Access) => {

            return access.tag === 'SOCIETY_LEGAL_DOCUMENT_READ_IS_MINE';
        });

        return this.isAccountAdmin() && (accesses.length > 0);
    }

    public changeType(): void {

        switch (this.bankAccountDetailsControl.get('type').value) {

            case 'IBAN':

                this._removeBankDetailsValidators(this.bankAccountDetailsControl);
                this.__addBankDetailsValidators(this.bankAccountDetailsIbanControl, 'iban');

                break;
            case 'CA':

                this._removeBankDetailsValidators(this.bankAccountDetailsControl);
                this.__addBankDetailsValidators(this.bankAccountDetailsCaControl, 'ca');

                break;
            case 'US':

                this._removeBankDetailsValidators(this.bankAccountDetailsControl);
                this.__addBankDetailsValidators(this.bankAccountDetailsUsControl, 'us');

                break;
            case 'GB':

                this._removeBankDetailsValidators(this.bankAccountDetailsControl);
                this.__addBankDetailsValidators(this.bankAccountDetailsGbControl, 'gb');
                break;
            case 'OTHER':

                this._removeBankDetailsValidators(this.bankAccountDetailsControl);
                this.__addBankDetailsValidators(this.bankAccountDetailsOtherControl, 'other');
                break;
        }
    }

    public addUbo(control: AbstractControl): void {

        if((control as UntypedFormArray).controls.length >= this.maxUboCount){

            return;
        }

        (control as UntypedFormArray).push(
            this._formBuilder.group({
                firstName: ['', [Validators.required]],
                lastName: ['', [Validators.required]],
                nationality: ['', [Validators.required]],
                birthday: ['', [Validators.required]],
                birthPlace: this._formBuilder.group({
                    city: ['', [Validators.required]],
                    country: ['', [Validators.required]],
                }),
                address: this._formBuilder.group({
                    address: ['', [Validators.required]],
                    additionalAddress: [''],
                    city: ['', [Validators.required]],
                    zipcode: ['', [Validators.required]],
                    region: ['', [Validators.required]],
                    country: ['', [Validators.required]],
                })
            })
        );
    }

    public removeUbo(index): void {

        this.uboControls.removeAt(index);
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ];
    }

    public isRefused(status: string): boolean {

        return status === 'refused';
    }

    public isRequiredDocument(type: SocietyDocumentType): boolean {

        return this.requiredDocuments.some((item: SocietyDocumentType): boolean => {

            return type === item;
        });
    }

    public isAccountAdmin(): boolean {

        return !!this.user.value.societyAdmin;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get societyForm(): UntypedFormGroup {

        return this.form.get('society') as UntypedFormGroup;
    }

    get societyLegalRepresentativeControl(): UntypedFormGroup {

        return this.societyForm.get('legalRepresentative') as UntypedFormGroup;
    }

    get bankAccountControl(): UntypedFormGroup {

        return this.societyForm.get('bankAccount') as UntypedFormGroup;
    }

    get bankAccountDetailsControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details') as UntypedFormGroup;
    }

    get bankAccountDetailsIbanControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details').get('iban') as UntypedFormGroup;
    }

    get bankAccountDetailsCaControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details').get('ca') as UntypedFormGroup;
    }

    get bankAccountDetailsGbControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details').get('gb') as UntypedFormGroup;
    }

    get bankAccountDetailsUsControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details').get('us') as UntypedFormGroup;
    }

    get bankAccountDetailsOtherControl(): UntypedFormGroup {

        return this.bankAccountControl.get('details').get('other') as UntypedFormGroup;
    }

    get bankAccountAddressControl(): UntypedFormGroup {

        return this.bankAccountControl.get('address') as UntypedFormGroup;
    }

    get kycControl(): UntypedFormGroup {

        return this.societyForm.get('kyc') as UntypedFormGroup;
    }

    get kycIdCardControl(): UntypedFormGroup {

        return this.kycControl.get('idCard') as UntypedFormGroup;
    }

    get kycKbisControl(): UntypedFormGroup {

        return this.kycControl.get('kbis') as UntypedFormGroup;
    }

    get kycStatusControl(): UntypedFormGroup {

        return this.kycControl.get('status') as UntypedFormGroup;
    }

    get kycShareholderControl(): UntypedFormGroup {

        return this.kycControl.get('shareholder') as UntypedFormGroup;
    }

    get uboControls(): UntypedFormArray {

        return this.societyForm.get('ubo') as UntypedFormArray;
    }

    get societyType(): SocietyType {

        return this.user.value.society.type;
    }

    get birthDayConfig(): InputDateConfig {

        return {
            id: 'birthDay',
            enableReset: false,
            attrs: {
                required: true,
                placeholder: this._translateService.instant('form.user.fields.birthDay.value'),
                disabled: true
            },
            format: DATE_FORMAT
        };
    }

    get birthDayUboConfig(): InputDateConfig {

        return {
            id: 'birthday',
            enableReset: false,
            attrs: {
                required: true,
                placeholder: this._translateService.instant('form.user.fields.birthDay.value'),
                disabled: false
            },
            format: DATE_FORMAT
        };
    }
}
