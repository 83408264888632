import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as ClassicEditor from "@lib/ckeditor";
import {FormService} from "@core/shared/services/form.service";
import {ApiService} from "@core/shared/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {LOCALE_ITEMS_USER, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {Society} from "@core/shared/models/society";
import {FieldCollection} from "@lib/form/field";
import {PromotionSubscriptionService} from "@core/shared/services/promotion-subscription.service";
import {Subscription} from "@core/shared/models/subscription";
import {Observable, of} from "rxjs";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {TranslationService} from "@core/shared/services/translation.service";
import {Moment} from "moment/moment";
import {DATE_FORMAT} from "@app/data";

@Component({
    selector: 'app-page-promotion-subscription-create',
    templateUrl: './page-promotion-subscription-create.component.html',
    styleUrls: ['./page-promotion-subscription-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PagePromotionSubscriptionCreateComponent implements OnInit, AfterContentChecked {

    public editor = ClassicEditor;

    public translationBuilder: TranslationBuilder;

    public societies: Object[] = [];

    public pages: Object[] = [];

    public roles: string[] = [];

    public fieldCollection = new FieldCollection();

    public availableSubscriptions: Subscription[] = [];

    public locales$: Observable<LocaleItem[]>;

    public durationOptions: { id: number, name: string }[] = [];

    public disabled: boolean = false;

    public typeDiscountChoices =  [
        {
            id : 'percent',
            name:  '%'
        },
        {    id : 'amount',
            name : '€'
        }
    ];

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar,
        private _apiService: ApiService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private cdref:ChangeDetectorRef,
        public formService: FormService,
        public translationService: TranslationService,
        public promotionSubscriptionService: PromotionSubscriptionService
    ) {}

    public ngOnInit(): void {

        if(this._activatedRoute.snapshot.params['id']) {

            this.disabled = true;
        }

        this.initForm();

        this.form.valueChanges.subscribe((value): void => {

            if (value.admissionPriceFree) {

                if (this.form.get('admissionPriceDiscount').value) {

                    this.form.get('admissionPriceDiscount').patchValue(null);
                }

                if (this.form.get('admissionPriceTypeDiscount').value) {

                    this.form.get('admissionPriceTypeDiscount').patchValue(null);
                }
            }

            if (value.subscriptionFree) {

                if (this.form.get('subscriptionPriceDiscount').value) {

                    this.form.get('subscriptionPriceDiscount').patchValue(null);
                }

                if (this.form.get('subscriptionPriceTypeDiscount').value) {

                    this.form.get('subscriptionPriceTypeDiscount').patchValue(null);
                }
            }

            if (value.typeUser === 'society') {

                if (this.customerForm.controls[0].get('firstName').value) {

                    this.customerForm.controls[0].get('firstName').patchValue(null);
                }

                if (this.customerForm.controls[0].get('lastName').value) {

                    this.customerForm.controls[0].get('lastName').patchValue(null);
                }

                if (this.customerForm.controls[0].get('email').value) {

                    this.customerForm.controls[0].get('email').patchValue(null);
                }

                if (this.customerForm.controls[0].get('code').value) {

                    this.customerForm.controls[0].get('code').patchValue(null);
                }
                if (this.customerForm.controls[0].get('societyName').value) {

                    this.customerForm.controls[0].get('societyName').patchValue(null);
                }
            }

            if (value.typeUser === 'new') {

                if (this.form.get('society').value) {

                    this.form.get('society').patchValue(null);
                }
            }
        });

        this.resetDuration('M');

        this._loadLocales();

        this._activatedRoute.data.subscribe((data: { societies: Society[], subscriptions: Subscription[] }): void => {

            data.societies.forEach((society: Society) => {
                this.societies.push(
                    {
                        id: society.id,
                        name: society.name
                    });
            });

            this.availableSubscriptions = data.subscriptions;

        });
    }

    public ngAfterContentChecked():void {

        this.cdref.detectChanges();
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS_USER);
    }

    private _initEvents(): void {

        this.form.get('subscriptionFree').valueChanges.subscribe((): void => {

            this._handlePeriodicity();

            this._handleSubscription();

            this._handleSubscriptionDurationDiscount();
        });

        this.form.get('subscriptionPriceDiscount').valueChanges.subscribe((): void => {

            this._handlePeriodicity();

            this._handleSubscription();

            this._handleSubscriptionDurationDiscount();
        });

        this.form.get('typeUser').valueChanges.subscribe((): void => {

            this.customerForm.controls[0].get('dateStart').updateValueAndValidity();

            this.customerForm.controls[0].get('dateEnd').updateValueAndValidity();
        });
    }

    private _handlePeriodicity(): void {

        const control: AbstractControl = this.form.get('periodicity');

        control.patchValue(null);

        control.clearValidators();

        const validators: ValidatorFn[] = [];

        if(this.form.get('subscriptionFree').value || this.form.get('subscriptionPriceDiscount').value){

            validators.push(Validators.required);

            control.enable();
        }
        else{

            control.disable();
        }

        control.setValidators(validators);

        control.updateValueAndValidity();
    }

    private _handleSubscription(): void {

        const control: AbstractControl = this.form.get('subscription');

        control.patchValue(null);

        control.clearValidators();

        const validators: ValidatorFn[] = [];

        if(this.form.get('subscriptionFree').value || this.form.get('subscriptionPriceDiscount').value){

            validators.push(Validators.required);

            control.enable();
        }
        else{

            control.disable();
        }

        control.setValidators(validators);

        control.updateValueAndValidity();
    }

    private _handleSubscriptionDurationDiscount(): void {

        const control: AbstractControl = this.form.get('subscriptionDurationDiscount');

        if(this.form.get('subscriptionFree').value || this.form.get('subscriptionPriceDiscount').value){

            control.patchValue(this.durationOptions[0].id);

            control.enable();
        }
        else{

            control.patchValue(null);

            control.disable();
        }

        control.updateValueAndValidity();
    }

    private _buildForm(): void {

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'societies',
                attrs: {
                    label: this._translateService.instant('promotionSubscription.societies.value'),
                    choices: this.societies,
                    multiple: false,
                }
            }
        });
    }

    public updateValidatorPromo(): void {

        this.form.get('admissionPriceDiscount').updateValueAndValidity();

        this.form.get('subscriptionPriceDiscount').updateValueAndValidity();
    }

    public validateAdmissionPriceTypeDiscount(): void {

        this.form.get('admissionPriceTypeDiscount').updateValueAndValidity();

        this.form.get('admissionPriceDiscount').updateValueAndValidity();

        this.form.get('subscriptionPriceDiscount').updateValueAndValidity();
    }

    public validateSubscriptionPriceTypeDiscount(): void {

        this.form.get('subscriptionPriceTypeDiscount').updateValueAndValidity();

        this.form.get('admissionPriceDiscount').updateValueAndValidity();

        this.form.get('subscriptionPriceDiscount').updateValueAndValidity();
    }

    public updateValidateUser(): void {

        this.form.get('society').updateValueAndValidity();

        this.customerForm.controls[0].get('firstName').updateValueAndValidity();

        this.customerForm.controls[0].get('lastName').updateValueAndValidity();

        this.customerForm.controls[0].get('email').updateValueAndValidity();

        this.customerForm.controls[0].get('code').updateValueAndValidity();

        this.customerForm.controls[0].get('societyName').updateValueAndValidity();
    }

    public initDuration(periodicity): { id: number, name: string }[] {

        return [
            {
                id: 1,
                name: '1 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.value'))
            },
            {
                id: 2,
                name: '2 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 3,
                name: '3 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 4,
                name: '4 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))

            },
            {
                id: 5,
                name: '5 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 6,
                name: '6 ' + ((periodicity== "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 7,
                name: '7 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 8,
                name: '8 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 9,
                name: '9 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 10,
                name: '10 ' +((periodicity== "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 11,
                name: '11 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: 12,
                name: '12 ' + ((periodicity == "M") ? this._translateService.instant('month.value') : this._translateService.instant('year.multiple.value'))
            },
            {
                id: null,
                name: this._translateService.instant('promotion.discount.duration.infinity.value')
            }
        ];
    }

    public initForm(): void {

        this.formService.form = this._formBuilder.group({
            name: ['', Validators.required],
            society: [''],
            admissionPriceFree: [false],
            admissionPriceDiscount: [''],
            admissionPriceTypeDiscount: [''],
            periodicity: [null],
            subscriptionFree: [false],
            subscriptionPriceDiscount: ['',  Validators.required],
            subscriptionPriceTypeDiscount: ['',  Validators.required],
            subscriptionDurationDiscount: [null],
            typeUser: ['', Validators.required],
            customer: new UntypedFormArray([]),
            subscription: [],
        });

        this.customerForm.push(this._formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: ['', [Validators.pattern(REGEX_EMAIL)]],
            code: [''],
            societyName: [''],
            dateStart: [null, [(control: UntypedFormControl) => {

                if(!this.customerForm || !this.customerForm.controls.length){

                    return null;
                }

                if(this.form.get('typeUser').value === 'new' && !control.value){

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                const dateStart: Moment = this.customerForm.controls[0].get('dateStart').value as Moment;

                if(!dateStart){

                    return null;
                }

                const dateEnd: Moment = this.customerForm.controls[0].get('dateEnd').value ? this.customerForm.controls[0].get('dateEnd').value as Moment : null;

                if(dateEnd && dateEnd.isBefore(dateStart)) {

                    return {
                        'startAfter': {
                            valid: false
                        }
                    };
                }

                return null;
            }]],
            dateEnd: [null, [(control: UntypedFormControl) => {

                if(!this.customerForm || !this.customerForm.controls.length){

                    return null;
                }

                if(this.form.get('typeUser').value === 'new' && !control.value){

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }

                const dateEnd: Moment = this.customerForm.controls[0].get('dateEnd').value as Moment;

                if(!dateEnd){

                    return null;
                }

                const dateStart: Moment = this.customerForm.controls[0].get('dateStart').value ? this.customerForm.controls[0].get('dateStart').value as Moment : null;

                if(dateStart && dateStart.isAfter(dateEnd)) {

                    return {
                        'startBefore': {
                            valid: false
                        }
                    };
                }

                return null;
            }]],
            locale: [this.localeId, Validators.required]
        }));

        this.form.controls["admissionPriceDiscount"].setValidators([(control: UntypedFormControl) => {
            return (
                !this.form.get('admissionPriceFree').value &&
                !this.form.get('subscriptionFree').value &&
                !this.form.get('admissionPriceDiscount').value &&
                !this.form.get('subscriptionPriceDiscount').value)

                ? {needPromo: {valid: false}} : null;
        }]);

        this.form.controls["admissionPriceTypeDiscount"].setValidators([(control: UntypedFormControl) => {
            return (
                this.form.get('admissionPriceDiscount').value &&
                !this.form.get('admissionPriceTypeDiscount').value)

                ? {required: {valid: false}} : null;
        }]);

        this.form.controls["subscriptionPriceDiscount"].setValidators([(control: UntypedFormControl) => {
            return (
                !this.form.get('admissionPriceFree').value &&
                !this.form.get('subscriptionFree').value &&
                !this.form.get('admissionPriceDiscount').value &&
                !this.form.get('subscriptionPriceDiscount').value)

                ? {needPromo: {valid: false}} : null;
        }]);

        this.form.controls["subscriptionPriceTypeDiscount"].setValidators([(control: UntypedFormControl) => {
            return (
                this.form.get('subscriptionPriceDiscount').value &&
                !this.form.get('subscriptionPriceTypeDiscount').value)

                ? {required: {valid: false}} : null;
        }]);

        this.form.controls["society"].setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'society' && !this.form.get('society').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("firstName").setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('firstName').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("lastName").setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('lastName').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("email").setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('email').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("code").setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('code').value) ? {required: {valid: false}} : null;
        }]);

        this.customerForm.controls[0].get("societyName").setValidators([(control: UntypedFormControl) => {
            return (this.form.get('typeUser').value == 'new' && !this.customerForm.controls[0].get('societyName').value) ? {required: {valid: false}} : null;
        }]);

        this._buildForm();

        this._handlePeriodicity();

        this._handleSubscription();

        this._handleSubscriptionDurationDiscount();

        this._initEvents();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign({...this.form.value}, {
                subscription: (this.form.get('subscription') && this.form.get('subscription').value) ? { id: parseInt(this.form.get('subscription').value ) } : null
            });

            if(!data['subscriptionFree'] && !data['subscriptionPriceDiscount'].trim()) {

                data['subscription'] = null;
                data['periodicity'] = null;
            }

            if(data['admissionPriceDiscount']){
                if(data['admissionPriceTypeDiscount'] === 'percent'){
                    data['admissionPriceDiscount'] = data['admissionPriceDiscount']/100;
                }

                if(data['admissionPriceTypeDiscount'] === 'amount'){
                    data['admissionPriceDiscount'] = data['admissionPriceDiscount']*100;
                }
            }

            if(data['subscriptionPriceDiscount']){
                if(data['subscriptionPriceTypeDiscount'] === 'percent'){
                    data['subscriptionPriceDiscount'] = data['subscriptionPriceDiscount']/100;
                }

                if(data['subscriptionPriceTypeDiscount'] === 'amount'){
                    data['subscriptionPriceDiscount'] = data['subscriptionPriceDiscount']*100;
                }
            }

            if(data['admissionPriceFree']){
                data['admissionPriceDiscount'] = null;
                data['admissionPriceTypeDiscount'] = null;
            }
            if(data['subscriptionFree']){
                data['subscriptionPriceDiscount'] = null;
                data['subscriptionPriceTypeDiscount'] = null;
            }

            if(!data['subscriptionPriceDiscount']){
                data['subscriptionPriceDiscount'] = null;
                data['subscriptionPriceTypeDiscount'] = null;
            }

            if(!data['admissionPriceDiscount']){
                data['admissionPriceDiscount'] = null;
                data['admissionPriceTypeDiscount'] = null;
            }

            data['customer'] = data['customer'][0];
            data['customer']['dateStart'] = data['customer']['dateStart'] ? (data['customer']['dateStart'] as Moment).format(DATE_FORMAT) : null;
            data['customer']['dateEnd'] = data['customer']['dateEnd'] ? (data['customer']['dateEnd'] as Moment).format(DATE_FORMAT) : null;

            if( data['typeUser'] == 'society'){
                data['customer'] = null;
            }

            if( data['typeUser'] == 'new'){
                data['society'] = null;
            }

            if(!('periodicity' in data)){

                data['periodicity'] = null;
            }

            this.promotionSubscriptionService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('promotionSubscription.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    public resetDuration(periodicity): void {

        this.durationOptions = this.initDuration(periodicity);
    }

    public redirectToList(): void {

        this._router.navigate(['/account/subscription/promotion/list']);
    }

    public changeSocieties(event): void {

        this.form.get('society').patchValue({id : event.value});
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get customerForm(): UntypedFormArray {

        if(!this.form){

            return null;
        }

        return this.form.get('customer') as UntypedFormArray;
    }
}
