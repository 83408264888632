<div [formGroup]="form" class="form-group">

    <button *ngIf="config.enableReset" (click)="reset()" class="btn_reset" type="button" mat-icon-button matTooltip="{{ 'form.field.action.reset' | translate }}" matTooltipPosition="below">
        <mat-icon>loop</mat-icon>
    </button>

    <mat-form-field>
        <mat-label *ngIf="config.attrs.placeholder">{{config.attrs.placeholder}}</mat-label>

        <input readonly [matDatepickerFilter]="filterDate" matInput appInput [attrs]="config.attrs" (click)="picker.open()" [formControlName]="config.id" [matDatepicker]="picker" placeholder="{{config.attrs.placeholder}}" (dateChange)="dateChange($event);">
        <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-error>
        <div *ngIf="form.controls[config.id].invalid && (form.controls[config.id].dirty || form.controls[config.id].touched)">
            <div *ngIf="form.controls[config.id].errors.required">{{ 'form.validation.error.required' | translate }}</div>
        </div>
    </mat-error>
</div>
