import {Directive, Input, OnInit, Self} from '@angular/core';
import {MatSelect} from "@angular/material/select";

/**
 * Gestion de l'affichage des attribut des champs select
 */
@Directive({
    selector: '[selectInput]'
})
export class SelectDirective implements OnInit {

    /**
     * Attributs
     */
    @Input() attrs: object;

    /**
     * Constructeur
     *
     * @constructor
     *
     */
    constructor(@Self() private _input: MatSelect) {
    }

    /**
     * Traitement des options
     */
    ngOnInit() {

        for (const [key, value] of Object.entries(this.attrs)) {
            if (key != 'choices' && key != 'label') {
                this._input[key] = value;
            }
        }
    }
}
